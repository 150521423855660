import React, { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import { Button, Grid, Tooltip, Paper } from '@mui/material';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import parse from 'html-react-parser';
import { KeycloakTokenParsed } from 'keycloak-js';

import { ErrorAlert, SuccessAlert } from './Alerts';
import keycloak, { getNetworkApi } from './keycloak';
import { OutsideInput } from '../generated/models';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { formatDate, viewportHeight } from '../components/format';

import TabPanel from './TabPanel';
import MaterialTable, { Column } from '@material-table/core';
import ApproveOutsideInputForm from './ApproveOutsideInputForm';
import grey from '@mui/material/colors/grey';

export default function RetrieveEmailsPanel({ value, index }) {
	type ParsedToken = KeycloakTokenParsed & {
		email?: string;
		preferred_username?: string;
		given_name?: string;
		family_name?: string;
		tenantIdentifier?: string;
	};

	const parsedToken: ParsedToken | undefined = keycloak?.idTokenParsed;

	let tenantIdentifier = parsedToken.tenantIdentifier;

	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [unreadEmailList, setUnreadEmailList] = useState([] as OutsideInput[]);
	const [materialTableLocalization, setMaterialTableLocalization] = useState({
		body: {
			emptyDataSourceMessage: 'Emails werden abgerufen ...',
		},
	});
	const [hasUnreadEmails, setHasUnreadEmails] = useState(true);
	const [showApproveOutsideInputForm, setShowApproveOutsideInputForm] =
		useState(false);
	const [currentOutsideInputData, setCurrentOutsideInputData] = useState(
		undefined as OutsideInput
	);

	useEffect(() => {
		const loadEmailList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUnreadEmailsList(tenantIdentifier);
				setUnreadEmailList(result);
				if (!(result?.length > 0)) {
					setHasUnreadEmails(false);
				}
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the email data!');
						setShowErrorAlert(true);
						setAlertText('Emails konnten nicht abgerufen werden');
					}
				} else {
					console.log('There was an error fetching the email data!');
					setShowErrorAlert(true);
					setAlertText('Emails konnten nicht abgerufen werden');
				}
			}
		};
		trackPromise(loadEmailList());
	}, [showSuccessAlert]);

	const handleApproveOutsideInput = (outsideInput: OutsideInput) => {
		setShowApproveOutsideInputForm(true);
		setCurrentOutsideInputData(outsideInput);
	};

	useEffect(() => {
		if (!hasUnreadEmails) {
			setMaterialTableLocalization({
				body: {
					emptyDataSourceMessage: 'Keine ungelesenen Emails vorhanden.',
				},
			});
		}
	}, [hasUnreadEmails]);

	const columns: Column<any>[] = [
		{
			title: 'An',
			field: 'inputJson',
			sorting: false,
			filtering: false,
			render: useCallback(
				(outsideInput: OutsideInput) => outsideInput.inputJson?.to,
				[]
			),
		},
		{
			title: 'Von',
			field: 'inputJson',
			sorting: false,
			filtering: false,
			render: useCallback(
				(outsideInput: OutsideInput) => outsideInput.inputJson?.from,
				[]
			),
		},
		{
			title: 'Betreff',
			field: 'inputJson',
			sorting: false,
			filtering: false,
			render: useCallback(
				(outsideInput: OutsideInput) => outsideInput.inputJson?.subject,
				[]
			),
		},
		{
			title: 'Inhalt',
			field: 'inputJson',
			sorting: false,
			filtering: false,
			render: useCallback(
				(outsideInput: OutsideInput) =>
					parse(
						outsideInput?.inputJson !== undefined
							? outsideInput.inputJson.body
							: ''
					),
				[]
			),
		},
		{
			title: 'Datum',
			field: 'techValidFrom',
			sorting: false,
			filtering: false,
			render: useCallback(
				(outsideInput: OutsideInput) => formatDate(outsideInput.techValidFrom),
				[]
			),
		},
		{
			width: 'min-content',
			sorting: false,
			render: useCallback(
				(outsideInput: OutsideInput) => {
					return (
						<Tooltip title='Gruß verarbeiten'>
							<Button onClick={() => handleApproveOutsideInput(outsideInput)}>
								{<MarkEmailReadIcon sx={{ color: 'primary' }} />}
							</Button>
						</Tooltip>
					);
				},
				[handleApproveOutsideInput]
			),
		},
	];

	return (
		<React.Fragment>
			<TabPanel value={value} index={index}>
				<Grid item xs={12} sx={{ mx: 5 }}>
					<MaterialTable
						localization={materialTableLocalization}
						style={{ borderRadius: '30px', color: grey[500] }}
						icons={MaterialTableIcons()}
						columns={columns}
						data={unreadEmailList}
						components={{
							Container: (props) => <Paper {...props} elevation={3} />,
						}}
						options={{
							paging: false,
							showTitle: false,
							sorting: true,
							filtering: true,
							search: false,
							rowStyle: { fontSize: 16 },
							headerStyle: {
								position: 'sticky',
								top: 0,
								backgroundColor: 'white',
							},
							maxBodyHeight: viewportHeight(80),
						}}
					/>
				</Grid>
			</TabPanel>
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
			{showApproveOutsideInputForm && (
				<ApproveOutsideInputForm
					open={showApproveOutsideInputForm}
					setOpen={setShowApproveOutsideInputForm}
					outsideInput={currentOutsideInputData}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
