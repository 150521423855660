import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import VideoThumbnail from 'react-video-thumbnail';

import { Close, Save } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';

import {
	Residence,
	ResidenceMediaData,
	ResidenceMediaMetaUpdateParameters,
} from '../generated';
import { getNetworkApi } from './keycloak';
import {
	EventIdData,
	getMediaFileType,
} from './format';
import FileUploader from './FileUploader';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface ResidenceMediaFileFormProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isNewMediaFile: boolean;
	currentResidence: Residence;
	currentMediaFileMetaData: ResidenceMediaData;
	setMediaId?: React.Dispatch<React.SetStateAction<number>>;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ResidenceMediaFileForm({
	open,
	setOpen,
	isNewMediaFile,
	currentResidence,
	currentMediaFileMetaData,
	setMediaId,
	setAlertText,
	setShowSuccessAlert,
	setShowErrorAlert,
}: ResidenceMediaFileFormProps) {
	const [residenceMediaFileMetadata, setResidenceMediaFileMetadata] = useState(
		currentMediaFileMetaData as ResidenceMediaData
	);
	const [selectedFile, setSelectedFile] = useState({} as File);
	const [selectedFileName, setSelectedFileName] = useState('');
	const [isFilePicked, setIsFilePicked] = useState(!isNewMediaFile);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setResidenceMediaFileMetadata({
			...residenceMediaFileMetadata,
			[name]: value,
		});
	};

	const handleSelectedFile = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		setSelectedFileName(file?.name);
		setIsFilePicked(true);
	};

	// The save-method contains a workaround:
	// the post request does not have the mediaCategories (due to problems with multipart/form-data & arrays of objects),
	// hence we submit an additional update request after the post
	const saveMediaFile = async () => {
		setOpen(false);
		const client = getNetworkApi();

		let residenceMediaId: number = undefined;
		const comment =
			residenceMediaFileMetadata?.comment !== ''
				? residenceMediaFileMetadata?.comment
				: undefined;
		try {
			residenceMediaId = await client.postResidenceMedia(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				selectedFile,
				selectedFile.name,
				comment
			);
		} catch (error) {
			setAlertText('Datei wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
		let residenceMediaMetaUpdateParameters: ResidenceMediaMetaUpdateParameters = {
			comment: comment,
		};
		try {
			await client.putResidenceMedia(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				residenceMediaId,
				residenceMediaMetaUpdateParameters
			);
			setAlertText('Datei wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Datei wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
		setMediaId && setMediaId(residenceMediaId);
	};

	const updateMediaMetaData = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceMediaMetaUpdateParameters: ResidenceMediaMetaUpdateParameters = {
			comment:
				residenceMediaFileMetadata.comment !== ''
					? residenceMediaFileMetadata.comment
					: undefined,
		};

		try {
			await client.putResidenceMedia(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				residenceMediaFileMetadata.residenceMediaId,
				residenceMediaMetaUpdateParameters
			);
			setAlertText('Informationen zur Datei wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Informationen zur Datei wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const makeDisabledTextField = (
		label: string,
		name: string,
		value: string
	) => {
		return (
			<TextField
				label={label}
				name={name}
				id='outlined-size-normal'
				value={value}
				disabled
			/>
		);
	};

	const displayPreview = () => {
		const mediaFileType = getMediaFileType(selectedFileName);
		if (isFilePicked && mediaFileType === 'img') {
			return (
				<Box
					component={mediaFileType}
					sx={{
						maxHeight: { xs: 150, md: 150 },
						maxWidth: { xs: 150, md: 150 },
					}}
					alt=''
					src={URL.createObjectURL(selectedFile)}
				/>
			);
		} else if (isFilePicked && mediaFileType === 'video') {
			return (
				<Box
					sx={{
						maxHeight: { xs: 100, md: 100 },
						maxWidth: { xs: 150, md: 150 },
					}}>
					<VideoThumbnail
						videoUrl={URL.createObjectURL(selectedFile)}
						maxHeight
						width={150}
						height={100}
					/>
				</Box>
			);
		} else {
			return null;
		}
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth={'md'}>
				<DialogTitle>
					{isNewMediaFile ? 'Datei hochladen' : 'Datei bearbeiten'}
				</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={2.5}>
								{isNewMediaFile && (
									<React.Fragment>{displayPreview()}</React.Fragment>
								)}
								
							</Grid>
							<Grid item xs={4} sx={{ marginLeft: 2 }}>
								{isNewMediaFile && (
									<React.Fragment>
										<FileUploader onChange={handleSelectedFile} />
									</React.Fragment>
								)}
								<FormControl sx={{ marginTop: 3.5 }}>
									{makeDisabledTextField(
										'Name',
										'fileName',
										isNewMediaFile
											? selectedFileName
											: residenceMediaFileMetadata.fileName
									)}
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									multiline
									rows={2}
									label='Kommentar'
									name='comment'
									id='outlined-size-normal'
									value={residenceMediaFileMetadata?.comment}
									onChange={handleInputChange}
									inputProps={{ maxLength: 40 }}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} Abbrechen
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant='contained'
											disabled={!isFilePicked}
											onClick={
												isNewMediaFile ? saveMediaFile : updateMediaMetaData
											}>
											{<Save />} Speichern
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
