/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidentMarkedForDeletion
 */
export interface ResidentMarkedForDeletion {
    /**
     * 
     * @type {number}
     * @memberof ResidentMarkedForDeletion
     */
    residentId?: number;
    /**
     * 
     * @type {number}
     * @memberof ResidentMarkedForDeletion
     */
    residentDeleteReservationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResidentMarkedForDeletion
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMarkedForDeletion
     */
    residenceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMarkedForDeletion
     */
    unitIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMarkedForDeletion
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMarkedForDeletion
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMarkedForDeletion
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMarkedForDeletion
     */
    associatedCaregiver?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMarkedForDeletion
     */
    deletionReservationComment?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMarkedForDeletion
     */
    deletionReservationUser?: string;
}

export function ResidentMarkedForDeletionFromJSON(json: any): ResidentMarkedForDeletion {
    return ResidentMarkedForDeletionFromJSONTyped(json, false);
}

export function ResidentMarkedForDeletionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentMarkedForDeletion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
        'residentDeleteReservationId': !exists(json, 'residentDeleteReservationId') ? undefined : json['residentDeleteReservationId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'residenceIdentifier': !exists(json, 'residenceIdentifier') ? undefined : json['residenceIdentifier'],
        'unitIdentifier': !exists(json, 'unitIdentifier') ? undefined : json['unitIdentifier'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'room': !exists(json, 'room') ? undefined : json['room'],
        'associatedCaregiver': !exists(json, 'associatedCaregiver') ? undefined : json['associatedCaregiver'],
        'deletionReservationComment': !exists(json, 'deletionReservationComment') ? undefined : json['deletionReservationComment'],
        'deletionReservationUser': !exists(json, 'deletionReservationUser') ? undefined : json['deletionReservationUser'],
    };
}

export function ResidentMarkedForDeletionToJSON(value?: ResidentMarkedForDeletion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'residentId': value.residentId,
        'residentDeleteReservationId': value.residentDeleteReservationId,
        'tenantIdentifier': value.tenantIdentifier,
        'residenceIdentifier': value.residenceIdentifier,
        'unitIdentifier': value.unitIdentifier,
        'firstName': value.firstName,
        'surname': value.surname,
        'room': value.room,
        'associatedCaregiver': value.associatedCaregiver,
        'deletionReservationComment': value.deletionReservationComment,
        'deletionReservationUser': value.deletionReservationUser,
    };
}


