import MaterialTable, { Column } from '@material-table/core';
import { Close, Extension, Face, Save } from '@mui/icons-material';
import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Paper,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useLocation, useHistory } from 'react-router-dom';
import {
	Residence,
	ResidenceEvent,
	ResidenceEventInsertParameters,
	ResidenceEventMediaInsertParameters,
	ResidenceEventMediaUpdateParameters,
	ResidenceEventResidentsUpdateParameters,
	ResidenceEventUpdateParameters,
	ResidenceMediaData,
	Resident,
	Unit,
} from '../generated';
import { ResidenceEventResidentsInsertParameters } from '../generated/models/ResidenceEventResidentsInsertParameters';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { EventIdData, formatDate, isIdTicked, ResidentIdData } from './format';
import { getNetworkApi } from './keycloak';

interface EventToResidentLinkFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	currentResidence: Residence;
	currentEvent: ResidenceEvent;
	eventId: number;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EventToResidentLinkForm(
	prop: EventToResidentLinkFormProp
) {
	const {
		open,
		setOpen,
		currentResidence,
		currentEvent,
		eventId,
		setAlertText,
		setShowErrorAlert,
		setShowSuccessAlert,
	} = prop;

	const [residentList, setResidentList] = useState([] as Resident[]);
	const currentSavedSelection: number[] = getCurrentSavedSelection();
	const [selectedResidentIds, setSelectedResidentIds] = useState(
		currentSavedSelection
	);
	const [currentUnit, setCurrentUnit] = useState(undefined as Unit);

	function getCurrentSavedSelection(): number[] {
		let currentSelection: number[] = [];
		currentEvent.associatedResidents?.forEach((elem) => {
			currentSelection.push(elem.residentId);
		});
		return currentSelection;
	}

	const isCurrentSavedSelectionEmpty = (): boolean => {
		if (currentSavedSelection.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSelectedResidentIds = (e) => {
		if (Object.entries(selectedResidentIds).length !== 0) {
			if (e.target.checked) {
				let newResidentIds = selectedResidentIds.slice();
				newResidentIds.push(Number(e.target.value));
				setSelectedResidentIds(newResidentIds);
			} else {
				setSelectedResidentIds(
					selectedResidentIds.filter((item) => item !== Number(e.target.value))
				);
			}
		} else {
			setSelectedResidentIds([Number(e.target.value)]);
		}
	};

	const handleSaveOrUpdate = () => {
		if (isCurrentSavedSelectionEmpty()) {
			saveResidentToEventLink();
		} else {
			updateResidentToEventLinks();
		}
	};

	const [unitList, setUnitList] = useState([] as Unit[]);
	const [refresh, setRefresh] = useState(0);
	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	let query = useQuery();

	const [unitIndex, setUnitIndex] = useState(
		query.get('unitId') ? query.get('unitId') : 0
	);

	useEffect(() => {
		const loadResidentData = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidenceResidentList(
					currentResidence.tenantIdentifier,
					currentResidence.residenceIdentifier
				);
				setResidentList(
					result.sort((a: Resident, b: Resident) =>
						a.surname.localeCompare(b.surname)
					)
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Bewohner konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Bewohner konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadResidentData());
	}, [unitIndex, unitList, refresh]);

	const saveResidentToEventLink = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceEventResidentsInsertParameters: ResidenceEventResidentsInsertParameters =
			{
				residentIds: selectedResidentIds.slice(),
			};
		try {
			await client.postResidenceEventResidents(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				eventId,
				residenceEventResidentsInsertParameters
			);
			setAlertText('Verknüpfung zu Bewohner wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Bewohner wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateResidentToEventLinks = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceEventResidentsUpdateParameters: ResidenceEventResidentsUpdateParameters =
			{
				residentIds: selectedResidentIds.slice(),
			};

		try {
			await client.putResidenceEventResidents(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				eventId,
				residenceEventResidentsUpdateParameters
			);
			setAlertText('Verknüpfung zu Bewohner wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Bewohner wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	let history = useHistory();

	const forwardToResident = useCallback(
		(resident: Resident) => {
			history.push({
				pathname:
					'/tenantId=' +
					resident.tenantIdentifier +
					',residenceId=' +
					resident.residenceIdentifier +
					',unitId=' +
					resident.unitIdentifier +
					'/profile/' +
					'residentId=' +
					resident.residentId,
			});
		},
		[history]
	);

	const columns: Column<Resident>[] = [
		{
			title: 'Name',
			field: 'surname',
			defaultSort: 'asc',
			render: useCallback(
				(data: Resident) => {
					return (
						<FormControlLabel
							key={data.residentId}
							value={data.residentId}
							checked={isIdTicked(selectedResidentIds, data.residentId)}
							control={<Checkbox />}
							label={data.surname + ', ' + data.firstName}
							onChange={(e) => handleSelectedResidentIds(e)}
						/>
					);
				},
				[selectedResidentIds]
			),
		},
		{
			width: 'min-content',
			sorting: false,
			render: useCallback(
				(data) => {
					return (
						<Tooltip title='Bewohner:in anzeigen'>
							<Button onClick={() => forwardToResident(data)}>
								{<Face sx={{ color: 'green', fontSize: '30px' }} />}
							</Button>
						</Tooltip>
					);
				},
				[forwardToResident]
			),
		},
		{
			title: 'Raum',
			field: 'room',
			filtering: true,
			hidden: window.innerHeight > window.innerWidth,
			render: useCallback((resident: Resident) => resident?.room, []),
		},
		{
			title: 'Wohnbereich',
			field: 'unit',
			filtering: true,
			hidden: window.innerHeight > window.innerWidth,
			render: useCallback((resident: Resident) => resident.unitIdentifier, []),
		},
	];

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth='md'>
				<DialogTitle>Bewohner mit Event verbinden:</DialogTitle>
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<MaterialTable
									localization={{
										body: {
											emptyDataSourceMessage: 'Noch keine Bewohner verknüpft.',
										},
									}}
									icons={MaterialTableIcons()}
									columns={columns}
									data={residentList}
									components={{
										Container: (props) => <Paper {...props} elevation={0} />,
									}}
									options={{
										paging: false,
										showTitle: false,
										sorting: true,
										filtering: true,
										search: false,
										rowStyle: { fontSize: 16 },
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} Abbrechen
										</Button>
									</Grid>
									{residentList.length !== 0 && (
										<Grid item>
											<Button variant='contained' onClick={handleSaveOrUpdate}>
												{<Save />} Speichern
											</Button>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
