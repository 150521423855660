import { Close, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
} from '@mui/material';
import React from 'react';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { NoteStatus } from '../generated/models';
import { getNetworkApi } from './keycloak';

export default function NoteStatusForm({
	open,
	setOpen,
	residentIdData,
	CurrentMemoryData,
	setAlertText,
	SetShowSuccessAlert,
	SetShowErrorAlert,
}) {
	const [MemoryStatusList, setMemoryStatusList] = useState([] as NoteStatus[]);
	const [SelectedMemoryStatusId, setSelectedMemoryStatusId] = useState(
		1 as number
	);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		setSelectedMemoryStatusId(Number(e.target.value));
	};

	useEffect(() => {
		const loadMemoryStatus = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getNoteStatuses(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier
				);
				setMemoryStatusList(result);
				setSelectedMemoryStatusId(
					result.filter(
						(name) => name.noteStatusName === CurrentMemoryData.noteStatusName
					)[0].noteStatusId
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the memory status data!');
					}
				} else {
					console.log('There was an error fetching the  memory status data!');
				}
			}
		};
		trackPromise(loadMemoryStatus());
	}, [CurrentMemoryData.noteStatusName, residentIdData]);

	const updateMemoryStatus = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.putStatusForNote(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				CurrentMemoryData.noteId,
				SelectedMemoryStatusId
			);
			setAlertText('Status wurde erfolgreich geändert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Status wurde nicht geändert');
			SetShowErrorAlert(true);
		}
	};

	return (
		<React.Fragment>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{'Status ändern'}</DialogTitle>
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<RadioGroup
									row={false}
									name='memorystatus'
									value={SelectedMemoryStatusId}
									onChange={handleInputChange}>
									{MemoryStatusList.map((item, index) => (
										<FormControlLabel
											key={item.noteStatusId}
											value={item.noteStatusId}
											control={<Radio />}
											label={item.noteStatusName}
										/>
									))}
								</RadioGroup>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} Abbrechen
										</Button>
									</Grid>
									<Grid item>
										<Button variant='contained' onClick={updateMemoryStatus}>
											{<Save />} Speichern
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
