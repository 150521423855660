import {
	Edit,
	Delete,
	ControlPoint,
	Event,
	ChevronLeft,
	ChevronRight,
	AutoStories,
	AddAPhoto,
} from '@mui/icons-material';
import {
	Button,
	Card,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Paper,
	Radio,
	RadioGroup,
	styled,
	Tooltip,
	Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import { ErrorAlert, SuccessAlert } from '../components/Alerts';
import {
	determineStatusChipColor,
	determineTagIconInLineSize,
	formatDate,
	isAuthorizedForRoleTenant,
	noteCategoryItems,
	ResidentIdData,
	returnTagChips,
	TCCStyledBadgeButton,
} from '../components/format';
import { getNetworkApi } from '../components/keycloak';
import NoteForm from '../components/NoteForm';
import NoteStatusForm from '../components/NoteStatusForm';
import NoteCommentForm from '../components/NoteCommentForm';
import { BaseRole } from '../components/Roles';
import {
	ConnectType,
	DeactivateNoteCommentRequest,
	NoteComment,
	NoteMediaInsertParameters,
	NoteMediaUpdateParameters,
	PutNoteCommentRequest,
	MediaCategory,
	ResidentMediaData,
	Tag
} from '../generated';
import { Note } from '../generated/models/Note';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Confirm from '../components/Confirm';
import MaterialTable, { Column, MTableBodyRow } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import DisplayContent from '../components/DisplayContent';
import DisplayMedia from '../components/DisplayMedia';
import MediaFileForm from '../components/MediaFileForm';
import NoteOrGreetingToMediaLinkForm from '../components/NoteOrGreetingToMediaLinkForm';
import ConnectionForm from '../components/ConnectionForm';

const commonStyles = {
	borderColor: 'grey',
	borderStyle: 'inherit',
};

	const defaultMediaFileData: ResidentMediaData = {
		mediaCategories: [] as MediaCategory[],
		tags: {} as Tag[],
};
	
export default function NotePage(props) {
	const residentIdData: ResidentIdData = {
		tenantIdentifier: props.match.params.tenantId,
		residenceIdentifier: props.match.params.residenceId,
		unitIdentifier: props.match.params.unitId,
		residentId: Number(props.match.params.residentId),
	};

	const [userRoleList, setUserRoleList] = React.useState([]);

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const [currentNote, setCurrentNote] = useState(undefined as Note);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showConnectionForm, setShowConnectionForm] = useState(false);

	const noteId = Number(props.match.params.noteId);

	useEffect(() => {
		const loadNote = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getNote(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId,
					noteId
				);
				setCurrentNote(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Erinnerung konnte nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Erinnerung konnte nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadNote());
	}, [showSuccessAlert]);

	const history = useHistory();

	const isAuthorized: boolean = useMemo(() => {
		return isAuthorizedForRoleTenant(
			userRoleList,
			BaseRole.BEWOHNERVERWALTUNG,
			residentIdData.tenantIdentifier,
			residentIdData.residenceIdentifier,
			residentIdData.unitIdentifier
		);
	}, [
		userRoleList,
		residentIdData.residenceIdentifier,
		residentIdData.unitIdentifier,
	]);

	const [showConfirmCommentDeletion, setShowConfirmCommentDeletion] =
		useState(false);

	const [showMemoryForm, setShowMemoryForm] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [showConfirmMemoryDeletion, setShowConfirmMemoryDeletion] =
		useState(false);
	const [showAddMediaLinkForm, setShowAddMediaLinkForm] = useState(false);
	const [deleteText, setDeleteText] = useState('');
	const [showMemoryStatusForm, setShowMemoryStatusForm] = useState(false);

	const [selectedMediaFile, setSelectedMediaFile] = useState(new Blob());
	const [mediaIdList, setMediaIdList] = useState(
		[] as { mediaId: number; fileName: string; typeId: string }[]
	);

	const [mediaIdListIndex, setMediaIdListIndex] = useState(0 as number);
	const [mediaIdForLink, setMediaIdForLink] = useState(undefined as number);

	const [showMediaFile, setShowMediaFile] = useState(false);

	const [showEditCommentForm, setShowEditCommentForm] = useState(false);
	const [isNewComment, setIsNewComment] = useState(true);
	const [currentNoteComment, setCurrentNoteComment] = useState({
		tenantIdentifier: residentIdData.tenantIdentifier,
		residenceIdentifier: residentIdData.residenceIdentifier,
		unitIdentifier: residentIdData.unitIdentifier,
		noteId: undefined,
		noteCommentId: undefined,
		noteCommentUpdateParameters: { content: undefined },
	} as PutNoteCommentRequest);
	const [noteCommentDeletionData, setNoteCommentDeletionData] = useState({
		tenantIdentifier: residentIdData.tenantIdentifier,
		residenceIdentifier: residentIdData.residenceIdentifier,
		unitIdentifier: residentIdData.unitIdentifier,
		noteId: undefined,
		noteCommentId: undefined,
	} as DeactivateNoteCommentRequest);

	const [showMediaFileForm, setShowMediaFileForm] = useState(false);

	const deleteMemory = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateNote(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				noteId
			);
			history.replace({
				pathname:
					'/tenantId=' +
					residentIdData.tenantIdentifier +
					',residenceId=' +
					residentIdData.residenceIdentifier +
					',unitId=' +
					residentIdData.unitIdentifier +
					'/profile/' +
					'residentId=' +
					residentIdData.residentId +
					'/memories',
				state: {
					alertText: 'Erinnerung wurde erfolgreich archiviert',
					isError: false,
				},
			});
		} catch (error) {
			console.log('There was an error while deactivating!');
			history.push({
				pathname:
					'/tenantId=' +
					residentIdData.tenantIdentifier +
					',residenceId=' +
					residentIdData.residenceIdentifier +
					',unitId=' +
					residentIdData.unitIdentifier +
					'/profile/' +
					'residentId=' +
					residentIdData.residentId +
					'/memories',
				state: {
					alertText: 'Erinnerung konnte nicht archiviert werden',
					isError: true,
				},
			});
		}
	};

	const handleClickOpenConnectionForm = () => {
		setShowConnectionForm(true);
	};

	const deleteNoteComment = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateNoteComment(
				noteCommentDeletionData.tenantIdentifier,
				noteCommentDeletionData.residenceIdentifier,
				noteCommentDeletionData.unitIdentifier,
				noteCommentDeletionData.noteId,
				noteCommentDeletionData.noteCommentId
			);
			setShowSuccessAlert(true);
			setShowConfirmCommentDeletion(false);
			setAlertText('Kommentar wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Kommentar konnte nicht archiviert werden');
			console.log('There was an error while deactivating!');
		}
	};

	useEffect(() => {
		setMediaIdList([]);
		currentNote?.media.map((el) =>
			setMediaIdList((prevMediaIdList) => [
				...prevMediaIdList,
				{
					mediaId: el.mediaId,
					fileName: el.fileName,
					typeId: el.mediaTypeIdentifier,
				},
			])
		);
	}, [currentNote]);

	const handleClickRightArrow = () => {
		let i = mediaIdListIndex;
		setMediaIdListIndex((i + 1) % mediaIdList.length);
	};

	const handleClickLeftArrow = () => {
		let i = mediaIdListIndex;

		if (i === 0) {
			setMediaIdListIndex(mediaIdList.length - 1);
		} else {
			setMediaIdListIndex(i - 1);
		}
	};

	useEffect(() => {
		const loadSingleMediaFile = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getMedia(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					mediaIdList[mediaIdListIndex]?.mediaId
				);
				setSelectedMediaFile(result);
			} catch (error) {
				return null;
			}
		};
		trackPromise(loadSingleMediaFile());
	}, [mediaIdListIndex, mediaIdList]);

	const displayContent = () => {
		if (mediaIdList.length === 0) {
			return null;
		}
		if (mediaIdList[mediaIdListIndex]?.typeId.includes('pdf')) {
			return (
				<Tooltip
					title={`Zeige ${mediaIdList[mediaIdListIndex]?.fileName} an`}
					placement='top'>
					<span>
						<Button onClick={() => setShowMediaFile(true)}>
							<AutoStories />
						</Button>
					</span>
				</Tooltip>
			);
		} else {
			return (
				<DisplayContent
					mediaFile={selectedMediaFile}
					mediaType={mediaIdList[mediaIdListIndex]?.typeId}
					title={mediaIdList[mediaIdListIndex]?.fileName}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
				/>
			);
		}
	};

	const handleDeleteMemory = () => {
		setDeleteText('Wollen Sie die Erinnerung wirklich archivieren?');
		setShowConfirmMemoryDeletion(true);
	};

	const handleClickOpenMemoryForm = () => {
		setShowMemoryForm(true);
	};

	const handleClickOpenNoteStatusForm = () => {
		setShowMemoryStatusForm(true);
	};

	const handleAddLinkToMediaFile = () => {
		setShowAddMediaLinkForm(true);
	};

	const handleAddComment = useCallback(
		(NoteId: number) => {
			setIsNewComment(true);
			setShowEditCommentForm(true);
			setCurrentNoteComment({
				...currentNoteComment,
				noteId: NoteId,
				noteCommentId: undefined,
				noteCommentUpdateParameters: { content: undefined },
			});
		},
		[currentNoteComment]
	);

	const handleClickOpenMediaFileForm = () => {
		setShowMediaFileForm(true);
	};
	const handleEditComment = useCallback(
		(noteComment) => {
			setIsNewComment(false);
			setShowEditCommentForm(true);
			setCurrentNoteComment({
				...currentNoteComment,
				noteId: noteComment.noteId,
				noteCommentId: noteComment.noteCommentId,
				noteCommentUpdateParameters: { content: noteComment.content },
			});
		},
		[currentNoteComment]
	);

	const handleDeleteComment = useCallback(
		(noteComment) => {
			setShowConfirmCommentDeletion(true);
			setDeleteText('Wollen Sie den Kommentar wirklich archivieren?');
			setNoteCommentDeletionData({
				...noteCommentDeletionData,
				noteId: noteComment.noteId,
				noteCommentId: noteComment.noteCommentId,
			});
		},
		[noteCommentDeletionData]
	);

	const Item = styled(Paper)(({ theme }) => ({
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.primary,
		height: '100%',
	}));

	const columns: Column<NoteComment>[] = [
		{
			width: '10%',
		},
		{
			field: 'description',
			width: '90%',
			filtering: false,
			render: useCallback(
				(data: NoteComment) => (
					<Grid container>
						<Grid item xs={12}>
							<Typography variant='body2' fontSize={18} color={'primary'}>
								{data.content}
							</Typography>
						</Grid>
						<Grid item xs={12} marginTop={-2}>
							<Typography variant='button' fontSize={10} color={'primary'}>
								{'('}
								{data.modifyingUser}
								{', '}
								{formatDate(data.lastUpdatedDate)}
								{')'}
							</Typography>
						</Grid>
					</Grid>
				),
				[]
			),
			cellStyle: {
				maxWidth: '100%',
				whiteSpace: 'pre-line',
				textOverflow: 'ellipsis',
			},
		},
	];

	useEffect(() => {
		if (mediaIdForLink !== undefined) {
			currentNote?.media.length > 0
				? updateMediaToNoteLinks()
				: saveMediaToNoteLink();
		}
	}, [mediaIdForLink]);

	const saveMediaToNoteLink = async () => {
		let noteMediaInsertParameters: NoteMediaInsertParameters = {
			mediaIds: [mediaIdForLink],
		};
		const client = getNetworkApi();
		try {
			await client.postNoteMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				currentNote?.noteId,
				noteMediaInsertParameters
			);
			setAlertText('Verknüpfung zu Medien wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateMediaToNoteLinks = async () => {
		let mediaIds: number[] = [];
		currentNote?.media.forEach((elem) => {
			mediaIds.push(elem.mediaId);
		});
		mediaIds.push(mediaIdForLink);
		let noteMediaUpdateParameters: NoteMediaUpdateParameters = {
			mediaIds: mediaIds,
		};
		const client = getNetworkApi();
		try {
			await client.putNoteMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				currentNote?.noteId,
				noteMediaUpdateParameters
			);
			setAlertText('Verknüpfung zu Medien wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	return (
		<React.Fragment>
			<Grid
				container
				direction='row'
				justifyContent='space-between'
				spacing={5}
				sx={{ padding: 3 }}
				columns={{ xs: 4, md: 8, lg: 12, xl: 12 }}>
				<Grid item xs={4} md={8} lg={5} xl={5}>
					<Grid container direction='row' spacing={4}>
						<Grid item xs={12} md={12} lg={12} xl={12} height={'100%'}>
							<Item
								sx={{
									...commonStyles,
									height: '100%',
									borderRadius: 8,
									color: 'primary',
								}}
								elevation={3}>
								<Typography color={'primary'}>
									<Grid container spacing={3} direction='row'>
										<Grid item xs={3} sx={{ marginTop: 1 }}>
											<TCCStyledBadgeButton
												onClick={() => handleClickOpenConnectionForm()}
												badgeContent={currentNote?.connects.length}
											/>
										</Grid>
										<Grid item xs={6} sx={{ marginTop: 1 }}>
											<Typography
												fontSize={28}
												color={'primary'}
												variant={'subtitle2'}>
												{currentNote?.title}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											{(currentNote?.pointInTime ||
												currentNote?.stageOfLife) && (
												<Typography
													fontSize={18}
													color={'primary'}
													variant={'body2'}>
													<Event
														style={{
															fontSize: '24',
															color: 'primary',
															verticalAlign: 'middle',
														}}
													/>{' '}
													{currentNote?.pointInTime}
													{currentNote?.stageOfLife &&
														currentNote?.pointInTime &&
														', '}
													{currentNote?.stageOfLife}
												</Typography>
											)}
										</Grid>
										<Grid
											item
											xs={12}
											sx={{
												marginTop: -3,
												marginLeft: 'auto',
											}}>
											<Typography
												fontSize={10}
												color={'primary'}
												variant={'button'}
												align={'right'}>
												{'( erstellt von: '}
												{currentNote?.modifyingUser}
												{')'}
											</Typography>
										</Grid>
										<Grid item xs={12} sx={{}}>
											<Divider textAlign='left'>
												<Typography
													fontSize={16}
													color={'primary'}
													align={'right'}>
													{' '}
													Beschreibung:{' '}
												</Typography>
											</Divider>
											<Typography
												fontSize={18}
												color={'primary'}
												variant={'body2'}
												marginTop={2}
												marginBottom={2}
												align={'left'}
												style={{ wordWrap: 'break-word' }}>
												{currentNote?.content}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Divider textAlign='left'>
												<Typography
													fontSize={16}
													color={'primary'}
													align={'right'}>
													{' '}
													Kommentare:{' '}
												</Typography>
											</Divider>
											<MaterialTable
												localization={{
													body: {
														emptyDataSourceMessage:
															'Keine Kommentare hinterlegt.',
													},
												}}
												style={{
													maxHeight: 160,
													overflow: 'auto',
													width: '100%',
													borderBottomStyle: 'none',
												}}
												icons={MaterialTableIcons()}
												columns={columns}
												data={currentNote?.comments}
												components={{
													Container: (props) => (
														<Paper {...props} elevation={0} />
													),
													Row: (props) => (
														<MTableBodyRow
															key={props.noteCommentId}
															id={props.noteCommentId}
															{...props}
														/>
													),
												}}
												options={{
													paging: false,
													showTitle: false,
													sorting: false,
													filtering: false,
													toolbar: false,
													tableLayout: 'fixed',
													search: false,
													rowStyle: {
														height: 2,
														borderBottomStyle: 'none',
														borderTopStyle: 'none',
													},
												}}
												detailPanel={[
													{
														tooltip: 'Aktionen',
														render: (row) => {
															return (
																<div key={row.rowData.noteCommentId}>
																	<Tooltip title='Archiviere Kommentar'>
																		<Button
																			onClick={() =>
																				handleDeleteComment(row.rowData)
																			}>
																			{
																				<Delete
																					sx={{ marginLeft: 5 }}
																					color='primary'
																				/>
																			}
																		</Button>
																	</Tooltip>
																	<Tooltip title='Bearbeite Kommentar'>
																		<Button
																			onClick={() =>
																				handleEditComment(row.rowData)
																			}>
																			{<Edit color='primary' />}
																		</Button>
																	</Tooltip>
																</div>
															);
														},
													},
												]}
											/>
										</Grid>
										<Grid item xs={12}>
											<Tooltip title='Neuer Kommentar' placement='right-start'>
												<IconButton
													color='primary'
													component='span'
													onClick={() => handleAddComment(noteId)}>
													<ControlPoint />
												</IconButton>
											</Tooltip>
										</Grid>
									</Grid>
								</Typography>
							</Item>
						</Grid>
						<Grid
							container
							direction='row'
							justifyContent='space-between'
							spacing={5}
							sx={{ padding: 3 }}>
							<Grid item xs={12} md={6} lg={6} xl={6}>
								<Item
									sx={{
										borderRadius: 8,
										height: 'max-content',
										marginTop: '2%',
									}}
									elevation={3}>
									<Grid container spacing={1} direction='row'>
										<Grid item xs={12} sx={{ marginTop: -0.5 }}>
											<Typography
												fontSize={18}
												color={'primary'}
												variant={'subtitle2'}
												textAlign={'left'}
												marginLeft={1}>
												{'    Aktionen'}
											</Typography>
										</Grid>
										<Grid item xs={12} marginTop={-0.5}>
											<Tooltip
												title={isAuthorized ? 'Bearbeite Erinnerung' : ''}>
												<span>
													<Button
														disabled={!isAuthorized}
														onClick={() => handleClickOpenMemoryForm()}>
														<Edit
															color={isAuthorized ? 'primary' : 'secondary'}
														/>
													</Button>
												</span>
											</Tooltip>
											<Tooltip
												title={isAuthorized ? 'Archiviere Erinnerung' : ''}>
												<span>
													<Button
														disabled={!isAuthorized}
														onClick={() => handleDeleteMemory()}>
														<Delete
															color={isAuthorized ? 'primary' : 'secondary'}
														/>
													</Button>
												</span>
											</Tooltip>
										</Grid>
									</Grid>
								</Item>
								<Item
									sx={{
										borderRadius: 8,
										height: 'max-content',
										marginTop: '2%',
									}}
									elevation={3}>
									<Grid container spacing={1} direction='row'>
										<Grid item xs={12} sx={{ marginTop: -0.5 }}>
											<Typography
												fontSize={18}
												color={'primary'}
												variant={'subtitle2'}
												textAlign={'left'}
												marginLeft={1}>
												{'  Status'}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Tooltip
												title={isAuthorized ? 'Aktualisiere den Status' : ''}
												placement='top'>
												<span>
													<Button
														disabled={!isAuthorized}
														onClick={() => handleClickOpenNoteStatusForm()}
														sx={{
															color: 'white',
															fontSize: 'medium',
															backgroundColor: determineStatusChipColor(
																currentNote?.noteStatus
															),
															'&:hover': {
																backgroundColor: 'darkgrey',
															},
														}}>
														{currentNote?.noteStatusName}
														<Edit sx={{ color: 'white' }} />
													</Button>
												</span>
											</Tooltip>
										</Grid>
									</Grid>
								</Item>
								<Item
									sx={{
										borderRadius: 8,
										height: 'max-content',
										marginTop: '2%',
									}}
									elevation={3}>
									<Grid container spacing={1} direction='row'>
										<Grid item xs={12} sx={{ marginTop: -0.5 }}>
											<Typography
												fontSize={18}
												color={'primary'}
												variant={'subtitle2'}
												textAlign={'left'}
												marginLeft={1}>
												{'  Kategorie'}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											{
												noteCategoryItems.find(
													(el) => el.value === currentNote?.category
												)?.icon
											}
										</Grid>
										<Grid item xs={12} maxHeight={'max-content'}></Grid>
									</Grid>
								</Item>
							</Grid>
							<Grid item xs={12} md={6} lg={6} xl={6}>
								<Item
									sx={{
										borderRadius: 8,
										height: 'fit-content',
										marginTop: '2%',
									}}
									elevation={3}>
									<Grid container spacing={1} direction='row'>
										<Grid item xs={12} sx={{ marginTop: -0.5 }}>
											<Typography
												fontSize={18}
												color={'primary'}
												variant={'subtitle2'}
												textAlign={'left'}
												marginLeft={1}>
												{'SIS-Tags'}
											</Typography>
										</Grid>
										<Grid item xs={12} maxHeight={'max-content'}>
											{currentNote &&
												returnTagChips(currentNote?.tags, 'SIS', 200)}
										</Grid>
									</Grid>
								</Item>
								<Item
									sx={{
										borderRadius: 8,
										height: 'fit-content',
										marginTop: '2%',
									}}
									elevation={3}>
									<Grid container spacing={1} direction='row'>
										<Grid item xs={12} sx={{ marginTop: -0.5 }}>
											<Typography
												fontSize={18}
												color={'primary'}
												variant={'subtitle2'}
												textAlign={'left'}
												marginLeft={1}>
												{'Emotionen'}
											</Typography>
										</Grid>
										<Grid item xs={12} maxHeight={'max-content'}>
											{currentNote?.tags
												.filter((el) => el.tagGroupIdentifier === 'EMOTION')
												.map((el, index) =>
													determineTagIconInLineSize(el.tagAbbreviation, '64px')
												)}
										</Grid>
									</Grid>
								</Item>
								<Item
									sx={{
										borderRadius: 8,
										height: 'fit-content',
										marginTop: '2%',
									}}
									elevation={3}>
									<Grid container spacing={1} direction='row'>
										<Grid item xs={12} sx={{ marginTop: -0.5 }}>
											<Typography
												fontSize={18}
												color={'primary'}
												variant={'subtitle2'}
												textAlign={'left'}
												marginLeft={1}>
												{'Pflegerelevant?'}
											</Typography>
										</Grid>
										<Grid item xs={12} maxHeight={'max-content'}>
											<FormControl>
													<RadioGroup
														row
														aria-labelledby="demo-controlled-radio-buttons-group"
														name="controlled-radio-buttons-group"
														value={currentNote?.relevant}
													>
												<FormControlLabel value={true} disabled  control={<Radio />} label="Ja" />
												<FormControlLabel value={false} disabled control={<Radio />} label="Nein" />
											</RadioGroup>
											</FormControl>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={12} lg={7}>
					<Grid item xs={12}>
						<Item
							sx={{
								maxHeight: 'max-content',
								borderRadius: 8,
							}}
							elevation={3}>
							<Grid container spacing={1} direction='row'>
								<Grid item xs={12} sx={{ marginTop: -0.5 }}>
									<Typography
										fontSize={18}
										color={'primary'}
										variant={'subtitle2'}
										textAlign={'left'}
										marginLeft={1}>
										{'Medien'}
									</Typography>
								</Grid>
								<Grid
									item
									xs={12}
									marginTop={-3}
									maxHeight={'max-content'}
									maxWidth={'xl'}>
									<Tooltip title={'vorheriges Medium'} placement='top'>
										<span>
											<Button
												disabled={mediaIdList.length < 2}
												onClick={() => handleClickLeftArrow()}>
												<ChevronLeft />
											</Button>
										</span>
									</Tooltip>
									<Tooltip title='Medien verknüpfen' placement='right-start'>
										<IconButton
											color='primary'
											component='span'
											onClick={() => handleAddLinkToMediaFile()}>
											<AddLinkIcon />
										</IconButton>
									</Tooltip>
									<Tooltip
										title='Medien hochladen und verknüpfen'
										placement='right-start'>
										<IconButton
											color='primary'
											component='span'
											onClick={() => handleClickOpenMediaFileForm()}>
											<AddAPhoto />
										</IconButton>
									</Tooltip>
									<Tooltip title={'nächstes Medium'} placement='top'>
										<span>
											<Button
												disabled={mediaIdList.length < 2}
												onClick={() => handleClickRightArrow()}>
												<ChevronRight />
											</Button>
										</span>
									</Tooltip>
									<Card>{displayContent()}</Card>
								</Grid>
								<Grid item xs={12}></Grid>
							</Grid>
						</Item>
					</Grid>
				</Grid>
			</Grid>
			{showConnectionForm && (
				<ConnectionForm
					open={showConnectionForm}
					setOpen={setShowConnectionForm}
					residentIdData={residentIdData}
					connectType={ConnectType.Note}
					databaseId={currentNote.noteId}
					currentConnectDataList={currentNote.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showMemoryForm && (
				<NoteForm
					open={showMemoryForm}
					setOpen={setShowMemoryForm}
					IsNewMemory={false}
					residentIdData={residentIdData}
					setAlertText={setAlertText}
					SetShowSuccessAlert={setShowSuccessAlert}
					SetShowErrorAlert={setShowErrorAlert}
					CurrentMemoryData={currentNote}
					setCurrentMemoryData={setCurrentNote}
				/>
			)}
			{showMemoryStatusForm && (
				<NoteStatusForm
					open={showMemoryStatusForm}
					setOpen={setShowMemoryStatusForm}
					residentIdData={residentIdData}
					CurrentMemoryData={currentNote}
					setAlertText={setAlertText}
					SetShowSuccessAlert={setShowSuccessAlert}
					SetShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showEditCommentForm && (
				<NoteCommentForm
					open={showEditCommentForm}
					setOpen={setShowEditCommentForm}
					NoteCommentDetails={currentNoteComment}
					IsNewComment={isNewComment}
					setAlertText={setAlertText}
					SetShowSuccessAlert={setShowSuccessAlert}
					SetShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showAddMediaLinkForm && (
				<NoteOrGreetingToMediaLinkForm
					open={showAddMediaLinkForm}
					setOpen={setShowAddMediaLinkForm}
					residentIdData={residentIdData}
					currentNoteOrGreeting={currentNote}
					mediaLinkType='note'
					elementId={currentNote?.noteId}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showMediaFile && (
				<DisplayMedia
					open={showMediaFile}
					setOpen={setShowMediaFile}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
					metaData={{
						fileName: mediaIdList[mediaIdListIndex]?.fileName,
						mediaTypeIdentifier: mediaIdList[mediaIdListIndex]?.typeId,
					}}
					mediaFile={selectedMediaFile}
				/>
			)}
			{showMediaFileForm && (
				<MediaFileForm
					open={showMediaFileForm}
					setOpen={setShowMediaFileForm}
					isNewMediaFile={true}
					residentIdData={residentIdData}
					setMediaId={setMediaIdForLink}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					currentMediaFileMetaData={defaultMediaFileData}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
			{showConfirmMemoryDeletion && (
				<Confirm
					open={showConfirmMemoryDeletion}
					setOpen={setShowConfirmMemoryDeletion}
					text={deleteText}
					executefct={deleteMemory}
				/>
			)}
			{showConfirmCommentDeletion && (
				<Confirm
					open={showConfirmCommentDeletion}
					setOpen={setShowConfirmCommentDeletion}
					text={deleteText}
					executefct={deleteNoteComment}
				/>
			)}
		</React.Fragment>
	);
}
