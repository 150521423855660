import { Box, Button, Card, Grid, Typography } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { Note } from '../generated';
import NoteCard from './NoteCard';

interface NotesGridViewProps {
	noteList: Note[];
	forwardToNote: any;
	handleClickOpenConnectionForm: any;
}

export default function NotesGridView({
	noteList,
	forwardToNote,
	handleClickOpenConnectionForm,
}: NotesGridViewProps) {
	return (
		<Grid
			container
			spacing={{ xs: 2, lg: 3 }}
			columns={{ xs: 3, sm: 6, md: 9, lg: 12 }}>
			<Grid
				item
				xs={12}
				padding={5}
				style={{
					fontSize: '12',
					color: grey[500],
					textAlign: 'center',
				}}>
				{noteList?.length > 0 ? '' : 'Keine Erinnerungen hinterlegt.'}
			</Grid>
			{noteList.map((note) => (
				<Grid item xs={3} sm={3} md={3} lg={3} key={note.noteId}>
					<NoteCard
						note={note}
						onClickCard={() => forwardToNote(note)}
						onClickConnectButton={() => handleClickOpenConnectionForm(note)}
					/>
				</Grid>
			))}
		</Grid>
	);
}
