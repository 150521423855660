import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface DisplayPdfProp {
	src: String;
}

export default function DisplayPdf(prop: DisplayPdfProp) {
	const { src } = prop;

	const [numPages, setNumPages] = useState(null);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	return (
		<Document
			file={src}
			onLoadSuccess={onDocumentLoadSuccess}
			loading='Lade Pdf-Dokument ...'>
			{Array.from(new Array(numPages), (el, index) => (
				<Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.8} />
			))}
		</Document>
	);
}
