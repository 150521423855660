import { Button, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { ControlPoint, Delete, Edit, Info } from '@mui/icons-material';
import TabPanel from './TabPanel';
import MaterialTable, { Column, MTableBodyRow } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { Job } from '../generated';
import { useCallback } from 'react';
import { TCCStyledBadgeButton } from './format';

export default function JobPanel({
	value,
	index,
	list,
	defaultValue,
	hasEditPermission,
	handleDisplay,
	handleEdit,
	handleDelete,
	handleConnect,
}) {
	const columns: Column<Job>[] = [
		{ width: '10%' },
		{
			field: 'name',
			width: '90%',
			filtering: false,
			render: useCallback(
				(data: Job) => (
					<Typography variant='body2' color= 'primary' fontSize={16}>
						<TCCStyledBadgeButton
							badgeContent={data.connects.length}
							onClick={() => handleConnect(data)}
						/>
						{data.name}
						<Tooltip title='Details anzeigen'>
							<Button onClick={() => handleDisplay(data)}>
								{<Info color='primary' />}
							</Button>
						</Tooltip>
					</Typography>
				),
				[]
			),
			cellStyle: {
				maxWidth: '100%',
				whiteSpace: 'pre-line',
				textOverflow: 'ellipsis',
			},
		},
	];
	return (
		<TabPanel value={value} index={index}>
			<MaterialTable
				localization={{
					body: {
						emptyDataSourceMessage: 'Keine Einträge hinterlegt.',
					},
				}}
				style={{
					maxHeight: 293,
					overflow: 'auto',
					width: '100%',
					fontStyle: 'inherit',
				}}
				icons={MaterialTableIcons()}
				columns={columns}
				data={list}
				components={{
					Container: (props) => <Paper {...props} elevation={0} />,
					Row: (props) => (
						<MTableBodyRow key={props.jobId} id={props.jobId} {...props} />
					),
				}}
				options={{
					paging: false,
					showTitle: false,
					sorting: false,
					filtering: false,
					toolbar: false,
					tableLayout: 'fixed',
					search: false,
					rowStyle: { fontSize: 18 },
				}}
				detailPanel={[
					{
						tooltip: 'Aktionen',
						render: (row) => {
							return (
								<div key={row.rowData.jobId}>
										{hasEditPermission ? (
											<Tooltip title='Archiviere Beruf / Bildungsabschnitt'>
												<Button onClick={() => handleDelete(row.rowData.jobId)}>
													{<Delete sx={{ marginLeft: 5 }} color='primary' />}
												</Button>
											</Tooltip>
										) : (
											<Button disabled>{<Delete color='secondary' />}</Button>
										)}
										{hasEditPermission ? (
											<Tooltip title='Bearbeite Beruf / Bildungsabschnitt'>
												<Button onClick={() => handleEdit(false, row.rowData)}>
													{<Edit color='primary' />}
												</Button>
											</Tooltip>
										) : (
											<Button disabled>{<Edit color='secondary' />}</Button>
										)}
									</div>
							);
						},
					},
				]}
			/>
			{hasEditPermission ? (
				<Tooltip placement='right' title='Beruf / Bildungsabschnitt hinzufügen'>
					<IconButton
						sx={{
							position: 'sticky',
							right: '100%',
							marginTop: -2,
							marginBottom: -5,
						}}
						size='medium'
						onClick={() => handleEdit(true, defaultValue)}>
						{<ControlPoint color='primary' />}
					</IconButton>
				</Tooltip>
			) : (
				<IconButton size='medium' disabled>
					{<ControlPoint color='secondary' />}
				</IconButton>
			)}
		</TabPanel>
	);
}
