/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface GreetingUpdateParameters
 */
export interface GreetingUpdateParameters {
    /**
     * 
     * @type {string}
     * @memberof GreetingUpdateParameters
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof GreetingUpdateParameters
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof GreetingUpdateParameters
     */
    greetingPersonName?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof GreetingUpdateParameters
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof GreetingUpdateParameters
     */
    relevant?: boolean;
}

export function GreetingUpdateParametersFromJSON(json: any): GreetingUpdateParameters {
    return GreetingUpdateParametersFromJSONTyped(json, false);
}

export function GreetingUpdateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): GreetingUpdateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'greetingPersonName': !exists(json, 'greetingPersonName') ? undefined : json['greetingPersonName'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function GreetingUpdateParametersToJSON(value?: GreetingUpdateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'comment': value.comment,
        'greetingPersonName': value.greetingPersonName,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


