/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConnectType {
    Note = 'NOTE',
    Relative = 'RELATIVE',
    Job = 'JOB',
    Hobby = 'HOBBY',
    Link = 'LINK',
    Lifestyle = 'LIFESTYLE',
    Media = 'MEDIA',
    Residentassessment = 'RESIDENTASSESSMENT',
    Greeting = 'GREETING',
    FavoriteFood = 'FAVORITE_FOOD',
    ResidenceEvent = 'RESIDENCE_EVENT',
    ResidenceGroup = 'RESIDENCE_GROUP'
}

export function ConnectTypeFromJSON(json: any): ConnectType {
    return ConnectTypeFromJSONTyped(json, false);
}

export function ConnectTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectType {
    return json as ConnectType;
}

export function ConnectTypeToJSON(value?: ConnectType | null): any {
    return value as any;
}

