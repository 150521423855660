export enum BaseRole {
	ADMINISTRATION = 'Administration',
	BEWOHNERADMINISTRATION = 'Bewohneradministration',
	BEWOHNERVERWALTUNG = 'Bewohnerverwaltung',
	BEWOHNERDATENENTFERNUNG = 'Bewohnerdatenentfernung',
	FRAGEBOGENVERWALTUNG = 'Fragebogenverwaltung',
	VERWALTUNGEXTERNERINPUT = 'VerwaltungExternerInput',
	NUTZERVERWALTUNG = 'Nutzerverwaltung',
	HEIMEREIGNISSE = 'Heimereignisse-Schreiben',
	HEIMGRUPPEN = 'Heimgruppen-Schreiben',
}
