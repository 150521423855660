import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './components/keycloak';
import { AppRouter } from './routes/Approuter';
import { Footer } from './components/Footer';

// Wrap everything inside KeycloakProvider
function App() {
	return (
		<ReactKeycloakProvider
			authClient={keycloak}
			initOptions={{ onload: 'check-sso' }}
			enable-pkce={true}>
			<AppRouter />
			<Footer />
		</ReactKeycloakProvider>
	);
}

export default App;
