import React, { useCallback, useEffect, useState } from 'react';
import keycloak, { getNetworkApi } from './keycloak';

import { Close, ThumbDown, ThumbUp } from '@mui/icons-material';
import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	NativeSelect,
	Paper,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';

import {
	DefaultApi,
	GreetingInsertParameters,
	GreetingMediaInsertParameters,
	OutsideInputMediaData,
	OutsideInput,
	OutsideInputStatusEnum,
	OutsideInputUpdateParameters,
	Resident,
	ResidentMediaData,
	Unit,
} from '../generated';
import {
	isEmptyField,
	parseOutsideInput,
	ResidentIdData,
	trimString,
} from './format';
import { trackPromise } from 'react-promise-tracker';
import MaterialTable, { Column, MTableToolbar } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { MediaFromOutsideInputInsertParameters } from '../generated/models/MediaFromOutsideInputInsertParameters';
import { OutsideInputMediaUpdateParameters } from '../generated';
import DisplayMedia from './DisplayMedia';
import { KeycloakTokenParsed } from 'keycloak-js';

interface ApproveOutsideInputFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	outsideInput: OutsideInput;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ApproveOutsideInputForm(
	prop: ApproveOutsideInputFormProp
) {
	const {
		open,
		setOpen,
		outsideInput,
		setAlertText,
		setShowSuccessAlert,
		setShowErrorAlert,
	} = prop;

	type ParsedToken = KeycloakTokenParsed & {
		tenantIdentifier?: string;
	};

	const parsedToken: ParsedToken | undefined = keycloak?.idTokenParsed;

	const tenantIdentifier: string = parsedToken.tenantIdentifier;

	const [
		allegedResidentId,
		allegedUnitId,
		allegedResidenceId,
		allegedSender,
		allegedContent,
	] = parseOutsideInput(outsideInput);

	const allegedResidentIdData: ResidentIdData = {
		residentId: allegedResidentId,
		unitIdentifier: allegedUnitId,
		residenceIdentifier: allegedResidenceId,
		tenantIdentifier: tenantIdentifier,
	};

	const [unitList, setUnitList] = useState([] as Unit[]);
	const [unitIndex, setUnitIndex] = useState(0 as number);
	const [residentList, setResidentList] = useState([] as Resident[]);
	const [residentIndex, setResidentIndex] = useState(0 as number);
	const [attachmentList, setAttachmentList] = useState(
		[] as OutsideInputMediaData[]
	);
	const [selectedAttachmentIds, setSelectedAttachmentIds] = useState(
		[] as number[]
	);
	const [unselectedAttachmentIds, setUnselectedAttachmentIds] = useState(
		[] as number[]
	);

	const [greetingInsertParameters, setGreetingInsertParameters] = useState({
		title: trimString('Grüße von ' + allegedSender, 1000),
		comment: trimString(allegedContent, 10000),
		greetingPersonName: trimString(allegedSender, 100),
	} as GreetingInsertParameters);

	const [showMediaFile, setShowMediaFile] = useState(false);
	const [selectedMediaFile, setSelectedMediaFile] = useState(new Blob());
	const [currentMediaFileMetaData, setCurrentMediaFileMetaData] = useState(
		{} as ResidentMediaData
	);

	const handleChangeUnit = (e) => {
		setUnitIndex(e.target.value);
		setResidentIndex(0);
	};

	const handleChangeResident = (e) => {
		setResidentIndex(e.target.value);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setGreetingInsertParameters({
			...greetingInsertParameters,
			[name]: value,
		});
	};

	useEffect(() => {
		const loadUnits = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUnits(tenantIdentifier);

				let resultSorted = result.sort((a: Unit, b: Unit) =>
					a.unitIdentifier.localeCompare(b.unitIdentifier)
				);
				let proposedUnit = result.find(
					(el) => el.unitIdentifier === allegedUnitId
				);
				if (proposedUnit) {
					var index = resultSorted.indexOf(proposedUnit);
					resultSorted.splice(index, 1);
					resultSorted.unshift(proposedUnit);
					setUnitIndex(0);
				}
				setUnitList(resultSorted);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'Wohnbereichinformationen konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Wohnbereichinformationen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUnits());
	}, []);

	useEffect(() => {
		const loadResidentList = async () => {
			const api = getNetworkApi();
			if (unitList[unitIndex].tenantIdentifier !== undefined) {
				try {
					const result = await api.getResidentList(
						unitList[unitIndex].tenantIdentifier,
						unitList[unitIndex].residenceIdentifier,
						unitList[unitIndex].unitIdentifier
					);

					let resultSorted = result.sort((a: Resident, b: Resident) =>
						a.surname.localeCompare(b.surname)
					);

					let proposedResident = result.find(
						(el) => el.residentId === allegedResidentId
					);
					if (proposedResident) {
						resultSorted = resultSorted.filter((el) => el !== proposedResident);
						resultSorted.unshift(proposedResident);
						setResidentIndex(0);
					}
					setResidentList(resultSorted);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Bewohner:innen konnten nicht abgerufen werden');
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Bewohner:innen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadResidentList());
	}, [unitIndex, unitList]);

	useEffect(() => {
		const loadAttachments = async () => {
			const api = getNetworkApi();
			if (unitList[unitIndex].tenantIdentifier !== undefined) {
				try {
					const result = await api.getOutsideInputMediaList(
						unitList[unitIndex].tenantIdentifier,
						outsideInput.outsideInputId
					);
					setAttachmentList(result);
					setUnselectedAttachmentIds(
						result.map((item) => item.outsideInputMediaId)
					);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Anhänge konnten nicht abgerufen werden');
							console.log('There was an error fetching the attachment data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Anhänge konnten nicht abgerufen werden');
						console.log('There was an error fetching the attachment data!');
					}
				}
			}
		};
		trackPromise(loadAttachments());
	}, [unitIndex, unitList]);

	const handleApprove = async () => {
		setOpen(false);
		const client = getNetworkApi();

		let greetingId: number = undefined;
		try {
			greetingId = await client.postResidentGreeting(
				tenantIdentifier,
				residentList[residentIndex]?.residenceIdentifier,
				residentList[residentIndex]?.unitIdentifier,
				residentList[residentIndex]?.residentId,
				greetingInsertParameters
			);

			let outsideInputUpdateParameters: OutsideInputUpdateParameters = {
				status: OutsideInputStatusEnum.Approved,
				inputJson: outsideInput.inputJson,
				residentId: residentList[residentIndex]?.residentId,
			};

			await client.putOutsideInput(
				tenantIdentifier,
				outsideInput.outsideInputId,
				outsideInputUpdateParameters
			);

			handleApprovedAttachments(client, greetingId, selectedAttachmentIds);
			handleRejectedAttachments(client, unselectedAttachmentIds);

			setAlertText('Grüße wurden erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Grüße wurden nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const handleDeny = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let outsideInputUpdateParameters: OutsideInputUpdateParameters = {
			status: OutsideInputStatusEnum.Rejected,
		};

		try {
			await client.putOutsideInput(
				tenantIdentifier,
				outsideInput.outsideInputId,
				outsideInputUpdateParameters
			);

			let all = attachmentList.map((item) => item.outsideInputMediaId);
			handleRejectedAttachments(client, all);

			setAlertText('Input wurde erfolgreich abgelehnt');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Input wurde nicht abgelehnt');
			setShowErrorAlert(true);
		}
	};

	const handleApprovedAttachments = async (
		client: DefaultApi,
		greetingId: number,
		approvedAttachmentIds: number[]
	) => {
		approvedAttachmentIds.forEach(async (attachmentId) => {
			let mediaFromOutsideInputInsertParameters: MediaFromOutsideInputInsertParameters =
				{
					outsideInputMediaId: attachmentId,
				};
			let mediaId: number = undefined;

			try {
				mediaId = await client.postResidentMediaFromOutsideInput(
					tenantIdentifier,
					residentList[residentIndex]?.residenceIdentifier,
					residentList[residentIndex]?.unitIdentifier,
					residentList[residentIndex]?.residentId,
					mediaFromOutsideInputInsertParameters
				);
			} catch (error) {
				setAlertText('Anhang wurde nicht gespeichert');
				setShowErrorAlert(true);
			}

			let greetingMediaInsertParameters: GreetingMediaInsertParameters = {
				mediaIds: [mediaId],
			};

			try {
				await client.postGreetingMedia(
					tenantIdentifier,
					residentList[residentIndex]?.residenceIdentifier,
					residentList[residentIndex]?.unitIdentifier,
					greetingId,
					greetingMediaInsertParameters
				);
			} catch (error) {
				setAlertText('Verknüpfung zu Medien wurde nicht gespeichert');
				setShowErrorAlert(true);
			}

			let outsideInputMediaUpdateParameters: OutsideInputMediaUpdateParameters =
				{
					status: OutsideInputStatusEnum.Approved,
				};
			try {
				await client.putOutsideInputMedia(
					tenantIdentifier,
					attachmentId,
					outsideInputMediaUpdateParameters
				);
			} catch (error) {
				setAlertText('Metadaten des Anhangs wurde nicht aktualisiert');
				setShowErrorAlert(true);
			}

			try {
				await client.deleteOutsideInputMedia(
					tenantIdentifier,
					attachmentId);
			} catch (error) {
				setAlertText(
					'Anhang konnte nicht aus der Input-Schicht entfernt werden'
				);
				setShowErrorAlert(true);
			}
		});
	};

	const handleRejectedAttachments = async (
		client: DefaultApi,
		rejectedAttachmentIds: number[]
	) => {
		rejectedAttachmentIds.forEach(async (attachmentId) => {
			let outsideInputMediaUpdateParameters: OutsideInputMediaUpdateParameters =
				{
					status: OutsideInputStatusEnum.Rejected,
				};
			try {
				await client.putOutsideInputMedia(
					tenantIdentifier,
					attachmentId,
					outsideInputMediaUpdateParameters
				);
			} catch (error) {
				setAlertText('Metadaten des Anhangs wurden nicht aktualisiert');
				setShowErrorAlert(true);
			}

			try {
				await client.deleteOutsideInputMedia(
					tenantIdentifier,
					attachmentId);
			} catch (error) {
				setAlertText(
					'Anhang konnte nicht aus der Input-Schicht gelöscht werden'
				);
				setShowErrorAlert(true);
			}
		});
	};

	const handleSelectionChange = (rows: OutsideInputMediaData[]) => {
		let selection = rows.map((item) => item.outsideInputMediaId);
		let all = attachmentList.map((item) => item.outsideInputMediaId);
		let difference = all.filter((x) => !selection.includes(x));
		setSelectedAttachmentIds(selection);
		setUnselectedAttachmentIds(difference);
	};

	const loadSingleOutsideInputMediaFile = async (
		fileMetaData: OutsideInputMediaData
	) => {
		const api = getNetworkApi();
		try {
			const result = await api.getOutsideInputMedia(
				tenantIdentifier,
				fileMetaData.outsideInputMediaId
			);
			return result;
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Die ausgewählte Mediendatei konnte nicht abgerufen werden');
			return null;
		}
	};

	const columns: Column<OutsideInputMediaData>[] = [
		{
			sorting: false,
			render: useCallback((data: OutsideInputMediaData) => {
				const handleShowMediaFile = async (
					fileMetaData: OutsideInputMediaData
				) => {
					let promiseForBlob = trackPromise(
						loadSingleOutsideInputMediaFile(fileMetaData)
					);
					setSelectedMediaFile(await promiseForBlob);
					setCurrentMediaFileMetaData(fileMetaData);
					setShowMediaFile(true);
				};

				return (
					<Tooltip title='Zeige Datei an'>
						<Button onClick={() => handleShowMediaFile(data)}>
							<PlayCircleIcon color='primary' />
						</Button>
					</Tooltip>
				);
			}, []),
		},
		{
			title: 'Datei',
			render: useCallback((data: OutsideInputMediaData) => {
				return (
					<Typography fontSize={16} color={'primary'} variant={'body2'}>
						{data.fileName}
					</Typography>
				);
			}, []),
		},
	];

	const isSaveButtonEnabled = (
		greetingInsertParameters: GreetingInsertParameters
	) => {
		return (
			!isEmptyField(greetingInsertParameters?.title) &&
			!isEmptyField(greetingInsertParameters?.greetingPersonName)
		);
	};

	return (
		<React.Fragment>
			<Dialog fullScreen={true} open={open} onClose={handleClose}>
				<DialogTitle>{'Input an Grüße übertragen'}</DialogTitle>
				<div />
				<DialogContent>
					<form>
						<Grid container spacing={2} columns={{ xs: 3, md: 6, lg: 12 }}>
							<Grid item xs={3} md={6} lg={12}>
								<DialogContentText>Grußvorschlag von tcc:</DialogContentText>
							</Grid>
							<Grid item xs={3} md={6} lg={6}>
								<FormControl fullWidth>
									<InputLabel
										variant='standard'
										htmlFor='uncontrolled-native'
										shrink={true}>
										Wohnbereich auswählen
									</InputLabel>
									<NativeSelect
										onChange={handleChangeUnit}
										inputProps={{
											name: 'unit',
											id: 'uncontrolled-native',
										}}
										sx={{ color: 'primary' }}>
										{unitList.map((el, index) => (
											<option key={index} value={index}>
												{el.unitIdentifier} - {el.residenceIdentifier}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Grid>
							<Grid item xs={3} md={6} lg={6}>
								<FormControl fullWidth>
									<InputLabel
										variant='standard'
										htmlFor='uncontrolled-native'
										shrink={true}>
										Empfänger:in auswählen
									</InputLabel>
									<NativeSelect
										onChange={handleChangeResident}
										inputProps={{
											name: 'resident',
											id: 'uncontrolled-native',
										}}
										sx={{ color: 'primary' }}>
										{residentList.map((el, index) => (
											<option key={index} value={index}>
												{el.surname}, {el.firstName}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Grid>
							<Grid item xs={3} md={6} lg={6} marginTop={1}>
								<TextField
									fullWidth
									label='Titel '
									name='title'
									id='outlined-size-normal'
									value={greetingInsertParameters.title}
									onChange={handleInputChange}
									inputProps={{ maxLength: 1000 }}
									required
								/>
							</Grid>
							<Grid item xs={3} md={6} lg={6} marginTop={1}>
								<TextField
									fullWidth
									label='Absender'
									name='greetingPersonName'
									id='outlined-size-normal'
									value={greetingInsertParameters.greetingPersonName}
									onChange={handleInputChange}
									inputProps={{ maxLength: 100 }}
									required
								/>
							</Grid>
							<Grid item xs={3} md={6} lg={12}>
								<TextField
									fullWidth
									multiline
									rows={4}
									label='Kommentar'
									name='comment'
									id='outlined-size-normal'
									value={greetingInsertParameters.comment}
									onChange={handleInputChange}
									inputProps={{ maxLength: 10000 }}
								/>
							</Grid>
							<Grid item xs={3} md={6} lg={12}>
								{attachmentList.length > 0 && (
									<MaterialTable
										localization={{
											body: {
												emptyDataSourceMessage: 'Keine Anhänge vorhanden.',
											},
										}}
										icons={MaterialTableIcons()}
										columns={columns}
										data={attachmentList}
										components={{
											Container: (props) => <Paper {...props} elevation={0} />,
											// workaround to left align table title: https://github.com/mbrn/material-table/issues/1690
											Toolbar: (props) => {
												const propsCopy = { ...props };
												propsCopy.showTitle = false;
												return (
													<Grid container direction='row'>
														<Grid
															item
															xs={3}
															md={6}
															lg={12}
															marginBottom={-20}
															marginTop={2}>
															<DialogContentText>Anhänge:</DialogContentText>
														</Grid>
														<Grid item xs={6}>
															<MTableToolbar {...propsCopy} />
														</Grid>
													</Grid>
												);
											},
										}}
										options={{
											paging: false,
											sorting: false,
											filtering: false,
											search: false,
											rowStyle: { fontSize: 24 },
											selection: true,
											showTextRowsSelected: false,
										}}
										onSelectionChange={handleSelectionChange}
									/>
								)}
							</Grid>
							<Grid item xs={3} md={6} lg={12} marginTop={1}>
								<DialogContentText>
									Originale Daten aus der E-Mail:
								</DialogContentText>
							</Grid>

							<Grid item xs={3} md={6} lg={12} marginTop={1}>
								<TextField
									fullWidth
									label='Titel '
									name='inputJson.subject'
									id='outlined-size-normal'
									value={outsideInput?.inputJson?.subject}
									inputProps={{ maxLength: 100 }}
									disabled
								/>
							</Grid>
							<Grid item xs={3} md={6} lg={12} marginTop={1}>
								<TextField
									fullWidth
									label='Absender'
									name='inputJson.from'
									id='outlined-size-normal'
									value={outsideInput?.inputJson?.from}
									inputProps={{ maxLength: 100 }}
									disabled
								/>
							</Grid>
							<Grid item xs={3} md={6} lg={12}>
								<TextField
									fullWidth
									multiline
									rows={4}
									label='Inhalt'
									name='inputJson.body'
									id='outlined-size-normal'
									value={outsideInput?.inputJson?.body}
									inputProps={{ maxLength: 250 }}
									disabled
								/>
							</Grid>
							<Grid item xs={3} md={6} lg={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} Abbrechen
										</Button>
									</Grid>
									<Grid item>
										<Button variant='contained' onClick={handleDeny}>
											{<ThumbDown />} Ablehnen
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant='contained'
											onClick={handleApprove}
											disabled={!isSaveButtonEnabled(greetingInsertParameters)}>
											{<ThumbUp />} Genehmigen
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
			{showMediaFile && (
				<DisplayMedia
					open={showMediaFile}
					setOpen={setShowMediaFile}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
					metaData={currentMediaFileMetaData}
					mediaFile={selectedMediaFile}
				/>
			)}
		</React.Fragment>
	);
}
