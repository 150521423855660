import * as React from 'react';
import {
	FormControl,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
} from '@mui/material';

export default function SelectRadioButton(props) {
	const { name, label, value, onChange, isDisplay, items } = props;

	return (
		<FormControl>
			<FormLabel>{label}</FormLabel>
			<RadioGroup row={true} name={name} value={value} onChange={onChange}>
				{items.map((item, index) => (
					<FormControlLabel
						key={item.id}
						value={item.id}
						disabled={isDisplay}
						control={<Radio />}
						label={item.title}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
}
