import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Divider,
	CardActions,
} from '@mui/material';

import { Note } from '../generated';
import {
	noteCategoryItems,
	TCCStyledBadgeButton,
	abbreviateString,
	determineTagIconInLineSize,
} from './format';

interface NoteCardProps {
	note: Note;
	onClickCard: any;
	onClickConnectButton: any;
}

export default function NoteCard({
	note,
	onClickCard,
	onClickConnectButton,
}: NoteCardProps) {
	let cardcolor = 'primary';

	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 10, height: '100%' }}
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
			}}
			elevation={5}>
			<CardActionArea onClick={onClickCard}>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={12}>
							<Typography
								align='center'
								fontSize='24'
								variant='h6'
								color={cardcolor}>
								{abbreviateString(note.title, 30)}

								{
									noteCategoryItems.find((el) => el.value === note?.category)
										?.icon
								}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Divider variant='middle'>
							{(note?.pointInTime || note?.stageOfLife) && (
								<Typography fontSize={12} color={'primary'} variant={'body2'}>
									{' '}
									{note?.pointInTime}
									{note?.stageOfLife && note?.pointInTime && ', '}
									{note?.stageOfLife}
								</Typography>
							)}
						</Divider>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{
							textAlign: 'center',
						}}>
						{note?.tags === undefined
							? void 0
							: note?.tags
									.filter((el) => el.tagGroupIdentifier === 'EMOTION')
									.map((el, index) =>
										determineTagIconInLineSize(el.tagAbbreviation, '40px')
									)}
					</Grid>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={12}>
							<Typography
								fontSize={18}
								color={'primary'}
								variant={'body2'}
								justifyContent={'center'}
								marginTop={2}
								marginBottom={2}
								align={'left'}
								style={{ wordWrap: 'break-word' }}>
								{abbreviateString(note?.content, 80)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
			<CardActions sx={{ justifyContent: 'center' }}>
				<TCCStyledBadgeButton
					badgeContent={note.connects.length}
					onClick={onClickConnectButton}
				/>
			</CardActions>
		</Card>
	);
}
