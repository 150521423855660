import { Grid } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { ResidenceGroup } from '../generated';
import GroupCard from './GroupCard';


interface GroupsGridViewProps {
	groupList: ResidenceGroup[];
	handleDisplayGroup: any;
	handleClickOpenMediaAlbumForm: any;
	handleClickOpenResidentToGroupForm: any;
	isAuthorized: boolean;
}

export default function GroupsGridView({
	groupList,
	handleDisplayGroup,
	handleClickOpenMediaAlbumForm,
	handleClickOpenResidentToGroupForm,
	isAuthorized,
}: GroupsGridViewProps) {
	return (
		<Grid
			container
			spacing={{ xs: 2, lg: 3 }}
			columns={{ xs: 3, sm: 6, md: 9, lg: 12 }}>
			<Grid
				item
				xs={12}
				padding={5}
				style={{
					fontSize: '12',
					color: grey[500],
					textAlign: 'center',
				}}>
				{groupList?.length > 0 ? '' : 'Keine Gruppen hinterlegt.'}
			</Grid>
			{groupList.map((group) => (
				<Grid item xs={3} sm={3} md={3} lg={3} key={group.residenceGroupId}>
					<GroupCard
						group={group}
						onClickCard={() => handleDisplayGroup(group)}
						onClickMediaAlbum={() => handleClickOpenMediaAlbumForm(group)}
						onClickResidentToGroupForm={() => handleClickOpenResidentToGroupForm(group)}
						isAuthorized={isAuthorized}
					/>
				</Grid>
			))}
		</Grid>
	);
}
