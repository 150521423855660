/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssessedProfile,
    AssessedProfileFromJSON,
    AssessedProfileToJSON,
    AssessedQuestion,
    AssessedQuestionFromJSON,
    AssessedQuestionToJSON,
    AssessmentProfile,
    AssessmentProfileFromJSON,
    AssessmentProfileToJSON,
    AssessmentResult,
    AssessmentResultFromJSON,
    AssessmentResultToJSON,
    AssessmentResultsForTargetId,
    AssessmentResultsForTargetIdFromJSON,
    AssessmentResultsForTargetIdToJSON,
    AssessmentTypeEnum,
    AssessmentTypeEnumFromJSON,
    AssessmentTypeEnumToJSON,
    ConnectInsertParameters,
    ConnectInsertParametersFromJSON,
    ConnectInsertParametersToJSON,
    FavoriteFood,
    FavoriteFoodFromJSON,
    FavoriteFoodToJSON,
    FavoriteFoodInsertParameters,
    FavoriteFoodInsertParametersFromJSON,
    FavoriteFoodInsertParametersToJSON,
    FavoriteFoodMediaInsertParameters,
    FavoriteFoodMediaInsertParametersFromJSON,
    FavoriteFoodMediaInsertParametersToJSON,
    FavoriteFoodMediaUpdateParameters,
    FavoriteFoodMediaUpdateParametersFromJSON,
    FavoriteFoodMediaUpdateParametersToJSON,
    FavoriteFoodUpdateParameters,
    FavoriteFoodUpdateParametersFromJSON,
    FavoriteFoodUpdateParametersToJSON,
    Greeting,
    GreetingFromJSON,
    GreetingToJSON,
    GreetingInsertParameters,
    GreetingInsertParametersFromJSON,
    GreetingInsertParametersToJSON,
    GreetingMediaInsertParameters,
    GreetingMediaInsertParametersFromJSON,
    GreetingMediaInsertParametersToJSON,
    GreetingMediaUpdateParameters,
    GreetingMediaUpdateParametersFromJSON,
    GreetingMediaUpdateParametersToJSON,
    GreetingUpdateParameters,
    GreetingUpdateParametersFromJSON,
    GreetingUpdateParametersToJSON,
    Group,
    GroupFromJSON,
    GroupToJSON,
    Hobby,
    HobbyFromJSON,
    HobbyToJSON,
    HobbyInsertParameters,
    HobbyInsertParametersFromJSON,
    HobbyInsertParametersToJSON,
    HobbyUpdateParameters,
    HobbyUpdateParametersFromJSON,
    HobbyUpdateParametersToJSON,
    Job,
    JobFromJSON,
    JobToJSON,
    JobInsertParameters,
    JobInsertParametersFromJSON,
    JobInsertParametersToJSON,
    JobUpdateParameters,
    JobUpdateParametersFromJSON,
    JobUpdateParametersToJSON,
    Lifestyle,
    LifestyleFromJSON,
    LifestyleToJSON,
    LifestyleInsertParameters,
    LifestyleInsertParametersFromJSON,
    LifestyleInsertParametersToJSON,
    LifestyleUpdateParameters,
    LifestyleUpdateParametersFromJSON,
    LifestyleUpdateParametersToJSON,
    Link,
    LinkFromJSON,
    LinkToJSON,
    LinkInsertParameters,
    LinkInsertParametersFromJSON,
    LinkInsertParametersToJSON,
    LinkUpdateParameters,
    LinkUpdateParametersFromJSON,
    LinkUpdateParametersToJSON,
    MediaCategory,
    MediaCategoryFromJSON,
    MediaCategoryToJSON,
    MediaFromOutsideInputInsertParameters,
    MediaFromOutsideInputInsertParametersFromJSON,
    MediaFromOutsideInputInsertParametersToJSON,
    MediaMetaUpdateParameters,
    MediaMetaUpdateParametersFromJSON,
    MediaMetaUpdateParametersToJSON,
    Note,
    NoteFromJSON,
    NoteToJSON,
    NoteCommentInsertParameters,
    NoteCommentInsertParametersFromJSON,
    NoteCommentInsertParametersToJSON,
    NoteCommentUpdateParameters,
    NoteCommentUpdateParametersFromJSON,
    NoteCommentUpdateParametersToJSON,
    NoteInsertParameters,
    NoteInsertParametersFromJSON,
    NoteInsertParametersToJSON,
    NoteMediaInsertParameters,
    NoteMediaInsertParametersFromJSON,
    NoteMediaInsertParametersToJSON,
    NoteMediaUpdateParameters,
    NoteMediaUpdateParametersFromJSON,
    NoteMediaUpdateParametersToJSON,
    NoteStatus,
    NoteStatusFromJSON,
    NoteStatusToJSON,
    NoteUpdateParameters,
    NoteUpdateParametersFromJSON,
    NoteUpdateParametersToJSON,
    OutsideInput,
    OutsideInputFromJSON,
    OutsideInputToJSON,
    OutsideInputInsertParameters,
    OutsideInputInsertParametersFromJSON,
    OutsideInputInsertParametersToJSON,
    OutsideInputMediaData,
    OutsideInputMediaDataFromJSON,
    OutsideInputMediaDataToJSON,
    OutsideInputMediaUpdateParameters,
    OutsideInputMediaUpdateParametersFromJSON,
    OutsideInputMediaUpdateParametersToJSON,
    OutsideInputUpdateParameters,
    OutsideInputUpdateParametersFromJSON,
    OutsideInputUpdateParametersToJSON,
    Question,
    QuestionFromJSON,
    QuestionToJSON,
    Relative,
    RelativeFromJSON,
    RelativeToJSON,
    RelativeInsertParameters,
    RelativeInsertParametersFromJSON,
    RelativeInsertParametersToJSON,
    RelativeRole,
    RelativeRoleFromJSON,
    RelativeRoleToJSON,
    RelativeType,
    RelativeTypeFromJSON,
    RelativeTypeToJSON,
    RelativeUpdateParameters,
    RelativeUpdateParametersFromJSON,
    RelativeUpdateParametersToJSON,
    Residence,
    ResidenceFromJSON,
    ResidenceToJSON,
    ResidenceEvent,
    ResidenceEventFromJSON,
    ResidenceEventToJSON,
    ResidenceEventCategoryMapping,
    ResidenceEventCategoryMappingFromJSON,
    ResidenceEventCategoryMappingToJSON,
    ResidenceEventInsertParameters,
    ResidenceEventInsertParametersFromJSON,
    ResidenceEventInsertParametersToJSON,
    ResidenceEventMediaInsertParameters,
    ResidenceEventMediaInsertParametersFromJSON,
    ResidenceEventMediaInsertParametersToJSON,
    ResidenceEventMediaUpdateParameters,
    ResidenceEventMediaUpdateParametersFromJSON,
    ResidenceEventMediaUpdateParametersToJSON,
    ResidenceEventResidentsInsertParameters,
    ResidenceEventResidentsInsertParametersFromJSON,
    ResidenceEventResidentsInsertParametersToJSON,
    ResidenceEventResidentsUpdateParameters,
    ResidenceEventResidentsUpdateParametersFromJSON,
    ResidenceEventResidentsUpdateParametersToJSON,
    ResidenceEventUpdateParameters,
    ResidenceEventUpdateParametersFromJSON,
    ResidenceEventUpdateParametersToJSON,
    ResidenceGroup,
    ResidenceGroupFromJSON,
    ResidenceGroupToJSON,
    ResidenceGroupCategoryMapping,
    ResidenceGroupCategoryMappingFromJSON,
    ResidenceGroupCategoryMappingToJSON,
    ResidenceGroupInsertParameters,
    ResidenceGroupInsertParametersFromJSON,
    ResidenceGroupInsertParametersToJSON,
    ResidenceGroupMediaInsertParameters,
    ResidenceGroupMediaInsertParametersFromJSON,
    ResidenceGroupMediaInsertParametersToJSON,
    ResidenceGroupMediaUpdateParameters,
    ResidenceGroupMediaUpdateParametersFromJSON,
    ResidenceGroupMediaUpdateParametersToJSON,
    ResidenceGroupResidentsInsertParameters,
    ResidenceGroupResidentsInsertParametersFromJSON,
    ResidenceGroupResidentsInsertParametersToJSON,
    ResidenceGroupResidentsUpdateParameters,
    ResidenceGroupResidentsUpdateParametersFromJSON,
    ResidenceGroupResidentsUpdateParametersToJSON,
    ResidenceGroupUpdateParameters,
    ResidenceGroupUpdateParametersFromJSON,
    ResidenceGroupUpdateParametersToJSON,
    ResidenceMediaData,
    ResidenceMediaDataFromJSON,
    ResidenceMediaDataToJSON,
    ResidenceMediaMetaUpdateParameters,
    ResidenceMediaMetaUpdateParametersFromJSON,
    ResidenceMediaMetaUpdateParametersToJSON,
    Resident,
    ResidentFromJSON,
    ResidentToJSON,
    ResidentAssessmentInsertParameters,
    ResidentAssessmentInsertParametersFromJSON,
    ResidentAssessmentInsertParametersToJSON,
    ResidentAssessmentQuestionUpdateParameters,
    ResidentAssessmentQuestionUpdateParametersFromJSON,
    ResidentAssessmentQuestionUpdateParametersToJSON,
    ResidentDeleteReservationDeleteDecision,
    ResidentDeleteReservationDeleteDecisionFromJSON,
    ResidentDeleteReservationDeleteDecisionToJSON,
    ResidentDeleteReservationInsertParameters,
    ResidentDeleteReservationInsertParametersFromJSON,
    ResidentDeleteReservationInsertParametersToJSON,
    ResidentInsertParameters,
    ResidentInsertParametersFromJSON,
    ResidentInsertParametersToJSON,
    ResidentMarkedForDeletion,
    ResidentMarkedForDeletionFromJSON,
    ResidentMarkedForDeletionToJSON,
    ResidentMediaData,
    ResidentMediaDataFromJSON,
    ResidentMediaDataToJSON,
    ResidentUpdateParameters,
    ResidentUpdateParametersFromJSON,
    ResidentUpdateParametersToJSON,
    StageOfLife,
    StageOfLifeFromJSON,
    StageOfLifeToJSON,
    Tag,
    TagFromJSON,
    TagToJSON,
    Unit,
    UnitFromJSON,
    UnitToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserInsertParameters,
    UserInsertParametersFromJSON,
    UserInsertParametersToJSON,
    UserUpdateParameters,
    UserUpdateParametersFromJSON,
    UserUpdateParametersToJSON,
} from '../models';

export interface DeactivateConnectRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    connectId: number;
}

export interface DeactivateFavoriteFoodMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    favoriteFoodId: number;
    mediaId: number;
}

export interface DeactivateGreetingMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    greetingId: number;
    mediaId: number;
}

export interface DeactivateHobbyRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    hobbyId: number;
}

export interface DeactivateJobRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    jobId: number;
}

export interface DeactivateLifestyleRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    lifestyleId: number;
}

export interface DeactivateLinkRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    linkId: number;
}

export interface DeactivateNoteRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    noteId: number;
}

export interface DeactivateNoteCommentRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    noteId: number;
    noteCommentId: number;
}

export interface DeactivateNoteMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    noteId: number;
    mediaId: number;
}

export interface DeactivateOutsideInputRequest {
    tenantIdentifier: string;
    outsideInputId: number;
}

export interface DeactivateRelativeRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    relativeId: number;
}

export interface DeactivateResidenceEventRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceEventId: number;
}

export interface DeactivateResidenceEventMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceEventId: number;
    residenceMediaId: number;
}

export interface DeactivateResidenceGroupRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceGroupId: number;
}

export interface DeactivateResidenceGroupMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceGroupId: number;
    residenceMediaId: number;
}

export interface DeactivateResidenceMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceMediaId: number;
}

export interface DeactivateResidentRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
}

export interface DeactivateResidentAssessmentRequest {
    tenantIdentifier: string;
    unitIdentifier: string;
    residenceIdentifier: string;
    residentAssessmentId: number;
}

export interface DeactivateResidentFavoriteFoodRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    favoriteFoodId: number;
}

export interface DeactivateResidentGreetingRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    greetingId: number;
}

export interface DeactivateResidentMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    mediaId: number;
}

export interface DeactivateTagForNoteRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    tagId: number;
    noteId: number;
}

export interface DecideOnResidentDeleteReservationRequest {
    tenantIdentifier: string;
    residentId: number;
    residentDeleteReservationId: number;
    residentDeleteReservationDeleteDecision?: ResidentDeleteReservationDeleteDecision;
}

export interface DeleteOutsideInputMediaRequest {
    tenantIdentifier: string;
    outsideInputMediaId: number;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface GetAssessedProfilesFromResidentIdRequest {
    tenantIdentifier: string;
    unitIdentifier: string;
    residenceIdentifier: string;
    residentId: number;
    assessmentType: AssessmentTypeEnum;
}

export interface GetAssessedQuestionsForAssessmentTargetRequest {
    tenantIdentifier: string;
    unitIdentifier: string;
    residenceIdentifier: string;
    residentAssessmentId: number;
    assessmentTargetId: number;
    assessmentProfileId: number;
}

export interface GetAssessedQuestionsForResidentAssessmentRequest {
    tenantIdentifier: string;
    unitIdentifier: string;
    residenceIdentifier: string;
    assessmentProfileId: number;
    residentAssessmentId: number;
}

export interface GetAssessmentProfilesForAssessmentTypeRequest {
    tenantIdentifier: string;
    assessmentType: AssessmentTypeEnum;
}

export interface GetAssessmentQuestionsRequest {
    tenantIdentifier: string;
    assessmentProfileId: number;
}

export interface GetAssessmentResultForResidentAssessmentRequest {
    tenantIdentifier: string;
    unitIdentifier: string;
    residenceIdentifier: string;
    residentAssessmentId: number;
    assessmentProfileId: number;
}

export interface GetAssessmentResultsForTargetIdsForAssessmentProfileRequest {
    tenantIdentifier: string;
    unitIdentifier: string;
    residenceIdentifier: string;
    residentId: number;
    assessmentType: AssessmentTypeEnum;
    assessmentProfileId: number;
}

export interface GetEmailAddressRequest {
    tenantIdentifier: string;
}

export interface GetHobbiesListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
}

export interface GetJobListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
}

export interface GetLifestyleListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
}

export interface GetLinkListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
}

export interface GetMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    mediaId: number;
}

export interface GetMediaCategoriesRequest {
    tenantIdentifier: string;
}

export interface GetNoteRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    noteId: number;
}

export interface GetNoteStatusesRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
}

export interface GetNotesListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    nMostRecent?: number;
}

export interface GetOutsideInputMediaRequest {
    tenantIdentifier: string;
    outsideInputMediaId: number;
}

export interface GetOutsideInputMediaListRequest {
    tenantIdentifier: string;
    outsideInputId: number;
}

export interface GetRelativeRolesRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
}

export interface GetRelativeTypesRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
}

export interface GetRelativesListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
}

export interface GetResidenceEventCategoryMappingRequest {
    tenantIdentifier: string;
}

export interface GetResidenceEventsRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
}

export interface GetResidenceGroupCategoryMappingRequest {
    tenantIdentifier: string;
}

export interface GetResidenceGroupsRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
}

export interface GetResidenceMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceMediaId: number;
}

export interface GetResidenceMediaListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
}

export interface GetResidenceResidentListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
}

export interface GetResidencesRequest {
    tenantIdentifier: string;
}

export interface GetResidentRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
}

export interface GetResidentFavoriteFoodListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
}

export interface GetResidentGreetingListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    nMostRecent?: number;
}

export interface GetResidentListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
}

export interface GetResidentMediaListRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    isGetProfilePicture: boolean;
    nMostRecent?: number;
}

export interface GetResidentsMarkedForDeletionRequest {
    tenantIdentifier: string;
}

export interface GetStagesOfLifeRequest {
    tenantIdentifier: string;
}

export interface GetTagsRequest {
    tenantIdentifier: string;
}

export interface GetUnitsRequest {
    tenantIdentifier: string;
}

export interface GetUnreadEmailsListRequest {
    tenantIdentifier: string;
}

export interface GetUserGroupsByIdRequest {
    userId: string;
}

export interface PostConnectRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    connectInsertParameters?: ConnectInsertParameters;
}

export interface PostFavoriteFoodMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    favoriteFoodId: number;
    favoriteFoodMediaInsertParameters?: FavoriteFoodMediaInsertParameters;
}

export interface PostGreetingMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    greetingId: number;
    greetingMediaInsertParameters?: GreetingMediaInsertParameters;
}

export interface PostHobbyRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    hobbyInsertParameters?: HobbyInsertParameters;
}

export interface PostJobRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    jobInsertParameters?: JobInsertParameters;
}

export interface PostLifestyleRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    lifestyleInsertParameters?: LifestyleInsertParameters;
}

export interface PostLinkRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    linkInsertParameters?: LinkInsertParameters;
}

export interface PostNoteRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    noteInsertParameters?: NoteInsertParameters;
}

export interface PostNoteCommentRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    noteId: number;
    noteCommentInsertParameters?: NoteCommentInsertParameters;
}

export interface PostNoteMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    noteId: number;
    noteMediaInsertParameters?: NoteMediaInsertParameters;
}

export interface PostOutsideInputRequest {
    tenantIdentifier: string;
    outsideInputInsertParameters?: OutsideInputInsertParameters;
}

export interface PostRelativeRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    relativeInsertParameters?: RelativeInsertParameters;
}

export interface PostResidenceEventRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceEventInsertParameters?: ResidenceEventInsertParameters;
}

export interface PostResidenceEventMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceEventId: number;
    residenceEventMediaInsertParameters?: ResidenceEventMediaInsertParameters;
}

export interface PostResidenceEventResidentsRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceEventId: number;
    residenceEventResidentsInsertParameters?: ResidenceEventResidentsInsertParameters;
}

export interface PostResidenceGroupRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceGroupInsertParameters?: ResidenceGroupInsertParameters;
}

export interface PostResidenceGroupMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceGroupId: number;
    residenceGroupMediaInsertParameters?: ResidenceGroupMediaInsertParameters;
}

export interface PostResidenceGroupResidentsRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceGroupId: number;
    residenceGroupResidentsInsertParameters?: ResidenceGroupResidentsInsertParameters;
}

export interface PostResidenceMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    file?: Blob;
    fileName?: string;
    comment?: string;
}

export interface PostResidentRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentInsertParameters?: ResidentInsertParameters;
}

export interface PostResidentAssessmentRequest {
    tenantIdentifier: string;
    unitIdentifier: string;
    residenceIdentifier: string;
    residentId: number;
    residentAssessmentInsertParameters?: ResidentAssessmentInsertParameters;
}

export interface PostResidentDeleteReservationRequest {
    tenantIdentifier: string;
    residentId: number;
    residentDeleteReservationInsertParameters?: ResidentDeleteReservationInsertParameters;
}

export interface PostResidentFavoriteFoodRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    favoriteFoodInsertParameters?: FavoriteFoodInsertParameters;
}

export interface PostResidentGreetingRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    greetingInsertParameters?: GreetingInsertParameters;
}

export interface PostResidentMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    file?: Blob;
    fileName?: string;
    comment?: string;
    stageOfLife?: string;
}

export interface PostResidentMediaFromOutsideInputRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    mediaFromOutsideInputInsertParameters?: MediaFromOutsideInputInsertParameters;
}

export interface PostResidentsFileRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    file?: Blob;
}

export interface PostTagForNoteRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    noteId: number;
    tag?: Tag;
}

export interface PostUserRequest {
    userInsertParameters?: UserInsertParameters;
}

export interface PutFavoriteFoodMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    favoriteFoodId: number;
    favoriteFoodMediaUpdateParameters?: FavoriteFoodMediaUpdateParameters;
}

export interface PutGreetingMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    greetingId: number;
    greetingMediaUpdateParameters?: GreetingMediaUpdateParameters;
}

export interface PutHobbyRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    hobbyId: number;
    hobbyUpdateParameters?: HobbyUpdateParameters;
}

export interface PutJobRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    jobId: number;
    jobUpdateParameters?: JobUpdateParameters;
}

export interface PutLifestyleRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    lifestyleId: number;
    lifestyleUpdateParameters?: LifestyleUpdateParameters;
}

export interface PutLinkRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    linkId: number;
    linkUpdateParameters?: LinkUpdateParameters;
}

export interface PutNoteRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    noteId: number;
    noteUpdateParameters?: NoteUpdateParameters;
}

export interface PutNoteCommentRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    noteId: number;
    noteCommentId: number;
    noteCommentUpdateParameters?: NoteCommentUpdateParameters;
}

export interface PutNoteMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    noteId: number;
    noteMediaUpdateParameters?: NoteMediaUpdateParameters;
}

export interface PutOutsideInputRequest {
    tenantIdentifier: string;
    outsideInputId: number;
    outsideInputUpdateParameters?: OutsideInputUpdateParameters;
}

export interface PutOutsideInputMediaRequest {
    tenantIdentifier: string;
    outsideInputMediaId: number;
    outsideInputMediaUpdateParameters?: OutsideInputMediaUpdateParameters;
}

export interface PutRelativeRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    relativeId: number;
    relativeUpdateParameters?: RelativeUpdateParameters;
}

export interface PutResidenceEventMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceEventId: number;
    residenceEventMediaUpdateParameters?: ResidenceEventMediaUpdateParameters;
}

export interface PutResidenceEventResidentsRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceEventId: number;
    residenceEventResidentsUpdateParameters?: ResidenceEventResidentsUpdateParameters;
}

export interface PutResidenceGroupMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceGroupId: number;
    residenceGroupMediaUpdateParameters?: ResidenceGroupMediaUpdateParameters;
}

export interface PutResidenceGroupResidentsRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceGroupId: number;
    residenceGroupResidentsUpdateParameters?: ResidenceGroupResidentsUpdateParameters;
}

export interface PutResidenceMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceMediaId: number;
    residenceMediaMetaUpdateParameters?: ResidenceMediaMetaUpdateParameters;
}

export interface PutResidentRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    residentId: number;
    residentUpdateParameters?: ResidentUpdateParameters;
}

export interface PutResidentEventRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceEventId: number;
    residenceEventUpdateParameters?: ResidenceEventUpdateParameters;
}

export interface PutResidentFavoriteFoodRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    favoriteFoodId: number;
    favoriteFoodUpdateParameters?: FavoriteFoodUpdateParameters;
}

export interface PutResidentGreetingRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    greetingId: number;
    greetingUpdateParameters?: GreetingUpdateParameters;
}

export interface PutResidentGroupRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    residenceGroupId: number;
    residenceGroupUpdateParameters?: ResidenceGroupUpdateParameters;
}

export interface PutResidentMediaRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    mediaId: number;
    mediaMetaUpdateParameters?: MediaMetaUpdateParameters;
}

export interface PutStatusForNoteRequest {
    tenantIdentifier: string;
    residenceIdentifier: string;
    unitIdentifier: string;
    noteId: number;
    noteStatusId: number;
}

export interface PutUserRequest {
    userId: string;
    userUpdateParameters?: UserUpdateParameters;
}

export interface UpdateResidentAssessmentQuestionRequest {
    tenantIdentifier: string;
    unitIdentifier: string;
    residenceIdentifier: string;
    residentAssessmentId: number;
    questionId: number;
    residentAssessmentQuestionUpdateParameters?: ResidentAssessmentQuestionUpdateParameters;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Archives a connect.
     */
    async deactivateConnectRaw(requestParameters: DeactivateConnectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateConnect.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateConnect.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateConnect.');
        }

        if (requestParameters.connectId === null || requestParameters.connectId === undefined) {
            throw new runtime.RequiredError('connectId','Required parameter requestParameters.connectId was null or undefined when calling deactivateConnect.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/connectData/connects/residentId/{residentId}/{connectId}`.replace(`{${"connectId"}}`, encodeURIComponent(String(requestParameters.connectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a connect.
     */
    async deactivateConnect(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, connectId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateConnectRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, connectId: connectId }, initOverrides);
    }

    /**
     * Archives the favorite food media.
     */
    async deactivateFavoriteFoodMediaRaw(requestParameters: DeactivateFavoriteFoodMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateFavoriteFoodMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateFavoriteFoodMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateFavoriteFoodMedia.');
        }

        if (requestParameters.favoriteFoodId === null || requestParameters.favoriteFoodId === undefined) {
            throw new runtime.RequiredError('favoriteFoodId','Required parameter requestParameters.favoriteFoodId was null or undefined when calling deactivateFavoriteFoodMedia.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling deactivateFavoriteFoodMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/favoriteFoodMediaData/favoriteFoodMedia/favoriteFoodId/{favoriteFoodId}/mediaId/{mediaId}`.replace(`{${"favoriteFoodId"}}`, encodeURIComponent(String(requestParameters.favoriteFoodId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives the favorite food media.
     */
    async deactivateFavoriteFoodMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, favoriteFoodId: number, mediaId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateFavoriteFoodMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, favoriteFoodId: favoriteFoodId, mediaId: mediaId }, initOverrides);
    }

    /**
     * Archives the greeting media.
     */
    async deactivateGreetingMediaRaw(requestParameters: DeactivateGreetingMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateGreetingMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateGreetingMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateGreetingMedia.');
        }

        if (requestParameters.greetingId === null || requestParameters.greetingId === undefined) {
            throw new runtime.RequiredError('greetingId','Required parameter requestParameters.greetingId was null or undefined when calling deactivateGreetingMedia.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling deactivateGreetingMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/greetingMediaData/greetingMedia/greetingId/{greetingId}/mediaId/{mediaId}`.replace(`{${"greetingId"}}`, encodeURIComponent(String(requestParameters.greetingId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives the greeting media.
     */
    async deactivateGreetingMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, greetingId: number, mediaId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateGreetingMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, greetingId: greetingId, mediaId: mediaId }, initOverrides);
    }

    /**
     * Archives a hobby.
     */
    async deactivateHobbyRaw(requestParameters: DeactivateHobbyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateHobby.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateHobby.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateHobby.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling deactivateHobby.');
        }

        if (requestParameters.hobbyId === null || requestParameters.hobbyId === undefined) {
            throw new runtime.RequiredError('hobbyId','Required parameter requestParameters.hobbyId was null or undefined when calling deactivateHobby.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/hobbyData/hobbies/residentId/{residentId}/{hobbyId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"hobbyId"}}`, encodeURIComponent(String(requestParameters.hobbyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a hobby.
     */
    async deactivateHobby(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, hobbyId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateHobbyRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, hobbyId: hobbyId }, initOverrides);
    }

    /**
     * Archives a job.
     */
    async deactivateJobRaw(requestParameters: DeactivateJobRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateJob.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateJob.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateJob.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling deactivateJob.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling deactivateJob.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/jobData/jobs/residentId/{residentId}/{jobId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a job.
     */
    async deactivateJob(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, jobId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateJobRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, jobId: jobId }, initOverrides);
    }

    /**
     * Archives a lifestyle.
     */
    async deactivateLifestyleRaw(requestParameters: DeactivateLifestyleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateLifestyle.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateLifestyle.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateLifestyle.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling deactivateLifestyle.');
        }

        if (requestParameters.lifestyleId === null || requestParameters.lifestyleId === undefined) {
            throw new runtime.RequiredError('lifestyleId','Required parameter requestParameters.lifestyleId was null or undefined when calling deactivateLifestyle.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/lifestyleData/lifestyles/residentId/{residentId}/{lifestyleId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"lifestyleId"}}`, encodeURIComponent(String(requestParameters.lifestyleId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a lifestyle.
     */
    async deactivateLifestyle(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, lifestyleId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateLifestyleRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, lifestyleId: lifestyleId }, initOverrides);
    }

    /**
     * Archives a link.
     */
    async deactivateLinkRaw(requestParameters: DeactivateLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateLink.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateLink.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateLink.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling deactivateLink.');
        }

        if (requestParameters.linkId === null || requestParameters.linkId === undefined) {
            throw new runtime.RequiredError('linkId','Required parameter requestParameters.linkId was null or undefined when calling deactivateLink.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/linkData/links/residentId/{residentId}/{linkId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"linkId"}}`, encodeURIComponent(String(requestParameters.linkId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a link.
     */
    async deactivateLink(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, linkId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateLinkRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, linkId: linkId }, initOverrides);
    }

    /**
     * Archives a note.
     */
    async deactivateNoteRaw(requestParameters: DeactivateNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateNote.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateNote.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateNote.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling deactivateNote.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling deactivateNote.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteData/notes/residentId/{residentId}/{noteId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a note.
     */
    async deactivateNote(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, noteId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateNoteRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, noteId: noteId }, initOverrides);
    }

    /**
     * Archives a note comment.
     */
    async deactivateNoteCommentRaw(requestParameters: DeactivateNoteCommentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateNoteComment.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateNoteComment.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateNoteComment.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling deactivateNoteComment.');
        }

        if (requestParameters.noteCommentId === null || requestParameters.noteCommentId === undefined) {
            throw new runtime.RequiredError('noteCommentId','Required parameter requestParameters.noteCommentId was null or undefined when calling deactivateNoteComment.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/NoteCommentData/notecomments/noteId/{noteId}/noteCommentId/{noteCommentId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))).replace(`{${"noteCommentId"}}`, encodeURIComponent(String(requestParameters.noteCommentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a note comment.
     */
    async deactivateNoteComment(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, noteId: number, noteCommentId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateNoteCommentRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, noteId: noteId, noteCommentId: noteCommentId }, initOverrides);
    }

    /**
     * Archives the note media.
     */
    async deactivateNoteMediaRaw(requestParameters: DeactivateNoteMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateNoteMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateNoteMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateNoteMedia.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling deactivateNoteMedia.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling deactivateNoteMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteMediaData/noteMedia/noteId/{noteId}/mediaId/{mediaId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives the note media.
     */
    async deactivateNoteMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, noteId: number, mediaId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateNoteMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, noteId: noteId, mediaId: mediaId }, initOverrides);
    }

    /**
     * Archives an outside input.
     */
    async deactivateOutsideInputRaw(requestParameters: DeactivateOutsideInputRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateOutsideInput.');
        }

        if (requestParameters.outsideInputId === null || requestParameters.outsideInputId === undefined) {
            throw new runtime.RequiredError('outsideInputId','Required parameter requestParameters.outsideInputId was null or undefined when calling deactivateOutsideInput.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/outsideInputData/outsideInput/{outsideInputId}`.replace(`{${"outsideInputId"}}`, encodeURIComponent(String(requestParameters.outsideInputId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an outside input.
     */
    async deactivateOutsideInput(tenantIdentifier: string, outsideInputId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateOutsideInputRaw({ tenantIdentifier: tenantIdentifier, outsideInputId: outsideInputId }, initOverrides);
    }

    /**
     * Archives a relative.
     */
    async deactivateRelativeRaw(requestParameters: DeactivateRelativeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateRelative.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateRelative.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateRelative.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling deactivateRelative.');
        }

        if (requestParameters.relativeId === null || requestParameters.relativeId === undefined) {
            throw new runtime.RequiredError('relativeId','Required parameter requestParameters.relativeId was null or undefined when calling deactivateRelative.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/relativeData/relatives/residentId/{residentId}/{relativeId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"relativeId"}}`, encodeURIComponent(String(requestParameters.relativeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a relative.
     */
    async deactivateRelative(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, relativeId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateRelativeRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, relativeId: relativeId }, initOverrides);
    }

    /**
     * Archives residence event.
     */
    async deactivateResidenceEventRaw(requestParameters: DeactivateResidenceEventRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateResidenceEvent.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateResidenceEvent.');
        }

        if (requestParameters.residenceEventId === null || requestParameters.residenceEventId === undefined) {
            throw new runtime.RequiredError('residenceEventId','Required parameter requestParameters.residenceEventId was null or undefined when calling deactivateResidenceEvent.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceEvent/residenceEventId/{residenceEventId}`.replace(`{${"residenceEventId"}}`, encodeURIComponent(String(requestParameters.residenceEventId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives residence event.
     */
    async deactivateResidenceEvent(tenantIdentifier: string, residenceIdentifier: string, residenceEventId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateResidenceEventRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceEventId: residenceEventId }, initOverrides);
    }

    /**
     * Archives the event media.
     */
    async deactivateResidenceEventMediaRaw(requestParameters: DeactivateResidenceEventMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateResidenceEventMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateResidenceEventMedia.');
        }

        if (requestParameters.residenceEventId === null || requestParameters.residenceEventId === undefined) {
            throw new runtime.RequiredError('residenceEventId','Required parameter requestParameters.residenceEventId was null or undefined when calling deactivateResidenceEventMedia.');
        }

        if (requestParameters.residenceMediaId === null || requestParameters.residenceMediaId === undefined) {
            throw new runtime.RequiredError('residenceMediaId','Required parameter requestParameters.residenceMediaId was null or undefined when calling deactivateResidenceEventMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceEventMediaData/residenceEventMedia/residenceEventId/{residenceEventId}/residenceMediaId/{residenceMediaId}`.replace(`{${"residenceEventId"}}`, encodeURIComponent(String(requestParameters.residenceEventId))).replace(`{${"residenceMediaId"}}`, encodeURIComponent(String(requestParameters.residenceMediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives the event media.
     */
    async deactivateResidenceEventMedia(tenantIdentifier: string, residenceIdentifier: string, residenceEventId: number, residenceMediaId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateResidenceEventMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceEventId: residenceEventId, residenceMediaId: residenceMediaId }, initOverrides);
    }

    /**
     * Archives residence group.
     */
    async deactivateResidenceGroupRaw(requestParameters: DeactivateResidenceGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateResidenceGroup.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateResidenceGroup.');
        }

        if (requestParameters.residenceGroupId === null || requestParameters.residenceGroupId === undefined) {
            throw new runtime.RequiredError('residenceGroupId','Required parameter requestParameters.residenceGroupId was null or undefined when calling deactivateResidenceGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceGroup/residenceGroupId/{residenceGroupId}`.replace(`{${"residenceGroupId"}}`, encodeURIComponent(String(requestParameters.residenceGroupId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives residence group.
     */
    async deactivateResidenceGroup(tenantIdentifier: string, residenceIdentifier: string, residenceGroupId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateResidenceGroupRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceGroupId: residenceGroupId }, initOverrides);
    }

    /**
     * Archives the group media.
     */
    async deactivateResidenceGroupMediaRaw(requestParameters: DeactivateResidenceGroupMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateResidenceGroupMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateResidenceGroupMedia.');
        }

        if (requestParameters.residenceGroupId === null || requestParameters.residenceGroupId === undefined) {
            throw new runtime.RequiredError('residenceGroupId','Required parameter requestParameters.residenceGroupId was null or undefined when calling deactivateResidenceGroupMedia.');
        }

        if (requestParameters.residenceMediaId === null || requestParameters.residenceMediaId === undefined) {
            throw new runtime.RequiredError('residenceMediaId','Required parameter requestParameters.residenceMediaId was null or undefined when calling deactivateResidenceGroupMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceGroupMediaData/residenceGroupMedia/residenceGroupId/{residenceGroupId}/residenceMediaId/{residenceMediaId}`.replace(`{${"residenceGroupId"}}`, encodeURIComponent(String(requestParameters.residenceGroupId))).replace(`{${"residenceMediaId"}}`, encodeURIComponent(String(requestParameters.residenceMediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives the group media.
     */
    async deactivateResidenceGroupMedia(tenantIdentifier: string, residenceIdentifier: string, residenceGroupId: number, residenceMediaId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateResidenceGroupMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceGroupId: residenceGroupId, residenceMediaId: residenceMediaId }, initOverrides);
    }

    /**
     * Archives residence media metadata.
     */
    async deactivateResidenceMediaRaw(requestParameters: DeactivateResidenceMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateResidenceMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateResidenceMedia.');
        }

        if (requestParameters.residenceMediaId === null || requestParameters.residenceMediaId === undefined) {
            throw new runtime.RequiredError('residenceMediaId','Required parameter requestParameters.residenceMediaId was null or undefined when calling deactivateResidenceMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceMediaData/residenceMediaData/residenceMediaId/{residenceMediaId}`.replace(`{${"residenceMediaId"}}`, encodeURIComponent(String(requestParameters.residenceMediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives residence media metadata.
     */
    async deactivateResidenceMedia(tenantIdentifier: string, residenceIdentifier: string, residenceMediaId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateResidenceMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceMediaId: residenceMediaId }, initOverrides);
    }

    /**
     * Archives a resident.
     */
    async deactivateResidentRaw(requestParameters: DeactivateResidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateResident.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateResident.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateResident.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling deactivateResident.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residentMetadata/residents/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a resident.
     */
    async deactivateResident(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateResidentRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId }, initOverrides);
    }

    /**
     * Archives Resident Assessment.
     */
    async deactivateResidentAssessmentRaw(requestParameters: DeactivateResidentAssessmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateResidentAssessment.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateResidentAssessment.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateResidentAssessment.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling deactivateResidentAssessment.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/assessmentData/assessmentProfileId/{assessmentProfileId}/residentAssessementId/{residentAssessmentId}`.replace(`{${"residentAssessmentId"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives Resident Assessment.
     */
    async deactivateResidentAssessment(tenantIdentifier: string, unitIdentifier: string, residenceIdentifier: string, residentAssessmentId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateResidentAssessmentRaw({ tenantIdentifier: tenantIdentifier, unitIdentifier: unitIdentifier, residenceIdentifier: residenceIdentifier, residentAssessmentId: residentAssessmentId }, initOverrides);
    }

    /**
     * Archives favorite food.
     */
    async deactivateResidentFavoriteFoodRaw(requestParameters: DeactivateResidentFavoriteFoodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateResidentFavoriteFood.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateResidentFavoriteFood.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateResidentFavoriteFood.');
        }

        if (requestParameters.favoriteFoodId === null || requestParameters.favoriteFoodId === undefined) {
            throw new runtime.RequiredError('favoriteFoodId','Required parameter requestParameters.favoriteFoodId was null or undefined when calling deactivateResidentFavoriteFood.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/favoriteFoodData/favoriteFoodId/{favoriteFoodId}`.replace(`{${"favoriteFoodId"}}`, encodeURIComponent(String(requestParameters.favoriteFoodId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives favorite food.
     */
    async deactivateResidentFavoriteFood(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, favoriteFoodId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateResidentFavoriteFoodRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, favoriteFoodId: favoriteFoodId }, initOverrides);
    }

    /**
     * Archives greeting.
     */
    async deactivateResidentGreetingRaw(requestParameters: DeactivateResidentGreetingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateResidentGreeting.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateResidentGreeting.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateResidentGreeting.');
        }

        if (requestParameters.greetingId === null || requestParameters.greetingId === undefined) {
            throw new runtime.RequiredError('greetingId','Required parameter requestParameters.greetingId was null or undefined when calling deactivateResidentGreeting.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/greetingData/greetingId/{greetingId}`.replace(`{${"greetingId"}}`, encodeURIComponent(String(requestParameters.greetingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives greeting.
     */
    async deactivateResidentGreeting(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, greetingId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateResidentGreetingRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, greetingId: greetingId }, initOverrides);
    }

    /**
     * Archives media metadata.
     */
    async deactivateResidentMediaRaw(requestParameters: DeactivateResidentMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateResidentMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateResidentMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateResidentMedia.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling deactivateResidentMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/mediaData/metaData/mediaId/{mediaId}`.replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives media metadata.
     */
    async deactivateResidentMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, mediaId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateResidentMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, mediaId: mediaId }, initOverrides);
    }

    /**
     * Archives a tag for a note.
     */
    async deactivateTagForNoteRaw(requestParameters: DeactivateTagForNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deactivateTagForNote.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling deactivateTagForNote.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling deactivateTagForNote.');
        }

        if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
            throw new runtime.RequiredError('tagId','Required parameter requestParameters.tagId was null or undefined when calling deactivateTagForNote.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling deactivateTagForNote.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/tagData/tags/noteId/{noteId}/tagId/{tagId}`.replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))).replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives a tag for a note.
     */
    async deactivateTagForNote(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, tagId: number, noteId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deactivateTagForNoteRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, tagId: tagId, noteId: noteId }, initOverrides);
    }

    /**
     * Decides on the deletion of a resident.
     */
    async decideOnResidentDeleteReservationRaw(requestParameters: DecideOnResidentDeleteReservationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling decideOnResidentDeleteReservation.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling decideOnResidentDeleteReservation.');
        }

        if (requestParameters.residentDeleteReservationId === null || requestParameters.residentDeleteReservationId === undefined) {
            throw new runtime.RequiredError('residentDeleteReservationId','Required parameter requestParameters.residentDeleteReservationId was null or undefined when calling decideOnResidentDeleteReservation.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residentDeleteReservation/residents/{residentId}/{residentDeleteReservationId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"residentDeleteReservationId"}}`, encodeURIComponent(String(requestParameters.residentDeleteReservationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidentDeleteReservationDeleteDecisionToJSON(requestParameters.residentDeleteReservationDeleteDecision),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decides on the deletion of a resident.
     */
    async decideOnResidentDeleteReservation(tenantIdentifier: string, residentId: number, residentDeleteReservationId: number, residentDeleteReservationDeleteDecision?: ResidentDeleteReservationDeleteDecision, initOverrides?: RequestInit): Promise<void> {
        await this.decideOnResidentDeleteReservationRaw({ tenantIdentifier: tenantIdentifier, residentId: residentId, residentDeleteReservationId: residentDeleteReservationId, residentDeleteReservationDeleteDecision: residentDeleteReservationDeleteDecision }, initOverrides);
    }

    /**
     * Archives an outside input media meta data & deletes the file.
     */
    async deleteOutsideInputMediaRaw(requestParameters: DeleteOutsideInputMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling deleteOutsideInputMedia.');
        }

        if (requestParameters.outsideInputMediaId === null || requestParameters.outsideInputMediaId === undefined) {
            throw new runtime.RequiredError('outsideInputMediaId','Required parameter requestParameters.outsideInputMediaId was null or undefined when calling deleteOutsideInputMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/outsideInputMediaData/metaData/outsideInputMediaId/{outsideInputMediaId}`.replace(`{${"outsideInputMediaId"}}`, encodeURIComponent(String(requestParameters.outsideInputMediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archives an outside input media meta data & deletes the file.
     */
    async deleteOutsideInputMedia(tenantIdentifier: string, outsideInputMediaId: number, initOverrides?: RequestInit): Promise<void> {
        await this.deleteOutsideInputMediaRaw({ tenantIdentifier: tenantIdentifier, outsideInputMediaId: outsideInputMediaId }, initOverrides);
    }

    /**
     * Deletes user.
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/keycloak/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes user.
     */
    async deleteUser(userId: string, initOverrides?: RequestInit): Promise<void> {
        await this.deleteUserRaw({ userId: userId }, initOverrides);
    }

    /**
     * Status of the  API.
     * Status of the API.
     */
    async getApiStatusRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Status of the  API.
     * Status of the API.
     */
    async getApiStatus(initOverrides?: RequestInit): Promise<string> {
        const response = await this.getApiStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * List of completed assessment profiles for some resident.
     * List of completed assessment profiles for some resident.
     */
    async getAssessedProfilesFromResidentIdRaw(requestParameters: GetAssessedProfilesFromResidentIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AssessedProfile>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getAssessedProfilesFromResidentId.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getAssessedProfilesFromResidentId.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getAssessedProfilesFromResidentId.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getAssessedProfilesFromResidentId.');
        }

        if (requestParameters.assessmentType === null || requestParameters.assessmentType === undefined) {
            throw new runtime.RequiredError('assessmentType','Required parameter requestParameters.assessmentType was null or undefined when calling getAssessedProfilesFromResidentId.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/assessmentData/residentAssessments/assessmentType/{assessmentType}/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"assessmentType"}}`, encodeURIComponent(String(requestParameters.assessmentType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssessedProfileFromJSON));
    }

    /**
     * List of completed assessment profiles for some resident.
     * List of completed assessment profiles for some resident.
     */
    async getAssessedProfilesFromResidentId(tenantIdentifier: string, unitIdentifier: string, residenceIdentifier: string, residentId: number, assessmentType: AssessmentTypeEnum, initOverrides?: RequestInit): Promise<Array<AssessedProfile>> {
        const response = await this.getAssessedProfilesFromResidentIdRaw({ tenantIdentifier: tenantIdentifier, unitIdentifier: unitIdentifier, residenceIdentifier: residenceIdentifier, residentId: residentId, assessmentType: assessmentType }, initOverrides);
        return await response.value();
    }

    /**
     * List of assessed questions specific to a target.
     * List of assessed questions specific to a target.
     */
    async getAssessedQuestionsForAssessmentTargetRaw(requestParameters: GetAssessedQuestionsForAssessmentTargetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AssessedQuestion>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getAssessedQuestionsForAssessmentTarget.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getAssessedQuestionsForAssessmentTarget.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getAssessedQuestionsForAssessmentTarget.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling getAssessedQuestionsForAssessmentTarget.');
        }

        if (requestParameters.assessmentTargetId === null || requestParameters.assessmentTargetId === undefined) {
            throw new runtime.RequiredError('assessmentTargetId','Required parameter requestParameters.assessmentTargetId was null or undefined when calling getAssessedQuestionsForAssessmentTarget.');
        }

        if (requestParameters.assessmentProfileId === null || requestParameters.assessmentProfileId === undefined) {
            throw new runtime.RequiredError('assessmentProfileId','Required parameter requestParameters.assessmentProfileId was null or undefined when calling getAssessedQuestionsForAssessmentTarget.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/assessmentData/assessmentProfileId/{assessmentProfileId}/residentAssessementId/{residentAssessmentId}/assessmentTargetId/{assessmentTargetId}/assessedQuestions`.replace(`{${"residentAssessmentId"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"assessmentTargetId"}}`, encodeURIComponent(String(requestParameters.assessmentTargetId))).replace(`{${"assessmentProfileId"}}`, encodeURIComponent(String(requestParameters.assessmentProfileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssessedQuestionFromJSON));
    }

    /**
     * List of assessed questions specific to a target.
     * List of assessed questions specific to a target.
     */
    async getAssessedQuestionsForAssessmentTarget(tenantIdentifier: string, unitIdentifier: string, residenceIdentifier: string, residentAssessmentId: number, assessmentTargetId: number, assessmentProfileId: number, initOverrides?: RequestInit): Promise<Array<AssessedQuestion>> {
        const response = await this.getAssessedQuestionsForAssessmentTargetRaw({ tenantIdentifier: tenantIdentifier, unitIdentifier: unitIdentifier, residenceIdentifier: residenceIdentifier, residentAssessmentId: residentAssessmentId, assessmentTargetId: assessmentTargetId, assessmentProfileId: assessmentProfileId }, initOverrides);
        return await response.value();
    }

    /**
     * List of assessed questions for one assessment profile.
     * List of assessed questions for one assessment profile.
     */
    async getAssessedQuestionsForResidentAssessmentRaw(requestParameters: GetAssessedQuestionsForResidentAssessmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AssessedQuestion>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getAssessedQuestionsForResidentAssessment.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getAssessedQuestionsForResidentAssessment.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getAssessedQuestionsForResidentAssessment.');
        }

        if (requestParameters.assessmentProfileId === null || requestParameters.assessmentProfileId === undefined) {
            throw new runtime.RequiredError('assessmentProfileId','Required parameter requestParameters.assessmentProfileId was null or undefined when calling getAssessedQuestionsForResidentAssessment.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling getAssessedQuestionsForResidentAssessment.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/assessmentData/assessmentProfileId/{assessmentProfileId}/residentAssessementId/{residentAssessmentId}`.replace(`{${"assessmentProfileId"}}`, encodeURIComponent(String(requestParameters.assessmentProfileId))).replace(`{${"residentAssessmentId"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssessedQuestionFromJSON));
    }

    /**
     * List of assessed questions for one assessment profile.
     * List of assessed questions for one assessment profile.
     */
    async getAssessedQuestionsForResidentAssessment(tenantIdentifier: string, unitIdentifier: string, residenceIdentifier: string, assessmentProfileId: number, residentAssessmentId: number, initOverrides?: RequestInit): Promise<Array<AssessedQuestion>> {
        const response = await this.getAssessedQuestionsForResidentAssessmentRaw({ tenantIdentifier: tenantIdentifier, unitIdentifier: unitIdentifier, residenceIdentifier: residenceIdentifier, assessmentProfileId: assessmentProfileId, residentAssessmentId: residentAssessmentId }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of assessment profiles from the database
     * Get a list of assessment profiles from the database
     */
    async getAssessmentProfilesForAssessmentTypeRaw(requestParameters: GetAssessmentProfilesForAssessmentTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AssessmentProfile>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getAssessmentProfilesForAssessmentType.');
        }

        if (requestParameters.assessmentType === null || requestParameters.assessmentType === undefined) {
            throw new runtime.RequiredError('assessmentType','Required parameter requestParameters.assessmentType was null or undefined when calling getAssessmentProfilesForAssessmentType.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/assessmentData/assessmentProfiles/{assessmentType}`.replace(`{${"assessmentType"}}`, encodeURIComponent(String(requestParameters.assessmentType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssessmentProfileFromJSON));
    }

    /**
     * Get a list of assessment profiles from the database
     * Get a list of assessment profiles from the database
     */
    async getAssessmentProfilesForAssessmentType(tenantIdentifier: string, assessmentType: AssessmentTypeEnum, initOverrides?: RequestInit): Promise<Array<AssessmentProfile>> {
        const response = await this.getAssessmentProfilesForAssessmentTypeRaw({ tenantIdentifier: tenantIdentifier, assessmentType: assessmentType }, initOverrides);
        return await response.value();
    }

    /**
     * List of assessment questions.
     * List of assessment questions.
     */
    async getAssessmentQuestionsRaw(requestParameters: GetAssessmentQuestionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Question>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getAssessmentQuestions.');
        }

        if (requestParameters.assessmentProfileId === null || requestParameters.assessmentProfileId === undefined) {
            throw new runtime.RequiredError('assessmentProfileId','Required parameter requestParameters.assessmentProfileId was null or undefined when calling getAssessmentQuestions.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/assessmentData/assessmentProfileId/{assessmentProfileId}`.replace(`{${"assessmentProfileId"}}`, encodeURIComponent(String(requestParameters.assessmentProfileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(QuestionFromJSON));
    }

    /**
     * List of assessment questions.
     * List of assessment questions.
     */
    async getAssessmentQuestions(tenantIdentifier: string, assessmentProfileId: number, initOverrides?: RequestInit): Promise<Array<Question>> {
        const response = await this.getAssessmentQuestionsRaw({ tenantIdentifier: tenantIdentifier, assessmentProfileId: assessmentProfileId }, initOverrides);
        return await response.value();
    }

    /**
     * List of assessment results for one resident assessment.
     * List of assessment results for one resident assessment.
     */
    async getAssessmentResultForResidentAssessmentRaw(requestParameters: GetAssessmentResultForResidentAssessmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AssessmentResult>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getAssessmentResultForResidentAssessment.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getAssessmentResultForResidentAssessment.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getAssessmentResultForResidentAssessment.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling getAssessmentResultForResidentAssessment.');
        }

        if (requestParameters.assessmentProfileId === null || requestParameters.assessmentProfileId === undefined) {
            throw new runtime.RequiredError('assessmentProfileId','Required parameter requestParameters.assessmentProfileId was null or undefined when calling getAssessmentResultForResidentAssessment.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/assessmentData/residentAssessementId/{residentAssessmentId}/assessmentProfileId/{assessmentProfileId}/assessmentResult`.replace(`{${"residentAssessmentId"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"assessmentProfileId"}}`, encodeURIComponent(String(requestParameters.assessmentProfileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssessmentResultFromJSON));
    }

    /**
     * List of assessment results for one resident assessment.
     * List of assessment results for one resident assessment.
     */
    async getAssessmentResultForResidentAssessment(tenantIdentifier: string, unitIdentifier: string, residenceIdentifier: string, residentAssessmentId: number, assessmentProfileId: number, initOverrides?: RequestInit): Promise<Array<AssessmentResult>> {
        const response = await this.getAssessmentResultForResidentAssessmentRaw({ tenantIdentifier: tenantIdentifier, unitIdentifier: unitIdentifier, residenceIdentifier: residenceIdentifier, residentAssessmentId: residentAssessmentId, assessmentProfileId: assessmentProfileId }, initOverrides);
        return await response.value();
    }

    /**
     * List of targetIds with their respective assessment results of a given assessment profile.
     * List of targetIds with their respective assessment results of a given assessment profile.
     */
    async getAssessmentResultsForTargetIdsForAssessmentProfileRaw(requestParameters: GetAssessmentResultsForTargetIdsForAssessmentProfileRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AssessmentResultsForTargetId>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getAssessmentResultsForTargetIdsForAssessmentProfile.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getAssessmentResultsForTargetIdsForAssessmentProfile.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getAssessmentResultsForTargetIdsForAssessmentProfile.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getAssessmentResultsForTargetIdsForAssessmentProfile.');
        }

        if (requestParameters.assessmentType === null || requestParameters.assessmentType === undefined) {
            throw new runtime.RequiredError('assessmentType','Required parameter requestParameters.assessmentType was null or undefined when calling getAssessmentResultsForTargetIdsForAssessmentProfile.');
        }

        if (requestParameters.assessmentProfileId === null || requestParameters.assessmentProfileId === undefined) {
            throw new runtime.RequiredError('assessmentProfileId','Required parameter requestParameters.assessmentProfileId was null or undefined when calling getAssessmentResultsForTargetIdsForAssessmentProfile.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/assessmentData/residentId/{residentId}/assessmentType/{assessmentType}/assessmentProfileId/{assessmentProfileId}/assessmentResultsForTargetIds`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"assessmentType"}}`, encodeURIComponent(String(requestParameters.assessmentType))).replace(`{${"assessmentProfileId"}}`, encodeURIComponent(String(requestParameters.assessmentProfileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AssessmentResultsForTargetIdFromJSON));
    }

    /**
     * List of targetIds with their respective assessment results of a given assessment profile.
     * List of targetIds with their respective assessment results of a given assessment profile.
     */
    async getAssessmentResultsForTargetIdsForAssessmentProfile(tenantIdentifier: string, unitIdentifier: string, residenceIdentifier: string, residentId: number, assessmentType: AssessmentTypeEnum, assessmentProfileId: number, initOverrides?: RequestInit): Promise<Array<AssessmentResultsForTargetId>> {
        const response = await this.getAssessmentResultsForTargetIdsForAssessmentProfileRaw({ tenantIdentifier: tenantIdentifier, unitIdentifier: unitIdentifier, residenceIdentifier: residenceIdentifier, residentId: residentId, assessmentType: assessmentType, assessmentProfileId: assessmentProfileId }, initOverrides);
        return await response.value();
    }

    /**
     * Email Address of tenant.
     * Email Address.
     */
    async getEmailAddressRaw(requestParameters: GetEmailAddressRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getEmailAddress.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/emailAddressData/emailAddress`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Email Address of tenant.
     * Email Address.
     */
    async getEmailAddress(tenantIdentifier: string, initOverrides?: RequestInit): Promise<string> {
        const response = await this.getEmailAddressRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Get all groups.
     */
    async getGroupsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Group>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/keycloak/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupFromJSON));
    }

    /**
     * Get all groups.
     */
    async getGroups(initOverrides?: RequestInit): Promise<Array<Group>> {
        const response = await this.getGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List of hobbies.
     * List of hobbies.
     */
    async getHobbiesListRaw(requestParameters: GetHobbiesListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Hobby>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getHobbiesList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getHobbiesList.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getHobbiesList.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getHobbiesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/hobbyData/hobbies/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HobbyFromJSON));
    }

    /**
     * List of hobbies.
     * List of hobbies.
     */
    async getHobbiesList(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, initOverrides?: RequestInit): Promise<Array<Hobby>> {
        const response = await this.getHobbiesListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId }, initOverrides);
        return await response.value();
    }

    /**
     * List of jobs.
     * List of jobs.
     */
    async getJobListRaw(requestParameters: GetJobListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Job>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getJobList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getJobList.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getJobList.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getJobList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/jobData/jobs/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobFromJSON));
    }

    /**
     * List of jobs.
     * List of jobs.
     */
    async getJobList(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, initOverrides?: RequestInit): Promise<Array<Job>> {
        const response = await this.getJobListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId }, initOverrides);
        return await response.value();
    }

    /**
     * List of lifestyle.
     * List of lifestyles.
     */
    async getLifestyleListRaw(requestParameters: GetLifestyleListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Lifestyle>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getLifestyleList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getLifestyleList.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getLifestyleList.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getLifestyleList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/lifestyleData/lifestyles/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LifestyleFromJSON));
    }

    /**
     * List of lifestyle.
     * List of lifestyles.
     */
    async getLifestyleList(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, initOverrides?: RequestInit): Promise<Array<Lifestyle>> {
        const response = await this.getLifestyleListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId }, initOverrides);
        return await response.value();
    }

    /**
     * List of links.
     * List of links.
     */
    async getLinkListRaw(requestParameters: GetLinkListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Link>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getLinkList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getLinkList.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getLinkList.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getLinkList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/linkData/links/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LinkFromJSON));
    }

    /**
     * List of links.
     * List of links.
     */
    async getLinkList(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, initOverrides?: RequestInit): Promise<Array<Link>> {
        const response = await this.getLinkListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId }, initOverrides);
        return await response.value();
    }

    /**
     * Get media file from the file storage.
     * Get media file from the file storage.
     */
    async getMediaRaw(requestParameters: GetMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getMedia.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling getMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/mediaData/metaData/mediaId/{mediaId}`.replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get media file from the file storage.
     * Get media file from the file storage.
     */
    async getMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, mediaId: number, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.getMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, mediaId: mediaId }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of media categories from the database
     * Get a list of media categories from the database
     */
    async getMediaCategoriesRaw(requestParameters: GetMediaCategoriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MediaCategory>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getMediaCategories.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/mediaData/mediaCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MediaCategoryFromJSON));
    }

    /**
     * Get a list of media categories from the database
     * Get a list of media categories from the database
     */
    async getMediaCategories(tenantIdentifier: string, initOverrides?: RequestInit): Promise<Array<MediaCategory>> {
        const response = await this.getMediaCategoriesRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * single note.
     * single note.
     */
    async getNoteRaw(requestParameters: GetNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Note>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getNote.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getNote.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getNote.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getNote.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling getNote.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteData/notes/residentId/{residentId}/{noteId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteFromJSON(jsonValue));
    }

    /**
     * single note.
     * single note.
     */
    async getNote(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, noteId: number, initOverrides?: RequestInit): Promise<Note> {
        const response = await this.getNoteRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, noteId: noteId }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of all statuses for notes from the database
     * Get a list of all statuses for notes from the database
     */
    async getNoteStatusesRaw(requestParameters: GetNoteStatusesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<NoteStatus>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getNoteStatuses.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getNoteStatuses.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getNoteStatuses.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteStatusData/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NoteStatusFromJSON));
    }

    /**
     * Get a list of all statuses for notes from the database
     * Get a list of all statuses for notes from the database
     */
    async getNoteStatuses(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, initOverrides?: RequestInit): Promise<Array<NoteStatus>> {
        const response = await this.getNoteStatusesRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * List of notes.
     * List of notes.
     */
    async getNotesListRaw(requestParameters: GetNotesListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Note>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getNotesList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getNotesList.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getNotesList.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getNotesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.nMostRecent !== undefined) {
            queryParameters['nMostRecent'] = requestParameters.nMostRecent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteData/notes/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NoteFromJSON));
    }

    /**
     * List of notes.
     * List of notes.
     */
    async getNotesList(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, nMostRecent?: number, initOverrides?: RequestInit): Promise<Array<Note>> {
        const response = await this.getNotesListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, nMostRecent: nMostRecent }, initOverrides);
        return await response.value();
    }

    /**
     * Get outside input media file from the file storage.
     * Get outside input media file from the file storage.
     */
    async getOutsideInputMediaRaw(requestParameters: GetOutsideInputMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getOutsideInputMedia.');
        }

        if (requestParameters.outsideInputMediaId === null || requestParameters.outsideInputMediaId === undefined) {
            throw new runtime.RequiredError('outsideInputMediaId','Required parameter requestParameters.outsideInputMediaId was null or undefined when calling getOutsideInputMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/outsideInputMediaData/metaData/outsideInputMediaId/{outsideInputMediaId}`.replace(`{${"outsideInputMediaId"}}`, encodeURIComponent(String(requestParameters.outsideInputMediaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get outside input media file from the file storage.
     * Get outside input media file from the file storage.
     */
    async getOutsideInputMedia(tenantIdentifier: string, outsideInputMediaId: number, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.getOutsideInputMediaRaw({ tenantIdentifier: tenantIdentifier, outsideInputMediaId: outsideInputMediaId }, initOverrides);
        return await response.value();
    }

    /**
     * List of media outside input.
     * List of media for outside input.
     */
    async getOutsideInputMediaListRaw(requestParameters: GetOutsideInputMediaListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<OutsideInputMediaData>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getOutsideInputMediaList.');
        }

        if (requestParameters.outsideInputId === null || requestParameters.outsideInputId === undefined) {
            throw new runtime.RequiredError('outsideInputId','Required parameter requestParameters.outsideInputId was null or undefined when calling getOutsideInputMediaList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/outsideInputMediaData/metaData/outsideInputId/{outsideInputId}`.replace(`{${"outsideInputId"}}`, encodeURIComponent(String(requestParameters.outsideInputId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutsideInputMediaDataFromJSON));
    }

    /**
     * List of media outside input.
     * List of media for outside input.
     */
    async getOutsideInputMediaList(tenantIdentifier: string, outsideInputId: number, initOverrides?: RequestInit): Promise<Array<OutsideInputMediaData>> {
        const response = await this.getOutsideInputMediaListRaw({ tenantIdentifier: tenantIdentifier, outsideInputId: outsideInputId }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of relative roles from the database
     * Get a list of relative roles from the database
     */
    async getRelativeRolesRaw(requestParameters: GetRelativeRolesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RelativeRole>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getRelativeRoles.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getRelativeRoles.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getRelativeRoles.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/relativeData/relativeRole`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RelativeRoleFromJSON));
    }

    /**
     * Get a list of relative roles from the database
     * Get a list of relative roles from the database
     */
    async getRelativeRoles(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, initOverrides?: RequestInit): Promise<Array<RelativeRole>> {
        const response = await this.getRelativeRolesRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of relative types from the database
     * Get a list of relative types from the database
     */
    async getRelativeTypesRaw(requestParameters: GetRelativeTypesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RelativeType>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getRelativeTypes.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getRelativeTypes.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getRelativeTypes.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/relativeData/relativeType`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RelativeTypeFromJSON));
    }

    /**
     * Get a list of relative types from the database
     * Get a list of relative types from the database
     */
    async getRelativeTypes(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, initOverrides?: RequestInit): Promise<Array<RelativeType>> {
        const response = await this.getRelativeTypesRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * List of relatives.
     * List of relatives.
     */
    async getRelativesListRaw(requestParameters: GetRelativesListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Relative>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getRelativesList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getRelativesList.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getRelativesList.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getRelativesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/relativeData/relatives/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RelativeFromJSON));
    }

    /**
     * List of relatives.
     * List of relatives.
     */
    async getRelativesList(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, initOverrides?: RequestInit): Promise<Array<Relative>> {
        const response = await this.getRelativesListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId }, initOverrides);
        return await response.value();
    }

    /**
     * List of suitable category mappings.
     * List of suitable category mappings.
     */
    async getResidenceEventCategoryMappingRaw(requestParameters: GetResidenceEventCategoryMappingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ResidenceEventCategoryMapping>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidenceEventCategoryMapping.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceEvent/categoryMapping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidenceEventCategoryMappingFromJSON));
    }

    /**
     * List of suitable category mappings.
     * List of suitable category mappings.
     */
    async getResidenceEventCategoryMapping(tenantIdentifier: string, initOverrides?: RequestInit): Promise<Array<ResidenceEventCategoryMapping>> {
        const response = await this.getResidenceEventCategoryMappingRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * List of residence events.
     * List of residence events.
     */
    async getResidenceEventsRaw(requestParameters: GetResidenceEventsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ResidenceEvent>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidenceEvents.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getResidenceEvents.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceEvent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidenceEventFromJSON));
    }

    /**
     * List of residence events.
     * List of residence events.
     */
    async getResidenceEvents(tenantIdentifier: string, residenceIdentifier: string, initOverrides?: RequestInit): Promise<Array<ResidenceEvent>> {
        const response = await this.getResidenceEventsRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * List of suitable category mappings.
     * List of suitable category mappings.
     */
    async getResidenceGroupCategoryMappingRaw(requestParameters: GetResidenceGroupCategoryMappingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ResidenceGroupCategoryMapping>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidenceGroupCategoryMapping.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceGroup/categoryMapping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidenceGroupCategoryMappingFromJSON));
    }

    /**
     * List of suitable category mappings.
     * List of suitable category mappings.
     */
    async getResidenceGroupCategoryMapping(tenantIdentifier: string, initOverrides?: RequestInit): Promise<Array<ResidenceGroupCategoryMapping>> {
        const response = await this.getResidenceGroupCategoryMappingRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * List of residence groups.
     * List of residence groups.
     */
    async getResidenceGroupsRaw(requestParameters: GetResidenceGroupsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ResidenceGroup>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidenceGroups.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getResidenceGroups.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceGroup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidenceGroupFromJSON));
    }

    /**
     * List of residence groups.
     * List of residence groups.
     */
    async getResidenceGroups(tenantIdentifier: string, residenceIdentifier: string, initOverrides?: RequestInit): Promise<Array<ResidenceGroup>> {
        const response = await this.getResidenceGroupsRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Get residence media file from the file storage.
     * Get residence media file from the file storage.
     */
    async getResidenceMediaRaw(requestParameters: GetResidenceMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidenceMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getResidenceMedia.');
        }

        if (requestParameters.residenceMediaId === null || requestParameters.residenceMediaId === undefined) {
            throw new runtime.RequiredError('residenceMediaId','Required parameter requestParameters.residenceMediaId was null or undefined when calling getResidenceMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceMediaData/residenceMediaData/residenceMediaId/{residenceMediaId}`.replace(`{${"residenceMediaId"}}`, encodeURIComponent(String(requestParameters.residenceMediaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get residence media file from the file storage.
     * Get residence media file from the file storage.
     */
    async getResidenceMedia(tenantIdentifier: string, residenceIdentifier: string, residenceMediaId: number, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.getResidenceMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceMediaId: residenceMediaId }, initOverrides);
        return await response.value();
    }

    /**
     * List of media.
     * List of media.
     */
    async getResidenceMediaListRaw(requestParameters: GetResidenceMediaListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ResidenceMediaData>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidenceMediaList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getResidenceMediaList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceMediaData/residenceMediaData/residence/{residence}`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidenceMediaDataFromJSON));
    }

    /**
     * List of media.
     * List of media.
     */
    async getResidenceMediaList(tenantIdentifier: string, residenceIdentifier: string, initOverrides?: RequestInit): Promise<Array<ResidenceMediaData>> {
        const response = await this.getResidenceMediaListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * List of residents for a residence.
     * List of residents for a residence.
     */
    async getResidenceResidentListRaw(requestParameters: GetResidenceResidentListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Resident>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidenceResidentList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getResidenceResidentList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residentMetadata/residenceResidents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidentFromJSON));
    }

    /**
     * List of residents for a residence.
     * List of residents for a residence.
     */
    async getResidenceResidentList(tenantIdentifier: string, residenceIdentifier: string, initOverrides?: RequestInit): Promise<Array<Resident>> {
        const response = await this.getResidenceResidentListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Extract all residences on which the user is authorized.
     * Extract all residences on which the user is authorized.
     */
    async getResidencesRaw(requestParameters: GetResidencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Residence>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidences.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/hierarchyConfiguration/residences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidenceFromJSON));
    }

    /**
     * Extract all residences on which the user is authorized.
     * Extract all residences on which the user is authorized.
     */
    async getResidences(tenantIdentifier: string, initOverrides?: RequestInit): Promise<Array<Residence>> {
        const response = await this.getResidencesRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * get a single resident.
     * get a single resident.
     */
    async getResidentRaw(requestParameters: GetResidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Resident>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResident.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getResident.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getResident.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getResident.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residentMetadata/residents/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResidentFromJSON(jsonValue));
    }

    /**
     * get a single resident.
     * get a single resident.
     */
    async getResident(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, initOverrides?: RequestInit): Promise<Resident> {
        const response = await this.getResidentRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId }, initOverrides);
        return await response.value();
    }

    /**
     * List of favorite food items.
     * List of favorite food items.
     */
    async getResidentFavoriteFoodListRaw(requestParameters: GetResidentFavoriteFoodListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FavoriteFood>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidentFavoriteFoodList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getResidentFavoriteFoodList.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getResidentFavoriteFoodList.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getResidentFavoriteFoodList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/favoriteFoodData/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FavoriteFoodFromJSON));
    }

    /**
     * List of favorite food items.
     * List of favorite food items.
     */
    async getResidentFavoriteFoodList(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, initOverrides?: RequestInit): Promise<Array<FavoriteFood>> {
        const response = await this.getResidentFavoriteFoodListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId }, initOverrides);
        return await response.value();
    }

    /**
     * List of greetings.
     * List of greetings.
     */
    async getResidentGreetingListRaw(requestParameters: GetResidentGreetingListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Greeting>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidentGreetingList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getResidentGreetingList.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getResidentGreetingList.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getResidentGreetingList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.nMostRecent !== undefined) {
            queryParameters['nMostRecent'] = requestParameters.nMostRecent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/greetingData/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GreetingFromJSON));
    }

    /**
     * List of greetings.
     * List of greetings.
     */
    async getResidentGreetingList(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, nMostRecent?: number, initOverrides?: RequestInit): Promise<Array<Greeting>> {
        const response = await this.getResidentGreetingListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, nMostRecent: nMostRecent }, initOverrides);
        return await response.value();
    }

    /**
     * List of residents for a unit.
     * List of residents for a unit.
     */
    async getResidentListRaw(requestParameters: GetResidentListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Resident>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidentList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getResidentList.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getResidentList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residentMetadata/residents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidentFromJSON));
    }

    /**
     * List of residents for a unit.
     * List of residents for a unit.
     */
    async getResidentList(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, initOverrides?: RequestInit): Promise<Array<Resident>> {
        const response = await this.getResidentListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * List of media.
     * List of media.
     */
    async getResidentMediaListRaw(requestParameters: GetResidentMediaListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ResidentMediaData>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidentMediaList.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling getResidentMediaList.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling getResidentMediaList.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling getResidentMediaList.');
        }

        if (requestParameters.isGetProfilePicture === null || requestParameters.isGetProfilePicture === undefined) {
            throw new runtime.RequiredError('isGetProfilePicture','Required parameter requestParameters.isGetProfilePicture was null or undefined when calling getResidentMediaList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.isGetProfilePicture !== undefined) {
            queryParameters['isGetProfilePicture'] = requestParameters.isGetProfilePicture;
        }

        if (requestParameters.nMostRecent !== undefined) {
            queryParameters['nMostRecent'] = requestParameters.nMostRecent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/mediaData/metaData/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidentMediaDataFromJSON));
    }

    /**
     * List of media.
     * List of media.
     */
    async getResidentMediaList(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, isGetProfilePicture: boolean, nMostRecent?: number, initOverrides?: RequestInit): Promise<Array<ResidentMediaData>> {
        const response = await this.getResidentMediaListRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, isGetProfilePicture: isGetProfilePicture, nMostRecent: nMostRecent }, initOverrides);
        return await response.value();
    }

    /**
     * Gets all residents marked for deletion.
     * Gets all residents marked for deletion.
     */
    async getResidentsMarkedForDeletionRaw(requestParameters: GetResidentsMarkedForDeletionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ResidentMarkedForDeletion>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getResidentsMarkedForDeletion.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residentDeleteReservation/residents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidentMarkedForDeletionFromJSON));
    }

    /**
     * Gets all residents marked for deletion.
     * Gets all residents marked for deletion.
     */
    async getResidentsMarkedForDeletion(tenantIdentifier: string, initOverrides?: RequestInit): Promise<Array<ResidentMarkedForDeletion>> {
        const response = await this.getResidentsMarkedForDeletionRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of stages of life from the database
     * Get a list of stages of life from the database
     */
    async getStagesOfLifeRaw(requestParameters: GetStagesOfLifeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<StageOfLife>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getStagesOfLife.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteData/stageOfLife`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StageOfLifeFromJSON));
    }

    /**
     * Get a list of stages of life from the database
     * Get a list of stages of life from the database
     */
    async getStagesOfLife(tenantIdentifier: string, initOverrides?: RequestInit): Promise<Array<StageOfLife>> {
        const response = await this.getStagesOfLifeRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of tags from the database
     * Get a list of tags from the database
     */
    async getTagsRaw(requestParameters: GetTagsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Tag>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getTags.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/tagData/tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagFromJSON));
    }

    /**
     * Get a list of tags from the database
     * Get a list of tags from the database
     */
    async getTags(tenantIdentifier: string, initOverrides?: RequestInit): Promise<Array<Tag>> {
        const response = await this.getTagsRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Extract all units on which the user is authorized.
     * Extract all units on which the user is authorized.
     */
    async getUnitsRaw(requestParameters: GetUnitsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Unit>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getUnits.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/hierarchyConfiguration/units`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UnitFromJSON));
    }

    /**
     * Extract all units on which the user is authorized.
     * Extract all units on which the user is authorized.
     */
    async getUnits(tenantIdentifier: string, initOverrides?: RequestInit): Promise<Array<Unit>> {
        const response = await this.getUnitsRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * List of downloaded unread emails.
     * List of downloaded unread emails.
     */
    async getUnreadEmailsListRaw(requestParameters: GetUnreadEmailsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<OutsideInput>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling getUnreadEmailsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/emailData/emails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OutsideInputFromJSON));
    }

    /**
     * List of downloaded unread emails.
     * List of downloaded unread emails.
     */
    async getUnreadEmailsList(tenantIdentifier: string, initOverrides?: RequestInit): Promise<Array<OutsideInput>> {
        const response = await this.getUnreadEmailsListRaw({ tenantIdentifier: tenantIdentifier }, initOverrides);
        return await response.value();
    }

    /**
     * Get all effective realm roles that the user is endowed with in the systems default Keycloak realm (locally the <local> realm).
     */
    async getUserEffectiveRolesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/keycloak/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all effective realm roles that the user is endowed with in the systems default Keycloak realm (locally the <local> realm).
     */
    async getUserEffectiveRoles(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.getUserEffectiveRolesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get user groups by Id.
     */
    async getUserGroupsByIdRaw(requestParameters: GetUserGroupsByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Group>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserGroupsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/keycloak/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupFromJSON));
    }

    /**
     * Get user groups by Id.
     */
    async getUserGroupsById(userId: string, initOverrides?: RequestInit): Promise<Array<Group>> {
        const response = await this.getUserGroupsByIdRaw({ userId: userId }, initOverrides);
        return await response.value();
    }

    /**
     * Get all users.
     */
    async getUsersRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/keycloak/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * Get all users.
     */
    async getUsers(initOverrides?: RequestInit): Promise<Array<User>> {
        const response = await this.getUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create a connect in the database by connect type.
     * Create a connect in the database by connect type.
     */
    async postConnectRaw(requestParameters: PostConnectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postConnect.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postConnect.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postConnect.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postConnect.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/connectData/connects/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectInsertParametersToJSON(requestParameters.connectInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a connect in the database by connect type.
     * Create a connect in the database by connect type.
     */
    async postConnect(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, connectInsertParameters?: ConnectInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postConnectRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, connectInsertParameters: connectInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create links to media data for a given favorite food in the database.
     * Create links to media data for a given favorite food in the database.
     */
    async postFavoriteFoodMediaRaw(requestParameters: PostFavoriteFoodMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postFavoriteFoodMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postFavoriteFoodMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postFavoriteFoodMedia.');
        }

        if (requestParameters.favoriteFoodId === null || requestParameters.favoriteFoodId === undefined) {
            throw new runtime.RequiredError('favoriteFoodId','Required parameter requestParameters.favoriteFoodId was null or undefined when calling postFavoriteFoodMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/favoriteFoodMediaData/favoriteFoodMedia/favoriteFoodId/{favoriteFoodId}`.replace(`{${"favoriteFoodId"}}`, encodeURIComponent(String(requestParameters.favoriteFoodId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteFoodMediaInsertParametersToJSON(requestParameters.favoriteFoodMediaInsertParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create links to media data for a given favorite food in the database.
     * Create links to media data for a given favorite food in the database.
     */
    async postFavoriteFoodMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, favoriteFoodId: number, favoriteFoodMediaInsertParameters?: FavoriteFoodMediaInsertParameters, initOverrides?: RequestInit): Promise<void> {
        await this.postFavoriteFoodMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, favoriteFoodId: favoriteFoodId, favoriteFoodMediaInsertParameters: favoriteFoodMediaInsertParameters }, initOverrides);
    }

    /**
     * Create links to media data for a given greeting in the database.
     * Create links to media data for a given greeting in the database.
     */
    async postGreetingMediaRaw(requestParameters: PostGreetingMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postGreetingMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postGreetingMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postGreetingMedia.');
        }

        if (requestParameters.greetingId === null || requestParameters.greetingId === undefined) {
            throw new runtime.RequiredError('greetingId','Required parameter requestParameters.greetingId was null or undefined when calling postGreetingMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/greetingMediaData/greetingMedia/greetingId/{greetingId}`.replace(`{${"greetingId"}}`, encodeURIComponent(String(requestParameters.greetingId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GreetingMediaInsertParametersToJSON(requestParameters.greetingMediaInsertParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create links to media data for a given greeting in the database.
     * Create links to media data for a given greeting in the database.
     */
    async postGreetingMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, greetingId: number, greetingMediaInsertParameters?: GreetingMediaInsertParameters, initOverrides?: RequestInit): Promise<void> {
        await this.postGreetingMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, greetingId: greetingId, greetingMediaInsertParameters: greetingMediaInsertParameters }, initOverrides);
    }

    /**
     * Create a hobby in the database.
     * Create a hobby in the database.
     */
    async postHobbyRaw(requestParameters: PostHobbyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postHobby.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postHobby.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postHobby.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postHobby.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/hobbyData/hobbies/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HobbyInsertParametersToJSON(requestParameters.hobbyInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a hobby in the database.
     * Create a hobby in the database.
     */
    async postHobby(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, hobbyInsertParameters?: HobbyInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postHobbyRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, hobbyInsertParameters: hobbyInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create a job in the database.
     * Create a job in the database.
     */
    async postJobRaw(requestParameters: PostJobRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postJob.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postJob.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postJob.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postJob.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/jobData/jobs/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobInsertParametersToJSON(requestParameters.jobInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a job in the database.
     * Create a job in the database.
     */
    async postJob(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, jobInsertParameters?: JobInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postJobRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, jobInsertParameters: jobInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create a lifestyle in the database.
     * Create a lifestyle in the database.
     */
    async postLifestyleRaw(requestParameters: PostLifestyleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postLifestyle.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postLifestyle.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postLifestyle.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postLifestyle.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/lifestyleData/lifestyles/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LifestyleInsertParametersToJSON(requestParameters.lifestyleInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a lifestyle in the database.
     * Create a lifestyle in the database.
     */
    async postLifestyle(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, lifestyleInsertParameters?: LifestyleInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postLifestyleRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, lifestyleInsertParameters: lifestyleInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create a link in the database.
     * Create a link in the database.
     */
    async postLinkRaw(requestParameters: PostLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postLink.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postLink.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postLink.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postLink.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/linkData/links/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkInsertParametersToJSON(requestParameters.linkInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a link in the database.
     * Create a link in the database.
     */
    async postLink(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, linkInsertParameters?: LinkInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postLinkRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, linkInsertParameters: linkInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create a note in the database.
     * Create a note in the database.
     */
    async postNoteRaw(requestParameters: PostNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postNote.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postNote.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postNote.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postNote.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteData/notes/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoteInsertParametersToJSON(requestParameters.noteInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a note in the database.
     * Create a note in the database.
     */
    async postNote(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, noteInsertParameters?: NoteInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postNoteRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, noteInsertParameters: noteInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create a notecomment in the database.
     * Create a notecomment in the database.
     */
    async postNoteCommentRaw(requestParameters: PostNoteCommentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postNoteComment.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postNoteComment.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postNoteComment.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling postNoteComment.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/NoteCommentData/notecomments/noteId/{noteId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoteCommentInsertParametersToJSON(requestParameters.noteCommentInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a notecomment in the database.
     * Create a notecomment in the database.
     */
    async postNoteComment(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, noteId: number, noteCommentInsertParameters?: NoteCommentInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postNoteCommentRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, noteId: noteId, noteCommentInsertParameters: noteCommentInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create links to media data for a given note in the database.
     * Create links to media data for a given note in the database.
     */
    async postNoteMediaRaw(requestParameters: PostNoteMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postNoteMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postNoteMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postNoteMedia.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling postNoteMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteMediaData/noteMedia/noteId/{noteId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoteMediaInsertParametersToJSON(requestParameters.noteMediaInsertParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create links to media data for a given note in the database.
     * Create links to media data for a given note in the database.
     */
    async postNoteMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, noteId: number, noteMediaInsertParameters?: NoteMediaInsertParameters, initOverrides?: RequestInit): Promise<void> {
        await this.postNoteMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, noteId: noteId, noteMediaInsertParameters: noteMediaInsertParameters }, initOverrides);
    }

    /**
     * Create an outside input in the database.
     * Create an outside input in the database.
     */
    async postOutsideInputRaw(requestParameters: PostOutsideInputRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postOutsideInput.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/outsideInputData/outsideInput`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OutsideInputInsertParametersToJSON(requestParameters.outsideInputInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create an outside input in the database.
     * Create an outside input in the database.
     */
    async postOutsideInput(tenantIdentifier: string, outsideInputInsertParameters?: OutsideInputInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postOutsideInputRaw({ tenantIdentifier: tenantIdentifier, outsideInputInsertParameters: outsideInputInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create a relative in the database.
     * Create a relative in the database.
     */
    async postRelativeRaw(requestParameters: PostRelativeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postRelative.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postRelative.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postRelative.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postRelative.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/relativeData/relatives/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RelativeInsertParametersToJSON(requestParameters.relativeInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a relative in the database.
     * Create a relative in the database.
     */
    async postRelative(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, relativeInsertParameters?: RelativeInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postRelativeRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, relativeInsertParameters: relativeInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create residence event in the database.
     * Create residence event in the database.
     */
    async postResidenceEventRaw(requestParameters: PostResidenceEventRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidenceEvent.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidenceEvent.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceEvent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceEventInsertParametersToJSON(requestParameters.residenceEventInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create residence event in the database.
     * Create residence event in the database.
     */
    async postResidenceEvent(tenantIdentifier: string, residenceIdentifier: string, residenceEventInsertParameters?: ResidenceEventInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postResidenceEventRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceEventInsertParameters: residenceEventInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create links to media data for a given event in the database.
     * Create links to media data for a given event in the database.
     */
    async postResidenceEventMediaRaw(requestParameters: PostResidenceEventMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidenceEventMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidenceEventMedia.');
        }

        if (requestParameters.residenceEventId === null || requestParameters.residenceEventId === undefined) {
            throw new runtime.RequiredError('residenceEventId','Required parameter requestParameters.residenceEventId was null or undefined when calling postResidenceEventMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceEventMediaData/residenceEventMedia/residenceEventId/{residenceEventId}`.replace(`{${"residenceEventId"}}`, encodeURIComponent(String(requestParameters.residenceEventId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceEventMediaInsertParametersToJSON(requestParameters.residenceEventMediaInsertParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create links to media data for a given event in the database.
     * Create links to media data for a given event in the database.
     */
    async postResidenceEventMedia(tenantIdentifier: string, residenceIdentifier: string, residenceEventId: number, residenceEventMediaInsertParameters?: ResidenceEventMediaInsertParameters, initOverrides?: RequestInit): Promise<void> {
        await this.postResidenceEventMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceEventId: residenceEventId, residenceEventMediaInsertParameters: residenceEventMediaInsertParameters }, initOverrides);
    }

    /**
     * Create links to associated residents for a given event in the database.
     * Create links to associated residents for a given event in the database.
     */
    async postResidenceEventResidentsRaw(requestParameters: PostResidenceEventResidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidenceEventResidents.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidenceEventResidents.');
        }

        if (requestParameters.residenceEventId === null || requestParameters.residenceEventId === undefined) {
            throw new runtime.RequiredError('residenceEventId','Required parameter requestParameters.residenceEventId was null or undefined when calling postResidenceEventResidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceEventResidentsData/residenceEventResidents/residenceEventId/{residenceEventId}`.replace(`{${"residenceEventId"}}`, encodeURIComponent(String(requestParameters.residenceEventId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceEventResidentsInsertParametersToJSON(requestParameters.residenceEventResidentsInsertParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create links to associated residents for a given event in the database.
     * Create links to associated residents for a given event in the database.
     */
    async postResidenceEventResidents(tenantIdentifier: string, residenceIdentifier: string, residenceEventId: number, residenceEventResidentsInsertParameters?: ResidenceEventResidentsInsertParameters, initOverrides?: RequestInit): Promise<void> {
        await this.postResidenceEventResidentsRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceEventId: residenceEventId, residenceEventResidentsInsertParameters: residenceEventResidentsInsertParameters }, initOverrides);
    }

    /**
     * Create residence group in the database.
     * Create residence group in the database.
     */
    async postResidenceGroupRaw(requestParameters: PostResidenceGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidenceGroup.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidenceGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceGroupInsertParametersToJSON(requestParameters.residenceGroupInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create residence group in the database.
     * Create residence group in the database.
     */
    async postResidenceGroup(tenantIdentifier: string, residenceIdentifier: string, residenceGroupInsertParameters?: ResidenceGroupInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postResidenceGroupRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceGroupInsertParameters: residenceGroupInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create links to media data for a given group in the database.
     * Create links to media data for a given group in the database.
     */
    async postResidenceGroupMediaRaw(requestParameters: PostResidenceGroupMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidenceGroupMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidenceGroupMedia.');
        }

        if (requestParameters.residenceGroupId === null || requestParameters.residenceGroupId === undefined) {
            throw new runtime.RequiredError('residenceGroupId','Required parameter requestParameters.residenceGroupId was null or undefined when calling postResidenceGroupMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceGroupMediaData/residenceGroupMedia/residenceGroupId/{residenceGroupId}`.replace(`{${"residenceGroupId"}}`, encodeURIComponent(String(requestParameters.residenceGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceGroupMediaInsertParametersToJSON(requestParameters.residenceGroupMediaInsertParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create links to media data for a given group in the database.
     * Create links to media data for a given group in the database.
     */
    async postResidenceGroupMedia(tenantIdentifier: string, residenceIdentifier: string, residenceGroupId: number, residenceGroupMediaInsertParameters?: ResidenceGroupMediaInsertParameters, initOverrides?: RequestInit): Promise<void> {
        await this.postResidenceGroupMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceGroupId: residenceGroupId, residenceGroupMediaInsertParameters: residenceGroupMediaInsertParameters }, initOverrides);
    }

    /**
     * Create links to associated residents for a given group in the database.
     * Create links to associated residents for a given group in the database.
     */
    async postResidenceGroupResidentsRaw(requestParameters: PostResidenceGroupResidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidenceGroupResidents.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidenceGroupResidents.');
        }

        if (requestParameters.residenceGroupId === null || requestParameters.residenceGroupId === undefined) {
            throw new runtime.RequiredError('residenceGroupId','Required parameter requestParameters.residenceGroupId was null or undefined when calling postResidenceGroupResidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceGroupResidentsData/residenceGroupResidents/residenceGroupId/{residenceGroupId}`.replace(`{${"residenceGroupId"}}`, encodeURIComponent(String(requestParameters.residenceGroupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceGroupResidentsInsertParametersToJSON(requestParameters.residenceGroupResidentsInsertParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create links to associated residents for a given group in the database.
     * Create links to associated residents for a given group in the database.
     */
    async postResidenceGroupResidents(tenantIdentifier: string, residenceIdentifier: string, residenceGroupId: number, residenceGroupResidentsInsertParameters?: ResidenceGroupResidentsInsertParameters, initOverrides?: RequestInit): Promise<void> {
        await this.postResidenceGroupResidentsRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceGroupId: residenceGroupId, residenceGroupResidentsInsertParameters: residenceGroupResidentsInsertParameters }, initOverrides);
    }

    /**
     * Create media metadata in the database.
     * Create media metadata in the database.
     */
    async postResidenceMediaRaw(requestParameters: PostResidenceMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidenceMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidenceMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.fileName !== undefined) {
            formParams.append('fileName', requestParameters.fileName as any);
        }

        if (requestParameters.comment !== undefined) {
            formParams.append('comment', requestParameters.comment as any);
        }

        const response = await this.request({
            path: `/residenceMediaData/residenceMediaData/residence/{residence}`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create media metadata in the database.
     * Create media metadata in the database.
     */
    async postResidenceMedia(tenantIdentifier: string, residenceIdentifier: string, file?: Blob, fileName?: string, comment?: string, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postResidenceMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, file: file, fileName: fileName, comment: comment }, initOverrides);
        return await response.value();
    }

    /**
     * Create a resident in the database.
     * Create a resident in the database.
     */
    async postResidentRaw(requestParameters: PostResidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResident.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResident.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postResident.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residentMetadata/residents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidentInsertParametersToJSON(requestParameters.residentInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a resident in the database.
     * Create a resident in the database.
     */
    async postResident(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentInsertParameters?: ResidentInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postResidentRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentInsertParameters: residentInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create a ResidentAssessment in the database.
     * Create a ResidentAssessment in the database.
     */
    async postResidentAssessmentRaw(requestParameters: PostResidentAssessmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidentAssessment.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postResidentAssessment.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidentAssessment.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postResidentAssessment.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/assessmentData/residentAssessments/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidentAssessmentInsertParametersToJSON(requestParameters.residentAssessmentInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a ResidentAssessment in the database.
     * Create a ResidentAssessment in the database.
     */
    async postResidentAssessment(tenantIdentifier: string, unitIdentifier: string, residenceIdentifier: string, residentId: number, residentAssessmentInsertParameters?: ResidentAssessmentInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postResidentAssessmentRaw({ tenantIdentifier: tenantIdentifier, unitIdentifier: unitIdentifier, residenceIdentifier: residenceIdentifier, residentId: residentId, residentAssessmentInsertParameters: residentAssessmentInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Creates a request for deletion of a resident in the database.
     * Creates a request for deletion of a resident in the database.
     */
    async postResidentDeleteReservationRaw(requestParameters: PostResidentDeleteReservationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidentDeleteReservation.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postResidentDeleteReservation.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residentDeleteReservation/residents/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResidentDeleteReservationInsertParametersToJSON(requestParameters.residentDeleteReservationInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a request for deletion of a resident in the database.
     * Creates a request for deletion of a resident in the database.
     */
    async postResidentDeleteReservation(tenantIdentifier: string, residentId: number, residentDeleteReservationInsertParameters?: ResidentDeleteReservationInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postResidentDeleteReservationRaw({ tenantIdentifier: tenantIdentifier, residentId: residentId, residentDeleteReservationInsertParameters: residentDeleteReservationInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create favorite food in the database.
     * Create favorite food in the database.
     */
    async postResidentFavoriteFoodRaw(requestParameters: PostResidentFavoriteFoodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidentFavoriteFood.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidentFavoriteFood.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postResidentFavoriteFood.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postResidentFavoriteFood.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/favoriteFoodData/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteFoodInsertParametersToJSON(requestParameters.favoriteFoodInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create favorite food in the database.
     * Create favorite food in the database.
     */
    async postResidentFavoriteFood(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, favoriteFoodInsertParameters?: FavoriteFoodInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postResidentFavoriteFoodRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, favoriteFoodInsertParameters: favoriteFoodInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create greeting in the database.
     * Create greeting in the database.
     */
    async postResidentGreetingRaw(requestParameters: PostResidentGreetingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidentGreeting.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidentGreeting.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postResidentGreeting.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postResidentGreeting.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/greetingData/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GreetingInsertParametersToJSON(requestParameters.greetingInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create greeting in the database.
     * Create greeting in the database.
     */
    async postResidentGreeting(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, greetingInsertParameters?: GreetingInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postResidentGreetingRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, greetingInsertParameters: greetingInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create media metadata in the database.
     * Create media metadata in the database.
     */
    async postResidentMediaRaw(requestParameters: PostResidentMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidentMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidentMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postResidentMedia.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postResidentMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.fileName !== undefined) {
            formParams.append('fileName', requestParameters.fileName as any);
        }

        if (requestParameters.comment !== undefined) {
            formParams.append('comment', requestParameters.comment as any);
        }

        if (requestParameters.stageOfLife !== undefined) {
            formParams.append('stageOfLife', requestParameters.stageOfLife as any);
        }

        const response = await this.request({
            path: `/mediaData/metaData/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create media metadata in the database.
     * Create media metadata in the database.
     */
    async postResidentMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, file?: Blob, fileName?: string, comment?: string, stageOfLife?: string, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postResidentMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, file: file, fileName: fileName, comment: comment, stageOfLife: stageOfLife }, initOverrides);
        return await response.value();
    }

    /**
     * Create media metadata in the database based on outside input data.
     * Create media metadata in the database based on outside input data.
     */
    async postResidentMediaFromOutsideInputRaw(requestParameters: PostResidentMediaFromOutsideInputRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidentMediaFromOutsideInput.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidentMediaFromOutsideInput.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postResidentMediaFromOutsideInput.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling postResidentMediaFromOutsideInput.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/mediaData/metaDataFromOutsideInput/residentId/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MediaFromOutsideInputInsertParametersToJSON(requestParameters.mediaFromOutsideInputInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create media metadata in the database based on outside input data.
     * Create media metadata in the database based on outside input data.
     */
    async postResidentMediaFromOutsideInput(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, mediaFromOutsideInputInsertParameters?: MediaFromOutsideInputInsertParameters, initOverrides?: RequestInit): Promise<number> {
        const response = await this.postResidentMediaFromOutsideInputRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, mediaFromOutsideInputInsertParameters: mediaFromOutsideInputInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Create multiple residents in the database.
     * Create multiple residents in the database.
     */
    async postResidentsFileRaw(requestParameters: PostResidentsFileRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Resident>>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postResidentsFile.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postResidentsFile.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postResidentsFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/residentsMetadata/file`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResidentFromJSON));
    }

    /**
     * Create multiple residents in the database.
     * Create multiple residents in the database.
     */
    async postResidentsFile(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, file?: Blob, initOverrides?: RequestInit): Promise<Array<Resident>> {
        const response = await this.postResidentsFileRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, file: file }, initOverrides);
        return await response.value();
    }

    /**
     * Set a tag for a note in the database.
     * Set a tag for a note in the database.
     */
    async postTagForNoteRaw(requestParameters: PostTagForNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling postTagForNote.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling postTagForNote.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling postTagForNote.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling postTagForNote.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/tagData/tags/noteId/{noteId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagToJSON(requestParameters.tag),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set a tag for a note in the database.
     * Set a tag for a note in the database.
     */
    async postTagForNote(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, noteId: number, tag?: Tag, initOverrides?: RequestInit): Promise<void> {
        await this.postTagForNoteRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, noteId: noteId, tag: tag }, initOverrides);
    }

    /**
     * Create a user.
     * Create a user
     */
    async postUserRaw(requestParameters: PostUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/keycloak/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInsertParametersToJSON(requestParameters.userInsertParameters),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Create a user.
     * Create a user
     */
    async postUser(userInsertParameters?: UserInsertParameters, initOverrides?: RequestInit): Promise<string> {
        const response = await this.postUserRaw({ userInsertParameters: userInsertParameters }, initOverrides);
        return await response.value();
    }

    /**
     * Updates links to media data for a given favorite food in the database.
     * Updates links to media data for a given favorite food in the database.
     */
    async putFavoriteFoodMediaRaw(requestParameters: PutFavoriteFoodMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putFavoriteFoodMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putFavoriteFoodMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putFavoriteFoodMedia.');
        }

        if (requestParameters.favoriteFoodId === null || requestParameters.favoriteFoodId === undefined) {
            throw new runtime.RequiredError('favoriteFoodId','Required parameter requestParameters.favoriteFoodId was null or undefined when calling putFavoriteFoodMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/favoriteFoodMediaData/favoriteFoodMedia/favoriteFoodId/{favoriteFoodId}`.replace(`{${"favoriteFoodId"}}`, encodeURIComponent(String(requestParameters.favoriteFoodId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteFoodMediaUpdateParametersToJSON(requestParameters.favoriteFoodMediaUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates links to media data for a given favorite food in the database.
     * Updates links to media data for a given favorite food in the database.
     */
    async putFavoriteFoodMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, favoriteFoodId: number, favoriteFoodMediaUpdateParameters?: FavoriteFoodMediaUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putFavoriteFoodMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, favoriteFoodId: favoriteFoodId, favoriteFoodMediaUpdateParameters: favoriteFoodMediaUpdateParameters }, initOverrides);
    }

    /**
     * Updates links to media data for a given greeting in the database.
     * Updates links to media data for a given greeting in the database.
     */
    async putGreetingMediaRaw(requestParameters: PutGreetingMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putGreetingMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putGreetingMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putGreetingMedia.');
        }

        if (requestParameters.greetingId === null || requestParameters.greetingId === undefined) {
            throw new runtime.RequiredError('greetingId','Required parameter requestParameters.greetingId was null or undefined when calling putGreetingMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/greetingMediaData/greetingMedia/greetingId/{greetingId}`.replace(`{${"greetingId"}}`, encodeURIComponent(String(requestParameters.greetingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GreetingMediaUpdateParametersToJSON(requestParameters.greetingMediaUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates links to media data for a given greeting in the database.
     * Updates links to media data for a given greeting in the database.
     */
    async putGreetingMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, greetingId: number, greetingMediaUpdateParameters?: GreetingMediaUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putGreetingMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, greetingId: greetingId, greetingMediaUpdateParameters: greetingMediaUpdateParameters }, initOverrides);
    }

    /**
     * Updates a hobby for some resident.
     * Updates a hobby for some resident.
     */
    async putHobbyRaw(requestParameters: PutHobbyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putHobby.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putHobby.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putHobby.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling putHobby.');
        }

        if (requestParameters.hobbyId === null || requestParameters.hobbyId === undefined) {
            throw new runtime.RequiredError('hobbyId','Required parameter requestParameters.hobbyId was null or undefined when calling putHobby.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/hobbyData/hobbies/residentId/{residentId}/{hobbyId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"hobbyId"}}`, encodeURIComponent(String(requestParameters.hobbyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HobbyUpdateParametersToJSON(requestParameters.hobbyUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a hobby for some resident.
     * Updates a hobby for some resident.
     */
    async putHobby(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, hobbyId: number, hobbyUpdateParameters?: HobbyUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putHobbyRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, hobbyId: hobbyId, hobbyUpdateParameters: hobbyUpdateParameters }, initOverrides);
    }

    /**
     * Updates a job for some resident.
     * Updates a job for some resident.
     */
    async putJobRaw(requestParameters: PutJobRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putJob.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putJob.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putJob.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling putJob.');
        }

        if (requestParameters.jobId === null || requestParameters.jobId === undefined) {
            throw new runtime.RequiredError('jobId','Required parameter requestParameters.jobId was null or undefined when calling putJob.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/jobData/jobs/residentId/{residentId}/{jobId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters.jobId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: JobUpdateParametersToJSON(requestParameters.jobUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a job for some resident.
     * Updates a job for some resident.
     */
    async putJob(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, jobId: number, jobUpdateParameters?: JobUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putJobRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, jobId: jobId, jobUpdateParameters: jobUpdateParameters }, initOverrides);
    }

    /**
     * Updates a lifestyle for some resident.
     * Updates a lifestyle for some resident.
     */
    async putLifestyleRaw(requestParameters: PutLifestyleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putLifestyle.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putLifestyle.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putLifestyle.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling putLifestyle.');
        }

        if (requestParameters.lifestyleId === null || requestParameters.lifestyleId === undefined) {
            throw new runtime.RequiredError('lifestyleId','Required parameter requestParameters.lifestyleId was null or undefined when calling putLifestyle.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/lifestyleData/lifestyles/residentId/{residentId}/{lifestyleId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"lifestyleId"}}`, encodeURIComponent(String(requestParameters.lifestyleId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LifestyleUpdateParametersToJSON(requestParameters.lifestyleUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a lifestyle for some resident.
     * Updates a lifestyle for some resident.
     */
    async putLifestyle(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, lifestyleId: number, lifestyleUpdateParameters?: LifestyleUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putLifestyleRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, lifestyleId: lifestyleId, lifestyleUpdateParameters: lifestyleUpdateParameters }, initOverrides);
    }

    /**
     * Updates a link for some resident.
     * Updates a link for some resident.
     */
    async putLinkRaw(requestParameters: PutLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putLink.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putLink.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putLink.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling putLink.');
        }

        if (requestParameters.linkId === null || requestParameters.linkId === undefined) {
            throw new runtime.RequiredError('linkId','Required parameter requestParameters.linkId was null or undefined when calling putLink.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/linkData/links/residentId/{residentId}/{linkId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"linkId"}}`, encodeURIComponent(String(requestParameters.linkId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LinkUpdateParametersToJSON(requestParameters.linkUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a link for some resident.
     * Updates a link for some resident.
     */
    async putLink(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, linkId: number, linkUpdateParameters?: LinkUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putLinkRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, linkId: linkId, linkUpdateParameters: linkUpdateParameters }, initOverrides);
    }

    /**
     * Updates a note for some resident.
     * Updates a note for some resident.
     */
    async putNoteRaw(requestParameters: PutNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putNote.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putNote.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putNote.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling putNote.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling putNote.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteData/notes/residentId/{residentId}/{noteId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NoteUpdateParametersToJSON(requestParameters.noteUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a note for some resident.
     * Updates a note for some resident.
     */
    async putNote(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, noteId: number, noteUpdateParameters?: NoteUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putNoteRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, noteId: noteId, noteUpdateParameters: noteUpdateParameters }, initOverrides);
    }

    /**
     * Updates a note comment for some resident.
     * Updates a note comment for some resident.
     */
    async putNoteCommentRaw(requestParameters: PutNoteCommentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putNoteComment.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putNoteComment.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putNoteComment.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling putNoteComment.');
        }

        if (requestParameters.noteCommentId === null || requestParameters.noteCommentId === undefined) {
            throw new runtime.RequiredError('noteCommentId','Required parameter requestParameters.noteCommentId was null or undefined when calling putNoteComment.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/NoteCommentData/notecomments/noteId/{noteId}/noteCommentId/{noteCommentId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))).replace(`{${"noteCommentId"}}`, encodeURIComponent(String(requestParameters.noteCommentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NoteCommentUpdateParametersToJSON(requestParameters.noteCommentUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a note comment for some resident.
     * Updates a note comment for some resident.
     */
    async putNoteComment(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, noteId: number, noteCommentId: number, noteCommentUpdateParameters?: NoteCommentUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putNoteCommentRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, noteId: noteId, noteCommentId: noteCommentId, noteCommentUpdateParameters: noteCommentUpdateParameters }, initOverrides);
    }

    /**
     * Updates links to media data for a given note in the database.
     * Updates links to media data for a given note in the database.
     */
    async putNoteMediaRaw(requestParameters: PutNoteMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putNoteMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putNoteMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putNoteMedia.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling putNoteMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteMediaData/noteMedia/noteId/{noteId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NoteMediaUpdateParametersToJSON(requestParameters.noteMediaUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates links to media data for a given note in the database.
     * Updates links to media data for a given note in the database.
     */
    async putNoteMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, noteId: number, noteMediaUpdateParameters?: NoteMediaUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putNoteMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, noteId: noteId, noteMediaUpdateParameters: noteMediaUpdateParameters }, initOverrides);
    }

    /**
     * Updates an outside input
     * Updates an outside input.
     */
    async putOutsideInputRaw(requestParameters: PutOutsideInputRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putOutsideInput.');
        }

        if (requestParameters.outsideInputId === null || requestParameters.outsideInputId === undefined) {
            throw new runtime.RequiredError('outsideInputId','Required parameter requestParameters.outsideInputId was null or undefined when calling putOutsideInput.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/outsideInputData/outsideInput/{outsideInputId}`.replace(`{${"outsideInputId"}}`, encodeURIComponent(String(requestParameters.outsideInputId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OutsideInputUpdateParametersToJSON(requestParameters.outsideInputUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates an outside input
     * Updates an outside input.
     */
    async putOutsideInput(tenantIdentifier: string, outsideInputId: number, outsideInputUpdateParameters?: OutsideInputUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putOutsideInputRaw({ tenantIdentifier: tenantIdentifier, outsideInputId: outsideInputId, outsideInputUpdateParameters: outsideInputUpdateParameters }, initOverrides);
    }

    /**
     * Updates outside input media for some resident.
     * Updates outside input media for some resident.
     */
    async putOutsideInputMediaRaw(requestParameters: PutOutsideInputMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putOutsideInputMedia.');
        }

        if (requestParameters.outsideInputMediaId === null || requestParameters.outsideInputMediaId === undefined) {
            throw new runtime.RequiredError('outsideInputMediaId','Required parameter requestParameters.outsideInputMediaId was null or undefined when calling putOutsideInputMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/outsideInputMediaData/metaData/outsideInputMediaId/{outsideInputMediaId}`.replace(`{${"outsideInputMediaId"}}`, encodeURIComponent(String(requestParameters.outsideInputMediaId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OutsideInputMediaUpdateParametersToJSON(requestParameters.outsideInputMediaUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates outside input media for some resident.
     * Updates outside input media for some resident.
     */
    async putOutsideInputMedia(tenantIdentifier: string, outsideInputMediaId: number, outsideInputMediaUpdateParameters?: OutsideInputMediaUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putOutsideInputMediaRaw({ tenantIdentifier: tenantIdentifier, outsideInputMediaId: outsideInputMediaId, outsideInputMediaUpdateParameters: outsideInputMediaUpdateParameters }, initOverrides);
    }

    /**
     * Updates a relative for some resident.
     * Updates a relative for some resident.
     */
    async putRelativeRaw(requestParameters: PutRelativeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putRelative.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putRelative.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putRelative.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling putRelative.');
        }

        if (requestParameters.relativeId === null || requestParameters.relativeId === undefined) {
            throw new runtime.RequiredError('relativeId','Required parameter requestParameters.relativeId was null or undefined when calling putRelative.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/relativeData/relatives/residentId/{residentId}/{relativeId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))).replace(`{${"relativeId"}}`, encodeURIComponent(String(requestParameters.relativeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RelativeUpdateParametersToJSON(requestParameters.relativeUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a relative for some resident.
     * Updates a relative for some resident.
     */
    async putRelative(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, relativeId: number, relativeUpdateParameters?: RelativeUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putRelativeRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, relativeId: relativeId, relativeUpdateParameters: relativeUpdateParameters }, initOverrides);
    }

    /**
     * Updates links to media data for a given event in the database.
     * Updates links to media data for a given event in the database.
     */
    async putResidenceEventMediaRaw(requestParameters: PutResidenceEventMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidenceEventMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidenceEventMedia.');
        }

        if (requestParameters.residenceEventId === null || requestParameters.residenceEventId === undefined) {
            throw new runtime.RequiredError('residenceEventId','Required parameter requestParameters.residenceEventId was null or undefined when calling putResidenceEventMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceEventMediaData/residenceEventMedia/residenceEventId/{residenceEventId}`.replace(`{${"residenceEventId"}}`, encodeURIComponent(String(requestParameters.residenceEventId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceEventMediaUpdateParametersToJSON(requestParameters.residenceEventMediaUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates links to media data for a given event in the database.
     * Updates links to media data for a given event in the database.
     */
    async putResidenceEventMedia(tenantIdentifier: string, residenceIdentifier: string, residenceEventId: number, residenceEventMediaUpdateParameters?: ResidenceEventMediaUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidenceEventMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceEventId: residenceEventId, residenceEventMediaUpdateParameters: residenceEventMediaUpdateParameters }, initOverrides);
    }

    /**
     * Updates links to associated residents for a given event in the database.
     * Updates links to associated residents for a given event in the database.
     */
    async putResidenceEventResidentsRaw(requestParameters: PutResidenceEventResidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidenceEventResidents.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidenceEventResidents.');
        }

        if (requestParameters.residenceEventId === null || requestParameters.residenceEventId === undefined) {
            throw new runtime.RequiredError('residenceEventId','Required parameter requestParameters.residenceEventId was null or undefined when calling putResidenceEventResidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceEventResidentsData/residenceEventResidents/residenceEventId/{residenceEventId}`.replace(`{${"residenceEventId"}}`, encodeURIComponent(String(requestParameters.residenceEventId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceEventResidentsUpdateParametersToJSON(requestParameters.residenceEventResidentsUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates links to associated residents for a given event in the database.
     * Updates links to associated residents for a given event in the database.
     */
    async putResidenceEventResidents(tenantIdentifier: string, residenceIdentifier: string, residenceEventId: number, residenceEventResidentsUpdateParameters?: ResidenceEventResidentsUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidenceEventResidentsRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceEventId: residenceEventId, residenceEventResidentsUpdateParameters: residenceEventResidentsUpdateParameters }, initOverrides);
    }

    /**
     * Updates links to media data for a given group in the database.
     * Updates links to media data for a given group in the database.
     */
    async putResidenceGroupMediaRaw(requestParameters: PutResidenceGroupMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidenceGroupMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidenceGroupMedia.');
        }

        if (requestParameters.residenceGroupId === null || requestParameters.residenceGroupId === undefined) {
            throw new runtime.RequiredError('residenceGroupId','Required parameter requestParameters.residenceGroupId was null or undefined when calling putResidenceGroupMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceGroupMediaData/residenceGroupMedia/residenceGroupId/{residenceGroupId}`.replace(`{${"residenceGroupId"}}`, encodeURIComponent(String(requestParameters.residenceGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceGroupMediaUpdateParametersToJSON(requestParameters.residenceGroupMediaUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates links to media data for a given group in the database.
     * Updates links to media data for a given group in the database.
     */
    async putResidenceGroupMedia(tenantIdentifier: string, residenceIdentifier: string, residenceGroupId: number, residenceGroupMediaUpdateParameters?: ResidenceGroupMediaUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidenceGroupMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceGroupId: residenceGroupId, residenceGroupMediaUpdateParameters: residenceGroupMediaUpdateParameters }, initOverrides);
    }

    /**
     * Updates links to associated residents for a given group in the database.
     * Updates links to associated residents for a given group in the database.
     */
    async putResidenceGroupResidentsRaw(requestParameters: PutResidenceGroupResidentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidenceGroupResidents.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidenceGroupResidents.');
        }

        if (requestParameters.residenceGroupId === null || requestParameters.residenceGroupId === undefined) {
            throw new runtime.RequiredError('residenceGroupId','Required parameter requestParameters.residenceGroupId was null or undefined when calling putResidenceGroupResidents.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceGroupResidentsData/residenceGroupResidents/residenceGroupId/{residenceGroupId}`.replace(`{${"residenceGroupId"}}`, encodeURIComponent(String(requestParameters.residenceGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceGroupResidentsUpdateParametersToJSON(requestParameters.residenceGroupResidentsUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates links to associated residents for a given group in the database.
     * Updates links to associated residents for a given group in the database.
     */
    async putResidenceGroupResidents(tenantIdentifier: string, residenceIdentifier: string, residenceGroupId: number, residenceGroupResidentsUpdateParameters?: ResidenceGroupResidentsUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidenceGroupResidentsRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceGroupId: residenceGroupId, residenceGroupResidentsUpdateParameters: residenceGroupResidentsUpdateParameters }, initOverrides);
    }

    /**
     * Updates residence media for some resident.
     * Updates residence media for some resident.
     */
    async putResidenceMediaRaw(requestParameters: PutResidenceMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidenceMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidenceMedia.');
        }

        if (requestParameters.residenceMediaId === null || requestParameters.residenceMediaId === undefined) {
            throw new runtime.RequiredError('residenceMediaId','Required parameter requestParameters.residenceMediaId was null or undefined when calling putResidenceMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceMediaData/residenceMediaData/residenceMediaId/{residenceMediaId}`.replace(`{${"residenceMediaId"}}`, encodeURIComponent(String(requestParameters.residenceMediaId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceMediaMetaUpdateParametersToJSON(requestParameters.residenceMediaMetaUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates residence media for some resident.
     * Updates residence media for some resident.
     */
    async putResidenceMedia(tenantIdentifier: string, residenceIdentifier: string, residenceMediaId: number, residenceMediaMetaUpdateParameters?: ResidenceMediaMetaUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidenceMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceMediaId: residenceMediaId, residenceMediaMetaUpdateParameters: residenceMediaMetaUpdateParameters }, initOverrides);
    }

    /**
     * Updates a resident.
     * Updates a resident.
     */
    async putResidentRaw(requestParameters: PutResidentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResident.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResident.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putResident.');
        }

        if (requestParameters.residentId === null || requestParameters.residentId === undefined) {
            throw new runtime.RequiredError('residentId','Required parameter requestParameters.residentId was null or undefined when calling putResident.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residentMetadata/residents/{residentId}`.replace(`{${"residentId"}}`, encodeURIComponent(String(requestParameters.residentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidentUpdateParametersToJSON(requestParameters.residentUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a resident.
     * Updates a resident.
     */
    async putResident(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, residentId: number, residentUpdateParameters?: ResidentUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidentRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, residentId: residentId, residentUpdateParameters: residentUpdateParameters }, initOverrides);
    }

    /**
     * Updates resident event data for some residence.
     * Updates residence event data for some residence.
     */
    async putResidentEventRaw(requestParameters: PutResidentEventRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidentEvent.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidentEvent.');
        }

        if (requestParameters.residenceEventId === null || requestParameters.residenceEventId === undefined) {
            throw new runtime.RequiredError('residenceEventId','Required parameter requestParameters.residenceEventId was null or undefined when calling putResidentEvent.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceEvent/residenceEventId/{residenceEventId}`.replace(`{${"residenceEventId"}}`, encodeURIComponent(String(requestParameters.residenceEventId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceEventUpdateParametersToJSON(requestParameters.residenceEventUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates resident event data for some residence.
     * Updates residence event data for some residence.
     */
    async putResidentEvent(tenantIdentifier: string, residenceIdentifier: string, residenceEventId: number, residenceEventUpdateParameters?: ResidenceEventUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidentEventRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceEventId: residenceEventId, residenceEventUpdateParameters: residenceEventUpdateParameters }, initOverrides);
    }

    /**
     * Updates favorite food data for some resident.
     * Updates favorite food data for some resident.
     */
    async putResidentFavoriteFoodRaw(requestParameters: PutResidentFavoriteFoodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidentFavoriteFood.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidentFavoriteFood.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putResidentFavoriteFood.');
        }

        if (requestParameters.favoriteFoodId === null || requestParameters.favoriteFoodId === undefined) {
            throw new runtime.RequiredError('favoriteFoodId','Required parameter requestParameters.favoriteFoodId was null or undefined when calling putResidentFavoriteFood.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/favoriteFoodData/favoriteFoodId/{favoriteFoodId}`.replace(`{${"favoriteFoodId"}}`, encodeURIComponent(String(requestParameters.favoriteFoodId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FavoriteFoodUpdateParametersToJSON(requestParameters.favoriteFoodUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates favorite food data for some resident.
     * Updates favorite food data for some resident.
     */
    async putResidentFavoriteFood(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, favoriteFoodId: number, favoriteFoodUpdateParameters?: FavoriteFoodUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidentFavoriteFoodRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, favoriteFoodId: favoriteFoodId, favoriteFoodUpdateParameters: favoriteFoodUpdateParameters }, initOverrides);
    }

    /**
     * Updates greeting data for some resident.
     * Updates greeting data for some resident.
     */
    async putResidentGreetingRaw(requestParameters: PutResidentGreetingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidentGreeting.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidentGreeting.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putResidentGreeting.');
        }

        if (requestParameters.greetingId === null || requestParameters.greetingId === undefined) {
            throw new runtime.RequiredError('greetingId','Required parameter requestParameters.greetingId was null or undefined when calling putResidentGreeting.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/greetingData/greetingId/{greetingId}`.replace(`{${"greetingId"}}`, encodeURIComponent(String(requestParameters.greetingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GreetingUpdateParametersToJSON(requestParameters.greetingUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates greeting data for some resident.
     * Updates greeting data for some resident.
     */
    async putResidentGreeting(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, greetingId: number, greetingUpdateParameters?: GreetingUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidentGreetingRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, greetingId: greetingId, greetingUpdateParameters: greetingUpdateParameters }, initOverrides);
    }

    /**
     * Updates resident group data for some residence.
     * Updates residence group data for some residence.
     */
    async putResidentGroupRaw(requestParameters: PutResidentGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidentGroup.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidentGroup.');
        }

        if (requestParameters.residenceGroupId === null || requestParameters.residenceGroupId === undefined) {
            throw new runtime.RequiredError('residenceGroupId','Required parameter requestParameters.residenceGroupId was null or undefined when calling putResidentGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/residenceGroup/residenceGroupId/{residenceGroupId}`.replace(`{${"residenceGroupId"}}`, encodeURIComponent(String(requestParameters.residenceGroupId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidenceGroupUpdateParametersToJSON(requestParameters.residenceGroupUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates resident group data for some residence.
     * Updates residence group data for some residence.
     */
    async putResidentGroup(tenantIdentifier: string, residenceIdentifier: string, residenceGroupId: number, residenceGroupUpdateParameters?: ResidenceGroupUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidentGroupRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, residenceGroupId: residenceGroupId, residenceGroupUpdateParameters: residenceGroupUpdateParameters }, initOverrides);
    }

    /**
     * Updates media for some resident.
     * Updates media for some resident.
     */
    async putResidentMediaRaw(requestParameters: PutResidentMediaRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putResidentMedia.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putResidentMedia.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putResidentMedia.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling putResidentMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/mediaData/metaData/mediaId/{mediaId}`.replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaMetaUpdateParametersToJSON(requestParameters.mediaMetaUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates media for some resident.
     * Updates media for some resident.
     */
    async putResidentMedia(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, mediaId: number, mediaMetaUpdateParameters?: MediaMetaUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putResidentMediaRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, mediaId: mediaId, mediaMetaUpdateParameters: mediaMetaUpdateParameters }, initOverrides);
    }

    /**
     * Change status for a note in the database.
     * Change status for a note in the database.
     */
    async putStatusForNoteRaw(requestParameters: PutStatusForNoteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling putStatusForNote.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling putStatusForNote.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling putStatusForNote.');
        }

        if (requestParameters.noteId === null || requestParameters.noteId === undefined) {
            throw new runtime.RequiredError('noteId','Required parameter requestParameters.noteId was null or undefined when calling putStatusForNote.');
        }

        if (requestParameters.noteStatusId === null || requestParameters.noteStatusId === undefined) {
            throw new runtime.RequiredError('noteStatusId','Required parameter requestParameters.noteStatusId was null or undefined when calling putStatusForNote.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/noteStatusData/status/noteId/{noteId}/noteStatusId/{noteStatusId}`.replace(`{${"noteId"}}`, encodeURIComponent(String(requestParameters.noteId))).replace(`{${"noteStatusId"}}`, encodeURIComponent(String(requestParameters.noteStatusId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change status for a note in the database.
     * Change status for a note in the database.
     */
    async putStatusForNote(tenantIdentifier: string, residenceIdentifier: string, unitIdentifier: string, noteId: number, noteStatusId: number, initOverrides?: RequestInit): Promise<void> {
        await this.putStatusForNoteRaw({ tenantIdentifier: tenantIdentifier, residenceIdentifier: residenceIdentifier, unitIdentifier: unitIdentifier, noteId: noteId, noteStatusId: noteStatusId }, initOverrides);
    }

    /**
     * Updates a user
     * Updates a user.
     */
    async putUserRaw(requestParameters: PutUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling putUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/keycloak/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateParametersToJSON(requestParameters.userUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a user
     * Updates a user.
     */
    async putUser(userId: string, userUpdateParameters?: UserUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.putUserRaw({ userId: userId, userUpdateParameters: userUpdateParameters }, initOverrides);
    }

    /**
     * Updates a Resident Assessment for some resident.
     * Updates a Resident Assessment for some resident.
     */
    async updateResidentAssessmentQuestionRaw(requestParameters: UpdateResidentAssessmentQuestionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantIdentifier === null || requestParameters.tenantIdentifier === undefined) {
            throw new runtime.RequiredError('tenantIdentifier','Required parameter requestParameters.tenantIdentifier was null or undefined when calling updateResidentAssessmentQuestion.');
        }

        if (requestParameters.unitIdentifier === null || requestParameters.unitIdentifier === undefined) {
            throw new runtime.RequiredError('unitIdentifier','Required parameter requestParameters.unitIdentifier was null or undefined when calling updateResidentAssessmentQuestion.');
        }

        if (requestParameters.residenceIdentifier === null || requestParameters.residenceIdentifier === undefined) {
            throw new runtime.RequiredError('residenceIdentifier','Required parameter requestParameters.residenceIdentifier was null or undefined when calling updateResidentAssessmentQuestion.');
        }

        if (requestParameters.residentAssessmentId === null || requestParameters.residentAssessmentId === undefined) {
            throw new runtime.RequiredError('residentAssessmentId','Required parameter requestParameters.residentAssessmentId was null or undefined when calling updateResidentAssessmentQuestion.');
        }

        if (requestParameters.questionId === null || requestParameters.questionId === undefined) {
            throw new runtime.RequiredError('questionId','Required parameter requestParameters.questionId was null or undefined when calling updateResidentAssessmentQuestion.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenantIdentifier !== undefined) {
            queryParameters['tenantIdentifier'] = requestParameters.tenantIdentifier;
        }

        if (requestParameters.unitIdentifier !== undefined) {
            queryParameters['unitIdentifier'] = requestParameters.unitIdentifier;
        }

        if (requestParameters.residenceIdentifier !== undefined) {
            queryParameters['residenceIdentifier'] = requestParameters.residenceIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("keycloak", ["email"]);
        }

        const response = await this.request({
            path: `/assessmentData/residentAssessments/residentAssessmentId/{residentAssessmentId}/questionId/{questionId}`.replace(`{${"residentAssessmentId"}}`, encodeURIComponent(String(requestParameters.residentAssessmentId))).replace(`{${"questionId"}}`, encodeURIComponent(String(requestParameters.questionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResidentAssessmentQuestionUpdateParametersToJSON(requestParameters.residentAssessmentQuestionUpdateParameters),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates a Resident Assessment for some resident.
     * Updates a Resident Assessment for some resident.
     */
    async updateResidentAssessmentQuestion(tenantIdentifier: string, unitIdentifier: string, residenceIdentifier: string, residentAssessmentId: number, questionId: number, residentAssessmentQuestionUpdateParameters?: ResidentAssessmentQuestionUpdateParameters, initOverrides?: RequestInit): Promise<void> {
        await this.updateResidentAssessmentQuestionRaw({ tenantIdentifier: tenantIdentifier, unitIdentifier: unitIdentifier, residenceIdentifier: residenceIdentifier, residentAssessmentId: residentAssessmentId, questionId: questionId, residentAssessmentQuestionUpdateParameters: residentAssessmentQuestionUpdateParameters }, initOverrides);
    }

}
