/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidentInsertParameters
 */
export interface ResidentInsertParameters {
    /**
     * 
     * @type {string}
     * @memberof ResidentInsertParameters
     */
    residenceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentInsertParameters
     */
    unitIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentInsertParameters
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentInsertParameters
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentInsertParameters
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentInsertParameters
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentInsertParameters
     */
    associatedCaregiver?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentInsertParameters
     */
    birthplace?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentInsertParameters
     */
    birthname?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentInsertParameters
     */
    nickname?: string;
    /**
     * 
     * @type {Date}
     * @memberof ResidentInsertParameters
     */
    birthday?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ResidentInsertParameters
     */
    residentSince?: Date;
}

export function ResidentInsertParametersFromJSON(json: any): ResidentInsertParameters {
    return ResidentInsertParametersFromJSONTyped(json, false);
}

export function ResidentInsertParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentInsertParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'residenceIdentifier': !exists(json, 'residenceIdentifier') ? undefined : json['residenceIdentifier'],
        'unitIdentifier': !exists(json, 'unitIdentifier') ? undefined : json['unitIdentifier'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'room': !exists(json, 'room') ? undefined : json['room'],
        'associatedCaregiver': !exists(json, 'associatedCaregiver') ? undefined : json['associatedCaregiver'],
        'birthplace': !exists(json, 'birthplace') ? undefined : json['birthplace'],
        'birthname': !exists(json, 'birthname') ? undefined : json['birthname'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'residentSince': !exists(json, 'residentSince') ? undefined : (new Date(json['residentSince'])),
    };
}

export function ResidentInsertParametersToJSON(value?: ResidentInsertParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'residenceIdentifier': value.residenceIdentifier,
        'unitIdentifier': value.unitIdentifier,
        'firstName': value.firstName,
        'surname': value.surname,
        'gender': value.gender,
        'room': value.room,
        'associatedCaregiver': value.associatedCaregiver,
        'birthplace': value.birthplace,
        'birthname': value.birthname,
        'nickname': value.nickname,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substr(0,10)),
        'residentSince': value.residentSince === undefined ? undefined : (value.residentSince.toISOString().substr(0,10)),
    };
}


