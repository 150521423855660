/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NoteCategory,
    NoteCategoryFromJSON,
    NoteCategoryFromJSONTyped,
    NoteCategoryToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface NoteUpdateParameters
 */
export interface NoteUpdateParameters {
    /**
     * 
     * @type {string}
     * @memberof NoteUpdateParameters
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteUpdateParameters
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteUpdateParameters
     */
    stageOfLife?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteUpdateParameters
     */
    pointInTime?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof NoteUpdateParameters
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {NoteCategory}
     * @memberof NoteUpdateParameters
     */
    category?: NoteCategory;
    /**
     * 
     * @type {boolean}
     * @memberof NoteUpdateParameters
     */
    relevant?: boolean;
}

export function NoteUpdateParametersFromJSON(json: any): NoteUpdateParameters {
    return NoteUpdateParametersFromJSONTyped(json, false);
}

export function NoteUpdateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteUpdateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'stageOfLife': !exists(json, 'stageOfLife') ? undefined : json['stageOfLife'],
        'pointInTime': !exists(json, 'pointInTime') ? undefined : json['pointInTime'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'category': !exists(json, 'category') ? undefined : NoteCategoryFromJSON(json['category']),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function NoteUpdateParametersToJSON(value?: NoteUpdateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'content': value.content,
        'stageOfLife': value.stageOfLife,
        'pointInTime': value.pointInTime,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'category': NoteCategoryToJSON(value.category),
        'relevant': value.relevant,
    };
}


