/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectData,
    ConnectDataFromJSON,
    ConnectDataFromJSONTyped,
    ConnectDataToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface Lifestyle
 */
export interface Lifestyle {
    /**
     * 
     * @type {number}
     * @memberof Lifestyle
     */
    lifestyleId?: number;
    /**
     * 
     * @type {number}
     * @memberof Lifestyle
     */
    residentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Lifestyle
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Lifestyle
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Lifestyle
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Lifestyle
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Array<ConnectData>}
     * @memberof Lifestyle
     */
    connects?: Array<ConnectData>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Lifestyle
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof Lifestyle
     */
    relevant?: boolean;
}

export function LifestyleFromJSON(json: any): Lifestyle {
    return LifestyleFromJSONTyped(json, false);
}

export function LifestyleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Lifestyle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lifestyleId': !exists(json, 'lifestyleId') ? undefined : json['lifestyleId'],
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'connects': !exists(json, 'connects') ? undefined : ((json['connects'] as Array<any>).map(ConnectDataFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function LifestyleToJSON(value?: Lifestyle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lifestyleId': value.lifestyleId,
        'residentId': value.residentId,
        'name': value.name,
        'description': value.description,
        'tenantIdentifier': value.tenantIdentifier,
        'modifyingUser': value.modifyingUser,
        'connects': value.connects === undefined ? undefined : ((value.connects as Array<any>).map(ConnectDataToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


