import { useCallback } from 'react';
import { Paper, Button, Tooltip, TableCell, TextField, InputAdornment, Grid } from '@mui/material';
import MaterialTable, { Column, MTableBodyRow } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { Edit, Face, Info, ExitToApp } from '@mui/icons-material';

import { Resident } from '../generated';
import {
	formatDate,
	getGenderIcon,
	GetNewIcon,
	viewportHeight,
} from '../components/format';
import grey from '@mui/material/colors/grey';

interface ResidentsTableViewProps {
	residentList: Resident[];
	forwardToResidentProfile: any;
	handleClickInfo: any;
	handleClickOpen: any;
	isAuthorized: boolean;
}

export default function ResidentsTableView({
	residentList,
	forwardToResidentProfile,
	handleClickInfo,
	handleClickOpen,
	isAuthorized,
}: ResidentsTableViewProps) {
	const columns: Column<Resident>[] = [
		{
			sorting: false,
			render: useCallback(
				(data) => {
					return (
							<Tooltip title='Öffne Steckbrief'>
								<Button onClick={() => forwardToResidentProfile(data)}>
									{<Face sx={{ color: 'green' }} />}
								</Button>
							</Tooltip>	
					);
				},
				[forwardToResidentProfile]
			),
		},
		{ title: 'Nachname', field: 'surname', defaultSort: 'asc' },
		{ title: 'Vorname', field: 'firstName' },
		{
			field: 'gender',
			filtering: false,
			align: 'center',
			render: useCallback((data) => getGenderIcon(data.gender), []),
		},
		{ title: 'Zimmer', field: 'room' },
		{ title: 'Bezugspflegekraft', field: 'associatedCaregiver' },
		{
			title: 'Geburtsdatum',
			field: 'birthday',
			type: 'date',
			filtering: false,
			hidden: window.innerHeight > window.innerWidth,
			render: useCallback((data) => formatDate(data.birthday), []),
		},
		{
			title: 'Bewohner:in seit',
			field: 'residentSince',
			type: 'date',
			filtering: false,
			hidden: window.innerHeight > window.innerWidth,
			render: useCallback(
				(data: Resident) => {
					return (
						<TextField 
											
											fullWidth
											name='Einzugsdatum'
											value={formatDate(data.residentSince)}
											disabled={true}
											variant='standard'
											InputLabelProps={{ style: { fontSize: 18 } }}
											InputProps={{
												disableUnderline: true,
												style: { fontSize: 18},
												endAdornment: (
												<InputAdornment position = 'end'>
													{GetNewIcon(data.residentSince, false)}
											  	</InputAdornment>
												),
										  	}}
										/>
					);
				},
				[]
			),
		},
		

		{
			title: 'Geändert am',
			field: 'lastChangeDate',
			type: 'date',
			hidden: window.innerHeight > window.innerWidth,
			filtering: false,

			render: useCallback((data) => formatDate(data.lastChangeDate), []),
		},
		{
			sorting: false,
			render: useCallback(
				(data: Resident) => {
					return (
						<Tooltip title='Zeige die letzten Änderungen'>
							<Button onClick={() => handleClickInfo(data)}>
								{<Info color='primary' />}
							</Button>
						</Tooltip>
					);
				},
				[handleClickInfo]
			),
		},
		{
			sorting: false,
			render: useCallback(
				(data: Resident) => {
					return (
						<Tooltip title={isAuthorized ? 'Bearbeite Bewohner:In' : ''}>
							<span>
								<Button
									disabled={!isAuthorized}
									onClick={() => handleClickOpen('edit', data)}>
									<Edit color={isAuthorized ? 'primary' : 'secondary'} />
								</Button>
							</span>
						</Tooltip>
					);
				},
				[isAuthorized, handleClickOpen]
			),
		},
	];

	return (
		<MaterialTable
			style={{ borderRadius: '30px', color: grey[500], fontSize: '12' }}
			localization={{
				body: {
					emptyDataSourceMessage:
						'Keine Bewohner:innen für den ausgewählten Wohnbereich hinterlegt.',
				},
				pagination: {
					labelDisplayedRows: '{from}-{to} von {count}',
					labelRowsSelect: 'Zeilen',
					labelRowsPerPage: 'Zeilen pro Seite:',
				},
			}}
			icons={MaterialTableIcons()}
			columns={columns}
			data={residentList}
			components={{
				Container: (props) => <Paper {...props} elevation={3} />,
				Row: (props) => <MTableBodyRow id='residentId' {...props} />,
			}}
			options={{
				paging: true,
				pageSize: window.innerHeight > window.innerWidth ? 20 : 5,
				showTitle: false,
				sorting: true,
				filtering: true,
				search: false,
				rowStyle: { fontSize: 16 },
				headerStyle: {
					position: 'sticky',
					top: 0,
					backgroundColor: 'white',
				},
				maxBodyHeight: viewportHeight(80),
			}}
		/>
	);
}
