import { Badge, Button, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { AddAPhoto, ControlPoint, Delete, Edit, Info, PlayArrow } from '@mui/icons-material';
import TabPanel from './TabPanel';
import { useCallback, useState } from 'react';
import MaterialTable, { Column, MTableBodyRow } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { favoriteFoodTypeItems, TCCStyledBadgeButton, isEmptyField } from './format';
import { FavoriteFood } from '../generated';
import AddLinkIcon from '@mui/icons-material/AddLink';

export default function FavoriteFoodPanel({
	value,
	index,
	list,
	defaultValue,
	hasEditPermission,
	handleDisplay,
	handleEdit,
	handleDelete,
	handleConnect,
	handleClickOpenMediaAlbumFormFavoriteFood,
	handleClickOpenMediaFileForm,
	handleAddLinkToMediaFileFavoriteFood,
}) {
	const columns: Column<FavoriteFood>[] = [
		{ width: '10%' },
		{
			field: 'name',
			width: '90%',
			filtering: false,
			render: useCallback(
				(data: FavoriteFood) => (
					<Typography variant='body2' color= 'primary' fontSize={16}>
						<TCCStyledBadgeButton
							badgeContent={data.connects.length}
							onClick={() => handleConnect(data)}
						/>
						{data.name}
						{	
							favoriteFoodTypeItems.find((el) => el.value === data.favoriteFoodType)?.icon
						}
						<Tooltip title='Medien anzeigen'>
							<span>
								<Button
									disabled={ data.media?.length === 0}
									onClick={() => handleClickOpenMediaAlbumFormFavoriteFood(data)}>
									<Badge badgeContent={data.media?.length} color='primary'>
										<PlayArrow
											color={
												data.media?.length > 0
													? 'primary'
													: 'secondary'
											}
										/>
									</Badge>
								</Button>
							</span>
						</Tooltip>
						<Tooltip title='Details anzeigen'>
						<Button onClick={() => handleDisplay(data)}>
							{<Info color='primary' />}
						</Button>
						</Tooltip>
					</Typography>
				),
				[]
			),
		
			cellStyle: {
				maxWidth: '100%',
				whiteSpace: 'pre-line',
				textOverflow: 'ellipsis',
			},
		},
	];
	return (
		<TabPanel value={value} index={index}>
			<MaterialTable
				localization={{
					body: {
						emptyDataSourceMessage: 'Keine Einträge hinterlegt.',
					},
				}}
				style={{
					maxHeight: 293,
					overflow: 'auto',
					width: '100%',
					fontStyle: 'inherit',
				}}
				icons={MaterialTableIcons()}
				columns={columns}
				data={list}
				components={{
					Container: (props) => <Paper {...props} elevation={0} />,
					Row: (props) => (
						<MTableBodyRow
							key={props.favoriteFoodId}
							id={props.favoriteFoodId}
							{...props}
						/>
					),
				}}
				options={{
					paging: false,
					showTitle: false,
					sorting: false,
					filtering: false,
					toolbar: false,
					tableLayout: 'fixed',
					search: false,
					rowStyle: { fontSize: 18 },
				}}
				detailPanel={[
					{
						tooltip: 'Aktionen',
						render: (row) => {
							return (
										<div key={row.rowData.favoriteFoodId}>
										<div style={{ display: 'flex' }}>
										{hasEditPermission ? (
											<Tooltip title='Archiviere Lieblingsessen'>
												<Button onClick={() => handleDelete(row.rowData.favoriteFoodId)}>
													{<Delete sx={{ marginLeft: 5 }} color='primary' />}
												</Button>
											</Tooltip>
										) : (
											<Button disabled>{<Delete color='secondary' />}</Button>
										)}
										{hasEditPermission ? (
											<Tooltip title='Bearbeite Lieblingsessen'>
												<Button onClick={() => handleEdit(false, row.rowData)}>
													{<Edit color='primary' />}
												</Button>
											</Tooltip>
										) : (
											<Button disabled>{<Edit color='secondary' />}</Button>
										)}
										{hasEditPermission ? (
											<Tooltip title='Medium hochladen und verknüpfen'>
												<span>
													<Button onClick={() => handleClickOpenMediaFileForm(row.rowData)}>
														<AddAPhoto color='primary' />
													</Button>
												</span>
											</Tooltip>
										) : (
											<Button disabled>{<AddAPhoto color='secondary' />}</Button>
										)}
										{hasEditPermission ? (
											<Tooltip title='Medien verknüpfen'>
												<span>
													<Button onClick={() => handleAddLinkToMediaFileFavoriteFood(row.rowData)}>
														<AddLinkIcon color= 'primary' />
													</Button>
												</span>
											</Tooltip>
										) : (
											<Button disabled>{<AddLinkIcon color='secondary' />}</Button>
										)}
									</div>
								</div>
							);
						},
					},
				]}
			/>
			{hasEditPermission ? (
				<Tooltip placement='right' title='Lieblingsessen hinzufügen'>
					<IconButton
						sx={{
							position: 'sticky',
							right: '100%',
							marginTop: -2,
							marginBottom: -5,
						}}
						size='medium'
						onClick={() => handleEdit(true, defaultValue)}>
						{<ControlPoint color='primary' />}
					</IconButton>
				</Tooltip>
			) : (
				<IconButton size='medium' disabled>
					{<ControlPoint color='secondary' />}
				</IconButton>
			)}
			
		</TabPanel>
	);
}
