/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidenceEventMedia
 */
export interface ResidenceEventMedia {
    /**
     * 
     * @type {number}
     * @memberof ResidenceEventMedia
     */
    residenceMediaId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResidenceEventMedia
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceEventMedia
     */
    residenceMediaTypeIdentifier?: string;
}

export function ResidenceEventMediaFromJSON(json: any): ResidenceEventMedia {
    return ResidenceEventMediaFromJSONTyped(json, false);
}

export function ResidenceEventMediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceEventMedia {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'residenceMediaId': !exists(json, 'residenceMediaId') ? undefined : json['residenceMediaId'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'residenceMediaTypeIdentifier': !exists(json, 'ResidenceMediaTypeIdentifier') ? undefined : json['ResidenceMediaTypeIdentifier'],
    };
}

export function ResidenceEventMediaToJSON(value?: ResidenceEventMedia | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'residenceMediaId': value.residenceMediaId,
        'fileName': value.fileName,
        'ResidenceMediaTypeIdentifier': value.residenceMediaTypeIdentifier,
    };
}


