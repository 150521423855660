/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectData,
    ConnectDataFromJSON,
    ConnectDataFromJSONTyped,
    ConnectDataToJSON,
    GreetingMedia,
    GreetingMediaFromJSON,
    GreetingMediaFromJSONTyped,
    GreetingMediaToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface Greeting
 */
export interface Greeting {
    /**
     * 
     * @type {number}
     * @memberof Greeting
     */
    greetingId?: number;
    /**
     * 
     * @type {string}
     * @memberof Greeting
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof Greeting
     */
    residentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Greeting
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Greeting
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof Greeting
     */
    modifyingUser?: string;
    /**
     * 
     * @type {string}
     * @memberof Greeting
     */
    greetingPersonName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Greeting
     */
    techValidFrom?: Date;
    /**
     * 
     * @type {Array<GreetingMedia>}
     * @memberof Greeting
     */
    media?: Array<GreetingMedia>;
    /**
     * 
     * @type {Array<ConnectData>}
     * @memberof Greeting
     */
    connects?: Array<ConnectData>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Greeting
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof Greeting
     */
    relevant?: boolean;
}

export function GreetingFromJSON(json: any): Greeting {
    return GreetingFromJSONTyped(json, false);
}

export function GreetingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Greeting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'greetingId': !exists(json, 'greetingId') ? undefined : json['greetingId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'greetingPersonName': !exists(json, 'greetingPersonName') ? undefined : json['greetingPersonName'],
        'techValidFrom': !exists(json, 'techValidFrom') ? undefined : (new Date(json['techValidFrom'])),
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(GreetingMediaFromJSON)),
        'connects': !exists(json, 'connects') ? undefined : ((json['connects'] as Array<any>).map(ConnectDataFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function GreetingToJSON(value?: Greeting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'greetingId': value.greetingId,
        'tenantIdentifier': value.tenantIdentifier,
        'residentId': value.residentId,
        'title': value.title,
        'comment': value.comment,
        'modifyingUser': value.modifyingUser,
        'greetingPersonName': value.greetingPersonName,
        'techValidFrom': value.techValidFrom === undefined ? undefined : (value.techValidFrom.toISOString().substr(0,10)),
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(GreetingMediaToJSON)),
        'connects': value.connects === undefined ? undefined : ((value.connects as Array<any>).map(ConnectDataToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


