import { Grid } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { ResidenceEvent } from '../generated';
import EventCard from './EventCard';

interface EventsGridViewProps {
	eventList: ResidenceEvent[];
	handleDisplayEvent: any;
	handleClickOpenMediaAlbumForm: any;
	handleClickOpenResidentToEventForm: any;
	isAuthorized: boolean;
}

export default function EventsGridView({
	eventList,
	handleDisplayEvent,
	handleClickOpenMediaAlbumForm,
	handleClickOpenResidentToEventForm,
	isAuthorized,
}: EventsGridViewProps) {
	return (
		<Grid
			container
			spacing={{ xs: 2, lg: 3 }}
			columns={{ xs: 3, sm: 6, md: 9, lg: 12 }}>
			<Grid
				item
				xs={12}
				padding={5}
				style={{
					fontSize: '12',
					color: grey[500],
					textAlign: 'center',
				}}>
				{eventList?.length > 0 ? '' : 'Keine Events hinterlegt.'}
			</Grid>
			{eventList.map((event) => (
				<Grid item xs={3} sm={3} md={3} lg={3} key={event.residenceEventId}>
					<EventCard
						event={event}
						onClickCard={() => handleDisplayEvent(event)}
						onClickMediaAlbum={() => handleClickOpenMediaAlbumForm(event)}
						onClickResidentToEventForm={() => handleClickOpenResidentToEventForm(event)}
						isAuthorized={isAuthorized}
					/>
				</Grid>
			))}
		</Grid>
	);
}
