import React from 'react';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import {
	Button,
	Grid,
	Table,
	TableBody,
	TableContainer,
	TableRow,
	Typography,
	Tooltip,
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { KeycloakTokenParsed } from 'keycloak-js';

import { ErrorAlert, SuccessAlert } from './Alerts';
import keycloak, { getNetworkApi } from './keycloak';
import { ResidentMarkedForDeletion } from '../generated/models';
import { TableCellStyled } from '../theme';

import Confirm from './Confirm';

import TabPanel from './TabPanel';

export default function ConfirmResidentRemovalPanel({ value, index }) {
	const [residentsMarkedForDeletionList, setResidentsMarkedForDeletionList] =
		useState([] as ResidentMarkedForDeletion[]);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
	const [refresh, setRefresh] = useState(0);

	const [deleteText, setDeleteText] = useState('');
	const [finalDeletionDecision, setFinalDeletionDecision] = useState(
		{} as {
			tenantIdentifier: string;
			residentId: number;
			residentDeleteReservationId: number;
			residentDeleteReservationDecision: boolean;
		}
	);

	type ParsedToken = KeycloakTokenParsed & {
		email?: string;
		preferred_username?: string;
		given_name?: string;
		family_name?: string;
		tenantIdentifier?: string;
	};

	const parsedToken: ParsedToken | undefined = keycloak?.idTokenParsed;

	const tenantIdentifier: string = parsedToken?.tenantIdentifier;

	const handleDelete = (
		ResidentMarkedForDeletion: ResidentMarkedForDeletion,
		confirmDelete: boolean
	) => {
		setShowConfirmDeletion(true);
		confirmDelete
			? setDeleteText(
					'Wollen Sie wirklich Bewohner:in ' +
						ResidentMarkedForDeletion.firstName +
						' ' +
						ResidentMarkedForDeletion.surname +
						' löschen? Dies kann nicht mehr rückgängig gemacht werden. Die Daten sind für immer gelöscht.'
			  )
			: setDeleteText(
					'Wollen Sie wirklich die Löschung für Bewohner:in ' +
						ResidentMarkedForDeletion.firstName +
						' ' +
						ResidentMarkedForDeletion.surname +
						' rückgängig machen?'
			  );
		setFinalDeletionDecision({
			tenantIdentifier: ResidentMarkedForDeletion.tenantIdentifier,
			residentId: ResidentMarkedForDeletion.residentId,
			residentDeleteReservationId:
				ResidentMarkedForDeletion.residentDeleteReservationId,
			residentDeleteReservationDecision: confirmDelete,
		});
	};

	const finalDeleteResidentDecision = async () => {
		const client = getNetworkApi();
		try {
			await client.decideOnResidentDeleteReservation(
				finalDeletionDecision.tenantIdentifier,
				finalDeletionDecision.residentId,
				finalDeletionDecision.residentDeleteReservationId,
				{
					performDeletion:
						finalDeletionDecision.residentDeleteReservationDecision,
				}
			);
			setShowSuccessAlert(true);
			setShowConfirmDeletion(false);
			finalDeletionDecision.residentDeleteReservationDecision
				? setAlertText('Bewohner:in wurde erfolgreich gelöscht')
				: setAlertText('Löschvermerk für Bewohner:in wurde zurückgenommen');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Bewohner:in konnte nicht gelöscht werden');
			console.log('There was an error while deleting!');
		}
	};

	useEffect(() => {
		const loadDeletionList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidentsMarkedForDeletion(
					tenantIdentifier
				);
				setResidentsMarkedForDeletionList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log(
							'There was an error fetching the Marked for Deletion data!'
						);
					}
				} else {
					console.log(
						'There was an error fetching the Marked for Deletion data!'
					);
				}
			}
		};
		trackPromise(loadDeletionList());
	}, [tenantIdentifier, showSuccessAlert, refresh]);

	return (
		<React.Fragment>
			<TabPanel value={value} index={index}>
				<Grid item xs={12}>
					<Typography>
						Bestätige endgültige Löschung folgender Bewohner:In:
					</Typography>
					<Grid item xs={12} sx={{ mt: 4 }}>
						{Object.entries(residentsMarkedForDeletionList).length !== 0 && (
							<Grid
								container
								direction='column'
								spacing={2}
								// sx={{ mx: 8, mt: 5 }}
							>
								<Grid item>
									<TableContainer sx={{ width: '90%' }}>
										<Table sx={{ fontSize: 40, color: 'black' }}>
											<TableBody>
												<TableRow>
													<TableCellStyled>Name</TableCellStyled>
													<TableCellStyled>Wohnbereich</TableCellStyled>
													<TableCellStyled>Zimmer</TableCellStyled>
													<TableCellStyled>Erläuterung</TableCellStyled>
													<TableCellStyled>Löschauftrag von</TableCellStyled>
													<TableCellStyled></TableCellStyled>
												</TableRow>
												{residentsMarkedForDeletionList.map((el, index) => {
													return (
														<TableRow key={index}>
															<TableCellStyled>
																{el.surname}, {el.firstName}
															</TableCellStyled>
															<TableCellStyled>
																{el.residenceIdentifier} - {el.unitIdentifier}
															</TableCellStyled>
															<TableCellStyled>{el.room}</TableCellStyled>
															<TableCellStyled>
																{el.deletionReservationComment}
															</TableCellStyled>
															<TableCellStyled>
																{el.deletionReservationUser}
															</TableCellStyled>
															<TableCellStyled>
																{el.deletionReservationUser !==
																	parsedToken?.preferred_username && (
																	<Tooltip title='Bestätige Löschung'>
																		<Button
																			onClick={() => handleDelete(el, true)}>
																			{<Check />}
																		</Button>
																	</Tooltip>
																)}
																<Tooltip title='Vormerkung rückgängig machen'>
																	<Button
																		onClick={() => handleDelete(el, false)}>
																		{<Close color='primary' />}
																	</Button>
																</Tooltip>
															</TableCellStyled>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</TabPanel>
			{showConfirmDeletion && (
				<Confirm
					open={showConfirmDeletion}
					setOpen={setShowConfirmDeletion}
					text={deleteText}
					executefct={finalDeleteResidentDecision}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
