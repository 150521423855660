/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectData,
    ConnectDataFromJSON,
    ConnectDataFromJSONTyped,
    ConnectDataToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
    VisitFrequency,
    VisitFrequencyFromJSON,
    VisitFrequencyFromJSONTyped,
    VisitFrequencyToJSON,
} from './';

/**
 * 
 * @export
 * @interface Relative
 */
export interface Relative {
    /**
     * 
     * @type {number}
     * @memberof Relative
     */
    relativeId?: number;
    /**
     * 
     * @type {number}
     * @memberof Relative
     */
    residentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    relativeType?: string;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    relativeRole?: string;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof Relative
     */
    birthday?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Relative
     */
    dateOfDeath?: Date;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    lifeStage?: string;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    profession?: string;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    education?: string;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    relationship?: string;
    /**
     * 
     * @type {VisitFrequency}
     * @memberof Relative
     */
    visitFrequency?: VisitFrequency;
    /**
     * 
     * @type {Array<ConnectData>}
     * @memberof Relative
     */
    connects?: Array<ConnectData>;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Relative
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Relative
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof Relative
     */
    relevant?: boolean;
}

export function RelativeFromJSON(json: any): Relative {
    return RelativeFromJSONTyped(json, false);
}

export function RelativeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Relative {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'relativeId': !exists(json, 'relativeId') ? undefined : json['relativeId'],
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'relativeType': !exists(json, 'relativeType') ? undefined : json['relativeType'],
        'relativeRole': !exists(json, 'relativeRole') ? undefined : json['relativeRole'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'dateOfDeath': !exists(json, 'dateOfDeath') ? undefined : (new Date(json['dateOfDeath'])),
        'lifeStage': !exists(json, 'lifeStage') ? undefined : json['lifeStage'],
        'profession': !exists(json, 'profession') ? undefined : json['profession'],
        'education': !exists(json, 'education') ? undefined : json['education'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'relationship': !exists(json, 'relationship') ? undefined : json['relationship'],
        'visitFrequency': !exists(json, 'visitFrequency') ? undefined : VisitFrequencyFromJSON(json['visitFrequency']),
        'connects': !exists(json, 'connects') ? undefined : ((json['connects'] as Array<any>).map(ConnectDataFromJSON)),
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function RelativeToJSON(value?: Relative | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'relativeId': value.relativeId,
        'residentId': value.residentId,
        'firstName': value.firstName,
        'surname': value.surname,
        'relativeType': value.relativeType,
        'relativeRole': value.relativeRole,
        'comment': value.comment,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substr(0,10)),
        'dateOfDeath': value.dateOfDeath === undefined ? undefined : (value.dateOfDeath.toISOString().substr(0,10)),
        'lifeStage': value.lifeStage,
        'profession': value.profession,
        'education': value.education,
        'gender': value.gender,
        'relationship': value.relationship,
        'visitFrequency': VisitFrequencyToJSON(value.visitFrequency),
        'connects': value.connects === undefined ? undefined : ((value.connects as Array<any>).map(ConnectDataToJSON)),
        'tenantIdentifier': value.tenantIdentifier,
        'modifyingUser': value.modifyingUser,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


