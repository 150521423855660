import React, { useEffect, useMemo, useState } from 'react';
import keycloak, { getNetworkApi } from './keycloak';
import {
	Residence,
	ResidenceEvent,
	ResidenceEventMediaInsertParameters,
	ResidenceEventMediaUpdateParameters,
	Resident,
} from '../generated';
import { trackPromise } from 'react-promise-tracker';
import {
	Grid,
	Button,
	Tooltip,
	FormControl,
	InputLabel,
	NativeSelect,
	Typography,
} from '@mui/material';
import {
	getEventIdData,
	isAuthorizedForRoleTenant,
} from './format';
import { Add, Autorenew, GridView, TableRows } from '@mui/icons-material';
import { SuccessAlert, ErrorAlert } from './Alerts';
import { BaseRole } from './Roles';
import EventsGridView from './EventsGridView';
import EventForm from './EventForm';
import EventsTableView from './EventsTableView';
import Confirm from './Confirm';
import TabPanel from './TabPanel';
import { useHistory, useLocation } from 'react-router-dom';
import ResidenceMediaFileForm from './ResidenceMediaFileForm';
import EventToMediaLinkForm from './EventToMediaLinkForm ';
import EventsDisplayMediaAlbumForm from './EventsDisplayMediaAlbumForm';
import EventToResidentLinkForm from './EventToResidentLinkForm';
import { KeycloakTokenParsed } from 'keycloak-js';

export default function ResidenceEventPanel({ value, index }) {
	let history = useHistory();

	const location = useLocation();

	const handleChangeResidence = (e) => {
		setResidenceIndex(e.target.value);
		setResidentIndex(undefined);
		setCurrentResident(undefined);
	};
	const handleChangeResident = (e) => {
		setResidentIndex(e.target.value);
	};

	const [tableView, setTableView] = useState(false as boolean);

	const [residenceList, setResidenceList] = useState([] as Residence[]);
	const [residentList, setResidentList] = useState([] as Resident[]);
	const [userRoleList, setUserRoleList] = useState([]);
	const [displayEvent, setDisplayEvent] = useState(false);
	const [eventIdForDeletion, setEventIdForDeletion] = useState(
		undefined as number
	);
	const [showConfirmEventDeletion, setShowConfirmEventDeletion] =
		useState(false);
	const [showEventForm, setShowEventForm] = useState(false);
	const [isNewEvent, setIsNewEvent] = useState(false);
	const [eventList, setEventList] = useState([] as ResidenceEvent[]);
	const [refresh, setRefresh] = useState(0);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [deleteText, setDeleteText] = useState('');
	const [currentEventData, setCurrentEventData] = useState(
		undefined as ResidenceEvent
	);

	const [showMediaFileForm, setShowMediaFileForm] = useState(false);
	const [mediaIdForLink, setMediaIdForLink] = useState(undefined as number);
	const [showAddMediaLinkForm, setShowAddMediaLinkForm] = useState(
		false as boolean
	);
	const [showDisplayMediaAlbumForm, setShowDisplayMediaAlbumForm] = useState(
		false as boolean
	);
	const [showAddResidentToEventLinkForm, setShowAddResidentToEventLinkForm] =
		useState(false as boolean);
	const [currentResidence, setCurrentResidence] = useState({} as Residence);

	const [currentResident, setCurrentResident] = useState(
		location?.state?.resident as Resident
	);

	type ParsedToken = KeycloakTokenParsed & {
		tenantIdentifier?: string;
	};

	const parsedToken: ParsedToken | undefined = keycloak?.idTokenParsed;

	const tenantIdentifier: string = parsedToken.tenantIdentifier;

	useEffect(() => {
		const loadResidence = async () => {
			const api = getNetworkApi();
			try {
				const result1 = await api.getResidences(tenantIdentifier);
				setResidenceList(result1);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'Wohnheimsinformationen konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Wohnheimsinformationen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadResidence());
	}, []);

	const [residenceIndex, setResidenceIndex] = useState(0 as number);

	const isAuthorized: boolean = useMemo(() => {
		return isAuthorizedForRoleTenant(
			userRoleList,
			BaseRole.HEIMEREIGNISSE,
			tenantIdentifier,
			residenceList[residenceIndex]?.residenceIdentifier
				? residenceList[residenceIndex]?.residenceIdentifier
				: residenceList[0]?.residenceIdentifier,
			undefined
		);
	}, [userRoleList, residenceList, residenceIndex]);

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const [residentIndex, setResidentIndex] = useState(
		residentList.findIndex(
			(el) => el.residentId === location?.state?.residentId
		) as number
	);

	useMemo(() => {
		setResidentIndex(
			residentList.findIndex(
				(el) => el.residentId === location?.state?.residentId
			)
		);
	}, [residentList]);

	useEffect(() => {
		const loadResidents = async () => {
			const api = getNetworkApi();
			if (currentResidence) {
				try {
					const result = await api.getResidenceResidentList(
						currentResidence.tenantIdentifier,
						currentResidence.residenceIdentifier
					);
					setResidentList(result);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText(
								'Bewohnerinformationen konnten nicht abgerufen werden'
							);
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'Bewohnerinformationen konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadResidents());
	}, [
		currentResidence,
		residenceIndex,
		refresh,
		index,
		value,
		residenceList[0],
	]);

	const deleteEvent = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateResidenceEvent(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				eventIdForDeletion
			);
			setShowSuccessAlert(true);
			setShowConfirmEventDeletion(false);
			setAlertText('Event wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Event konnte nicht archiviert werden');
			console.log(
				'There was an error while deactivating: ' + eventIdForDeletion
			);
		}
	};

	const handleDeleteEvent = (residenceEventId: number) => {
		setDeleteText('Wollen Sie das Event wirklich archivieren?');
		setEventIdForDeletion(residenceEventId);
		setShowConfirmEventDeletion(true);
	};

	const handleClickOpenEventForm = (
		isNewEvent: boolean,
		eventData: ResidenceEvent
	) => {
		setCurrentEventData(undefined);
		if (!isNewEvent) {
			setCurrentEventData(eventData);
		}
		setIsNewEvent(isNewEvent);
		setShowEventForm(true);
		setDisplayEvent(false);
	};

	const handleDisplayEvent = (eventData: ResidenceEvent) => {
		setDisplayEvent(true);
		setShowEventForm(true);
		setCurrentEventData(eventData);
	};

	const handleClickOpenMediaAlbumForm = (eventData: ResidenceEvent) => {
		setCurrentEventData(eventData);
		setShowDisplayMediaAlbumForm(true);
	};

	const handleClickOpenResidentToEventForm = (eventData: ResidenceEvent) => {
		setCurrentEventData(eventData);
		setShowAddResidentToEventLinkForm(true);
	};

	const handleClickOpenMediaFileForm = (eventData: ResidenceEvent) => {
		setCurrentEventData(eventData);
		setShowMediaFileForm(true);
	};

	const handleAddLinkToMediaFile = (eventData: ResidenceEvent) => {
		setCurrentEventData(eventData);
		setShowAddMediaLinkForm(true);
	};
	useEffect(() => {
		if (mediaIdForLink !== undefined) {
			currentEventData?.residenceMedia?.length > 0
				? updateMediaToEventLinks()
				: saveMediaToEventLink();
		}
	}, [mediaIdForLink]);

	const saveMediaToEventLink = async () => {
		let residenceEventMediaInsertParameters: ResidenceEventMediaInsertParameters =
			{
				residenceMediaIds: [Number(mediaIdForLink)],
			};

		const client = getNetworkApi();
		try {
			await client.postResidenceEventMedia(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				currentEventData?.residenceEventId,
				residenceEventMediaInsertParameters
			);

			setAlertText('Verknüpfung zu Medien wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateMediaToEventLinks = async () => {
		let residenceMediaIds: number[] = [];
		currentEventData.residenceMedia.forEach((elem) => {
			residenceMediaIds.push(elem.residenceMediaId);
		});
		residenceMediaIds.push(mediaIdForLink);
		let residenceEventMediaUpdateParameters: ResidenceEventMediaUpdateParameters =
			{
				residenceMediaIds: residenceMediaIds,
			};
		const client = getNetworkApi();
		try {
			await client.putResidenceEventMedia(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				currentEventData.residenceEventId,
				residenceEventMediaUpdateParameters
			);
			setAlertText('Verknüpfung zu Medien wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	useMemo(() => {
		setCurrentResidence(residenceList[residenceIndex]);
		setCurrentResident(undefined);
	}, [history, residenceIndex, index, value, residenceList]);

	useMemo(() => {
		setCurrentResident(residentList[residentIndex]);
	}, [residentIndex, residenceIndex, index, value]);

	const handleSwitchChange = () => {
		setTableView(!tableView);
	};

	useEffect(() => {
		const loadEvents = async () => {
			const api = getNetworkApi();
			if (currentResidence.residenceIdentifier !== undefined) {
				try {
					const result = await api.getResidenceEvents(
						currentResidence.tenantIdentifier,
						currentResidence.residenceIdentifier
					);
					setEventList(
						currentResident
							? result.filter((event) =>
									event.associatedResidents.find(
										(resident) =>
											resident.residentId === currentResident.residentId
									)
							  )
							: result
					);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Events konnten nicht abgerufen werden');
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Events konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				}
			} else {
				try {
					const result = await api.getResidenceEvents(
						tenantIdentifier,
						residenceList[0].residenceIdentifier
					);
					setEventList(result);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Events konnten nicht abgerufen werden');
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Events konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadEvents());
	}, [
		currentResidence,
		showSuccessAlert,
		refresh,
		showDisplayMediaAlbumForm,
		showMediaFileForm,
		showAddMediaLinkForm,
		currentResident,
		residenceList,
	]);

	return (
		<React.Fragment>
			<TabPanel value={value} index={index}>
				{Object.entries(residenceList).length !== 0 ? (
					<>
						<Grid container direction='row' spacing={2} sx={{ padding: 5 }}>
							<Grid item xs={3}>
								<FormControl fullWidth>
									<InputLabel
										sx={{ fontSize: 18 }}
										variant='standard'
										htmlFor='uncontrolled-native'>
										Wohnheim auswählen
									</InputLabel>
									<NativeSelect
										defaultValue={residenceIndex}
										onChange={handleChangeResidence}
										inputProps={{
											name: 'residence',
											id: 'uncontrolled-native',
										}}
										sx={{ color: 'green', fontSize: 18 }}>
										{residenceList.map((el, index) => (
											<option key={el.residenceIdentifier} value={index}>
												{el.residenceIdentifier}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Grid>
							<Grid item xs={3}>
								<FormControl fullWidth>
									<InputLabel
										sx={{ fontSize: 18 }}
										variant='standard'
										htmlFor='uncontrolled-native'>
										Bewohner:in auswählen
									</InputLabel>
									<NativeSelect
										value={residentIndex}
										onChange={handleChangeResident}
										inputProps={{
											name: 'resident',
											id: 'uncontrolled-native',
										}}
										sx={{ color: 'green', fontSize: 18 }}>
										<option aria-label='None' value='' />
										{residentList.map((el, index) => (
											<option key={el.residentId} value={index}>
												{el.surname + ', ' + el.firstName}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<Grid
									container
									direction='column'
									justifyContent='space-between'
									alignItems='right'
									sx={{ padding: 0 }}>
									<Grid item xs={1} sx={{ mt: 2 }}>
										<Button
											disabled={!isAuthorized}
											color={isAuthorized ? 'primary' : 'secondary'}
											variant='contained'
											sx={{ float: 'right' }}
											onClick={() => handleClickOpenEventForm(true, null)}>
											{<Add />} Event
										</Button>
										<Button
											variant='contained'
											sx={{ float: 'right', mr: 5 }}
											onClick={() => handleRefresh()}>
											{<Autorenew />}
										</Button>
										<Tooltip title={tableView ? 'Eventkärtchen' : 'Tabelle'}>
											<Button
												variant='contained'
												sx={{ float: 'right', mr: 5 }}
												onClick={() => handleSwitchChange()}>
												{tableView ? <GridView /> : <TableRows />}
											</Button>
										</Tooltip>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								{tableView ? (
									<EventsTableView
										eventList={eventList}
										handleClickOpenEventForm={handleClickOpenEventForm}
										handleDeleteEvent={handleDeleteEvent}
										isAuthorized={isAuthorized}
										handleClickOpenMediaFileForm={handleClickOpenMediaFileForm}
										handleClickOpenMediaAlbumForm={
											handleClickOpenMediaAlbumForm
										}
										handleClickOpenResidentToEventForm={
											handleClickOpenResidentToEventForm
										}
										handleAddLinkToMediaFile={handleAddLinkToMediaFile}
									/>
								) : (
									<EventsGridView
										eventList={eventList}
										handleDisplayEvent={handleDisplayEvent}
										handleClickOpenMediaAlbumForm={
											handleClickOpenMediaAlbumForm
										}
										handleClickOpenResidentToEventForm={
											handleClickOpenResidentToEventForm
										}
										isAuthorized={isAuthorized}
									/>
								)}
							</Grid>
						</Grid>
					</>
				) : (
					<Typography>Wird geladen ...</Typography>
				)}
			</TabPanel>
			{showEventForm && (
				<EventForm
					open={showEventForm}
					setOpen={setShowEventForm}
					isNewEvent={isNewEvent}
					isReadOnly={displayEvent}
					displayEvent={displayEvent}
					eventIdData={getEventIdData(currentResidence)}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					currentEventData={currentEventData}
				/>
			)}
			{showMediaFileForm && (
				<ResidenceMediaFileForm
					open={showMediaFileForm}
					setOpen={setShowMediaFileForm}
					isNewMediaFile={true}
					currentResidence={currentResidence}
					setMediaId={setMediaIdForLink}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					currentMediaFileMetaData={undefined}
				/>
			)}
			{showAddMediaLinkForm && (
				<EventToMediaLinkForm
					open={showAddMediaLinkForm}
					setOpen={setShowAddMediaLinkForm}
					currentResidence={currentResidence}
					currentEvent={currentEventData}
					eventId={currentEventData?.residenceEventId}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showDisplayMediaAlbumForm && (
				<EventsDisplayMediaAlbumForm
					open={showDisplayMediaAlbumForm}
					isAuthorized={isAuthorized}
					setOpen={setShowDisplayMediaAlbumForm}
					currentResidence={currentResidence}
					residenceMediaIdList={currentEventData?.residenceMedia}
					title={'Medien für Event: ' + currentEventData?.title}
				/>
			)}
			{showAddResidentToEventLinkForm && (
				<EventToResidentLinkForm
					open={showAddResidentToEventLinkForm}
					setOpen={setShowAddResidentToEventLinkForm}
					currentResidence={currentResidence}
					currentEvent={currentEventData}
					eventId={currentEventData?.residenceEventId}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
					setShowSuccessAlert={setShowSuccessAlert}
				/>
			)}
			{showConfirmEventDeletion && (
				<Confirm
					open={showConfirmEventDeletion}
					setOpen={setShowConfirmEventDeletion}
					text={deleteText}
					executefct={deleteEvent}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
