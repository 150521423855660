/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaCategory,
    MediaCategoryFromJSON,
    MediaCategoryFromJSONTyped,
    MediaCategoryToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface MediaMetaUpdateParameters
 */
export interface MediaMetaUpdateParameters {
    /**
     * 
     * @type {string}
     * @memberof MediaMetaUpdateParameters
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaMetaUpdateParameters
     */
    stageOfLife?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaMetaUpdateParameters
     */
    isProfilePicture?: boolean;
    /**
     * 
     * @type {Array<MediaCategory>}
     * @memberof MediaMetaUpdateParameters
     */
    mediaCategories?: Array<MediaCategory>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof MediaMetaUpdateParameters
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof MediaMetaUpdateParameters
     */
    relevant?: boolean;
}

export function MediaMetaUpdateParametersFromJSON(json: any): MediaMetaUpdateParameters {
    return MediaMetaUpdateParametersFromJSONTyped(json, false);
}

export function MediaMetaUpdateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaMetaUpdateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'stageOfLife': !exists(json, 'stageOfLife') ? undefined : json['stageOfLife'],
        'isProfilePicture': !exists(json, 'isProfilePicture') ? undefined : json['isProfilePicture'],
        'mediaCategories': !exists(json, 'mediaCategories') ? undefined : ((json['mediaCategories'] as Array<any>).map(MediaCategoryFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function MediaMetaUpdateParametersToJSON(value?: MediaMetaUpdateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment': value.comment,
        'stageOfLife': value.stageOfLife,
        'isProfilePicture': value.isProfilePicture,
        'mediaCategories': value.mediaCategories === undefined ? undefined : ((value.mediaCategories as Array<any>).map(MediaCategoryToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


