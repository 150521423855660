/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResidenceEventCategory,
    ResidenceEventCategoryFromJSON,
    ResidenceEventCategoryFromJSONTyped,
    ResidenceEventCategoryToJSON,
    Resident,
    ResidentFromJSON,
    ResidentFromJSONTyped,
    ResidentToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResidenceEventInsertParameters
 */
export interface ResidenceEventInsertParameters {
    /**
     * 
     * @type {string}
     * @memberof ResidenceEventInsertParameters
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceEventInsertParameters
     */
    description?: string;
    /**
     * 
     * @type {ResidenceEventCategory}
     * @memberof ResidenceEventInsertParameters
     */
    residenceEventCategory?: ResidenceEventCategory;
    /**
     * 
     * @type {string}
     * @memberof ResidenceEventInsertParameters
     */
    residenceEventSubCategory?: string;
    /**
     * 
     * @type {Date}
     * @memberof ResidenceEventInsertParameters
     */
    eventDate?: Date;
    /**
     * 
     * @type {Array<Resident>}
     * @memberof ResidenceEventInsertParameters
     */
    associatedResidents?: Array<Resident>;
}

export function ResidenceEventInsertParametersFromJSON(json: any): ResidenceEventInsertParameters {
    return ResidenceEventInsertParametersFromJSONTyped(json, false);
}

export function ResidenceEventInsertParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceEventInsertParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'residenceEventCategory': !exists(json, 'residenceEventCategory') ? undefined : ResidenceEventCategoryFromJSON(json['residenceEventCategory']),
        'residenceEventSubCategory': !exists(json, 'residenceEventSubCategory') ? undefined : json['residenceEventSubCategory'],
        'eventDate': !exists(json, 'eventDate') ? undefined : (new Date(json['eventDate'])),
        'associatedResidents': !exists(json, 'associatedResidents') ? undefined : ((json['associatedResidents'] as Array<any>).map(ResidentFromJSON)),
    };
}

export function ResidenceEventInsertParametersToJSON(value?: ResidenceEventInsertParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'residenceEventCategory': ResidenceEventCategoryToJSON(value.residenceEventCategory),
        'residenceEventSubCategory': value.residenceEventSubCategory,
        'eventDate': value.eventDate === undefined ? undefined : (value.eventDate.toISOString().substr(0,10)),
        'associatedResidents': value.associatedResidents === undefined ? undefined : ((value.associatedResidents as Array<any>).map(ResidentToJSON)),
    };
}


