/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectData,
    ConnectDataFromJSON,
    ConnectDataFromJSONTyped,
    ConnectDataToJSON,
    MediaCategory,
    MediaCategoryFromJSON,
    MediaCategoryFromJSONTyped,
    MediaCategoryToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResidentMediaData
 */
export interface ResidentMediaData {
    /**
     * 
     * @type {number}
     * @memberof ResidentMediaData
     */
    mediaId?: number;
    /**
     * 
     * @type {number}
     * @memberof ResidentMediaData
     */
    residentId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResidentMediaData
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMediaData
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMediaData
     */
    mediaTypeIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMediaData
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidentMediaData
     */
    stageOfLife?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentMediaData
     */
    isProfilePicture?: boolean;
    /**
     * 
     * @type {Array<MediaCategory>}
     * @memberof ResidentMediaData
     */
    mediaCategories?: Array<MediaCategory>;
    /**
     * 
     * @type {Array<ConnectData>}
     * @memberof ResidentMediaData
     */
    connects?: Array<ConnectData>;
    /**
     * 
     * @type {string}
     * @memberof ResidentMediaData
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof ResidentMediaData
     */
    techValidFrom?: Date;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ResidentMediaData
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof ResidentMediaData
     */
    relevant?: boolean;
}

export function ResidentMediaDataFromJSON(json: any): ResidentMediaData {
    return ResidentMediaDataFromJSONTyped(json, false);
}

export function ResidentMediaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentMediaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mediaId': !exists(json, 'mediaId') ? undefined : json['mediaId'],
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'mediaTypeIdentifier': !exists(json, 'mediaTypeIdentifier') ? undefined : json['mediaTypeIdentifier'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'stageOfLife': !exists(json, 'stageOfLife') ? undefined : json['stageOfLife'],
        'isProfilePicture': !exists(json, 'isProfilePicture') ? undefined : json['isProfilePicture'],
        'mediaCategories': !exists(json, 'mediaCategories') ? undefined : ((json['mediaCategories'] as Array<any>).map(MediaCategoryFromJSON)),
        'connects': !exists(json, 'connects') ? undefined : ((json['connects'] as Array<any>).map(ConnectDataFromJSON)),
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'techValidFrom': !exists(json, 'techValidFrom') ? undefined : (new Date(json['techValidFrom'])),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function ResidentMediaDataToJSON(value?: ResidentMediaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mediaId': value.mediaId,
        'residentId': value.residentId,
        'tenantIdentifier': value.tenantIdentifier,
        'fileName': value.fileName,
        'mediaTypeIdentifier': value.mediaTypeIdentifier,
        'comment': value.comment,
        'stageOfLife': value.stageOfLife,
        'isProfilePicture': value.isProfilePicture,
        'mediaCategories': value.mediaCategories === undefined ? undefined : ((value.mediaCategories as Array<any>).map(MediaCategoryToJSON)),
        'connects': value.connects === undefined ? undefined : ((value.connects as Array<any>).map(ConnectDataToJSON)),
        'modifyingUser': value.modifyingUser,
        'techValidFrom': value.techValidFrom === undefined ? undefined : (value.techValidFrom.toISOString().substr(0,10)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


