import { Close } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Slider,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { AssessedQuestion } from '../generated';
import { getMarksForSlider } from './format';
import { getNetworkApi } from './keycloak';

export default function AssesmentResultInfo({
    open,
    setOpen,
    currentAssesmentResult,
    setAlertText,
    setShowErrorAlert,
}) {
    const handleClose = () => {
        setOpen(false);
    };

    const [assessedQuestionList, setAssessedQuestionList] = useState(
        [] as AssessedQuestion[]
    );

    useEffect(() => {
        const loadQuestions = async () => {
            const api = getNetworkApi();
            try {
                const result =
                    await api.getAssessedQuestionsForAssessmentTarget(
                        currentAssesmentResult.tenantidentifier,
                        currentAssesmentResult.unitIdentifier,
                        currentAssesmentResult.residenceIdentifier,
                        currentAssesmentResult.residentAssessmentId,
                        currentAssesmentResult.assessmentTargetId,
                        currentAssesmentResult.assessmentProfileId
                    );
                setAssessedQuestionList(result);
            } catch (error) {
                if (error.message) {
                    if (error.response && error.response.status === 401) {
                        setShowErrorAlert(true);
                        setAlertText('Nutzer nicht autorisiert');
                        console.log('User Unauthorized!');
                    } else {
                        setShowErrorAlert(true);
                        setAlertText(
                            'Motivprofil Ergebnisse konnten nicht abgerufen werden'
                        );
                        console.log('There was an error fetching the data!');
                    }
                } else {
                    setShowErrorAlert(true);
                    setAlertText(
                        'Motivprofil Ergebnisse konnten nicht abgerufen werden'
                    );
                    console.log('There was an error fetching the data!');
                }
            }
        };
        trackPromise(loadQuestions());
    }, [currentAssesmentResult, setShowErrorAlert, setAlertText]);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    return (
        <React.Fragment>
            <Dialog fullScreen={fullScreen} fullWidth={true} open={open} onClose={handleClose} maxWidth={'md'}>
                <DialogTitle>
                    Welche Fragen führen zum Ergebnis für{' '}
                    {currentAssesmentResult.assessmentTargetName}?
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden' }} sx={{ mr: 3 }}>
                    {assessedQuestionList.map((el, index) => (
                        <Grid
                            key={index}
                            container
                            spacing={5}
                            alignItems='center'
                            sx={{ pr: 5, pt: 5 }}>
                            <Grid item xs={8} sx={{ fontSize: 22 }}>
                                {el.question}
                            </Grid>
                            <Grid item xs={3} sx={{ ml: 'auto' }}>
                                {
                                    <Slider
                                        aria-label='answer-slider'
                                        value={el.assessedValue}
                                        step={1}
                                        marks={getMarksForSlider(
                                            el.scaleMin,
                                            el.scaleMax,
                                            el.guidanceMin,
                                            el.guidanceMax
                                        )}
                                        min={el.scaleMin}
                                        max={el.scaleMax}
                                        valueLabelDisplay='on'
                                        disabled={true}
                                    />
                                }
                            </Grid>
                            {el.comment !== undefined && (
                                <Grid
                                    key={index}
                                    container
                                    direction='row'
                                    justifyContent='flex-start'
                                    alignItems='center'
                                    sx={{ fontStyle: 'italic', ml: 5 }}>
                                    Kommentar: {el.comment}
                                </Grid>
                            )}
                        </Grid>
                    ))}
                    <Grid
                        container
                        spacing={4}
                        direction='row'
                        justifyContent='flex-end'
                        sx={{ mt: 2, pt: 3 }}>
                        <Button variant='contained' onClick={handleClose}>
                            {<Close />} Schliessen
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
