/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NoteMedia
 */
export interface NoteMedia {
    /**
     * 
     * @type {number}
     * @memberof NoteMedia
     */
    mediaId?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteMedia
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteMedia
     */
    mediaTypeIdentifier?: string;
}

export function NoteMediaFromJSON(json: any): NoteMedia {
    return NoteMediaFromJSONTyped(json, false);
}

export function NoteMediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteMedia {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mediaId': !exists(json, 'mediaId') ? undefined : json['mediaId'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'mediaTypeIdentifier': !exists(json, 'mediaTypeIdentifier') ? undefined : json['mediaTypeIdentifier'],
    };
}

export function NoteMediaToJSON(value?: NoteMedia | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mediaId': value.mediaId,
        'fileName': value.fileName,
        'mediaTypeIdentifier': value.mediaTypeIdentifier,
    };
}


