/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssessmentResultWithTimestamp,
    AssessmentResultWithTimestampFromJSON,
    AssessmentResultWithTimestampFromJSONTyped,
    AssessmentResultWithTimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface AssessmentResultsForTargetId
 */
export interface AssessmentResultsForTargetId {
    /**
     * 
     * @type {number}
     * @memberof AssessmentResultsForTargetId
     */
    assessmentTargetId?: number;
    /**
     * 
     * @type {Array<AssessmentResultWithTimestamp>}
     * @memberof AssessmentResultsForTargetId
     */
    assessmentResults?: Array<AssessmentResultWithTimestamp>;
}

export function AssessmentResultsForTargetIdFromJSON(json: any): AssessmentResultsForTargetId {
    return AssessmentResultsForTargetIdFromJSONTyped(json, false);
}

export function AssessmentResultsForTargetIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentResultsForTargetId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assessmentTargetId': !exists(json, 'assessmentTargetId') ? undefined : json['assessmentTargetId'],
        'assessmentResults': !exists(json, 'assessmentResults') ? undefined : ((json['assessmentResults'] as Array<any>).map(AssessmentResultWithTimestampFromJSON)),
    };
}

export function AssessmentResultsForTargetIdToJSON(value?: AssessmentResultsForTargetId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assessmentTargetId': value.assessmentTargetId,
        'assessmentResults': value.assessmentResults === undefined ? undefined : ((value.assessmentResults as Array<any>).map(AssessmentResultWithTimestampToJSON)),
    };
}


