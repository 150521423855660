/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    tagId?: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    tagAbbreviation?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    tagName?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    tagGroupIdentifier?: string;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tagId': !exists(json, 'tagId') ? undefined : json['tagId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'tagAbbreviation': !exists(json, 'tagAbbreviation') ? undefined : json['tagAbbreviation'],
        'tagName': !exists(json, 'tagName') ? undefined : json['tagName'],
        'tagGroupIdentifier': !exists(json, 'tagGroupIdentifier') ? undefined : json['tagGroupIdentifier'],
    };
}

export function TagToJSON(value?: Tag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tagId': value.tagId,
        'tenantIdentifier': value.tenantIdentifier,
        'tagAbbreviation': value.tagAbbreviation,
        'tagName': value.tagName,
        'tagGroupIdentifier': value.tagGroupIdentifier,
    };
}


