import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	Grid,
	ThemeProvider,
	MenuItem,
	FormControl,
	FormLabel,
	Select,
	useMediaQuery,
	useTheme,
	Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Save, Close } from '@mui/icons-material';
import FileUploader from './FileUploader';
import { Unit, Resident } from '../generated';
import { getNetworkApi } from './keycloak';
import { makeDisabledTextField } from './format';

interface UploadResidentsFileFormProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	currentUnit: Unit;
	units: Unit[];
	setResidentsFromFile: React.Dispatch<React.SetStateAction<Resident[]>>;
	setShowResidentsFromFileForm: React.Dispatch<React.SetStateAction<boolean>>;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UploadResidentsFileForm({
	open,
	setOpen,
	currentUnit,
	units,
	setResidentsFromFile,
	setShowResidentsFromFileForm,
	setAlertText,
	setShowSuccessAlert,
	setShowErrorAlert,
}: UploadResidentsFileFormProps) {
	const [selectedFile, setSelectedFile] = useState({} as File);
	const [selectedFileName, setSelectedFileName] = useState('');
	const [isFilePicked, setIsFilePicked] = useState(false);

	const [uniqueTenantList, setUniqueTenantList] = useState(
		units
			?.map((el, idx) => el.tenantIdentifier)
			.filter((el, idx, arr) => arr.indexOf(el) === idx)
	);

	const [uniqueResidenceList, setUniqueResidenceList] = useState(
		units
			?.map((el, idx) => el.residenceIdentifier)
			.filter((el, idx, arr) => arr.indexOf(el) === idx)
	);

	const [selectedTenant, setSelectedTenant] = useState(
		currentUnit.tenantIdentifier
	);
	const [selectedResidence, setSelectedResidence] = useState(
		currentUnit.residenceIdentifier
	);

	const handleClose = () => {
		setOpen(false);
	};

	function isSaveButtonEnabled() {
		return isFilePicked;
	}

	const handleTenantChange = (e) => {
		setSelectedTenant(uniqueTenantList[e.target.value]);
	};

	const handleResidenceChange = (e) => {
		setSelectedResidence(uniqueResidenceList[e.target.value]);
	};

	const handleSelectedFile = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		setSelectedFileName(file?.name);
		setIsFilePicked(true);
	};

	const uploadResidents = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			const result = await client.postResidentsFile(
				selectedTenant,
				selectedResidence,
				currentUnit.unitIdentifier,
				selectedFile
			);
			setAlertText('Datei mit Bewohnerdaten wurde erfolgreich hochgeladen');
			setShowSuccessAlert(true);
			setResidentsFromFile(result);
			setShowResidentsFromFileForm(!(result.length==0));
		} catch (error) {
			setAlertText('Datei mit Bewohnerdaten wurde nicht hochgeladen');
			setShowErrorAlert(true);
		}
	};

	function SampleDataTable() {
		function createData(
			name: string,
			unit: string,
			room: string,
			associatedCaregiver: string,
			birthDate: string
		) {
			return { name, unit, room, associatedCaregiver, birthDate };
		}

		const rows = [
			createData(
				'Simpson, Lisa',
				'Wohnbereich Neckar',
				'123',
				'Simpson, Margarete',
				'31.12.1950'
			),
		];

		return (
			<TableContainer component={Paper}>
				<Table
					sx={{ minWidth: 650, fontStyle: 'italic' }}
					size='small'
					aria-label='a dense table'>
					<TableHead>
						<TableRow>
							<TableCell>Bewohner: Nachname,&nbsp;Vorname</TableCell>
							<TableCell align='left'>Wohnbereich</TableCell>
							<TableCell align='left'>Zimmer</TableCell>
							<TableCell align='left'>
								Bezugspflegeperson: Nachname,&nbsp;Vorname
							</TableCell>
							<TableCell align='left'>
								Geburtsdatum (optional): TT.MM.YYYY
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow
								key={row.name}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component='th' scope='row'>
									{row.name}
								</TableCell>
								<TableCell align='left' sx={{ whiteSpace: 'nowrap' }}>
									{row.unit}
								</TableCell>
								<TableCell align='left'>{row.room}</TableCell>
								<TableCell align='left'>{row.associatedCaregiver}</TableCell>
								<TableCell align='left'>{row.birthDate}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					maxWidth='md'>
					<DialogTitle>{'Bewohner:innen aus Datei importieren'}</DialogTitle>
					<DialogContent>
						<form>
							<Grid container spacing={2} paddingTop={2}>
								<Grid item xs={3}>
									<React.Fragment>
										<FileUploader onChange={handleSelectedFile} />
									</React.Fragment>
								</Grid>
								<Grid item xs={9}>
									<FormControl>
										{makeDisabledTextField(
											'Name',
											'fileName',
											selectedFileName
										)}
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction='row'
										alignItems='left'
										spacing={2}
										paddingBottom={4}>
										<Grid item>
											<Typography sx={{ fontStyle: 'italic' }}>
												Damit die Datei importiert werden kann, muss sie die
												folgenden fünf Spalten haben, getrennt durch Semikolons:
											</Typography>
										</Grid>
										<Grid item>{SampleDataTable()}</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<FormControl variant='outlined' sx={{ width: '100%' }}>
										<FormLabel>Betreiber</FormLabel>
										<Select
											variant='outlined'
											label='Betreiber'
											name='betreiber'
											value={uniqueTenantList.indexOf(selectedTenant)}
											onChange={handleTenantChange}>
											{uniqueTenantList.map((el, idx) => (
												<MenuItem key={idx} value={idx}>
													{el}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} sx={{ marginTop: 1 }}>
									<FormControl variant='outlined' sx={{ width: '100%' }}>
										<FormLabel>Wohnheim</FormLabel>
										<Select
											variant='outlined'
											label='Wohnheim'
											name='wohnheim'
											value={uniqueResidenceList.indexOf(selectedResidence)}
											onChange={handleResidenceChange}>
											{uniqueResidenceList.map((el, idx) => (
												<MenuItem key={idx} value={idx}>
													{el}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>

								<Grid item xs={12}>
									<Grid
										container
										direction='row'
										justifyContent='flex-end'
										alignItems='center'
										spacing={2}>
										<Grid item>
											<Button variant='contained' onClick={handleClose}>
												{<Close />} {'Abbrechen'}
											</Button>
										</Grid>
										<Grid item>
											<Button
												variant='contained'
												onClick={uploadResidents}
												disabled={!isSaveButtonEnabled()}>
												{<Save />} Hochladen
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
