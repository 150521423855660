import { useKeycloak } from '@react-keycloak/web';
import Menu from '../components/Menu';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ResidentProfile from '../page/ResidentProfile';
import Notes from '../page/Notes';
import Motivations from '../page/Motivations';
import React from 'react';
import {
	Box,
	Card,
	CardActions,
	CardMedia,
	Divider,
	Button,
	ThemeProvider,
	Typography,
	CssBaseline,
	Fab,
	useScrollTrigger,
	Zoom,
	Link,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import theme from '../theme';
import MotivationResult from '../page/MotivationResult';
import EditMotivationAssessment from '../page/EditMotivationAssessment';
import Admin from '../page/Admin';
import Media from '../page/Media';
import DisplayLocalMedia from '../components/DisplayLocalMedia';
import icon_tcc from '../data/icon_tcc_abgerundet.svg';
import NotePage from '../page/NotePage';
import MotivationChart from '../page/MotivationChart';
import Greetings from '../page/Greetings';
import OverviewPage from '../page/OverviewPage';

export const AppRouter = () => {
	const k = useKeycloak();

	const [showMediaFile, setShowMediaFile] = React.useState(false);

	const handleInstruction = () => {
		setShowMediaFile(true);
	};

	const basePath =
		'/tenantId=:tenantId,residenceId=:residenceId,unitId=:unitId/profile/residentId=:residentId';

	const residentProfilePath = basePath;
	const memoriesPath = basePath + '/memories';
	const memoryPath = basePath + '/memory/noteId=:noteId';
	const motivationsPath = basePath + '/motivation';
	const motivationResultPath =
		basePath +
		'/motivation/motivationResult/residentAssessmentId=:residentAssessmentId,assessmentProfileId=:assessmentProfileId';
	const editMotivationAssessmentPath =
		basePath +
		'/motivation/editMotivationAssessment/residentAssessmentId=:residentAssessmentId,assessmentProfileId=:assessmentProfileId,assessmentProfileName=:assessmentProfileName';
	const motivationChartPath = basePath + '/motivation/motivationChart';
	const mediaPath = basePath + '/media';
	const greetingsPath = basePath + '/greetings';

	let windows: () => Window;

	const trigger = useScrollTrigger({
		target: windows ? windows() : undefined,
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const anchor = (
			(event.target as HTMLDivElement).ownerDocument || document
		).querySelector('#back-to-top-anchor');

		if (anchor) {
			anchor.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	};

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Router>
					{!k.keycloak.authenticated && <Menu />}
					{k.keycloak.authenticated ? (
						<>
							<Menu />
							<Link id='back-to-top-anchor'></Link>
							<Switch>
								<Route exact path='/' component={OverviewPage} />
								<Route exact path='/admin' component={Admin} />
								<Route
									exact
									path={residentProfilePath}
									component={ResidentProfile}
								/>
								<Route path={memoriesPath} component={Notes} />
								<Route exact path={memoryPath} component={NotePage} />
								<Route exact path={motivationsPath} component={Motivations} />
								<Route
									path={motivationResultPath}
									component={MotivationResult}
								/>
								<Route
									path={editMotivationAssessmentPath}
									component={EditMotivationAssessment}
								/>
								<Route path={motivationChartPath} component={MotivationChart} />
								<Route path={mediaPath} component={Media} />
								<Route path={greetingsPath} component={Greetings} />
							</Switch>
						</>
					) : (
						<Box
							sx={{
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
								width: '100%',
								height: '100%',
							}}>
							<Card
								sx={{
									maxWidth: 'min-content',
									borderRadius: '16px',
									height: '100%',
									marginTop: '10%',
								}}
								elevation={4}>
								<CardMedia
									component='img'
									height='100%'
									alt=''
									src={icon_tcc}
								/>
								<Divider />
								<CardActions>
									<Button fullWidth size='small' onClick={handleInstruction}>
										<Typography variant='subtitle2' align='center'>
											Benutzerleitfaden touch. connect. care anzeigen
										</Typography>
									</Button>
								</CardActions>
							</Card>
						</Box>
					)}
				</Router>
				<Zoom in={trigger}>
					<Box
						onClick={handleClick}
						role='presentation'
						sx={{ position: 'fixed', bottom: 16, right: 16 }}>
						<Fab color='secondary' size='small' aria-label='scroll back to top'>
							<KeyboardArrowUpIcon />
						</Fab>
					</Box>
				</Zoom>
			</ThemeProvider>

			{showMediaFile && (
				<DisplayLocalMedia
					open={showMediaFile}
					setOpen={setShowMediaFile}
					filePathInPublicDir='/data/Benutzerleitfaden.pdf'
					mediaType={'pdf'}
				/>
			)}
		</React.Fragment>
	);
};
