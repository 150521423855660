import { styled } from '@mui/system';
import { theme } from './theme';
import { CakeTwoTone } from '@mui/icons-material';
import GradeTwoToneIcon from '@mui/icons-material/GradeTwoTone';

export const FormButtonstyle = {
	marginLeft: 'auto',
	backgroundColor: theme.palette.primary.main,
	color: 'grey',
};

export const StyledCake = styled(CakeTwoTone)({
	color: '#ef9a9a',
	position: 'relative',
	top: '5px',
});

export const StyledCake2 = styled(CakeTwoTone)({
	color: '#ef9a9a',
	position: 'relative',
	top: '-5px',
});

export const StyledGrade = styled(GradeTwoToneIcon)({
	color: '#9aef9a',
	position: 'relative',
	top: '5px',
});

export const StyledIcon = {
	position: 'relative',
	top: '5px',
	marginLeft: '3px',
};
