/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResidenceGroupCategory,
    ResidenceGroupCategoryFromJSON,
    ResidenceGroupCategoryFromJSONTyped,
    ResidenceGroupCategoryToJSON,
    Resident,
    ResidentFromJSON,
    ResidentFromJSONTyped,
    ResidentToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResidenceGroupUpdateParameters
 */
export interface ResidenceGroupUpdateParameters {
    /**
     * 
     * @type {string}
     * @memberof ResidenceGroupUpdateParameters
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceGroupUpdateParameters
     */
    motto?: string;
    /**
     * 
     * @type {ResidenceGroupCategory}
     * @memberof ResidenceGroupUpdateParameters
     */
    residenceGroupCategory?: ResidenceGroupCategory;
    /**
     * 
     * @type {string}
     * @memberof ResidenceGroupUpdateParameters
     */
    residenceGroupSubCategory?: string;
    /**
     * 
     * @type {Array<Resident>}
     * @memberof ResidenceGroupUpdateParameters
     */
    associatedResidents?: Array<Resident>;
}

export function ResidenceGroupUpdateParametersFromJSON(json: any): ResidenceGroupUpdateParameters {
    return ResidenceGroupUpdateParametersFromJSONTyped(json, false);
}

export function ResidenceGroupUpdateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceGroupUpdateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'motto': !exists(json, 'motto') ? undefined : json['motto'],
        'residenceGroupCategory': !exists(json, 'residenceGroupCategory') ? undefined : ResidenceGroupCategoryFromJSON(json['residenceGroupCategory']),
        'residenceGroupSubCategory': !exists(json, 'residenceGroupSubCategory') ? undefined : json['residenceGroupSubCategory'],
        'associatedResidents': !exists(json, 'associatedResidents') ? undefined : ((json['associatedResidents'] as Array<any>).map(ResidentFromJSON)),
    };
}

export function ResidenceGroupUpdateParametersToJSON(value?: ResidenceGroupUpdateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'motto': value.motto,
        'residenceGroupCategory': ResidenceGroupCategoryToJSON(value.residenceGroupCategory),
        'residenceGroupSubCategory': value.residenceGroupSubCategory,
        'associatedResidents': value.associatedResidents === undefined ? undefined : ((value.associatedResidents as Array<any>).map(ResidentToJSON)),
    };
}


