import React, { useCallback, useMemo } from 'react';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Tag } from '../generated/models/Tag';
import { Add, Autorenew, Delete, Edit } from '@mui/icons-material';
import {
	Box,
	Button,
	Grid,
	Paper,
	SelectChangeEvent,
	Tooltip,
} from '@mui/material';
import MaterialTable, { Column } from '@material-table/core';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

import { getNetworkApi } from '../components/keycloak';
import {
	ConnectType,
	MediaCategory,
	ResidentMediaData,
	StageOfLife,
} from '../generated';
import { SuccessAlert, ErrorAlert } from '../components/Alerts';
import { MaterialTableIcons } from '../MaterialTableIcons';
import {
	filterForMediaCategory,
	formatDate,
	dropDownFilter,
	ResidentIdData,
	mediaTypeToIcon,
	TCCStyledBadgeButton,
	viewportHeight,
	isAuthorizedForRoleTenant,
} from '../components/format';
import Confirm from '../components/Confirm';
import MediaFileForm from '../components/MediaFileForm';
import { BaseRole } from '../components/Roles';
import DisplayMedia from '../components/DisplayMedia';
import ConnectionForm from '../components/ConnectionForm';
import grey from '@mui/material/colors/grey';

export default function Media(props) {
	const residentIdData: ResidentIdData = {
		tenantIdentifier: props.match.params.tenantId,
		residenceIdentifier: props.match.params.residenceId,
		unitIdentifier: props.match.params.unitId,
		residentId: Number(props.match.params.residentId),
	};

	const [userRoleList, setUserRoleList] = React.useState([]);

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const [mediaFileList, setMediaFileList] = useState([] as ResidentMediaData[]);
	const [refresh, setRefresh] = useState(0);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [showConfirmMediaFileDeletion, setShowConfirmMediaFileDeletion] =
		useState(false);
	const [showMediaFileForm, setShowMediaFileForm] = useState(false);
	const [isNewMediaFile, setIsNewMediaFile] = useState(false);

	const [deleteText, setDeleteText] = useState('');
	const [mediaIdForDeletion, setMediaIdForDeletion] = useState(0 as number);
	const [currentMediaFileMetaData, setCurrentMediaFileMetaData] = useState(
		{} as ResidentMediaData
	);
	const defaultMediaFileData: ResidentMediaData = {
		mediaCategories: [] as MediaCategory[],
		tags: {} as Tag[],

	};
	const [stagesOfLifeList, setStagesOfLifeList] = useState([] as StageOfLife[]);
	const [currentStageOfLifeName, setCurrentStageOfLifeName] =
		React.useState('');

	const [allMediaCategories, setAllMediaCategories] = useState(
		[] as MediaCategory[]
	);
	const [selectedMediaFile, setSelectedMediaFile] = useState(new Blob());
	const [showMediaFile, setShowMediaFile] = useState(false);

	const [showConnectionForm, setShowConnectionForm] = useState(false);

	const isAuthorized: boolean = useMemo(() => {
		return isAuthorizedForRoleTenant(
			userRoleList,
			BaseRole.BEWOHNERVERWALTUNG,
			residentIdData.tenantIdentifier,
			residentIdData.residenceIdentifier,
			residentIdData.unitIdentifier
		);
	}, [
		userRoleList,
		residentIdData.residenceIdentifier,
		residentIdData.unitIdentifier,
	]);

	useEffect(() => {
		const loadMediaFileData = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidentMediaList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId,
					false
				);
				setMediaFileList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Medien konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Medien konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadMediaFileData());
	}, [residentIdData.residentId, showSuccessAlert, refresh]);

	const deleteMediaFile = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateResidentMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				mediaIdForDeletion
			);
			setShowSuccessAlert(true);
			setShowConfirmMediaFileDeletion(false);
			setAlertText('Mediendatei wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Mediendatei konnte nicht archiviert werden');
			console.log(
				'There was an error while deactivating: ' + mediaIdForDeletion
			);
		}
	};

	const handleClickOpenConnectionForm = (fileMetaData: ResidentMediaData) => {
		setShowConnectionForm(true);
		setCurrentMediaFileMetaData(fileMetaData);
	};

	useEffect(() => {
		const loadStagesOfLife = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getStagesOfLife(
					residentIdData.tenantIdentifier
				);
				setStagesOfLifeList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the StagesOfLife data!');
					}
				} else {
					console.log('There was an error fetching the StagesOfLife data!');
				}
			}
		};
		trackPromise(loadStagesOfLife());
		const loadMediaCategories = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getMediaCategories(
					residentIdData.tenantIdentifier
				);
				setAllMediaCategories(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the media category data!');
					}
				} else {
					console.log('There was an error fetching the media category data!');
				}
			}
		};
		trackPromise(loadMediaCategories());
	}, [residentIdData.tenantIdentifier]);

	const handleDeleteMediaFile = (mediaId: number) => {
		setDeleteText('Wollen Sie die Mediendatei wirklich archivieren?');
		setMediaIdForDeletion(mediaId);
		setShowConfirmMediaFileDeletion(true);
	};

	const handleClickOpenMediaFileForm = (
		isNewFile: boolean,
		fileMetaData: ResidentMediaData
	) => {
		setIsNewMediaFile(isNewFile);
		setCurrentMediaFileMetaData(fileMetaData);
		setShowMediaFileForm(true);
	};

	const loadSingleMediaFile = async (fileMetaData: ResidentMediaData) => {
		const api = getNetworkApi();
		try {
			const result = await api.getMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				fileMetaData.mediaId
			);
			return result;
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Die ausgewählte Mediendatei konnte nicht abgerufen werden');
			return null;
		}
	};

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	let MediaCategoryDict = Object.assign(
		{},
		...allMediaCategories
			.map((item) => item.name)
			.map((x) => ({ [x.substring(0, 3)]: x }))
	);

	const handleChangeStagesOfLife = (event: SelectChangeEvent) => {
		setCurrentStageOfLifeName(event.target.value);
	};

	const renderMediaCategories = (mediaCategories: MediaCategory[]) => {
		return (
			<React.Fragment>
				{mediaCategories.map((el) => (
					<Box> {el.name} </Box>
				))}
			</React.Fragment>
		);
	};

	const columns: Column<any>[] = [
		{
			width: 'min-content',
			sorting: false,
			render: useCallback((fileMetaData: ResidentMediaData) => {
				return (
					<TCCStyledBadgeButton
						badgeContent={fileMetaData.connects.length}
						onClick={() => handleClickOpenConnectionForm(fileMetaData)}
					/>
				);
			}, []),
		},
		{
			width: 'min-content',
			sorting: false,
			filtering: false,
			render: useCallback(
				(data: ResidentMediaData) => (
					<p>{mediaTypeToIcon(data.mediaTypeIdentifier)}</p>
				),
				[]
			),
		},
		{
			title: 'Name',
			field: 'fileName',
		},
		{
			title: 'Lebensabschnitt',
			field: 'stageOfLife',
			width: 'min-content',
			customSort: (a, b) =>
				stagesOfLifeList.findIndex((obj) => {
					return obj.name === a.stageOfLife;
				}) -
				stagesOfLifeList.findIndex((obj) => {
					return obj.name === b.stageOfLife;
				}),
			defaultFilter: currentStageOfLifeName,
			filterComponent: () =>
				dropDownFilter({
					value: currentStageOfLifeName,
					valueList: stagesOfLifeList,
					onChange: handleChangeStagesOfLife,
					width: '100%',
					name: 'stageOfLife',
				}),
		},
		{
			title: 'Kategorien',
			field: 'mediaCategories',
			customFilterAndSearch: (filter: object, rowData: ResidentMediaData) =>
				filterForMediaCategory(filter, rowData),
			lookup: MediaCategoryDict,
			sorting: false,
			width: 'min-content',
			render: useCallback(
				(data: ResidentMediaData) =>
					renderMediaCategories(data.mediaCategories),
				[]
			),
		},
		{
			title: 'Kommentar',
			field: 'comment',
			sorting: false,
			width: 'min-content',
		},
		{
			title: 'Hochgeladen von',
			field: 'modifyingUser',
		},
		{
			title: 'Datum',
			field: 'techValidFrom',
			width: 'min-content',
			defaultSort: 'desc',
			sorting: true,
			render: useCallback(
				(data: ResidentMediaData) => formatDate(data.techValidFrom),
				[]
			),
		},
		{
			sorting: false,
			render: useCallback(
				(data: ResidentMediaData) => {
					const handleShowMediaFile = async (
						fileMetaData: ResidentMediaData
					) => {
						let promiseForBlob = trackPromise(
							loadSingleMediaFile(fileMetaData)
						);
						setSelectedMediaFile(await promiseForBlob);
						setCurrentMediaFileMetaData(fileMetaData);
						setShowMediaFile(true);
					};

					const handleDownloadMediaFile = async (
						fileMetaData: ResidentMediaData
					) => {
						let promiseForBlob = trackPromise(
							loadSingleMediaFile(fileMetaData)
						);
						let hyperlink = document.createElement('a');
						try {
							hyperlink.href = URL.createObjectURL(await promiseForBlob);
							hyperlink.download = fileMetaData.fileName;
							hyperlink.click();
						} catch (error) {
							setShowErrorAlert(true);
							setAlertText(
								'Die ausgewählte Mediendatei konnte nicht heruntergeladen werden'
							);
						}
					};

					return (
						<Grid container spacing={0}>
							<Grid item xs={12}>
								<Tooltip title='Zeige Datei an'>
									<Button onClick={() => handleShowMediaFile(data)}>
										<PlayCircleIcon color='primary' />
									</Button>
								</Tooltip>
								<Tooltip title={isAuthorized ? 'Bearbeite Datei' : ''}>
									<span>
										<Button
											disabled={!isAuthorized}
											onClick={() => handleClickOpenMediaFileForm(false, data)}>
											<Edit color={isAuthorized ? 'primary' : 'secondary'} />
										</Button>
									</span>
								</Tooltip>
							</Grid>
							<Grid item xs={12}>
								<Tooltip title='Herunterladen'>
									<Button onClick={() => handleDownloadMediaFile(data)}>
										<DownloadForOfflineIcon color='primary' />
									</Button>
								</Tooltip>
								<Tooltip title={isAuthorized ? 'Archiviere Datei' : ''}>
									<span>
										<Button
											disabled={!isAuthorized}
											onClick={() => handleDeleteMediaFile(data.mediaId)}>
											<Delete color={isAuthorized ? 'primary' : 'secondary'} />
										</Button>
									</span>
								</Tooltip>
							</Grid>
						</Grid>
					);
				},
				[isAuthorized]
			),
		},
	];

	return (
		<React.Fragment>
			<Grid
				container
				direction='column'
				justifyContent='space-between'
				alignItems='right'
				sx={{ padding: 3 }}>
				<Grid item xs={1} sx={{ mt: 2 }}>
					<Button
						disabled={!isAuthorized}
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() =>
							handleClickOpenMediaFileForm(true, defaultMediaFileData)
						}>
						{<Add />} Datei
					</Button>
					<Button
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleRefresh()}>
						{<Autorenew />}
					</Button>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ mx: 5 }}>
				<MaterialTable
					localization={{
						body: {
							emptyDataSourceMessage: 'Keine Dateien hinterlegt.',
						},
					}}
					style={{ borderRadius: '30px', fontSize: '12', color: grey[500] }}
					icons={MaterialTableIcons()}
					columns={columns}
					data={mediaFileList}
					components={{
						Container: (props) => <Paper {...props} elevation={3} />,
					}}
					options={{
						paging: false,
						showTitle: false,
						sorting: true,
						filtering: true,
						search: false,
						rowStyle: { fontSize: 'subtitle2' },
						headerStyle: {
							position: 'sticky',
							top: 0,
							backgroundColor: 'white',
						},
						maxBodyHeight: viewportHeight(80),
					}}
				/>
			</Grid>
			{showConnectionForm && (
				<ConnectionForm
					open={showConnectionForm}
					setOpen={setShowConnectionForm}
					residentIdData={residentIdData}
					connectType={ConnectType.Media}
					databaseId={currentMediaFileMetaData.mediaId}
					currentConnectDataList={currentMediaFileMetaData.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showMediaFile && (
				<DisplayMedia
					open={showMediaFile}
					setOpen={setShowMediaFile}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
					metaData={currentMediaFileMetaData}
					mediaFile={selectedMediaFile}
				/>
			)}
			{showMediaFileForm && (
				<MediaFileForm
					open={showMediaFileForm}
					setOpen={setShowMediaFileForm}
					isNewMediaFile={isNewMediaFile}
					residentIdData={residentIdData}
					currentMediaFileMetaData={currentMediaFileMetaData}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showConfirmMediaFileDeletion && (
				<Confirm
					open={showConfirmMediaFileDeletion}
					setOpen={setShowConfirmMediaFileDeletion}
					text={deleteText}
					executefct={deleteMediaFile}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
