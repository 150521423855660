import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import {
	Add,
	Autorenew,
	GridView,
	QrCode2,
	TableRows,
} from '@mui/icons-material';
import { Button, Grid, Tooltip } from '@mui/material';
import { getNetworkApi } from '../components/keycloak';
import { SuccessAlert, ErrorAlert } from '../components/Alerts';
import {
	isAuthorizedForRoleTenant,
	ResidentIdData
} from '../components/format';
import Confirm from '../components/Confirm';
import {
	GreetingMediaInsertParameters,
	GreetingMediaUpdateParameters,
	ResidentMediaData,
	MediaCategory,
	Tag,
	GreetingMedia,
	ConnectData
} from '../generated';
import { BaseRole } from '../components/Roles';
import GreetingForm from '../components/GreetingForm';
import QRCodeGreetingsForm from '../components/QRCodeGreetingsForm';
import { Greeting } from '../generated/models/Greeting';
import MediaFileForm from '../components/MediaFileForm';
import NoteOrGreetingToMediaLinkForm from '../components/NoteOrGreetingToMediaLinkForm';
import DisplayMediaAlbumForm from '../components/DisplayMediaAlbumForm';
import ConnectionForm from '../components/ConnectionForm';
import { ConnectType } from '../generated';
import GreetingsTableView from '../components/GreetingsTableView';
import GreetingsGridView from '../components/GreetingsGridView';

export default function Greetings(props) {
	const residentIdData: ResidentIdData = {
		tenantIdentifier: props.match.params.tenantId,
		residenceIdentifier: props.match.params.residenceId,
		unitIdentifier: props.match.params.unitId,
		residentId: Number(props.match.params.residentId),
	};

		const defaultMediaFileData: ResidentMediaData = {
		mediaCategories: [] as MediaCategory[],
		tags: {} as Tag[],

	};

	const [userRoleList, setUserRoleList] = React.useState([]);
	const [emailAddress, setEmailAddress] = React.useState({} as string)
	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const [refresh, setRefresh] = useState(0);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [deleteText, setDeleteText] = useState('');

	const [greetingsList, setGreetingsList] = useState([] as Greeting[]);
	const [isNewGreeting, setIsNewGreeting] = useState(false);
	const [displayGreeting, setDisplayGreeting] = useState(false);
	const [showGreetingForm, setShowGreetingForm] = useState(false);
	const defaultGreeting: Greeting = {
	media: {} as GreetingMedia[],
    connects: {} as ConnectData[],
	tags: {} as Tag[],
	};


	const [selectedGreeting, setSelectedGreeting] = useState(
		undefined as Greeting
	);
	const [greetingIdForDeletion, setGreetingIdForDeletion] = useState(
		undefined as number
	);
	const [showConfirmGreetingDeletion, setShowConfirmGreetingDeletion] =
		useState(false);
	const [showQRCodeForm, setShowQRCodeForm] = useState(false);
	const [showMediaFileForm, setShowMediaFileForm] = useState(false);
	const [mediaIdForLink, setMediaIdForLink] = useState(undefined as number);
	const [showAddMediaLinkForm, setShowAddMediaLinkForm] = useState(
		false as boolean
	);
	const [showDisplayMediaAlbumForm, setShowDisplayMediaAlbumForm] = useState(
		false as boolean
	);
	const [showConnectionForm, setShowConnectionForm] = useState(false);

	const isAuthorized: boolean = useMemo(() => {
		return isAuthorizedForRoleTenant(
			userRoleList,
			BaseRole.BEWOHNERVERWALTUNG,
			residentIdData.tenantIdentifier,
			residentIdData.residenceIdentifier,
			residentIdData.unitIdentifier
		);
	}, [
		userRoleList,
		residentIdData.residenceIdentifier,
		residentIdData.unitIdentifier,
	]);

	useEffect(() => {
		const loadEmailAddress = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getEmailAddress(
					residentIdData.tenantIdentifier
					);
				setEmailAddress(result)
			} catch (error) {
				console.log('There was an error fetching the email address!');
			}
		};
		trackPromise(loadEmailAddress());
	}, [residentIdData.tenantIdentifier, showSuccessAlert, refresh]);

	useEffect(() => {
		const loadGreetings = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidentGreetingList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId
				);
				setGreetingsList(
					result.sort(
						(a: Greeting, b: Greeting) =>
							b.techValidFrom.getTime() - a.techValidFrom.getTime()
					)
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Grüße konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Grüße konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadGreetings());
	}, [residentIdData.residentId, showSuccessAlert, refresh]);

	const deleteGreeting = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateResidentGreeting(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				greetingIdForDeletion
			);
			setShowSuccessAlert(true);
			setShowConfirmGreetingDeletion(false);
			setAlertText('Gruß wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Gruß konnte nicht archiviert werden');
			console.log(
				'There was an error while deactivating: ' + greetingIdForDeletion
			);
		}
	};

	const handleDeleteGreeting = (greetingId: number) => {
		setDeleteText('Wollen Sie den Gruß wirklich archivieren?');
		setGreetingIdForDeletion(greetingId);
		setShowConfirmGreetingDeletion(true);
	};

	const handleClickOpenGreetingsForm = (
		isNewGreeting: boolean,
		greeting: Greeting
	) => {
		setSelectedGreeting(greeting);
		if (!isNewGreeting) {
			setSelectedGreeting(greeting);
		}
		setIsNewGreeting(isNewGreeting);
		setShowGreetingForm(true);
		setDisplayGreeting(false);
	};

	const handleDisplayGreeting = (greeting: Greeting) => {
		setDisplayGreeting(true);
		setShowGreetingForm(true);
		setSelectedGreeting(greeting);
	};

	const handleClickOpenQRCodeForm = () => {
		setShowQRCodeForm(true);
	};

	const handleClickOpenMediaAlbumForm = (greeting: Greeting) => {
		setSelectedGreeting(greeting);
		setShowDisplayMediaAlbumForm(true);
	};

	const handleClickOpenMediaFileForm = (greeting: Greeting) => {
		setSelectedGreeting(greeting);
		setShowMediaFileForm(true);
	};

	const handleAddLinkToMediaFile = (greeting: Greeting) => {
		setSelectedGreeting(greeting);
		setShowAddMediaLinkForm(true);
	};

	useEffect(() => {
		if (mediaIdForLink !== undefined) {
			selectedGreeting?.media?.length > 0
				? updateMediaToGreetingLinks()
				: saveMediaToGreetingLink();
		}
	}, [mediaIdForLink]);

	const saveMediaToGreetingLink = async () => {
		let greetingMediaInsertParameters: GreetingMediaInsertParameters = {
			mediaIds: [mediaIdForLink],
		};
		const client = getNetworkApi();
		try {
			await client.postGreetingMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				selectedGreeting.greetingId,
				greetingMediaInsertParameters
			);
			setAlertText('Verknüpfung zu Medien wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateMediaToGreetingLinks = async () => {
		let mediaIds: number[] = [];
		selectedGreeting.media.forEach((elem) => {
			mediaIds.push(elem.mediaId);
		});
		mediaIds.push(mediaIdForLink);
		let greetingMediaUpdateParameters: GreetingMediaUpdateParameters = {
			mediaIds: mediaIds,
		};
		const client = getNetworkApi();
		try {
			await client.putGreetingMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				selectedGreeting.greetingId,
				greetingMediaUpdateParameters
			);
			setAlertText('Verknüpfung zu Medien wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	const handleClickOpenConnectionForm = (greeting: Greeting) => {
		setShowConnectionForm(true);
		setSelectedGreeting(greeting);
	};

	const [isTableViewSelected, setIsTableViewSelected] = React.useState(false);
	const handleSwitchChange = () => {
		setIsTableViewSelected(!isTableViewSelected);
	};

	return (
		<React.Fragment>
			<Grid
				container
				direction='column'
				justifyContent='space-between'
				alignItems='right'
				sx={{ padding: 3 }}>
				<Grid item xs={1} sx={{ mt: 2, marginBottom: 5 }}>
					<Button
						disabled={(!isAuthorized) || (emailAddress === "No Email Address")}
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleClickOpenQRCodeForm()}>
						{<QrCode2 />} QR
					</Button>
					<Button
						disabled={!isAuthorized}
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleClickOpenGreetingsForm(true, defaultGreeting)}>
						{<Add />} Grüße
					</Button>
					<Button
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleRefresh()}>
						{<Autorenew />}
					</Button>
					<Tooltip title={isTableViewSelected ? 'Grüßekärtchen' : 'Tabelle'}>
						<Button
							variant='contained'
							sx={{ float: 'right', mr: 5 }}
							onClick={() => handleSwitchChange()}>
							{isTableViewSelected ? <GridView /> : <TableRows />}
						</Button>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					{isTableViewSelected ? (
						<GreetingsTableView
							greetingsList={greetingsList}
							isAuthorized={isAuthorized}
							handleClickOpenConnectionForm={handleClickOpenConnectionForm}
							handleClickOpenMediaAlbumForm={handleClickOpenMediaAlbumForm}
							handleClickOpenGreetingsForm={handleClickOpenGreetingsForm}
							handleDeleteGreeting={handleDeleteGreeting}
							handleClickOpenMediaFileForm={handleClickOpenMediaFileForm}
							handleAddLinkToMediaFile={handleAddLinkToMediaFile}
						/>
					) : (
						<GreetingsGridView
							greetingsList={greetingsList}
							handleClickOpenConnectionForm={handleClickOpenConnectionForm}
							handleDisplayGreeting={handleDisplayGreeting}
							isAuthorized={isAuthorized}
							handleClickOpenMediaAlbumForm={handleClickOpenMediaAlbumForm}
						/>
					)}
				</Grid>
			</Grid>
			{showConnectionForm && (
				<ConnectionForm
					open={showConnectionForm}
					setOpen={setShowConnectionForm}
					residentIdData={residentIdData}
					connectType={ConnectType.Greeting}
					databaseId={selectedGreeting.greetingId}
					currentConnectDataList={selectedGreeting.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showGreetingForm && (
				<GreetingForm
					open={showGreetingForm}
					setOpen={setShowGreetingForm}
					residentIdData={residentIdData}
					isNewGreeting={isNewGreeting}
					displayGreeting={displayGreeting}
					selectedGreeting={selectedGreeting}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showMediaFileForm && (
				<MediaFileForm
					open={showMediaFileForm}
					setOpen={setShowMediaFileForm}
					isNewMediaFile={true}
					residentIdData={residentIdData}
					setMediaId={setMediaIdForLink}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					currentMediaFileMetaData={defaultMediaFileData}
				/>
			)}
			{showAddMediaLinkForm && (
				<NoteOrGreetingToMediaLinkForm
					open={showAddMediaLinkForm}
					setOpen={setShowAddMediaLinkForm}
					residentIdData={residentIdData}
					currentNoteOrGreeting={selectedGreeting}
					mediaLinkType='greeting'
					elementId={selectedGreeting?.greetingId}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showDisplayMediaAlbumForm && (
				<DisplayMediaAlbumForm
					open={showDisplayMediaAlbumForm}
					setOpen={setShowDisplayMediaAlbumForm}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
					residentIdData={residentIdData}
					currentDisplayMediaAlbum={selectedGreeting}
					mediaIdList={selectedGreeting?.media}
					title={
						'Medien für Gruß: ' +
						selectedGreeting?.title +
						' von ' +
						selectedGreeting?.greetingPersonName
					}
				/>
			)}
			{showConfirmGreetingDeletion && (
				<Confirm
					open={showConfirmGreetingDeletion}
					setOpen={setShowConfirmGreetingDeletion}
					text={deleteText}
					executefct={deleteGreeting}
				/>
			)}
			{showQRCodeForm && (
				<QRCodeGreetingsForm
					open={showQRCodeForm}
					setOpen={setShowQRCodeForm}
					residentIdData={residentIdData}
					emailAddress={emailAddress}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
