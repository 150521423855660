import MaterialTable, { Column } from '@material-table/core';
import { Close, Save } from '@mui/icons-material';
import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Paper,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import {
	Residence,
	ResidenceEvent,
	ResidenceEventMediaInsertParameters,
	ResidenceEventMediaUpdateParameters,
	ResidenceMediaData,
} from '../generated';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { EventIdData, formatDate, isIdTicked } from './format';
import { getNetworkApi } from './keycloak';

interface EventToMediaLinkFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	currentResidence: Residence;
	currentEvent: ResidenceEvent;
	eventId: number;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EventToMediaLinkForm(prop: EventToMediaLinkFormProp) {
	const {
		open,
		setOpen,
		currentResidence,
		currentEvent,
		eventId,
		setAlertText,
		setShowSuccessAlert,
		setShowErrorAlert,
	} = prop;

	const [mediaFileList, setMediaFileList] = useState(
		[] as ResidenceMediaData[]
	);
	const currentSavedSelection: number[] = getCurrentSavedSelection();
	const [selectedMediaIds, setSelectedMediaIds] = useState(
		currentSavedSelection
	);

	function getCurrentSavedSelection(): number[] {
		let currentSelection: number[] = [];
		currentEvent.residenceMedia?.forEach((elem) => {
			currentSelection.push(elem.residenceMediaId);
		});
		return currentSelection;
	}

	const isCurrentSavedSelectionEmpty = (): boolean => {
		if (currentSavedSelection.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSelectedMediaIds = (e) => {
		if (Object.entries(selectedMediaIds).length !== 0) {
			if (e.target.checked) {
				let newMediaIds = selectedMediaIds.slice();
				newMediaIds.push(Number(e.target.value));
				setSelectedMediaIds(newMediaIds);
			} else {
				setSelectedMediaIds(
					selectedMediaIds.filter((item) => item !== Number(e.target.value))
				);
			}
		} else {
			setSelectedMediaIds([Number(e.target.value)]);
		}
	};

	const handleSaveOrUpdate = () => {
		if (isCurrentSavedSelectionEmpty()) {
			saveMediaToEventLink();
		} else {
			updateMediaToEventLinks();
		}
	};

	useEffect(() => {
		const loadMediaFileData = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidenceMediaList(
					currentResidence.tenantIdentifier,
					currentResidence.residenceIdentifier
				);
				setMediaFileList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Medien konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Medien konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadMediaFileData());
	}, []);

	const saveMediaToEventLink = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceEventMediaInsertParameters: ResidenceEventMediaInsertParameters =
			{
				residenceMediaIds: selectedMediaIds.slice(),
			};
		try {
			await client.postResidenceEventMedia(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				eventId,
				residenceEventMediaInsertParameters
			);
			setAlertText('Verknüpfung zu Medien wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateMediaToEventLinks = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceEventMediaUpdateParameters: ResidenceEventMediaUpdateParameters =
			{
				residenceMediaIds: selectedMediaIds.slice(),
			};

		try {
			await client.putResidenceEventMedia(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				eventId,
				residenceEventMediaUpdateParameters
			);
			setAlertText('Verknüpfung zu Medien wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const columns: Column<ResidenceMediaData>[] = [
		{
			title: 'Name',
			field: 'fileName',
			defaultSort: 'asc',
			render: useCallback(
				(data: ResidenceMediaData) => {
					return (
						<FormControlLabel
							key={data.residenceMediaId}
							value={data.residenceMediaId}
							checked={isIdTicked(selectedMediaIds, data.residenceMediaId)}
							control={<Checkbox />}
							label={data.fileName}
							onChange={(e) => handleSelectedMediaIds(e)}
						/>
					);
				},
				[selectedMediaIds]
			),
		},
		{
			title: 'Kommentar',
			field: 'comment',
			sorting: false,
		},
		{
			title: 'Hochgeladen von',
			field: 'modifyingUser',
		},
		{
			title: 'Hochgeladen am',
			field: 'techValidFrom',
			render: useCallback(
				(data: ResidenceMediaData) => formatDate(data.techValidFrom),
				[]
			),
		},
	];

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth='xl'>
				<DialogTitle>Medien mit Event verknüpfen:</DialogTitle>
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<MaterialTable
									localization={{
										body: {
											emptyDataSourceMessage: 'Noch keine Medien vorhanden.',
										},
									}}
									icons={MaterialTableIcons()}
									columns={columns}
									data={mediaFileList}
									components={{
										Container: (props) => <Paper {...props} elevation={0} />,
									}}
									options={{
										paging: false,
										showTitle: false,
										sorting: true,
										filtering: true,
										search: false,
										rowStyle: { fontSize: 24 },
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} Abbrechen
										</Button>
									</Grid>
									{mediaFileList.length !== 0 && (
										<Grid item>
											<Button variant='contained' onClick={handleSaveOrUpdate}>
												{<Save />} Speichern
											</Button>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
