/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssessmentProfile
 */
export interface AssessmentProfile {
    /**
     * 
     * @type {number}
     * @memberof AssessmentProfile
     */
    assessmentProfileId?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentProfile
     */
    assessmentTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProfile
     */
    assessmentTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProfile
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProfile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProfile
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentProfile
     */
    description?: string;
}

export function AssessmentProfileFromJSON(json: any): AssessmentProfile {
    return AssessmentProfileFromJSONTyped(json, false);
}

export function AssessmentProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assessmentProfileId': !exists(json, 'assessmentProfileId') ? undefined : json['assessmentProfileId'],
        'assessmentTypeId': !exists(json, 'assessmentTypeId') ? undefined : json['assessmentTypeId'],
        'assessmentTypeName': !exists(json, 'assessmentTypeName') ? undefined : json['assessmentTypeName'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function AssessmentProfileToJSON(value?: AssessmentProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assessmentProfileId': value.assessmentProfileId,
        'assessmentTypeId': value.assessmentTypeId,
        'assessmentTypeName': value.assessmentTypeName,
        'tenantIdentifier': value.tenantIdentifier,
        'name': value.name,
        'version': value.version,
        'description': value.description,
    };
}


