/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResidenceGroupCategory,
    ResidenceGroupCategoryFromJSON,
    ResidenceGroupCategoryFromJSONTyped,
    ResidenceGroupCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResidenceGroupCategoryMapping
 */
export interface ResidenceGroupCategoryMapping {
    /**
     * 
     * @type {ResidenceGroupCategory}
     * @memberof ResidenceGroupCategoryMapping
     */
    residenceGroupCategory?: ResidenceGroupCategory;
    /**
     * 
     * @type {string}
     * @memberof ResidenceGroupCategoryMapping
     */
    residenceGroupSubCategory?: string;
}

export function ResidenceGroupCategoryMappingFromJSON(json: any): ResidenceGroupCategoryMapping {
    return ResidenceGroupCategoryMappingFromJSONTyped(json, false);
}

export function ResidenceGroupCategoryMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceGroupCategoryMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'residenceGroupCategory': !exists(json, 'residenceGroupCategory') ? undefined : ResidenceGroupCategoryFromJSON(json['residenceGroupCategory']),
        'residenceGroupSubCategory': !exists(json, 'residenceGroupSubCategory') ? undefined : json['residenceGroupSubCategory'],
    };
}

export function ResidenceGroupCategoryMappingToJSON(value?: ResidenceGroupCategoryMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'residenceGroupCategory': ResidenceGroupCategoryToJSON(value.residenceGroupCategory),
        'residenceGroupSubCategory': value.residenceGroupSubCategory,
    };
}


