import { useEffect, useState } from 'react';
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	ReferenceLine,
	ResponsiveContainer,
} from 'recharts';
import {
	Typography,
	Card,
	CardActionArea,
	CardContent,
	Divider,
} from '@mui/material';

import { formatDate } from './format';
import { AssessmentResultWithTimestamp } from '../generated';

interface SelectedData {
	assessmentTargetName: string;
	assessmentTargetId: number;
	residentAssessmentId: number;
	descriptionLower: string;
	descriptionUpper: string;
	timeStamp: Date;
	date: string;
	score: number;
	adaptedScore: number;
}

interface AssessmentTargetChartProps {
	data: AssessmentResultWithTimestamp[];
	handleClick: any;
}

export default function AssessmentTargetChart({
	data,
	handleClick,
}: AssessmentTargetChartProps) {
	const [selectedData, setSelectedData] = useState([] as SelectedData[]);
	const [title, setTitle] = useState('');
	const [descriptionLower, setDescriptionLower] = useState('');
	const [descriptionUpper, setDescriptionUpper] = useState('');

	const offsetForFiftyPercentBar = 0.02;

	// special case: it seems that we have to hard-code the font type here
	const selectedFontFamily = ['Roboto', 'sans-serif'].join(',');

	const getScoreAdaptedForChart = (score: number): number => {
		if (score === 0.5) {
			return offsetForFiftyPercentBar;
		} else {
			return 0.5 - score;
		}
	};

	const handleColor = (localData: SelectedData[]) => {
		return localData.map((entry, index) => (
			<Cell
				cursor='pointer'
				fill={
					entry.adaptedScore === offsetForFiftyPercentBar
						? 'lightgrey'
						: entry.adaptedScore > 0
						? '#7da7ca'
						: '#008080'
				}
				key={`cell-${index}`}
			/>
		));
	};
	function tickFormatter(value) {
		if (value === -0.5) return descriptionLower;
		if (value === 0.5) return descriptionUpper;
		return '';
	}

	const labelFormatter = (value) => {
		let displayedValue =
			value === offsetForFiftyPercentBar
				? 50
				: 50 + Math.abs(Math.round(value * 100));

		return displayedValue + '%';
	};

	useEffect(() => {
		let localSelectedData: SelectedData[] = [];
		setTitle(data[0]?.assessmentTargetName);
		setDescriptionLower(data[0]?.descriptionLower);
		setDescriptionUpper(data[0]?.descriptionUpper);

		data?.forEach(function (el) {
			localSelectedData.push({
				assessmentTargetName: el.assessmentTargetName,
				assessmentTargetId: el.assessmentTargetId,
				residentAssessmentId: el.residentAssessmentId,
				descriptionLower: el.descriptionLower,
				descriptionUpper: el.descriptionUpper,
				timeStamp: el.assessmentTimeStamp,
				date: formatDate(el.assessmentTimeStamp),
				score: el.score,
				adaptedScore: getScoreAdaptedForChart(el.score),
			});
		});
		setSelectedData(localSelectedData);
	}, [data]);

	return (
		<Card
			sx={{
				borderRadius: 10,
				minHeight: 'maxContent',
			}}
			elevation={5}>
			<CardActionArea>
				<CardContent>
					<Typography align='center'>{title}</Typography>
				</CardContent>
				<div>
					<Divider></Divider>
					<CardContent>
						<ResponsiveContainer height={400}>
							<BarChart
								data={selectedData}
								margin={{
									top: 50,
									right: 30,
									left: 100,
									bottom: 5,
								}}>
								<XAxis
									dataKey='date'
									style={{
										fontSize: '18',
										fontFamily: selectedFontFamily,
									}}
								/>
								<YAxis
									domain={[-0.5, 0.5]}
									style={{
										fontSize: '18',
										fontFamily: selectedFontFamily,
									}}
									tickFormatter={tickFormatter}
								/>
								<ReferenceLine y={0} stroke='#000' />
								<Bar
									dataKey='adaptedScore'
									onClick={handleClick}
									label={{
										position: 'top',
										formatter: labelFormatter,
										fontSize: '18',
										fontFamily: selectedFontFamily,
									}}>
									{handleColor(selectedData)}
								</Bar>
							</BarChart>
						</ResponsiveContainer>
					</CardContent>
				</div>
			</CardActionArea>
		</Card>
	);
}
