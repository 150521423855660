/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NoteCategory {
    Highlight = 'HIGHLIGHT',
    Fateful = 'FATEFUL',
    Change = 'CHANGE',
    Encounter = 'ENCOUNTER',
    Party = 'PARTY'
}

export function NoteCategoryFromJSON(json: any): NoteCategory {
    return NoteCategoryFromJSONTyped(json, false);
}

export function NoteCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteCategory {
    return json as NoteCategory;
}

export function NoteCategoryToJSON(value?: NoteCategory | null): any {
    return value as any;
}

