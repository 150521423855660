import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import deLocale from 'date-fns/locale/de';
import TextField from '@mui/material/TextField';
import { addTime, handleKeyPress } from './format';

export default function SelectDate(props) {
	const { name, label, value, Values, setValues, disabled, enableFuture } =
		props;

	const onDateChange = (newValue) => {
		if (newValue === null) {
			setValues({
				...Values,
				[name]: null,
			});
		} else {
			setValues({
				...Values,
				[name]: addTime(newValue),
			});
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
			<DatePicker
				disableFuture={!enableFuture}
				label={label}
				mask='__.__.____'
				value={value}
				onChange={onDateChange}
				clearable
				disabled={disabled}
				renderInput={(params) => (
					<TextField
						{...params}
						inputProps={{
							...params.inputProps,
							placeholder: 'tt.mm.jjjj',
						}}
						fullWidth
						onKeyPress={handleKeyPress}
					/>
				)}
			/>
		</LocalizationProvider>
	);
}
