/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectData
 */
export interface ConnectData {
    /**
     * 
     * @type {number}
     * @memberof ConnectData
     */
    connectId?: number;
    /**
     * 
     * @type {string}
     * @memberof ConnectData
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectData
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof ConnectData
     */
    techValidFrom?: Date;
}

export function ConnectDataFromJSON(json: any): ConnectData {
    return ConnectDataFromJSONTyped(json, false);
}

export function ConnectDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connectId': !exists(json, 'connectId') ? undefined : json['connectId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'techValidFrom': !exists(json, 'techValidFrom') ? undefined : (new Date(json['techValidFrom'])),
    };
}

export function ConnectDataToJSON(value?: ConnectData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectId': value.connectId,
        'comment': value.comment,
        'modifyingUser': value.modifyingUser,
        'techValidFrom': value.techValidFrom === undefined ? undefined : (value.techValidFrom.toISOString().substr(0,10)),
    };
}


