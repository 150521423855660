/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssessedQuestion
 */
export interface AssessedQuestion {
    /**
     * 
     * @type {number}
     * @memberof AssessedQuestion
     */
    questionId?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessedQuestion
     */
    rank?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessedQuestion
     */
    residentAssessmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessedQuestion
     */
    assessedValue?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessedQuestion
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessedQuestion
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessedQuestion
     */
    question?: string;
    /**
     * 
     * @type {number}
     * @memberof AssessedQuestion
     */
    scaleMin?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessedQuestion
     */
    scaleMax?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessedQuestion
     */
    guidance?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessedQuestion
     */
    guidanceMin?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessedQuestion
     */
    guidanceMax?: string;
}

export function AssessedQuestionFromJSON(json: any): AssessedQuestion {
    return AssessedQuestionFromJSONTyped(json, false);
}

export function AssessedQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessedQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'questionId': !exists(json, 'questionId') ? undefined : json['questionId'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'residentAssessmentId': !exists(json, 'residentAssessmentId') ? undefined : json['residentAssessmentId'],
        'assessedValue': !exists(json, 'assessedValue') ? undefined : json['assessedValue'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'question': !exists(json, 'question') ? undefined : json['question'],
        'scaleMin': !exists(json, 'scaleMin') ? undefined : json['scaleMin'],
        'scaleMax': !exists(json, 'scaleMax') ? undefined : json['scaleMax'],
        'guidance': !exists(json, 'guidance') ? undefined : json['guidance'],
        'guidanceMin': !exists(json, 'guidanceMin') ? undefined : json['guidanceMin'],
        'guidanceMax': !exists(json, 'guidanceMax') ? undefined : json['guidanceMax'],
    };
}

export function AssessedQuestionToJSON(value?: AssessedQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'questionId': value.questionId,
        'rank': value.rank,
        'residentAssessmentId': value.residentAssessmentId,
        'assessedValue': value.assessedValue,
        'comment': value.comment,
        'tenantIdentifier': value.tenantIdentifier,
        'question': value.question,
        'scaleMin': value.scaleMin,
        'scaleMax': value.scaleMax,
        'guidance': value.guidance,
        'guidanceMin': value.guidanceMin,
        'guidanceMax': value.guidanceMax,
    };
}


