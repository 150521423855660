import { Close, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { getNetworkApi } from './keycloak';

export default function NoteCommentForm({
	open,
	setOpen,
	NoteCommentDetails,
	IsNewComment,
	setAlertText,
	SetShowSuccessAlert,
	SetShowErrorAlert,
}) {
	const handleClose = () => {
		setOpen(false);
	};

	const [comment, setComment] = useState('' as string);

	const handleInputChange = (e) => {
		setComment(e.target.value);
	};

	const saveNewComment = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.postNoteComment(
				NoteCommentDetails.tenantIdentifier,
				NoteCommentDetails.residenceIdentifier,
				NoteCommentDetails.unitIdentifier,
				NoteCommentDetails.noteId,
				{
					content: comment,
				}
			);
			setAlertText('Kommentar wurde erfolgreich gespeichert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Kommentar wurde nicht gespeichert');
			SetShowErrorAlert(true);
		}
	};

	const updateComment = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.putNoteComment(
				NoteCommentDetails.tenantIdentifier,
				NoteCommentDetails.residenceIdentifier,
				NoteCommentDetails.unitIdentifier,
				NoteCommentDetails.noteId,
				NoteCommentDetails.noteCommentId,
				{
					content: comment,
				}
			);
			setAlertText('Kommentar wurde erfolgreich geändert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Kommentar wurde nicht geändert');
			SetShowErrorAlert(true);
		}
	};

	return (
		<React.Fragment>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>
					{IsNewComment ? 'Kommentar anlegen:' : 'Kommentar bearbeiten'}
				</DialogTitle>
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									id='filled-multiline-static'
									fullWidth
									multiline
									rows={4}
									defaultValue={
										NoteCommentDetails.noteCommentUpdateParameters.content
									}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} Abbrechen
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant='contained'
											onClick={IsNewComment ? saveNewComment : updateComment}>
											{<Save />} Speichern
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
