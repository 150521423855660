/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidentDeleteReservationDeleteDecision
 */
export interface ResidentDeleteReservationDeleteDecision {
    /**
     * 
     * @type {boolean}
     * @memberof ResidentDeleteReservationDeleteDecision
     */
    performDeletion?: boolean;
}

export function ResidentDeleteReservationDeleteDecisionFromJSON(json: any): ResidentDeleteReservationDeleteDecision {
    return ResidentDeleteReservationDeleteDecisionFromJSONTyped(json, false);
}

export function ResidentDeleteReservationDeleteDecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentDeleteReservationDeleteDecision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'performDeletion': !exists(json, 'performDeletion') ? undefined : json['performDeletion'],
    };
}

export function ResidentDeleteReservationDeleteDecisionToJSON(value?: ResidentDeleteReservationDeleteDecision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'performDeletion': value.performDeletion,
    };
}


