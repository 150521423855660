import * as React from 'react';

export default function useForm(initialValues, units,unitIndex){

    const[Values, setValues] = React.useState(initialValues);
    const[UnitIndex, setUnitIndex] = React.useState(unitIndex);

    const handleInputChange = e => {
        const {name, value} =e.target
            setValues({
                ...Values,
                [name]:value
            })
        }

    const handleUnitChange = e => {
        const {value} =e.target
            setValues({
                ...Values,
                'unitIdentifier' : units[value].unitIdentifier,
                'tenantIdentifier' : units[value].tenantIdentifier,
                'residenceIdentifier' : units[value].residenceIdentifier,
            })
            setUnitIndex(value)
        }

    return{
        Values, 
        setValues,
        UnitIndex,
        handleInputChange,
        handleUnitChange
    }
}
