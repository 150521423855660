/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssessmentResult
 */
export interface AssessmentResult {
    /**
     * 
     * @type {number}
     * @memberof AssessmentResult
     */
    assessmentTargetId?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentResult
     */
    residentAssessmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentResult
     */
    assessmentTargetName?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentResult
     */
    descriptionLower?: string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentResult
     */
    descriptionUpper?: string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentResult
     */
    maxScore?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentResult
     */
    minScore?: number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentResult
     */
    score?: number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentResult
     */
    rationale?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssessmentResult
     */
    isPlausibility?: boolean;
}

export function AssessmentResultFromJSON(json: any): AssessmentResult {
    return AssessmentResultFromJSONTyped(json, false);
}

export function AssessmentResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssessmentResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assessmentTargetId': !exists(json, 'assessmentTargetId') ? undefined : json['assessmentTargetId'],
        'residentAssessmentId': !exists(json, 'residentAssessmentId') ? undefined : json['residentAssessmentId'],
        'assessmentTargetName': !exists(json, 'assessmentTargetName') ? undefined : json['assessmentTargetName'],
        'descriptionLower': !exists(json, 'descriptionLower') ? undefined : json['descriptionLower'],
        'descriptionUpper': !exists(json, 'descriptionUpper') ? undefined : json['descriptionUpper'],
        'maxScore': !exists(json, 'maxScore') ? undefined : json['maxScore'],
        'minScore': !exists(json, 'minScore') ? undefined : json['minScore'],
        'score': !exists(json, 'score') ? undefined : json['score'],
        'rationale': !exists(json, 'rationale') ? undefined : json['rationale'],
        'isPlausibility': !exists(json, 'isPlausibility') ? undefined : json['isPlausibility'],
    };
}

export function AssessmentResultToJSON(value?: AssessmentResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assessmentTargetId': value.assessmentTargetId,
        'residentAssessmentId': value.residentAssessmentId,
        'assessmentTargetName': value.assessmentTargetName,
        'descriptionLower': value.descriptionLower,
        'descriptionUpper': value.descriptionUpper,
        'maxScore': value.maxScore,
        'minScore': value.minScore,
        'score': value.score,
        'rationale': value.rationale,
        'isPlausibility': value.isPlausibility,
    };
}


