/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChangeDateDetail,
    ChangeDateDetailFromJSON,
    ChangeDateDetailFromJSONTyped,
    ChangeDateDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface Resident
 */
export interface Resident {
    /**
     * 
     * @type {number}
     * @memberof Resident
     */
    residentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    residenceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    unitIdentifier?: string;
    /**
     * 
     * @type {Date}
     * @memberof Resident
     */
    techValidFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Resident
     */
    techValidTo?: Date;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    room?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    associatedCaregiver?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    birthplace?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    birthname?: string;
    /**
     * 
     * @type {string}
     * @memberof Resident
     */
    nickname?: string;
    /**
     * 
     * @type {Date}
     * @memberof Resident
     */
    birthday?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Resident
     */
    residentSince?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Resident
     */
    lastChangeDate?: Date;
    /**
     * 
     * @type {Array<ChangeDateDetail>}
     * @memberof Resident
     */
    lastChangeDateDetails?: Array<ChangeDateDetail>;
}

export function ResidentFromJSON(json: any): Resident {
    return ResidentFromJSONTyped(json, false);
}

export function ResidentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resident {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'residenceIdentifier': !exists(json, 'residenceIdentifier') ? undefined : json['residenceIdentifier'],
        'unitIdentifier': !exists(json, 'unitIdentifier') ? undefined : json['unitIdentifier'],
        'techValidFrom': !exists(json, 'techValidFrom') ? undefined : (new Date(json['techValidFrom'])),
        'techValidTo': !exists(json, 'techValidTo') ? undefined : (new Date(json['techValidTo'])),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'room': !exists(json, 'room') ? undefined : json['room'],
        'associatedCaregiver': !exists(json, 'associatedCaregiver') ? undefined : json['associatedCaregiver'],
        'birthplace': !exists(json, 'birthplace') ? undefined : json['birthplace'],
        'birthname': !exists(json, 'birthname') ? undefined : json['birthname'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'residentSince': !exists(json, 'residentSince') ? undefined : (new Date(json['residentSince'])),
        'lastChangeDate': !exists(json, 'lastChangeDate') ? undefined : (new Date(json['lastChangeDate'])),
        'lastChangeDateDetails': !exists(json, 'lastChangeDateDetails') ? undefined : ((json['lastChangeDateDetails'] as Array<any>).map(ChangeDateDetailFromJSON)),
    };
}

export function ResidentToJSON(value?: Resident | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'residentId': value.residentId,
        'tenantIdentifier': value.tenantIdentifier,
        'residenceIdentifier': value.residenceIdentifier,
        'unitIdentifier': value.unitIdentifier,
        'techValidFrom': value.techValidFrom === undefined ? undefined : (value.techValidFrom.toISOString()),
        'techValidTo': value.techValidTo === undefined ? undefined : (value.techValidTo.toISOString()),
        'firstName': value.firstName,
        'surname': value.surname,
        'gender': value.gender,
        'room': value.room,
        'associatedCaregiver': value.associatedCaregiver,
        'birthplace': value.birthplace,
        'birthname': value.birthname,
        'nickname': value.nickname,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substr(0,10)),
        'residentSince': value.residentSince === undefined ? undefined : (value.residentSince.toISOString().substr(0,10)),
        'lastChangeDate': value.lastChangeDate === undefined ? undefined : (value.lastChangeDate.toISOString().substr(0,10)),
        'lastChangeDateDetails': value.lastChangeDateDetails === undefined ? undefined : ((value.lastChangeDateDetails as Array<any>).map(ChangeDateDetailToJSON)),
    };
}


