import React, { useEffect, useState } from 'react';
import {
	AssessedProfile,
	AssessedQuestion,
	Resident,
	ResidentAssessmentQuestionUpdateParameters,
} from '../generated';
import {
	Button,
	Grid,
	IconButton,
	Slider,
	Table,
	TableBody,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { getNetworkApi } from '../components/keycloak';
import { trackPromise } from 'react-promise-tracker';
import { AddComment, Close, Edit, Info, Save } from '@mui/icons-material';
import QuestionCommentForm from '../components/QuestionCommentForm';
import { TableCellStyled } from '../theme';
import QuestionGuidanceForm from '../components/QuestionGuidanceForm';
import { ErrorAlert, SuccessAlert } from '../components/Alerts';
import {
	getMarksForSlider,
	isLandscape,
	ResidentIdData,
} from '../components/format';

function EditMotivationAssessment(props) {
	const residentIdData: ResidentIdData = {
		tenantIdentifier: props.match.params.tenantId,
		residenceIdentifier: props.match.params.residenceId,
		unitIdentifier: props.match.params.unitId,
		residentId: Number(props.match.params.residentId),
	};

	const residentAssessmentData = {
		residentAssessmentId: Number(props.match.params.residentAssessmentId),
		assessmentProfileId: Number(props.match.params.assessmentProfileId),
		name: props.match.params.assessmentProfileName,
	};

	const [assessedQuestionList, setAssessedQuestionList] = useState(
		[] as AssessedQuestion[]
	);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [refresh, setRefresh] = useState(0);
	const [currentQuestionId, setCurrentQuestionId] = useState(null as number);
	const [isEditQuestion, setIsEditQuestion] = useState(false);
	const [questionUpdateParameters, setQuestionUpdateParameters] = useState(
		{} as ResidentAssessmentQuestionUpdateParameters
	);
	const [currentAssessedValue, setCurrentAssessedValue] = useState(0 as number);
	const [isEditResidentAssessment] = useState(true);

	const [showQuestionCommentForm, setShowQuestionCommentForm] = useState(false);
	const [showQuestionGuidanceForm, setShowQuestionGuidanceForm] =
		useState(false);

	useEffect(() => {
		const loadAssessedQuestions = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getAssessedQuestionsForResidentAssessment(
					residentIdData.tenantIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residenceIdentifier,
					residentAssessmentData.assessmentProfileId,
					residentAssessmentData.residentAssessmentId
				);
				setAssessedQuestionList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'bearbeiteter Fragebogen konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText(
						'bearbeiteter Fragebogen konnten nicht abgerufen werden'
					);
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadAssessedQuestions());
	}, [
		residentIdData.residentId,
		residentAssessmentData.residentAssessmentId,
		showSuccessAlert,
		refresh,
	]);

	const updateAssessedQuestion = async () => {
		const client = getNetworkApi();
		try {
			await client.updateResidentAssessmentQuestion(
				residentIdData.tenantIdentifier as string,
				residentIdData.unitIdentifier as string,
				residentIdData.residenceIdentifier as string,
				residentAssessmentData.residentAssessmentId as number,
				currentQuestionId as number,
				questionUpdateParameters
			);
			setAlertText('Frage wurde erfolgreich aktualisiert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Frage wurde nicht aktualisiert');
			setShowErrorAlert(true);
		}
	};

	const handleEditAssessedQuestion = (inputQuestionId: number) => {
		if (!isEditQuestion) {
			setIsEditQuestion(true);
			setCurrentQuestionId(inputQuestionId);

			let currentQuestionUpdateParameters: ResidentAssessmentQuestionUpdateParameters =
				questionUpdateParameters;
			currentQuestionUpdateParameters.comment = assessedQuestionList.find(
				(x) => x.questionId === inputQuestionId
			)?.comment;
			currentQuestionUpdateParameters.assessedValue = assessedQuestionList.find(
				(x) => x.questionId === inputQuestionId
			).assessedValue;
			setQuestionUpdateParameters(currentQuestionUpdateParameters);
		} else if (isEditQuestion && inputQuestionId === currentQuestionId) {
			setCurrentQuestionId(inputQuestionId);
			updateAssessedQuestion();
			setIsEditQuestion(false);
			setQuestionUpdateParameters({});
		}
	};

	const handleClose = (inputQuestionId: number) => {
		setCurrentQuestionId(inputQuestionId);
		setIsEditQuestion(false);
		setRefresh(refresh + 1);

		assessedQuestionList.find(
			(x) => x.questionId === inputQuestionId
		).assessedValue = currentAssessedValue;
	};

	const handleSliderChange = (e, inputQuestionId: number) => {
		const { value } = e.target;
		let currentQuestionUpdateParameters: ResidentAssessmentQuestionUpdateParameters =
			questionUpdateParameters;
		currentQuestionUpdateParameters.assessedValue = value;
		setQuestionUpdateParameters(currentQuestionUpdateParameters);
	};

	const handleEditQuestionComment = (inputQuestionId: number) => {
		setCurrentQuestionId(inputQuestionId);
		setShowQuestionCommentForm(true);
		setCurrentAssessedValue(
			assessedQuestionList.find((x) => x.questionId === inputQuestionId)
				.assessedValue
		);
	};

	const handleDisplayQuestionGuidance = (questionId: number) => {
		setCurrentQuestionId(questionId);
		setShowQuestionGuidanceForm(true);
	};

	const isEmptyComment = (question: AssessedQuestion) => {
		return (
			typeof question?.comment === 'undefined' ||
			question?.comment === (void 0 || '')
		);
	};

	return (
		<React.Fragment>
			<Grid container direction='column' spacing={2} sx={{ padding: 7 }}>
				<Grid item xs={12}>
					<Typography variant='h6' color='primary'>
						{residentAssessmentData.name} bearbeiten:
					</Typography>
					Per Klick auf den Stift ('Bearbeiten'-Button) eine Frage aktivieren,
					die Antwort sowie den Kommentar anpassen, und abschließend auf die
					Diskette ('Speichern'-Button) klicken.
				</Grid>
				<TableContainer sx={{ maxHeight: '90%', pt: 3 }}>
					<Table sx={{ fontSize: 40 }}>
						<TableBody>
							{assessedQuestionList.map((el, index) => {
								return (
									<TableRow key={el.questionId}>
										<TableCellStyled>
											{isEditQuestion && el.questionId === currentQuestionId ? (
												<React.Fragment>
													<Tooltip title={'Speichern'}>
														<IconButton
															size='medium'
															onClick={() =>
																handleEditAssessedQuestion(el.questionId)
															}>
															{' '}
															<Save />
														</IconButton>
													</Tooltip>
													<Tooltip title={'Abbrechen'}>
														<IconButton
															onClick={() => handleClose(el.questionId)}>
															{<Close />}
														</IconButton>
													</Tooltip>
												</React.Fragment>
											) : (
												<Tooltip title={'Frage bearbeiten'}>
													<IconButton
														size='medium'
														onClick={() =>
															handleEditAssessedQuestion(el.questionId)
														}>
														<Edit />
													</IconButton>
												</Tooltip>
											)}
										</TableCellStyled>
										<TableCellStyled
											style={{
												color:
													isEditQuestion && el.questionId === currentQuestionId
														? 'red'
														: '#696969',
											}}>
											{el.question}
										</TableCellStyled>
										<TableCellStyled width={5}></TableCellStyled>
										<TableCellStyled width={200}>
											{
												<Slider
													aria-label='answer-slider'
													key={`slider-${el.assessedValue}`}
													defaultValue={el.assessedValue}
													step={1}
													disabled={
														!(
															isEditQuestion &&
															el.questionId === currentQuestionId
														)
													}
													name='assessedValue'
													marks={getMarksForSlider(
														el.scaleMin,
														el.scaleMax,
														el.guidanceMin,
														el.guidanceMax
													)}
													min={el.scaleMin}
													max={el.scaleMax}
													valueLabelDisplay='auto'
													onChange={(e) => handleSliderChange(e, el.questionId)}
													style={{
														color:
															isEditQuestion &&
															el.questionId === currentQuestionId
																? 'red'
																: '#696969',
													}}
												/>
											}
										</TableCellStyled>
										{isLandscape() && (
											<TableCellStyled width={30}></TableCellStyled>
										)}
										<TableCellStyled>
											{isEditQuestion && el.questionId === currentQuestionId ? (
												<Tooltip
													title={
														isEmptyComment(el)
															? 'Kommentar hinzufügen'
															: 'Kommentar bearbeiten'
													}>
													<IconButton
														size='medium'
														onClick={() =>
															handleEditQuestionComment(el.questionId)
														}>
														<AddComment
															style={{
																color: isEmptyComment(el) ? '#e0e0e0' : 'green',
															}}
														/>
													</IconButton>
												</Tooltip>
											) : (
												<IconButton size='medium' disabled={true}>
													<AddComment
														style={{
															color: isEmptyComment(el) ? '#e0e0e0' : 'green',
														}}
													/>
												</IconButton>
											)}
											{el.guidance !== void 0 && (
												<Tooltip title='Guidance anzeigen'>
													<Button
														onClick={() =>
															handleDisplayQuestionGuidance(el.questionId)
														}>
														{<Info />}
													</Button>
												</Tooltip>
											)}
										</TableCellStyled>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			{showQuestionCommentForm && (
				<QuestionCommentForm
					open={showQuestionCommentForm}
					setOpen={setShowQuestionCommentForm}
					currentQuestionId={currentQuestionId}
					setReturnValuesList={setQuestionUpdateParameters}
					currentComment={questionUpdateParameters.comment}
					currentReturnValuesList={questionUpdateParameters}
					isEditResidentAssessment={isEditResidentAssessment}
				/>
			)}
			{showQuestionGuidanceForm && (
				<QuestionGuidanceForm
					open={showQuestionGuidanceForm}
					setOpen={setShowQuestionGuidanceForm}
					currentGuidance={
						assessedQuestionList.find((x) => x.questionId === currentQuestionId)
							.guidance
					}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}

export default EditMotivationAssessment;
