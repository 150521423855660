/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssessedQuestion,
    AssessedQuestionFromJSON,
    AssessedQuestionFromJSONTyped,
    AssessedQuestionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResidentAssessmentInsertParameters
 */
export interface ResidentAssessmentInsertParameters {
    /**
     * 
     * @type {string}
     * @memberof ResidentAssessmentInsertParameters
     */
    assessingUser?: string;
    /**
     * 
     * @type {number}
     * @memberof ResidentAssessmentInsertParameters
     */
    assessmentProfileId?: number;
    /**
     * 
     * @type {Array<AssessedQuestion>}
     * @memberof ResidentAssessmentInsertParameters
     */
    assessedQuestions?: Array<AssessedQuestion>;
}

export function ResidentAssessmentInsertParametersFromJSON(json: any): ResidentAssessmentInsertParameters {
    return ResidentAssessmentInsertParametersFromJSONTyped(json, false);
}

export function ResidentAssessmentInsertParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidentAssessmentInsertParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assessingUser': !exists(json, 'assessingUser') ? undefined : json['assessingUser'],
        'assessmentProfileId': !exists(json, 'assessmentProfileId') ? undefined : json['assessmentProfileId'],
        'assessedQuestions': !exists(json, 'assessedQuestions') ? undefined : ((json['assessedQuestions'] as Array<any>).map(AssessedQuestionFromJSON)),
    };
}

export function ResidentAssessmentInsertParametersToJSON(value?: ResidentAssessmentInsertParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assessingUser': value.assessingUser,
        'assessmentProfileId': value.assessmentProfileId,
        'assessedQuestions': value.assessedQuestions === undefined ? undefined : ((value.assessedQuestions as Array<any>).map(AssessedQuestionToJSON)),
    };
}


