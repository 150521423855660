import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	TextField,
	Grid,
	ThemeProvider,
	FormControl,
	InputLabel,
	Select,
	useMediaQuery,
	useTheme,
	MenuItem,
	Tooltip,
	FormGroup,
	FormLabel,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio
} from '@mui/material';
import { Close, Save } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Tag } from '../generated/models/Tag';
import { getNetworkApi } from './keycloak';
import { trackPromise } from 'react-promise-tracker';
import {
	favoriteFoodTypeItems,
	isEmptyField,
	makeMenuItemForValue,
	isTagTicked
} from './format';
import {
	FavoriteFood,
	FavoriteFoodInsertParameters,
	FavoriteFoodType,
	FavoriteFoodUpdateParameters,
} from '../generated';

export default function FavoriteFoodForm({
	open,
	setOpen,
	IsNewFavoriteFood,
	DisplayFavoriteFood,
	currentFavoriteFood,
	ResidenceDetails,
	setAlertText,
	SetShowSuccessAlert,
	SetShowErrorAlert,
}) {
	const [favoriteFoodValues, setFavoriteFoodValues] = React.useState(
		currentFavoriteFood as FavoriteFood
	);
	const [Tags, setTags] = useState([] as Tag[]);
	const [TagValues, setTagValues] = useState(favoriteFoodValues.tags as Tag[]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleRadioChange = (event) => {
	setFavoriteFoodValues((prevFavoriteFoodValues) => ({
		...prevFavoriteFoodValues,
		relevant: event.target.value === "true"
	}));
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFavoriteFoodValues({
			...favoriteFoodValues,
			[name]: value,
		});
	};

	const getTagValue = (e) => {
		if (Object.entries(TagValues).length !== 0) {
			if (e.target.checked) {
				setTagValues((prevTags) => [
					...prevTags,
					{ tagId: Number(e.target.value) },
				]);
			} else {
				setTagValues(
					TagValues.filter((item) => item.tagId !== Number(e.target.value))
				);
			}
		} else {
			setTagValues([{ tagId: Number(e.target.value) }]);
		}
	};



	const saveNewFavoriteFood = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.postResidentFavoriteFood(
				ResidenceDetails.tenantIdentifier,
				ResidenceDetails.residenceIdentifier,
				ResidenceDetails.unitIdentifier,
				currentFavoriteFood.residentId,
				{
					name: favoriteFoodValues.name,
					description: favoriteFoodValues.description,
					favoriteFoodType: favoriteFoodValues.favoriteFoodType,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: favoriteFoodValues.relevant
				} as FavoriteFoodInsertParameters
			);
			setAlertText('Lieblingsessen wurde erfolgreich gespeichert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Lieblingsessen wurde nicht gespeichert');
			SetShowErrorAlert(true);
		}
	};

	const updateFavoriteFood = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.putResidentFavoriteFood(
				ResidenceDetails.tenantIdentifier,
				ResidenceDetails.residenceIdentifier,
				ResidenceDetails.unitIdentifier,
				currentFavoriteFood.favoriteFoodId,
				{
					name: favoriteFoodValues.name,
					description: favoriteFoodValues.description,
					favoriteFoodType: favoriteFoodValues.favoriteFoodType,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: favoriteFoodValues.relevant
				} as FavoriteFoodUpdateParameters
			);
			setAlertText('Lieblingsessen wurde erfolgreich geändert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Lieblingsessen wurde nicht geändert');
			SetShowErrorAlert(true);
		}
	};

	const isSaveButtonEnabled = (favoriteFood: FavoriteFood) => {
		return (
			!isEmptyField(favoriteFood?.name) &&
			!isEmptyField(favoriteFood?.favoriteFoodType)
		);
	};

		
	useEffect(() => {
		const loadTags = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getTags(ResidenceDetails.tenantIdentifier);
				console.log(result)
				setTags(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the available Tags data!');
					}
				} else {
					console.log('There was an error fetching the available Tags data!');
				}
			}
		};
		trackPromise(loadTags());
	}, [
		ResidenceDetails.tenantIdentifier,
		ResidenceDetails.unitIdentifier,
		ResidenceDetails.tenantIndentifier
	]);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					maxWidth='md'>
					<DialogTitle>
						{DisplayFavoriteFood
							? 'Details'
							: [
									IsNewFavoriteFood
										? 'Neues Lieblingsessen anlegen'
										: 'Lieblingsessen bearbeiten',
							  ]}
					</DialogTitle>
					<div className='new-line' />
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										fullWidth
										label='Lieblingsessen'
										name='name'
										id='outlined-size-normal'
										value={favoriteFoodValues.name}
										onChange={handleInputChange}
										required
										disabled={DisplayFavoriteFood ? true : false}
										inputProps={{
											maxLength: 100,
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControl variant='outlined' sx={{ width: '100%' }}>
										<InputLabel id='demo-simple-select-helper-label' required>
											Art des Lieblingsessens
										</InputLabel>
										{Object.entries(favoriteFoodTypeItems).length !== 0 && (
											<Select
												required
												variant='outlined'
												label='Art des Lieblingsessens'
												name='favoriteFoodType'
												disabled={DisplayFavoriteFood ? true : false}
												onChange={handleInputChange}
												defaultValue={favoriteFoodValues.favoriteFoodType}>
												<MenuItem
													value={undefined as FavoriteFoodType}></MenuItem>
												{favoriteFoodTypeItems.map(makeMenuItemForValue)}
											</Select>
										)}
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										multiline
										rows={3.4}
										label='Beschreibung'
										name='description'
										id='outlined-size-normal'
										value={favoriteFoodValues.description}
										onChange={handleInputChange}
										disabled={DisplayFavoriteFood ? true : false}
										inputProps={{
											maxLength: 500,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
								<FormControl>
									<Tooltip
										title='Tags bzgl. der Strukturierten Informationssammlung'
										placement='top'>
										<FormLabel>SIS Tag</FormLabel>
									</Tooltip>
									<FormGroup>
										<Grid container columnSpacing={2} rowSpacing={0}>
											{Tags.filter(
												(el) => el.tagGroupIdentifier === 'SIS'
											).map((item, index) => (
												<Grid item xs={4} key={index}>
													<FormControlLabel
														key={item.tagId}
														value={item.tagId}
														checked={isTagTicked(TagValues, item.tagId)}
														control={<Checkbox />}
														label={item.tagAbbreviation}
														onChange={(e) => getTagValue(e)}
														disabled={DisplayFavoriteFood}
													/>
												</Grid>
											))}
										</Grid>
									</FormGroup>
								</FormControl>
								</Grid>
								<Grid item xs={12}>
								<FormControl>
									<Tooltip title="Pflegerelevant?" placement="top">
									<FormLabel>Pflegerelevant?</FormLabel>
									</Tooltip>
									<FormControl>
											<RadioGroup
												row
												aria-labelledby="demo-controlled-radio-buttons-group"
												name="controlled-radio-buttons-group"
												value={favoriteFoodValues.relevant}
												onChange={handleRadioChange}
											>
										<FormControlLabel value={true} disabled={DisplayFavoriteFood} control={<Radio />} label="Ja" />
										<FormControlLabel value={false} disabled={DisplayFavoriteFood} control={<Radio />} label="Nein" />
									</RadioGroup>
									</FormControl>
								</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction='row'
										justifyContent='flex-end'
										alignItems='center'
										spacing={2}>
										<Grid item>
											<Button variant='contained' onClick={handleClose}>
												{<Close />}{' '}
												{DisplayFavoriteFood ? 'Schließen' : 'Abbrechen'}
											</Button>
										</Grid>
										{!DisplayFavoriteFood && (
											<Grid item>
												<Button
													variant='contained'
													disabled={!isSaveButtonEnabled(favoriteFoodValues)}
													onClick={IsNewFavoriteFood ? saveNewFavoriteFood : updateFavoriteFood
													}>
													{<Save />} Speichern
												</Button>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
