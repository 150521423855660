import React, { useEffect, useState } from 'react';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	ThemeProvider,
	Button,
	Card,
	Tooltip,
	IconButton,
} from '@mui/material';
import { ChevronLeft, ChevronRight, Close, Delete } from '@mui/icons-material';

import theme from '../theme';
import DisplayContent from './DisplayContent';
import { Residence, ResidenceGroupMedia } from '../generated';
import { trackPromise } from 'react-promise-tracker';
import { getNetworkApi } from './keycloak';
import Confirm from './Confirm';
import { ErrorAlert, SuccessAlert } from './Alerts';

interface GroupDisplayMediaAlbumFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	currentResidence: Residence;
	isAuthorized: boolean;
	title: String;
	residenceMediaIdList: ResidenceGroupMedia[];
}

export default function GroupDisplayMediaAlbumForm(
	prop: GroupDisplayMediaAlbumFormProp
) {
	const {
		open,
		setOpen,
		currentResidence,
		isAuthorized,
		title,
		residenceMediaIdList,
	} = prop;

	const [mediaIdListIndex, setMediaIdListIndex] = useState(0 as number);
	const [selectedMediaFile, setSelectedMediaFile] = useState(new Blob());
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showConfirmMediaFileDeletion, setShowConfirmMediaFileDeletion] =
		useState(false);
	const [deleteText, setDeleteText] = useState('');
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [mediaIdForDeletion, setMediaIdForDeletion] = useState(0 as number);
	const [deletionCounter, increaseDeletionCounter] = useState(0 as number);

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickRightArrow = () => {
		let i = mediaIdListIndex;
		setMediaIdListIndex((i + 1) % residenceMediaIdList.length);
	};

	const handleClickLeftArrow = () => {
		let i = mediaIdListIndex;

		if (i === 0) {
			setMediaIdListIndex(residenceMediaIdList.length - 1);
		} else {
			setMediaIdListIndex(i - 1);
		}
	};

	const deleteMediaFile = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateResidenceMedia(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				mediaIdForDeletion
			);
			setShowSuccessAlert(true);
			setShowConfirmMediaFileDeletion(false);
			setAlertText('Mediendatei wurde erfolgreich archiviert');
			setMediaIdListIndex((mediaIdListIndex + 1) % residenceMediaIdList.length);
			if (residenceMediaIdList.length - deletionCounter < 2) {
				setOpen(false);
			}
			increaseDeletionCounter(deletionCounter + 1);
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Mediendatei konnte nicht archiviert werden');
			console.log(
				'There was an error while deactivating: ' + mediaIdForDeletion
			);
		}
	};
	const handleDeleteMediaFile = (residenceMediaId: number) => {
		setDeleteText('Wollen Sie die Mediendatei wirklich archivieren?');
		setMediaIdForDeletion(residenceMediaId);
		setShowConfirmMediaFileDeletion(true);
	};

	useEffect(() => {
		const loadSingleMediaFile = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidenceMedia(
					currentResidence.tenantIdentifier,
					currentResidence.residenceIdentifier,
					residenceMediaIdList[mediaIdListIndex]?.residenceMediaId
				);
				setSelectedMediaFile(result);
			} catch (error) {
				return null;
			}
		};
		trackPromise(loadSingleMediaFile());
	}, [mediaIdListIndex]);

	const displayContent = () => {
		if (residenceMediaIdList.length === 0) {
			return null;
		} else {
			return (
				<DisplayContent
					mediaFile={selectedMediaFile}
					mediaType={
						residenceMediaIdList[mediaIdListIndex]?.residenceMediaTypeIdentifier
					}
					title={residenceMediaIdList[mediaIdListIndex]?.fileName}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
				/>
			);
		}
	};

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					open={open}
					onClose={handleClose}
					maxWidth='lg'
					sx={{ maxHeight: '100%' }}>
					<Grid item xs={12} marginRight={2} marginTop={2}>
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='center'
							spacing={2}>
							<Grid item>
								<Button variant='contained' onClick={handleClose}>
									{<Close />} Schließen
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={12} style={{ textAlign: 'center' }}>
									<Tooltip title={'vorheriges Medium'} placement='top'>
										<span>
											<Button
												disabled={residenceMediaIdList.length < 2}
												onClick={() => handleClickLeftArrow()}>
												<ChevronLeft />
											</Button>
										</span>
									</Tooltip>

									<Tooltip title={'nächstes Medium'} placement='top'>
										<span>
											<Button
												disabled={residenceMediaIdList.length < 2}
												onClick={() => handleClickRightArrow()}>
												<ChevronRight />
											</Button>
										</span>
									</Tooltip>

									<Card>
										{displayContent()}
										<Tooltip
											title={isAuthorized ? 'Archiviere Datei' : ''}
											placement='top'>
											<IconButton
												disabled={!isAuthorized}
												edge='end'
												sx={{
													alignItems: 'right',
												}}
												onClick={() =>
													handleDeleteMediaFile(
														residenceMediaIdList[mediaIdListIndex]
															?.residenceMediaId
													)
												}>
												<Delete
													sx={{ fontSize: '35px' }}
													color={isAuthorized ? 'primary' : 'secondary'}
												/>
											</IconButton>
										</Tooltip>
									</Card>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
			{showConfirmMediaFileDeletion && (
				<Confirm
					open={showConfirmMediaFileDeletion}
					setOpen={setShowConfirmMediaFileDeletion}
					text={deleteText}
					executefct={deleteMediaFile}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
