import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
	Grid,
	Paper,
	Tooltip,
	Box,
	Tabs,
	Tab,
	Card,
	CardActions,
	CardMedia,
	CssBaseline,
	Divider,
	IconButton,
	TextField,
	InputAdornment,
	Button,
} from '@mui/material';
import {
	Extension,
	Theaters,
	ThumbUpOffAlt,
	Edit,
	People,
	Work,
	Sailing,
	RssFeed,
	DinnerDining,
	SelfImprovement,
	Info,
	EmojiPeople,
	AddCircle,
	LocalActivity,
	Groups,
} from '@mui/icons-material';
import { getNetworkApi } from '../components/keycloak';
import { trackPromise } from 'react-promise-tracker';
import {
	Relative,
	Hobby,
	FavoriteFood,
	FavoriteFoodType,
	Job,
	Link as LinkObj,
	Note,
	AssessedProfile,
	AssessmentTypeEnum,
	Lifestyle,
	ResidentMediaData,
	ConnectType,
	FavoriteFoodMediaInsertParameters,
	FavoriteFoodMediaUpdateParameters,
	Link,
	Tag
} from '../generated';
import Confirm from '../components/Confirm';
import LinkForm from '../components/LinkForm';
import HobbyForm from '../components/HobbyForm';
import FavoriteFoodForm from '../components/FavoriteFoodForm';

import { ErrorAlert, SuccessAlert } from '../components/Alerts';
import { Resident, MediaCategory } from '../generated';
import {
	isAnniversary,
	dateField2,
	ResidentIdData,
	getGenderIcon,
	nameWithNickname,
	isAuthorizedForRoleTenant,
	GetNewIcon,
} from '../components/format';
import JobForm from '../components/JobForm';
import RelativeForm from '../components/RelativeForm';
import { StyledCake2 } from '../Styles';
import { BaseRole } from '../components/Roles';
import LifestyleForm from '../components/LifestyleForm';
import LifestylePanel from '../components/LifestylePanel';
import HobbyPanel from '../components/HobbyPanel';
import FavoriteFoodPanel from '../components/FavoriteFoodPanel';
import LinkPanel from '../components/LinkPanel';
import JobPanel from '../components/JobPanel';
import RelativePanel from '../components/RelativePanel';
import draftProfilePicture from '../data/DraftProfilePicture.png';
import ResidentForm from '../components/ResidentForm';
import ProfilePictureForm from '../components/ProfilePictureForm';
import NoteSummaryCard from '../components/NoteSummaryCard';
import AssessmentSummaryCard from '../components/AssessmentSummaryCard';
import MediaSummaryCard from '../components/MediaSummaryCard';
import GreetingSummaryCard from '../components/GreetingSummaryCard';
import { Greeting } from '../generated';
import ConnectionForm from '../components/ConnectionForm';
import MediaFileForm from '../components/MediaFileForm';
import NoteOrGreetingToMediaLinkForm from '../components/NoteOrGreetingToMediaLinkForm';
import DisplayMediaAlbumForm from '../components/DisplayMediaAlbumForm';

export declare type ResidentFormType = 'new' | 'edit' | 'display';

function ResidentProfile(props) {
	const residentIdData: ResidentIdData = useMemo(() => {
		return {
			tenantIdentifier: props.match.params.tenantId,
			residenceIdentifier: props.match.params.residenceId,
			unitIdentifier: props.match.params.unitId,
			residentId: Number(props.match.params.residentId),
		};
	}, [props]);

	const defaultMediaFileData: ResidentMediaData = {
		mediaCategories: [] as MediaCategory[],
		tags: {} as Tag[],

	};

	const [residentData, setResidentData] = React.useState({} as Resident);

	useEffect(() => {
		const loadResidentData = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResident(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId
				);
				setResidentData(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Bewohner:innen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Bewohner:innen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadResidentData());
	}, [
		residentIdData.residentId,
		residentIdData.tenantIdentifier,
		residentIdData.residenceIdentifier,
		residentIdData.unitIdentifier,
	]);

	const [userRoleList, setUserRoleList] = React.useState([]);

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const isAuthorized: boolean = useMemo(() => {
		return isAuthorizedForRoleTenant(
			userRoleList,
			BaseRole.BEWOHNERVERWALTUNG,
			residentIdData.tenantIdentifier,
			residentIdData.residenceIdentifier,
			residentIdData.unitIdentifier
		);
	}, [userRoleList, residentIdData]);

	const [linkList, setLinkList] = useState([] as LinkObj[]);
	const [isNewLink, setIsNewLink] = useState(false);
	const [isReadOnlyLink, setIsReadOnlyLink] = useState(false);
	const [showLinkForm, setShowLinkForm] = useState(false);
	const [currentLink, setCurrentLink] = useState({} as LinkObj);
	const [deleteText, setDeleteText] = useState('');
	const [alertText, setAlertText] = useState('');
	const [showConfirmLinkDeletion, setShowConfirmLinkDeletion] = useState(false);
	const [showConfirmJobDeletion, setShowConfirmJobDeletion] = useState(false);
	const [showConfirmRelativeDeletion, setShowConfirmRelativeDeletion] =
		useState(false);
	const [showConfirmHobbyDeletion, setShowConfirmHobbyDeletion] =
		useState(false);
	const [showConfirmFavoriteFoodDeletion, setShowConfirmFavoriteFoodDeletion] =
		useState(false);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [deleteLinkId, setDeleteLinkId] = useState({} as number);
	const defaultLink: LinkObj = {
		residentId: residentIdData.residentId,
		name: undefined as string,
		linkString: undefined as string,
		tags: {} as Tag[]
	};
	const [showConnectionLinkForm, setShowConnectionLinkForm] = useState(false);

	const [hobbiesList, setHobbiesList] = useState([] as Hobby[]);
	const [isNewHobby, setIsNewHobby] = useState(false);
	const [displayHobby, setDisplayHobby] = useState(false);
	const [showHobbyForm, setShowHobbyForm] = useState(false);
	const [currentHobby, setCurrentHobby] = useState({} as Hobby);
	const [deleteHobbyId, setDeleteHobbyId] = useState({} as number);
	const defaultHobby: Hobby = {
		residentId: residentIdData.residentId,
		name: undefined as string,
		description: undefined as string,
		tags: {} as Tag[]
	};
	const [showConnectionHobbiesForm, setShowConnectionHobbiesForm] =
		useState(false);

	const [favoriteFoodList, setFavoriteFoodList] = useState(
		[] as FavoriteFood[]
	);
	const [isNewFavoriteFood, setIsNewFavoriteFood] = useState(false);
	const [displayFavoriteFood, setDisplayFavoriteFood] = useState(false);
	const [showFavoriteFoodForm, setShowFavoriteFoodForm] = useState(false);
	const [currentFavoriteFood, setCurrentFavoriteFood] = useState(
		undefined as FavoriteFood
	);
	const [deleteFavoriteFoodId, setDeleteFavoriteFoodId] = useState(
		{} as number
	);
	const defaultFavoriteFood: FavoriteFood = {
		residentId: residentIdData.residentId,
		name: undefined as string,
		description: undefined as string,
		favoriteFoodType: undefined as FavoriteFoodType,
		tags: {} as Tag[]
	};
	const [showConnectionFavoriteFoodForm, setShowConnectionFavoriteFoodForm] =
		useState(false);
	const [
		showDisplayMediaAlbumFormFavoriteFood,
		setShowDisplayMediaAlbumFormFavoriteFood,
	] = useState(false as boolean);
	const [showMediaFileFormFavoriteFood, setShowMediaFileFormFavoriteFood] =
		useState(false);
	const [
		showAddMediaLinkFormFavoriteFood,
		setShowAddMediaLinkFormFavoriteFood,
	] = useState(false as boolean);
	const [mediaIdForLinkFavoriteFood, setMediaIdForLinkFavoriteFood] = useState(
		undefined as number
	);

	const [jobsList, setJobsList] = useState([] as Job[]);
	const [isNewJob, setIsNewJob] = useState(false);
	const [displayJob, setDisplayJob] = useState(false);
	const [showJobForm, setShowJobForm] = useState(false);
	const [currentJob, setCurrentJob] = useState({} as Job);
	const [deleteJobId, setDeleteJobId] = useState({} as number);
	const defaultJob: Job = {
		residentId: residentIdData.residentId,
		name: undefined as string,
		description: undefined as string,
		tags: {} as Tag[]
	};
	const [showConnectionJobForm, setShowConnectionJobForm] = useState(false);
	const [relativesList, setRelativesList] = useState([] as Relative[]);
	const [mostRecentNotesList, setMostRecentNotesList] = useState([] as Note[]);
	const [mostRecentMediaList, setMostRecentMediaList] = useState(
		[] as ResidentMediaData[]
	);
	const [mostRecentGreetingsList, setMostRecentGreetingsList] = useState(
		[] as Greeting[]
	);
	const [mostRecentMediaUrl, setMostRecentMediaUrl] = useState(null as string);
	const [isNewRelative, setIsNewRelative] = useState(false);
	const [displayRelative, setDisplayRelative] = useState(false);
	const [showRelativeForm, setShowRelativeForm] = useState(false);
	const [currentRelative, setCurrentRelative] = useState({} as Relative);
	const [deleteRelativeId, setDeleteRelativeId] = useState({} as number);
	const defaultRelative: Relative = {
		residentId: residentIdData.residentId,
		firstName: undefined as string,
		surname: undefined as string,
		relativeType: undefined as string,
		relativeRole: undefined as string,
		comment: undefined as string,
		tags: {} as Tag[]
	};
	const [showConnectionRelativeForm, setShowConnectionRelativeForm] =
		useState(false);

	const [lifestyleList, setLifestyleList] = useState([] as Lifestyle[]);
	const [isNewLifestyle, setIsNewLifestyle] = useState(false);
	const [displayLifestyle, setDisplayLifestyle] = useState(false);
	const [showLifestyleForm, setShowLifestyleForm] = useState(false);
	const [currentLifestyle, setCurrentLifestyle] = useState({} as Lifestyle);
	const [deleteLifestyleId, setDeleteLifestyleId] = useState({} as number);
	const defaultLifestyle: Lifestyle = {
		residentId: residentIdData.residentId,
		name: undefined as string,
		description: undefined as string,
		tags: {} as Tag[]
	};
	const [showConnectionLifestyleForm, setShowConnectionLifestyleForm] =
		useState(false);
	const [showConfirmLifestyleDeletion, setShowConfirmLifestyleDeletion] =
		useState(false);

	const [mostRecentAssessedProfile, setMostRecentAssessedProfile] = useState(
		undefined as AssessedProfile
	);
	const [profilePictureData, setProfilePictureData] = useState(
		{} as ResidentMediaData
	);

	const [pictureURL, setPictureURL] = useState(draftProfilePicture);

	const [residentFormType, setResidentFormType] = useState(
		'new' as ResidentFormType
	);

	const [showResidentForm, setShowResidentForm] = useState(false as boolean);

	const [showProfilePictureForm, setShowProfilePictureForm] = useState(
		false as boolean
	);
	const [profilePictureChangeTracker, setProfilePictureChangeTracker] =
		useState(0 as number);

	const Item = styled(Paper)(({ theme }) => ({
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.primary,
		height: '100%',
	}));

	useEffect(() => {
		const loadLinkList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getLinkList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId
				);
				setLinkList(
					result.sort((a: Link, b: Link) => a.name.localeCompare(b.name))
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the Link data!');
					}
				} else {
					console.log('There was an error fetching the Link data!');
				}
			}
		};
		trackPromise(loadLinkList());
	}, [showSuccessAlert, residentIdData]);

	const handleEditLink = (NewLink: boolean, SelectedLink: LinkObj) => {
		setIsNewLink(NewLink);
		setIsReadOnlyLink(false);
		setShowLinkForm(true);
		setCurrentLink(SelectedLink);
	};

	const handleReadOnlyLink = (SelectedLink: LinkObj) => {
		setIsReadOnlyLink(true);
		setShowLinkForm(true);
		setCurrentLink(SelectedLink);
	};

	const handleDeleteLink = (Linkid: number) => {
		setShowConfirmLinkDeletion(true);
		setDeleteText('Wollen Sie den Link wirklich archivieren?');
		setDeleteLinkId(Linkid);
	};

	const deleteLink = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateLink(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				deleteLinkId
			);
			setShowSuccessAlert(true);
			setShowConfirmLinkDeletion(false);
			setAlertText('Link wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Link konnte nicht archiviert werden');
			console.log('There was an error while deactivating!');
		}
	};
	const handleClickOpenConnectionFormLink = (SelectedLink: LinkObj) => {
		setShowConnectionLinkForm(true);
		setCurrentLink(SelectedLink);
	};

	useEffect(() => {
		const loadLifestyleList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getLifestyleList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId
				);
				setLifestyleList(
					result.sort((a: Lifestyle, b: Lifestyle) =>
						a.name.localeCompare(b.name)
					)
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the lifestyle data!');
					}
				} else {
					console.log('There was an error fetching the lifestyle data!');
				}
			}
		};
		trackPromise(loadLifestyleList());
	}, [showSuccessAlert, residentIdData]);

	const handleEditLifestyle = (
		isNewLifestyle: boolean,
		selectedLifestyle: Lifestyle
	) => {
		setIsNewLifestyle(isNewLifestyle);
		setDisplayLifestyle(false);
		setShowLifestyleForm(true);
		setCurrentLifestyle(selectedLifestyle);
	};

	const handleDisplayLifestyle = (selectedLifestyle: Lifestyle) => {
		setDisplayLifestyle(true);
		setShowLifestyleForm(true);
		setCurrentLifestyle(selectedLifestyle);
	};

	const handleDeleteLifestyle = (lifestyleId: number) => {
		setShowConfirmLifestyleDeletion(true);
		setDeleteText(
			'Wollen Sie die Lebensweise / den Glauben wirklich archivieren?'
		);
		setDeleteLifestyleId(lifestyleId);
	};

	const handleClickOpenConnectionFormLifesytle = (
		SelectedLifestyle: Lifestyle
	) => {
		setShowConnectionLifestyleForm(true);
		setCurrentLifestyle(SelectedLifestyle);
	};

	const deleteLifestyle = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateLifestyle(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				deleteLifestyleId
			);
			setShowSuccessAlert(true);
			setShowConfirmLifestyleDeletion(false);
			setAlertText('Lebensweise / Glaube wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Lebensweise / Glaube konnte nicht archiviert werden');
			console.log('There was an error while deactivating!');
		}
	};

	useEffect(() => {
		const loadHobbiesList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getHobbiesList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId
				);
				setHobbiesList(
					result.sort((a: Hobby, b: Hobby) => a.name.localeCompare(b.name))
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the Hobby data!');
					}
				} else {
					console.log('There was an error fetching the Hobby data!');
				}
			}
		};
		trackPromise(loadHobbiesList());
	}, [showSuccessAlert, residentIdData]);

	const handleEditHobby = (NewHobby: boolean, SelectedHobby: Hobby) => {
		setIsNewHobby(NewHobby);
		setDisplayHobby(false);
		setShowHobbyForm(true);
		setCurrentHobby(SelectedHobby);
	};

	const handleDisplayHobby = (SelectedHobby: Hobby) => {
		setDisplayHobby(true);
		setShowHobbyForm(true);
		setCurrentHobby(SelectedHobby);
	};

	const handleDeleteHobby = (HobbyId: number) => {
		setShowConfirmHobbyDeletion(true);
		setDeleteText('Wollen Sie das Hobby wirklich archivieren?');
		setDeleteHobbyId(HobbyId);
	};

	const deleteHobby = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateHobby(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				deleteHobbyId
			);
			setShowSuccessAlert(true);
			setShowConfirmHobbyDeletion(false);
			setAlertText('Hobby wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Hobby konnte nicht archiviert werden');
			console.log('There was an error while deactivating!');
		}
	};

	const handleClickOpenConnectionFormHobbies = (SelectedHobby: Hobby) => {
		setShowConnectionHobbiesForm(true);
		setCurrentHobby(SelectedHobby);
	};

	useEffect(() => {
		const loadFavoriteFoodList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidentFavoriteFoodList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId
				);
				setFavoriteFoodList(
					result.sort((a: FavoriteFood, b: FavoriteFood) =>
						a.name.localeCompare(b.name)
					)
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the favorite food data!');
					}
				} else {
					console.log('There was an error fetching the favorite food data!');
				}
			}
		};
		trackPromise(loadFavoriteFoodList());
	}, [showSuccessAlert, residentIdData]);

	const handleEditFavoriteFood = (
		newFavoriteFood: boolean,
		selectedFavoriteFood: FavoriteFood
	) => {
		setIsNewFavoriteFood(newFavoriteFood);
		setDisplayFavoriteFood(false);
		setShowFavoriteFoodForm(true);
		setCurrentFavoriteFood(selectedFavoriteFood);
	};

	const handleDisplayFavoriteFood = (selectedFavoriteFood: FavoriteFood) => {
		setDisplayFavoriteFood(true);
		setShowFavoriteFoodForm(true);
		setCurrentFavoriteFood(selectedFavoriteFood);
	};

	const handleDeleteFavoriteFood = (favoriteFoodId: number) => {
		setShowConfirmFavoriteFoodDeletion(true);
		setDeleteText('Wollen Sie das Lieblingsessen wirklich archivieren?');
		setDeleteFavoriteFoodId(favoriteFoodId);
	};

	const deleteFavoriteFood = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateResidentFavoriteFood(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				deleteFavoriteFoodId
			);
			setShowSuccessAlert(true);
			setShowConfirmFavoriteFoodDeletion(false);
			setAlertText('Lieblingsessen wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Lieblingseesen konnte nicht archiviert werden');
			console.log('There was an error while deactivating!');
		}
	};

	const handleClickOpenConnectionFormFavoriteFood = (
		selectedFavoriteFood: FavoriteFood
	) => {
		setShowConnectionFavoriteFoodForm(true);
		setCurrentFavoriteFood(selectedFavoriteFood);
	};

	const handleClickOpenMediaAlbumFormFavoriteFood = (
		selectedFavoriteFood: FavoriteFood
	) => {
		setCurrentFavoriteFood(selectedFavoriteFood);
		setShowDisplayMediaAlbumFormFavoriteFood(true);
	};

	const handleClickOpenMediaFileFormFavoriteFood = (
		selectedFavoriteFood: FavoriteFood
	) => {
		setCurrentFavoriteFood(selectedFavoriteFood);
		setShowMediaFileFormFavoriteFood(true);
	};

	const handleAddLinkToMediaFileFavoriteFood = (
		selectedFavoriteFood: FavoriteFood
	) => {
		setCurrentFavoriteFood(selectedFavoriteFood);
		setShowAddMediaLinkFormFavoriteFood(true);
	};

	useEffect(() => {
		if (mediaIdForLinkFavoriteFood !== undefined) {
			currentFavoriteFood?.media?.length > 0
				? updateMediaToFavoriteFoodLinks()
				: saveMediaToFavoriteFoodLink();
		}
	}, [mediaIdForLinkFavoriteFood, residentIdData]);

	const saveMediaToFavoriteFoodLink = async () => {
		let favoriteFoodMediaInsertParameters: FavoriteFoodMediaInsertParameters = {
			mediaIds: [mediaIdForLinkFavoriteFood],
		};
		const client = getNetworkApi();
		try {
			await client.postFavoriteFoodMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				currentFavoriteFood?.favoriteFoodId,
				favoriteFoodMediaInsertParameters
			);
			setAlertText('Verknüpfung zu Medien wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateMediaToFavoriteFoodLinks = async () => {
		let mediaIds: number[] = [];
		currentFavoriteFood.media.forEach((elem) => {
			mediaIds.push(elem.mediaId);
		});
		mediaIds.push(mediaIdForLinkFavoriteFood);
		let favoriteFoodMediaUpdateParameters: FavoriteFoodMediaUpdateParameters = {
			mediaIds: mediaIds,
		};
		const client = getNetworkApi();
		try {
			await client.putFavoriteFoodMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				currentFavoriteFood?.favoriteFoodId,
				favoriteFoodMediaUpdateParameters
			);
			setAlertText('Verknüpfung zu Medien wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	useEffect(() => {
		const loadJobsList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getJobList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId
				);
				setJobsList(
					result.sort((a: Job, b: Job) => a.name.localeCompare(b.name))
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the Job/ Education data!');
					}
				} else {
					console.log('There was an error fetching the Job/ Education data!');
				}
			}
		};
		trackPromise(loadJobsList());
	}, [showSuccessAlert, residentIdData]);

	const handleEditJob = (NewJob: boolean, SelectedJob: Job) => {
		setIsNewJob(NewJob);
		setDisplayJob(false);
		setShowJobForm(true);
		setCurrentJob(SelectedJob);
	};

	const handleDisplayJob = (SelectedJob: Job) => {
		setDisplayJob(true);
		setShowJobForm(true);
		setCurrentJob(SelectedJob);
	};

	const handleDeleteJob = (JobId: number) => {
		setShowConfirmJobDeletion(true);
		setDeleteText(
			'Wollen Sie den Beruf / Bildungsabschnitt wirklich archivieren?'
		);
		setDeleteJobId(JobId);
	};

	const handleClickOpenConnectionFormJob = (SelectedJob: Job) => {
		setShowConnectionJobForm(true);
		setCurrentJob(SelectedJob);
	};

	const deleteJob = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateJob(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				deleteJobId
			);
			setShowSuccessAlert(true);
			setShowConfirmJobDeletion(false);
			setAlertText('Beruf / Bildungsabschnitt wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Beruf / Bildungsabschnitt konnte nicht archiviert werden');
			console.log('There was an error while deactivating!');
		}
	};

	useEffect(() => {
		const loadRelativesList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getRelativesList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId
				);
				setRelativesList(
					result.sort((a: Relative, b: Relative) =>
						a.surname.localeCompare(b.surname)
					)
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the Relative data!');
					}
				} else {
					console.log('There was an error fetching the Relative data!');
				}
			}
		};
		trackPromise(loadRelativesList());
	}, [showSuccessAlert, residentIdData]);

	const handleEditRelative = (
		NewRelative: boolean,
		SelectedRelative: Relative
	) => {
		setIsNewRelative(NewRelative);
		setDisplayRelative(false);
		setShowRelativeForm(true);
		setCurrentRelative(SelectedRelative);
	};

	const handleDisplayRelative = (SelectedRelative: Relative) => {
		setDisplayRelative(true);
		setShowRelativeForm(true);
		setCurrentRelative(SelectedRelative);
	};

	const handleDeleteRelative = (RelativeId: number) => {
		setShowConfirmRelativeDeletion(true);
		setDeleteText('Wollen Sie die Bezugsperson wirklich archivieren?');
		setDeleteRelativeId(RelativeId);
	};

	const handleClickOpenConnectionFormRelative = (
		SelectedRelative: Relative
	) => {
		setShowConnectionRelativeForm(true);
		setCurrentRelative(SelectedRelative);
	};

	const deleteRelative = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateRelative(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				deleteRelativeId
			);
			setShowSuccessAlert(true);
			setShowConfirmRelativeDeletion(false);
			setAlertText('Bezugsperson wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Bezugsperson konnte nicht archiviert werden');
			console.log('There was an error while deactivating!');
		}
	};

	useEffect(() => {
		const loadMostRecentNotes = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getNotesList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId,
					4
				);
				setMostRecentNotesList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the most recent note!');
					}
				} else {
					console.log('There was an error fetching the most recent note!');
				}
			}
		};
		trackPromise(loadMostRecentNotes());
	}, [residentIdData]);

	useEffect(() => {
		const loadMostRecentGreetings = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidentGreetingList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId,
					4
				);
				setMostRecentGreetingsList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log(
							'There was an error fetching the most recent greeting data!'
						);
					}
				} else {
					console.log(
						'There was an error fetching the most recent greeting data!'
					);
				}
			}
		};
		trackPromise(loadMostRecentGreetings());
	}, [residentIdData]);

	useEffect(() => {
		const loadMostRecentMedia = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidentMediaList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId,
					false,
					3
				);
				setMostRecentMediaList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log(
							'There was an error fetching the most recent media data!'
						);
					}
				} else {
					console.log(
						'There was an error fetching the most recent media data!'
					);
				}
			}
		};
		trackPromise(loadMostRecentMedia());
	}, [showSuccessAlert, residentIdData]);

	useEffect(() => {
		const loadMostRecentMediaFile = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getMedia(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					mostRecentMediaList[0].mediaId
				);
				setMostRecentMediaUrl(URL.createObjectURL(result));
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Profilbild konnte nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadMostRecentMediaFile());
	}, [mostRecentMediaList, residentIdData]);

	useEffect(() => {
		const loadAssessedProfiles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getAssessedProfilesFromResidentId(
					residentIdData.tenantIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.residentId,
					AssessmentTypeEnum.Motivation
				);
				setMostRecentAssessedProfile(
					result.sort(
						(obj1, obj2) =>
							obj1.assessmentTimeStamp.getTime() -
							obj2.assessmentTimeStamp.getTime()
					)[result.length - 1]
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Fragebogen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Fragebogen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadAssessedProfiles());
	}, [showSuccessAlert, residentIdData]);

	// get profile picture
	useEffect(() => {
		const loadMediaFileData = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidentMediaList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId,
					true
				);
				setProfilePictureData(result.pop());
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Medien konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadMediaFileData());
	}, [profilePictureChangeTracker, residentIdData]);

	useEffect(() => {
		const loadProfilePictureFile = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getMedia(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					profilePictureData.mediaId
				);
				setPictureURL(URL.createObjectURL(result));
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Profilbild konnte nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadProfilePictureFile());
	}, [profilePictureData, residentIdData]);

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleClickOpenResidentForm = (residentFormType: ResidentFormType) => {
		setResidentFormType(residentFormType);
		setShowResidentForm(true);
	};

	const handleClickOpenProfilePictureForm = () => {
		setShowProfilePictureForm(true);
	};

	let history = useHistory();

	return (
		<React.Fragment>
			<Grid
				container
				spacing={5}
				sx={{
					padding: 3,
					'@media (orientation: landscape)': { marginTop: -5 },
					'@media (orientation: portrait)': { marginTop: 0 },
				}}>
				<Grid item xs={12} md={6}>
					<Item sx={{ height: '100%', borderRadius: 5 }} elevation={3}>
						<Grid
							container
							item
							direction='row'
							xs={12}
							marginTop={0.5}
							marginBottom={3}>
							<Grid item xs={3.5} md={3.5} textAlign={'center'}>
								<Card
									sx={{
										maxWidth: '100%',
										maxHeight: '100%',
										borderRadius: '20px',
										marginTop: 1.5,
										marginLeft: 2.3,
									}}
									elevation={4}>
									<CardMedia
										component='img'
										alt='Profilepicture'
										src={pictureURL}
									/>
									<CardActions disableSpacing>
										<Tooltip
											title={isAuthorized ? 'Profilbild ändern' : ''}
											placement='right'>
											<IconButton
												disabled={!isAuthorized}
												size='small'
												edge='start'
												sx={{
													color: 'primary',
													alignItems: 'left',
												}}
												onClick={() => handleClickOpenProfilePictureForm()}>
												<AddCircle fontSize='small' color='primary' />
											</IconButton>
										</Tooltip>
										<div>
											<Tooltip
												title={
													isAuthorized ? 'Bewohnerstammdaten bearbeiten' : ''
												}>
												<IconButton
													disabled={!isAuthorized}
													size='small'
													onClick={() => handleClickOpenResidentForm('edit')}>
													<Edit
														fontSize='small'
														color={isAuthorized ? 'primary' : 'secondary'}
													/>
												</IconButton>
											</Tooltip>
											<Tooltip title='Bewohnerstammdaten anzeigen'>
												<IconButton
													size='small'
													onClick={() =>
														handleClickOpenResidentForm('display')
													}>
													<Info fontSize='small' color='primary' />
												</IconButton>
											</Tooltip>
										</div>
									</CardActions>
								</Card>
							</Grid>
							<Grid item xs={0.5} md={0.5}></Grid>

							<Grid item xs={7.5} sx={{ marginTop: '0%', marginLeft: '2%' }}>
								<Grid
									container
									spacing={2}
									columns={{ xs: 6, md: 12 }}
									style={{
										textAlign: 'right',
									}}>
									<Grid item xs={6} md={6}>
										<Button
											variant='contained'
											onClick={() => {
												history.push({
													pathname: '/',
													state: {
														residenceId: residentIdData.residenceIdentifier,
														residentId: residentIdData.residentId,
														tabValue: 1 as number,
														resident: residentData,
														residence: residentData.residenceIdentifier,
													},
												});
											}}>
											verlinkte Events {<LocalActivity />}
										</Button>
									</Grid>
									<Grid item xs={6} md={6}>
										<Button
											variant='contained'
											onClick={() => {
												history.push({
													pathname: '/',
													state: {
														residenceId: residentIdData.residenceIdentifier,
														residentId: residentIdData.residentId,
														tabValue: 2 as number,
														resident: residentData,
														residence: residentData.residenceIdentifier,
													},
												});
											}}>
											verlinkte Gruppen {<Groups />}
										</Button>
									</Grid>
								</Grid>
								<TextField
									fullWidth
									sx={{ marginTop: '11%' }}
									label='Name:'
									name='Name'
									value={nameWithNickname(residentData)}
									disabled={true}
									variant='standard'
									InputLabelProps={{ style: { fontSize: 18 } }}
									InputProps={{
										disableUnderline: true,
										style: { fontSize: 24 },
										startAdornment: (
											<InputAdornment position='start'>
												{getGenderIcon(residentData.gender)}
											</InputAdornment>
										),
									}}
								/>
								<Divider flexItem />
								<Grid container spacing={2} columns={{ xs: 6, md: 12 }}>
									<Grid item xs={6} md={6}>
										<TextField
											fullWidth
											label='Geburtsdatum:'
											name='Geburtsdatum'
											value={dateField2(residentData?.birthday)}
											disabled={true}
											variant='standard'
											InputLabelProps={{ style: { fontSize: 18 } }}
											InputProps={{
												disableUnderline: true,
												style: { fontSize: 18 },
												endAdornment: (
													<InputAdornment position='end'>
														{isAnniversary(residentData?.birthday) && (
															<StyledCake2 />
														)}
													</InputAdornment>
												),
											}}
										/>
									</Grid>
									<Grid item xs={6} md={4}>
										<TextField 
											fullWidth
											label={
												residentData.gender === 'female'
													? 'Bewohnerin seit:'
													: residentData.gender === 'male'
													? 'Bewohner seit:'
													: 'Bewohner:in seit:'
											}
											name='Einzugsdatum'
											value={dateField2(residentData?.residentSince)}
											disabled={true}
											variant='standard'
											InputLabelProps={{ style: { fontSize: 18 } }}
											InputProps={{
												disableUnderline: true,
												style: { fontSize: 18},
												endAdornment: (
												<InputAdornment position = 'end'>
													{GetNewIcon(residentData.residentSince, false)}
											  	</InputAdornment>
												),
										  	}}
										/>

									</Grid>
									
								</Grid>
							</Grid>
						</Grid>
						<Divider variant='middle' />
						<Box
							sx={{
								flexGrow: 1,
								display: 'flex',
							}}>
							<Tabs
								value={value}
								onChange={handleChange}
								variant='fullWidth'
								orientation='vertical'
								aria-label='resident-feature-tabs'
								sx={{
									borderRight: 1,
									borderColor: 'divider',
									borderTop: 0,
									borderTopStyle: 'groove',
									minWidth: '32%',
									textAlign: 'left',
								}}>
								<Tab
									sx={{
										justifyContent: 'left',
										textAlign: 'left',
										fontSize: 18,
									}}
									icon={<People />}
									iconPosition='start'
									label='Angehörige'
									{...a11yProps(0)}
								/>
								<Tab
									sx={{
										justifyContent: 'left',
										textAlign: 'left',
										fontSize: 18,
									}}
									icon={<Work />}
									label='Berufe/ Bildung'
									{...a11yProps(1)}
									iconPosition='start'
								/>
								<Tab
									sx={{
										justifyContent: 'left',
										textAlign: 'left',
										fontSize: 18,
									}}
									icon={<SelfImprovement />}
									label='Lebensweise/ Glaube'
									{...a11yProps(2)}
									iconPosition='start'
								/>
								<Tab
									sx={{
										justifyContent: 'left',
										textAlign: 'left',
										fontSize: 18,
									}}
									icon={<Sailing />}
									label='Hobbies'
									{...a11yProps(3)}
									iconPosition='start'
								/>
								<Tab
									sx={{
										justifyContent: 'left',
										textAlign: 'left',
										fontSize: 18,
									}}
									icon={<DinnerDining />}
									label='Lieblingsessen'
									{...a11yProps(4)}
									iconPosition='start'
								/>
								<Tab
									sx={{
										justifyContent: 'left',
										textAlign: 'left',
										fontSize: 18,
									}}
									icon={<RssFeed />}
									label='Links'
									{...a11yProps(4)}
									iconPosition='start'
								/>
							</Tabs>
							<RelativePanel
								value={value}
								index={0}
								list={relativesList}
								defaultValue={defaultRelative}
								hasEditPermission={isAuthorized}
								handleDisplay={handleDisplayRelative}
								handleEdit={handleEditRelative}
								handleDelete={handleDeleteRelative}
								handleConnect={handleClickOpenConnectionFormRelative}
							/>
							<JobPanel
								value={value}
								index={1}
								list={jobsList}
								defaultValue={defaultJob}
								hasEditPermission={isAuthorized}
								handleDisplay={handleDisplayJob}
								handleEdit={handleEditJob}
								handleDelete={handleDeleteJob}
								handleConnect={handleClickOpenConnectionFormJob}
							/>
							<LifestylePanel
								value={value}
								index={2}
								list={lifestyleList}
								defaultValue={defaultLifestyle}
								hasEditPermission={isAuthorized}
								handleDisplay={handleDisplayLifestyle}
								handleEdit={handleEditLifestyle}
								handleDelete={handleDeleteLifestyle}
								handleConnect={handleClickOpenConnectionFormLifesytle}
							/>
							<HobbyPanel
								value={value}
								index={3}
								list={hobbiesList}
								defaultValue={defaultHobby}
								hasEditPermission={isAuthorized}
								handleDisplay={handleDisplayHobby}
								handleEdit={handleEditHobby}
								handleDelete={handleDeleteHobby}
								handleConnect={handleClickOpenConnectionFormHobbies}
							/>
							<FavoriteFoodPanel
								value={value}
								index={4}
								list={favoriteFoodList}
								defaultValue={defaultFavoriteFood}
								hasEditPermission={isAuthorized}
								handleDisplay={handleDisplayFavoriteFood}
								handleEdit={handleEditFavoriteFood}
								handleDelete={handleDeleteFavoriteFood}
								handleConnect={handleClickOpenConnectionFormFavoriteFood}
								handleClickOpenMediaAlbumFormFavoriteFood={
									handleClickOpenMediaAlbumFormFavoriteFood
								}
								handleClickOpenMediaFileForm={
									handleClickOpenMediaFileFormFavoriteFood
								}
								handleAddLinkToMediaFileFavoriteFood={
									handleAddLinkToMediaFileFavoriteFood
								}
							/>
							<LinkPanel
								value={value}
								index={5}
								list={linkList}
								defaultValue={defaultLink}
								hasEditPermission={isAuthorized}
								handleEdit={handleEditLink}
								handleDelete={handleDeleteLink}
								handleConnect={handleClickOpenConnectionFormLink}
								handleReadOnly={handleReadOnlyLink}
							/>
						</Box>
					</Item>
				</Grid>
				<Grid
					container
					item
					xs={12}
					md={6}
					spacing={2}
					display={'100%'}
					direction='row'>
					<Grid item xs={12} sm={6}>
						<NoteSummaryCard
							title='Erinnerungen'
							icon={<Extension fontSize='medium' />}
							cardcolor='#009688'
							path={
								'/tenantId=' +
								residentIdData.tenantIdentifier +
								',residenceId=' +
								residentIdData.residenceIdentifier +
								',unitId=' +
								residentIdData.unitIdentifier +
								'/profile/' +
								'residentId=' +
								residentIdData.residentId +
								'/memories'
							}
							mostRecentNotesList={mostRecentNotesList}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<MediaSummaryCard
							title='Medien'
							icon={<Theaters fontSize='medium' />}
							cardcolor='#ff9800'
							path={
								'/tenantId=' +
								residentIdData.tenantIdentifier +
								',residenceId=' +
								residentIdData.residenceIdentifier +
								',unitId=' +
								residentIdData.unitIdentifier +
								'/profile/' +
								'residentId=' +
								residentIdData.residentId +
								'/media'
							}
							residentIdData={residentIdData}
							mostRecentMediaList={mostRecentMediaList}
							mostRecentMediaUrl={mostRecentMediaUrl}
						/>
					</Grid>
					<CssBaseline />
					<Grid item xs={12} sm={6}>
						<AssessmentSummaryCard
							title='Motivprofile'
							icon={<ThumbUpOffAlt fontSize='medium' />}
							cardcolor='#e53935'
							path={
								'/tenantId=' +
								residentIdData.tenantIdentifier +
								',residenceId=' +
								residentIdData.residenceIdentifier +
								',unitId=' +
								residentIdData.unitIdentifier +
								'/profile/' +
								'residentId=' +
								residentIdData.residentId +
								'/motivation'
							}
							mostRecentAssessedProfile={mostRecentAssessedProfile}
							residentIdData={residentIdData}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<GreetingSummaryCard
							title='Grüße'
							icon={<EmojiPeople fontSize='medium' />}
							cardcolor='#0277bd'
							path={
								'/tenantId=' +
								residentIdData.tenantIdentifier +
								',residenceId=' +
								residentIdData.residenceIdentifier +
								',unitId=' +
								residentIdData.unitIdentifier +
								'/profile/' +
								'residentId=' +
								residentIdData.residentId +
								'/greetings'
							}
							mostRecentGreetingsList={mostRecentGreetingsList}
						/>
					</Grid>
				</Grid>
			</Grid>
			{showResidentForm && (
				<ResidentForm
					open={showResidentForm}
					setOpen={setShowResidentForm}
					units={null}
					unitIndex={null}
					residentFormType={residentFormType}
					currentResident={residentData}
					setResidentData={setResidentData}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showProfilePictureForm && (
				<ProfilePictureForm
					open={showProfilePictureForm}
					setOpen={setShowProfilePictureForm}
					residentIdData={residentIdData}
					profilePictureChangeTracker={profilePictureChangeTracker}
					setProfilePictureChangeTracker={setProfilePictureChangeTracker}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showLinkForm && (
				<LinkForm
					open={showLinkForm}
					setOpen={setShowLinkForm}
					isReadOnly={isReadOnlyLink}
					IsNewLink={isNewLink}
					CurrentLink={currentLink}
					ResidenceDetails={residentData}
					setAlertText={setAlertText}
					SetShowSuccessAlert={setShowSuccessAlert}
					SetShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showJobForm && (
				<JobForm
					open={showJobForm}
					setOpen={setShowJobForm}
					IsNewJob={isNewJob}
					DisplayJob={displayJob}
					CurrentJob={currentJob}
					ResidenceDetails={residentData}
					setAlertText={setAlertText}
					SetShowSuccessAlert={setShowSuccessAlert}
					SetShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showHobbyForm && (
				<HobbyForm
					open={showHobbyForm}
					setOpen={setShowHobbyForm}
					IsNewHobby={isNewHobby}
					DisplayHobby={displayHobby}
					CurrentHobby={currentHobby}
					ResidenceDetails={residentData}
					setAlertText={setAlertText}
					SetShowSuccessAlert={setShowSuccessAlert}
					SetShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showFavoriteFoodForm && (
				<FavoriteFoodForm
					open={showFavoriteFoodForm}
					setOpen={setShowFavoriteFoodForm}
					IsNewFavoriteFood={isNewFavoriteFood}
					DisplayFavoriteFood={displayFavoriteFood}
					currentFavoriteFood={currentFavoriteFood}
					ResidenceDetails={residentData}
					setAlertText={setAlertText}
					SetShowSuccessAlert={setShowSuccessAlert}
					SetShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showLifestyleForm && (
				<LifestyleForm
					open={showLifestyleForm}
					setOpen={setShowLifestyleForm}
					isNewLifestyle={isNewLifestyle}
					isReadOnly={displayLifestyle}
					currentLifestyle={currentLifestyle}
					ResidenceDetails={residentData}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showRelativeForm && (
				<RelativeForm
					open={showRelativeForm}
					setOpen={setShowRelativeForm}
					IsNewRelative={isNewRelative}
					isReadOnly={displayRelative}
					CurrentRelative={currentRelative}
					ResidenceDetails={residentData}
					setAlertText={setAlertText}
					SetShowSuccessAlert={setShowSuccessAlert}
					SetShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showConnectionRelativeForm && (
				<ConnectionForm
					open={showConnectionRelativeForm}
					setOpen={setShowConnectionRelativeForm}
					residentIdData={residentIdData}
					connectType={ConnectType.Relative}
					databaseId={currentRelative.relativeId}
					currentConnectDataList={currentRelative.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showConnectionJobForm && (
				<ConnectionForm
					open={showConnectionJobForm}
					setOpen={setShowConnectionJobForm}
					residentIdData={residentIdData}
					connectType={ConnectType.Job}
					databaseId={currentJob.jobId}
					currentConnectDataList={currentJob.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showConnectionLifestyleForm && (
				<ConnectionForm
					open={showConnectionLifestyleForm}
					setOpen={setShowConnectionLifestyleForm}
					residentIdData={residentIdData}
					connectType={ConnectType.Lifestyle}
					databaseId={currentLifestyle.lifestyleId}
					currentConnectDataList={currentLifestyle.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showConnectionFavoriteFoodForm && (
				<ConnectionForm
					open={showConnectionFavoriteFoodForm}
					setOpen={setShowConnectionFavoriteFoodForm}
					residentIdData={residentIdData}
					connectType={ConnectType.FavoriteFood}
					databaseId={currentFavoriteFood.favoriteFoodId}
					currentConnectDataList={currentFavoriteFood.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showConnectionHobbiesForm && (
				<ConnectionForm
					open={showConnectionHobbiesForm}
					setOpen={setShowConnectionHobbiesForm}
					residentIdData={residentIdData}
					connectType={ConnectType.Hobby}
					databaseId={currentHobby.hobbyId}
					currentConnectDataList={currentHobby.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showConnectionLinkForm && (
				<ConnectionForm
					open={showConnectionLinkForm}
					setOpen={setShowConnectionLinkForm}
					residentIdData={residentIdData}
					connectType={ConnectType.Link}
					databaseId={currentLink.linkId}
					currentConnectDataList={currentLink.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showConfirmLinkDeletion && (
				<Confirm
					open={showConfirmLinkDeletion}
					setOpen={setShowConfirmLinkDeletion}
					text={deleteText}
					executefct={deleteLink}
				/>
			)}
			{showConfirmJobDeletion && (
				<Confirm
					open={showConfirmJobDeletion}
					setOpen={setShowConfirmJobDeletion}
					text={deleteText}
					executefct={deleteJob}
				/>
			)}
			{showConfirmLifestyleDeletion && (
				<Confirm
					open={showConfirmLifestyleDeletion}
					setOpen={setShowConfirmLifestyleDeletion}
					text={deleteText}
					executefct={deleteLifestyle}
				/>
			)}
			{showConfirmHobbyDeletion && (
				<Confirm
					open={showConfirmHobbyDeletion}
					setOpen={setShowConfirmHobbyDeletion}
					text={deleteText}
					executefct={deleteHobby}
				/>
			)}
			{showConfirmFavoriteFoodDeletion && (
				<Confirm
					open={showConfirmFavoriteFoodDeletion}
					setOpen={setShowConfirmFavoriteFoodDeletion}
					text={deleteText}
					executefct={deleteFavoriteFood}
				/>
			)}
			{showMediaFileFormFavoriteFood && (
				<MediaFileForm
					open={showMediaFileFormFavoriteFood}
					setOpen={setShowMediaFileFormFavoriteFood}
					isNewMediaFile={true}
					residentIdData={residentIdData}
					setMediaId={setMediaIdForLinkFavoriteFood}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					currentMediaFileMetaData={defaultMediaFileData}
				/>
			)}
			{showAddMediaLinkFormFavoriteFood && (
				<NoteOrGreetingToMediaLinkForm
					open={showAddMediaLinkFormFavoriteFood}
					setOpen={setShowAddMediaLinkFormFavoriteFood}
					residentIdData={residentIdData}
					currentNoteOrGreeting={currentFavoriteFood}
					mediaLinkType='favoriteFood'
					elementId={currentFavoriteFood?.favoriteFoodId}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showDisplayMediaAlbumFormFavoriteFood && (
				<DisplayMediaAlbumForm
					open={showDisplayMediaAlbumFormFavoriteFood}
					setOpen={setShowDisplayMediaAlbumFormFavoriteFood}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
					residentIdData={residentIdData}
					currentDisplayMediaAlbum={currentFavoriteFood}
					mediaIdList={currentFavoriteFood?.media}
					title={'Medien für Lieblingsessen: ' + currentFavoriteFood?.name}
				/>
			)}
			{showConfirmRelativeDeletion && (
				<Confirm
					open={showConfirmRelativeDeletion}
					setOpen={setShowConfirmRelativeDeletion}
					text={deleteText}
					executefct={deleteRelative}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}

export default ResidentProfile;
