import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	TextField,
	Grid,
	ThemeProvider,
	useMediaQuery,
	useTheme,
	Tooltip,
	FormGroup,
	FormLabel,
	FormControlLabel,
	Checkbox,
	FormControl,
	RadioGroup,
	Radio
} from '@mui/material';
import { Tag } from '../generated/models/Tag';
import { Close, Save } from '@mui/icons-material';
import { getNetworkApi } from './keycloak';
import { Lifestyle } from '../generated';
import { isEmptyField, isTagTicked } from './format';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';


export default function LifestyleForm({
	open,
	setOpen,
	isNewLifestyle,
	isReadOnly,
	currentLifestyle,
	ResidenceDetails,
	setAlertText,
	setShowSuccessAlert,
	setShowErrorAlert,
}) {
	const [lifestyleValues, setLifestyleValues] = React.useState(
		currentLifestyle as Lifestyle
	);
	const [Tags, setTags] = useState([] as Tag[]);
	const [TagValues, setTagValues] = useState(lifestyleValues.tags as Tag[]);

	const isSaveButtonEnabled = (lifestyle: Lifestyle) => {
		return (
			!isEmptyField(lifestyle?.name)
		);
	};

	const handleRadioChange = (event) => {
	setLifestyleValues((prevLifestyleValue) => ({
		...prevLifestyleValue,
		relevant: event.target.value === "true"
	}));
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setLifestyleValues({
			...lifestyleValues,
			[name]: value,
		});
	};

	const getTagValue = (e) => {
		if (Object.entries(TagValues).length !== 0) {
			if (e.target.checked) {
				setTagValues((prevTags) => [
					...prevTags,
					{ tagId: Number(e.target.value) },
				]);
			} else {
				setTagValues(
					TagValues.filter((item) => item.tagId !== Number(e.target.value))
				);
			}
		} else {
			setTagValues([{ tagId: Number(e.target.value) }]);
		}
	};



	const saveNewLifestyle = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.postLifestyle(
				ResidenceDetails.tenantIdentifier,
				ResidenceDetails.residenceIdentifier,
				ResidenceDetails.unitIdentifier,
				currentLifestyle.residentId,
				{
					name: lifestyleValues.name,
					description: lifestyleValues.description,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: lifestyleValues.relevant
				}
			);
			setAlertText('Lebensweise / Glaube wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Lebensweise / Glaube wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateLifestyle = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.putLifestyle(
				ResidenceDetails.tenantIdentifier,
				ResidenceDetails.residenceIdentifier,
				ResidenceDetails.unitIdentifier,
				currentLifestyle.residentId,
				currentLifestyle.lifestyleId,
				{
					name: lifestyleValues.name,
					description: lifestyleValues.description,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: lifestyleValues.relevant
				}
			);
			setAlertText('Lebensweise / Glaube wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Lebensweise / Glaube wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const getDialogTitle = () => {
		return (
			<DialogTitle>
				{isReadOnly
					? 'Details'
					: [
							isNewLifestyle
								? 'Neue Lebensweise / Glaube anlegen'
								: 'Lebensweise / Glaube bearbeiten',
					  ]}
			</DialogTitle>
		);
	};

		
	useEffect(() => {
		const loadTags = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getTags(ResidenceDetails.tenantIdentifier);
				console.log(result)
				setTags(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the available Tags data!');
					}
				} else {
					console.log('There was an error fetching the available Tags data!');
				}
			}
		};
		trackPromise(loadTags());
	}, [
		ResidenceDetails.tenantIdentifier,
		ResidenceDetails.unitIdentifier,
		ResidenceDetails.tenantIndentifier
	]);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth = 'md'>
					{getDialogTitle()}
					<div className='new-line' />
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label='Themengebiet'
										name='name'
										id='outlined-size-normal'
										value={lifestyleValues.name}
										onChange={handleInputChange}
										required
										disabled={isReadOnly}
										inputProps={{
											maxLength: 100,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										multiline
										rows={2}
										label='Beschreibung'
										name='description'
										id='outlined-size-normal'
										value={lifestyleValues.description}
										onChange={handleInputChange}
										disabled={isReadOnly}
										inputProps={{
											maxLength: 500,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
								<FormControl>
									<Tooltip
										title='Tags bzgl. der Strukturierten Informationssammlung'
										placement='top'>
										<FormLabel>SIS Tag</FormLabel>
									</Tooltip>
									<FormGroup>
										<Grid container columnSpacing={2} rowSpacing={0}>
											{Tags.filter(
												(el) => el.tagGroupIdentifier === 'SIS'
											).map((item, index) => (
												<Grid item xs={4} key={index}>
													<FormControlLabel
														key={item.tagId}
														value={item.tagId}
														checked={isTagTicked(TagValues, item.tagId)}
														control={<Checkbox />}
														label={item.tagAbbreviation}
														onChange={(e) => getTagValue(e)}
														disabled={isReadOnly}
													/>
												</Grid>
											))}
										</Grid>
									</FormGroup>
								</FormControl>
								</Grid>
								<Grid item xs={12}>
								<FormControl>
									<Tooltip title="Pflegerelevant?" placement="top">
									<FormLabel>Pflegerelevant?</FormLabel>
									</Tooltip>
									<FormControl>
											<RadioGroup
												row
												aria-labelledby="demo-controlled-radio-buttons-group"
												name="controlled-radio-buttons-group"
												value={lifestyleValues.relevant}
												onChange={handleRadioChange}
											>
										<FormControlLabel value={true}  disabled={isReadOnly} control={<Radio />} label="Ja" />
										<FormControlLabel value={false}  disabled={isReadOnly} control={<Radio />} label="Nein" />
									</RadioGroup>
									</FormControl>
								</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction='row'
										justifyContent='flex-end'
										alignItems='center'
										spacing={2}>
										<Grid item>
											<Button variant='contained' onClick={handleClose}>
												{<Close />} {isReadOnly ? 'Schließen' : 'Abbrechen'}
											</Button>
										</Grid>
										{!isReadOnly && (
											<Grid item>
												<Button
													variant='contained'
													disabled={!isSaveButtonEnabled(lifestyleValues)}
													onClick={
														isNewLifestyle ? saveNewLifestyle : updateLifestyle
													}>
													{<Save />} Speichern
												</Button>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
