import React, { useEffect, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { getNetworkApi } from '../components/keycloak';
import { AssessmentResult } from '../generated';
import {
	Box,
	Button,
	Grid,
	Table,
	TableBody,
	TableContainer,
	TableRow,
	Tooltip,
} from '@mui/material';
import { TableCellStyled } from '../theme';
import { Info } from '@mui/icons-material';
import { ResidentIdData, roundPercentage } from '../components/format';
import { ErrorAlert } from '../components/Alerts';
import AssesmentResultInfo from '../components/AssessmentResultInfo';

export default function MotivationResult(props) {
	const residentIdData: ResidentIdData = useMemo(() => {
		return {
			tenantIdentifier: props.match.params.tenantId,
			residenceIdentifier: props.match.params.residenceId,
			unitIdentifier: props.match.params.unitId,
			residentId: Number(props.match.params.residentId),
		};
	}, [props]);

	const residentAssessmentData = useMemo(() => {
		return {
			residentAssessmentId: props.match.params.residentAssessmentId,
			assessmentProfileId: props.match.params.assessmentProfileId,
		};
	}, [props]);

	const resultLowColor: string = '#008080';
	const resultUpColor: string = '#7da7ca';

	const [assessmentResultList, setAssessmentResultList] = useState(
		[] as AssessmentResult[]
	);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [showAssesmentResultInfo, setShowAssesmentResultInfo] = useState(false);
	const [currentAssesmentResult, setCurrentAssesmentResult] = useState({
		tenantidentifier: residentIdData.tenantIdentifier,
		unitIdentifier: residentIdData.unitIdentifier,
		residenceIdentifier: residentIdData.residenceIdentifier,
		assessmentProfileId: residentAssessmentData.assessmentProfileId,
		residentAssessmentId: undefined,
		assessmentTargetId: undefined,
		assessmentTargetName: undefined,
	});

	const showResultInfo = (Result: AssessmentResult) => {
		setShowAssesmentResultInfo(true);
		setCurrentAssesmentResult({
			...currentAssesmentResult,
			residentAssessmentId: Result.residentAssessmentId,
			assessmentTargetId: Result.assessmentTargetId,
			assessmentTargetName: Result.assessmentTargetName,
		});
	};

	/**fetch motivprofile result data */
	useEffect(() => {
		const loadAssessmentResultData = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getAssessmentResultForResidentAssessment(
					residentIdData.tenantIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residenceIdentifier,
					residentAssessmentData.residentAssessmentId,
					residentAssessmentData.assessmentProfileId
				);
				setAssessmentResultList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'Motivprofil Ergebnisse konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Motivprofil Ergebnisse konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadAssessmentResultData());
	}, [residentIdData, residentAssessmentData]);

	return (
		<React.Fragment>
			<Grid container direction='column' spacing={2} sx={{ padding: 5 }}>
				<Grid item xs={12}>
					<TableContainer sx={{ maxHeight: '90%' }}>
						<Table sx={{ fontSize: 40 }}>
							<TableBody>
								{assessmentResultList.map((el, index) => (
									<TableRow key={index}>
										<TableCellStyled sx={{ backgroundColor: '#f0e68c' }}>
											{el.assessmentTargetName}
										</TableCellStyled>
										<TableCellStyled sx={{ color: resultLowColor }}>
											{el.descriptionLower}
										</TableCellStyled>
										<TableCellStyled
											sx={{
												color: resultLowColor,
												textAlign: 'right',
											}}>
											{roundPercentage(el.score * 100)}
										</TableCellStyled>
										<TableCellStyled
											sx={{
												width: '60%',
												textAlign: 'right',
											}}>
											<Box
												sx={{
													height: 30,
													width: roundPercentage(el.score * 100),
													backgroundColor: resultLowColor,
													display: 'inline',
													float: 'left',
													color: resultLowColor,
												}}
											/>
											<Box
												sx={{
													height: 30,
													width: roundPercentage(100 - el.score * 100),
													backgroundColor: resultUpColor,
													display: 'inline',
													float: 'right',
													color: resultUpColor,
												}}
											/>
										</TableCellStyled>
										<TableCellStyled
											sx={{
												color: resultUpColor,
												textAlign: 'left',
											}}>
											{roundPercentage(100 - el.score * 100)}
										</TableCellStyled>
										<TableCellStyled
											sx={{ color: resultUpColor }}
											align='right'>
											{el.descriptionUpper}
										</TableCellStyled>
										<TableCellStyled>
											<Tooltip title='Zeige Details'>
												<Button>
													{
														<Info
															color='primary'
															onClick={() => showResultInfo(el)}
														/>
													}
												</Button>
											</Tooltip>
										</TableCellStyled>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			{showAssesmentResultInfo && (
				<AssesmentResultInfo
					open={showAssesmentResultInfo}
					setOpen={setShowAssesmentResultInfo}
					currentAssesmentResult={currentAssesmentResult}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
