/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StageOfLife
 */
export interface StageOfLife {
    /**
     * 
     * @type {string}
     * @memberof StageOfLife
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof StageOfLife
     */
    name?: string;
}

export function StageOfLifeFromJSON(json: any): StageOfLife {
    return StageOfLifeFromJSONTyped(json, false);
}

export function StageOfLifeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StageOfLife {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function StageOfLifeToJSON(value?: StageOfLife | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantIdentifier': value.tenantIdentifier,
        'name': value.name,
    };
}


