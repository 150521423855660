/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectType,
    ConnectTypeFromJSON,
    ConnectTypeFromJSONTyped,
    ConnectTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConnectInsertParameters
 */
export interface ConnectInsertParameters {
    /**
     * 
     * @type {number}
     * @memberof ConnectInsertParameters
     */
    databaseId?: number;
    /**
     * 
     * @type {string}
     * @memberof ConnectInsertParameters
     */
    comment?: string;
    /**
     * 
     * @type {ConnectType}
     * @memberof ConnectInsertParameters
     */
    connectType?: ConnectType;
}

export function ConnectInsertParametersFromJSON(json: any): ConnectInsertParameters {
    return ConnectInsertParametersFromJSONTyped(json, false);
}

export function ConnectInsertParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectInsertParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'databaseId': !exists(json, 'databaseId') ? undefined : json['databaseId'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'connectType': !exists(json, 'connectType') ? undefined : ConnectTypeFromJSON(json['connectType']),
    };
}

export function ConnectInsertParametersToJSON(value?: ConnectInsertParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'databaseId': value.databaseId,
        'comment': value.comment,
        'connectType': ConnectTypeToJSON(value.connectType),
    };
}


