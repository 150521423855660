import React from 'react';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	NativeSelect,
	TextField,
} from '@mui/material';

import { ErrorAlert, SuccessAlert } from './Alerts';
import keycloak, { getNetworkApi } from './keycloak';
import { Resident, Unit } from '../generated/models';
import Confirm from './Confirm';
import { isEmptyField } from './format';
import TabPanel from './TabPanel';
import { KeycloakTokenParsed } from 'keycloak-js';

export default function ResidentRelocationPanel({ value, index }) {
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [UnitList, setUnitList] = useState([] as Unit[]);
	const [unitIndex, setUnitIndex] = useState({} as number);
	const [residentList, setResidentList] = useState([] as Resident[]);

	const defaultRelocationDetails = {
		residentId: undefined as number,
		room: '' as string,
		associatedCaregiver: '' as string,
	};
	const [selectedRelocationDetails, setSelectedRelocationDetails] = useState({
		...defaultRelocationDetails,
		unitIndex: undefined as number,
	});
	const [showConfirmRelocation, setShowConfirmRelocation] = useState(false);
	const [relocationText, setRelocationText] = useState('' as string);

	const handleUnitChange = (e) => {
		setUnitIndex(e.target.value);
	};

	type ParsedToken = KeycloakTokenParsed & {
		tenantIdentifier?: string;
	};

	const parsedToken: ParsedToken | undefined = keycloak?.idTokenParsed;

	const tenantIdentifier: string = parsedToken.tenantIdentifier;

	useEffect(() => {
		const loadUnits = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUnits(tenantIdentifier);
				setUnitList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'Wohnbereichinformationen konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Wohnbereichinformationen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUnits());
	}, []);

	useEffect(() => {
		const loadResidentData = async () => {
			const api = getNetworkApi();
			if (UnitList[unitIndex].tenantIdentifier !== undefined) {
				try {
					const result = await api.getResidentList(
						UnitList[unitIndex].tenantIdentifier,
						UnitList[unitIndex].residenceIdentifier,
						UnitList[unitIndex].unitIdentifier
					);
					setResidentList(result);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Bewohner:innen konnten nicht abgerufen werden');
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Bewohner:innen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadResidentData());
	}, [unitIndex, UnitList, showSuccessAlert]);

	const handleRelocationInput = (e) => {
		const { name, value } = e.target;
		setSelectedRelocationDetails({
			...selectedRelocationDetails,
			[name]: value,
		});
	};

	const handleRelocation = () => {
		setShowConfirmRelocation(true);
		setRelocationText('Wollen Sie wirklich den Bewohner umziehen?');
	};

	const relocateResident = async () => {
		let selectedResident = residentList.find(
			(el) => el.residentId === Number(selectedRelocationDetails.residentId)
		);
		const client = getNetworkApi();
		try {
			await client.putResident(
				UnitList[unitIndex].tenantIdentifier,
				UnitList[unitIndex].residenceIdentifier,
				UnitList[unitIndex].unitIdentifier,
				selectedResident.residentId,
				{
					residenceIdentifier:
						UnitList[selectedRelocationDetails.unitIndex].residenceIdentifier,
					unitIdentifier:
						UnitList[selectedRelocationDetails.unitIndex].unitIdentifier,
					firstName: selectedResident.firstName,
					surname: selectedResident.surname,
					gender: selectedResident.gender,
					room: selectedRelocationDetails.room,
					associatedCaregiver: selectedRelocationDetails.associatedCaregiver,
					birthday: selectedResident.birthday,
					birthplace: selectedResident.birthplace,
					birthname: selectedResident.birthname,
					nickname: selectedResident.nickname,
					residentSince: undefined,
				}
			);
			setAlertText('Bewohner:in wurde erfolgreich umgezogen');
			setShowSuccessAlert(true);
			setShowConfirmRelocation(false);
			setSelectedRelocationDetails({
				...defaultRelocationDetails,
				unitIndex: selectedRelocationDetails.unitIndex,
			});
		} catch (error) {
			setAlertText('Bewohner:in konnte nicht umgezogen werden');
			setShowErrorAlert(true);
		}
	};

	const isValidInput = () => {
		if (
			!isEmptyField(unitIndex) &&
			!isEmptyField(selectedRelocationDetails.unitIndex) &&
			!isEmptyField(selectedRelocationDetails.residentId) &&
			!isEmptyField(selectedRelocationDetails.room) &&
			!isEmptyField(selectedRelocationDetails.associatedCaregiver) &&
			unitIndex !== selectedRelocationDetails.unitIndex
		) {
			return true;
		}
		return false;
	};

	return (
		<React.Fragment>
			<TabPanel value={value} index={index}>
				<Grid item xs={6}>
					<Grid container direction={'column'} spacing={4}>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<InputLabel variant='standard' htmlFor='uncontrolled-native'>
									Bisherigen Wohnbereich auswählen
								</InputLabel>
								<NativeSelect
									onChange={handleUnitChange}
									inputProps={{
										name: 'unit',
										id: 'uncontrolled-native',
									}}
									sx={{ color: 'green' }}>
									<option aria-label='None' value='' />
									{UnitList.map((el, index) => (
										<option key={index} value={index}>
											{el.residenceIdentifier} - {el.unitIdentifier}
										</option>
									))}
								</NativeSelect>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<InputLabel variant='standard' htmlFor='uncontrolled-native'>
									Bewohner:In auswählen
								</InputLabel>
								<NativeSelect
									onChange={handleRelocationInput}
									inputProps={{
										name: 'residentId',
										id: 'uncontrolled-native',
									}}
									sx={{ color: 'green' }}>
									<option aria-label='None' value='' />
									{residentList.map((el, index) => (
										<option key={el.residentId} value={el.residentId}>
											{el.surname}, {el.firstName} (Zimmer: {el.room})
										</option>
									))}
								</NativeSelect>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<FormControl fullWidth>
								<InputLabel variant='standard' htmlFor='uncontrolled-native'>
									Neuen Wohnbereich auswählen
								</InputLabel>
								<NativeSelect
									onChange={handleRelocationInput}
									inputProps={{
										name: 'unitIndex',
										id: 'uncontrolled-native',
									}}
									value={selectedRelocationDetails.unitIndex}
									sx={{ color: 'green' }}>
									<option aria-label='None' value='' />
									{UnitList.map((el, index) => (
										<option key={index} value={index}>
											{el.residenceIdentifier} - {el.unitIdentifier}
										</option>
									))}
								</NativeSelect>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								required
								label='Neue Zimmernummer'
								name='room'
								id='outlined-size-normal'
								value={selectedRelocationDetails.room}
								onChange={handleRelocationInput}
								inputProps={{ maxLength: 50 }}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								required
								label='Neue Bezugspflegekraft'
								name='associatedCaregiver'
								id='outlined-size-normal'
								value={selectedRelocationDetails.associatedCaregiver}
								onChange={handleRelocationInput}
								inputProps={{ maxLength: 50 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant='contained'
								onClick={handleRelocation}
								disabled={!isValidInput()}>
								Bewohner:In umziehen
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</TabPanel>
			{showConfirmRelocation && (
				<Confirm
					open={showConfirmRelocation}
					setOpen={setShowConfirmRelocation}
					text={relocationText}
					executefct={relocateResident}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
