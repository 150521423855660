import { Grid } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { Resident } from '../generated';
import ResidentProfileCard from './ResidentProfileCard';

interface ResidentsGridViewProps {
	residentList: Resident[];
	forwardToResidentProfile: any;
	handleClickInfo: any;
}

export default function ResidentsGridView({
	residentList,
	forwardToResidentProfile,
	handleClickInfo,
}: ResidentsGridViewProps) {
	return (
		<Grid
			container
			spacing={{ xs: 2, lg: 3 }}
			columns={{ xs: 3, sm: 6, md: 9, lg: 12 }}>
			<Grid item xs={12} padding={5}
				style={{
					fontSize: '12',
					color: grey[500],
					textAlign: 'center',
				
				}}
			>
				{(residentList?.length > 0 )?'' : 'Keine Bewohner hinterlegt.'}
			</Grid>
			{residentList.map((resident, index) => (
				<Grid item xs={3} sm={3} md={3} lg={3} key={resident.residentId}>
					<ResidentProfileCard
						resident={resident}
						onClickCard={() => forwardToResidentProfile(resident)}
						onClickInfoButton={() => handleClickInfo(resident)}
					/>
				</Grid>
			))}
		</Grid>
	);
}
