//import * as React from 'react';
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	TextField,
	Grid,
	ThemeProvider,
	useTheme,
	useMediaQuery,
	Tooltip,
	FormGroup,
	FormLabel,
	FormControlLabel,
	Checkbox,
	FormControl,
	RadioGroup,
	Radio
} from '@mui/material';
import { Tag } from '../generated/models/Tag';
import { Close, Save } from '@mui/icons-material';
import { getNetworkApi } from './keycloak';
import { Job } from '../generated';
import { handleKeyPress, isEmptyField, isTagTicked } from './format';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
export default function JobForm({
	open,
	setOpen,
	IsNewJob,
	DisplayJob,
	CurrentJob,
	ResidenceDetails,
	setAlertText,
	SetShowSuccessAlert,
	SetShowErrorAlert,
}) {
	const [jobValues, setJobValues] = React.useState(CurrentJob as Job);
	const [Tags, setTags] = useState([] as Tag[]);
	const [TagValues, setTagValues] = useState(jobValues.tags as Tag[]);
	const isSaveButtonEnabled = (job: Job) => {
		return (
			!isEmptyField(job?.name)
		);
	};

	const handleRadioChange = (event) => {
	setJobValues((prevJobValues) => ({
		...prevJobValues,
		relevant: event.target.value === "true"
	}));
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setJobValues({
			...jobValues,
			[name]: value,
		});
	};

	const getTagValue = (e) => {
		if (Object.entries(TagValues).length !== 0) {
			if (e.target.checked) {
				setTagValues((prevTags) => [
					...prevTags,
					{ tagId: Number(e.target.value) },
				]);
			} else {
				setTagValues(
					TagValues.filter((item) => item.tagId !== Number(e.target.value))
				);
			}
		} else {
			setTagValues([{ tagId: Number(e.target.value) }]);
		}
	};



	const saveNewJob = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.postJob(
				ResidenceDetails.tenantIdentifier,
				ResidenceDetails.residenceIdentifier,
				ResidenceDetails.unitIdentifier,
				CurrentJob.residentId,
				{
					name: jobValues.name,
					description: jobValues.description,
					startFrom: jobValues.startFrom,
					endTo: jobValues.endTo,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: jobValues.relevant,
				}
			);
			setAlertText('Beruf/ Bildungabschnitt wurde erfolgreich gespeichert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Beruf/ Bildungabschnitt wurde nicht gespeichert');
			SetShowErrorAlert(true);
		}
	};

	const updateJob = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.putJob(
				ResidenceDetails.tenantIdentifier,
				ResidenceDetails.residenceIdentifier,
				ResidenceDetails.unitIdentifier,
				CurrentJob.residentId,
				CurrentJob.jobId,
				{
					name: jobValues.name,
					description: jobValues.description,
					startFrom: jobValues.startFrom,
					endTo: jobValues.endTo,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: jobValues.relevant,
				}
			);
			setAlertText('Beruf/ Bildungabschnitt wurde erfolgreich geändert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Beruf/ Bildungabschnitt wurde nicht geändert');
			SetShowErrorAlert(true);
		}
	};

	const fontColor = {
		style: { color: 'black' },
	};

		
	useEffect(() => {
		const loadTags = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getTags(ResidenceDetails.tenantIdentifier);
				console.log(result)
				setTags(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the available Tags data!');
					}
				} else {
					console.log('There was an error fetching the available Tags data!');
				}
			}
		};
		trackPromise(loadTags());
	}, [
		ResidenceDetails.tenantIdentifier,
		ResidenceDetails.unitIdentifier,
		ResidenceDetails.tenantIndentifier
	]);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth = 'md'>
					<DialogTitle>
						{DisplayJob
							? 'Details'
							: [
									IsNewJob
										? 'Neuen Beruf/ Bildungabschnitt anlegen'
										: 'Beruf/ Bildungabschnitt bearbeiten',
							  ]}
					</DialogTitle>
					<div className='new-line' />
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										fullWidth
										label='Beruf/ Bildungabschnitt'
										name='name'
										id='outlined-size-normal'
										value={jobValues.name}
										color='primary'
										onChange={handleInputChange}
										onKeyPress={handleKeyPress}
										disabled={DisplayJob}
										inputProps={{
											fontColor,
											maxLength: 100,
										}}
										required
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										fullWidth
										label='von'
										name='startFrom'
										id='outlined-size-normal'
										disabled={DisplayJob}
										value={jobValues.startFrom}
										onChange={handleInputChange}
										inputProps={{
											maxLength: 25,
										}}
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										fullWidth
										label='bis'
										name='endTo'
										id='outlined-size-normal'
										disabled={DisplayJob}
										value={jobValues.endTo}
										onChange={handleInputChange}
										inputProps={{
											maxLength: 25,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										multiline
										rows={2}
										label='Beschreibung'
										name='description'
										id='outlined-size-normal'
										disabled={DisplayJob}
										value={jobValues.description}
										onChange={handleInputChange}
										inputProps={{
											maxLength: 500,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
								<FormControl>
									<Tooltip
										title='Tags bzgl. der Strukturierten Informationssammlung'
										placement='top'>
										<FormLabel>SIS Tag</FormLabel>
									</Tooltip>
									<FormGroup>
										<Grid container columnSpacing={2} rowSpacing={0}>
											{Tags.filter(
												(el) => el.tagGroupIdentifier === 'SIS'
											).map((item, index) => (
												<Grid item xs={4} key={index}>
													<FormControlLabel
														key={item.tagId}
														value={item.tagId}
														checked={isTagTicked(TagValues, item.tagId)}
														control={<Checkbox />}
														label={item.tagAbbreviation}
														onChange={(e) => getTagValue(e)}
														disabled={DisplayJob}
													/>
												</Grid>
											))}
										</Grid>
									</FormGroup>
								</FormControl>
								</Grid>
								<Grid item xs={12}>
								<FormControl>
									<Tooltip title="Pflegerelevant?" placement="top">
									<FormLabel>Pflegerelevant?</FormLabel>
									</Tooltip>
									<FormControl>
											<RadioGroup
												row
												aria-labelledby="demo-controlled-radio-buttons-group"
												name="controlled-radio-buttons-group"
												value={jobValues.relevant}
												onChange={handleRadioChange}
											>
										<FormControlLabel value={true} disabled={DisplayJob} control={<Radio />} label="Ja" />
										<FormControlLabel value={false} disabled={DisplayJob}  control={<Radio />} label="Nein" />
									</RadioGroup>
									</FormControl>
								</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction='row'
										justifyContent='flex-end'
										alignItems='center'
										spacing={2}>
										<Grid item>
											<Button variant='contained' onClick={handleClose}>
												{<Close />} {DisplayJob ? 'Schließen' : 'Abbrechen'}
											</Button>
										</Grid>
										{!DisplayJob && (
											<Grid item>
												<Button
													variant='contained'
													disabled={!isSaveButtonEnabled(jobValues)}
													onClick={IsNewJob ? saveNewJob : updateJob}>
													{<Save />} Speichern
												</Button>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
