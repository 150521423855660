import { Link, Typography } from '@mui/material';

/**
 * Footer
 */
export function Footer(): JSX.Element {
	return (
		<Typography
			variant='body2'
			color='textSecondary'
			style={{ position: 'fixed', bottom: '5px', marginLeft: '10px' }}>
			{`Version ${
				process.env.NODE_ENV === 'development'
					? process.env.REACT_APP_TCC_VERSION
					: window.env.TCC_VERSION
			} | Copyright © `}
			{new Date().getFullYear()} {' | '}
			<Link color='inherit' href='https://institute-for-compassion.de/'>
				Institute for Compassion
			</Link>
		</Typography>
	);
}
