/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VisitFrequency {
    Daily = 'DAILY',
    Everyotherday = 'EVERYOTHERDAY',
    Weekly = 'WEEKLY',
    Everyotherweek = 'EVERYOTHERWEEK',
    Monthly = 'MONTHLY',
    Onholidays = 'ONHOLIDAYS',
    Rarely = 'RARELY',
    Never = 'NEVER'
}

export function VisitFrequencyFromJSON(json: any): VisitFrequency {
    return VisitFrequencyFromJSONTyped(json, false);
}

export function VisitFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisitFrequency {
    return json as VisitFrequency;
}

export function VisitFrequencyToJSON(value?: VisitFrequency | null): any {
    return value as any;
}

