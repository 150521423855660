import React from 'react';
import { Close } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from '@mui/material';

export default function QuestionGuidanceForm({
	open,
	setOpen,
	currentGuidance,
}) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{'Hilfestellung'}</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								value={currentGuidance}
								multiline
								rows={4}
								contentEditable={false}
							/>
						</Grid>
						<Grid item xs={12}>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
								spacing={2}>
								<Grid item>
									<Button variant='contained' onClick={handleClose}>
										{<Close />} Ok
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
