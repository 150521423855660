/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NoteStatus
 */
export interface NoteStatus {
    /**
     * 
     * @type {number}
     * @memberof NoteStatus
     */
    noteStatusId?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteStatus
     */
    noteStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteStatus
     */
    noteStatusName?: string;
}

export function NoteStatusFromJSON(json: any): NoteStatus {
    return NoteStatusFromJSONTyped(json, false);
}

export function NoteStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'noteStatusId': !exists(json, 'noteStatusId') ? undefined : json['noteStatusId'],
        'noteStatus': !exists(json, 'noteStatus') ? undefined : json['noteStatus'],
        'noteStatusName': !exists(json, 'noteStatusName') ? undefined : json['noteStatusName'],
    };
}

export function NoteStatusToJSON(value?: NoteStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'noteStatusId': value.noteStatusId,
        'noteStatus': value.noteStatus,
        'noteStatusName': value.noteStatusName,
    };
}


