/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OutsideInputStatusEnum {
    Open = 'OPEN',
    Approved = 'APPROVED',
    Rejected = 'REJECTED'
}

export function OutsideInputStatusEnumFromJSON(json: any): OutsideInputStatusEnum {
    return OutsideInputStatusEnumFromJSONTyped(json, false);
}

export function OutsideInputStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutsideInputStatusEnum {
    return json as OutsideInputStatusEnum;
}

export function OutsideInputStatusEnumToJSON(value?: OutsideInputStatusEnum | null): any {
    return value as any;
}

