import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	TextField,
	Grid,
	ThemeProvider,
	FormControl,
	InputLabel,
	Select,
	useMediaQuery,
	useTheme,
	MenuItem,
	Tooltip,
	FormGroup,
	FormLabel,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio
} from '@mui/material';

import { Tag } from '../generated/models/Tag';
import { Close, Save } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { getNetworkApi } from './keycloak';
import { Hobby, StageOfLife } from '../generated';
import { trackPromise } from 'react-promise-tracker';
import { makeMenuItemForName, isTagTicked, isEmptyField } from './format';

export default function HobbyForm({
	open,
	setOpen,
	IsNewHobby,
	DisplayHobby,
	CurrentHobby,
	ResidenceDetails,
	setAlertText,
	SetShowSuccessAlert,
	SetShowErrorAlert,
}) {
	const [hobbyValues, setHobbyValues] = React.useState(CurrentHobby as Hobby);
	const [stagesOfLifeList, setStagesOfLifeList] = useState([] as StageOfLife[]);
	const [Tags, setTags] = useState([] as Tag[]);
	const [TagValues, setTagValues] = useState(hobbyValues.tags as Tag[]);

	const isSaveButtonEnabled = (hobby: Hobby) => {
		return (
			!isEmptyField(hobby?.name)
		);
	};

	const handleRadioChange = (event) => {
	setHobbyValues((prevHobbyValues) => ({
		...prevHobbyValues,
		relevant: event.target.value === "true"
	}));
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setHobbyValues({
			...hobbyValues,
			[name]: value,
		});
	};

	const getTagValue = (e) => {
		if (Object.entries(TagValues).length !== 0) {
			if (e.target.checked) {
				setTagValues((prevTags) => [
					...prevTags,
					{ tagId: Number(e.target.value) },
				]);
			} else {
				setTagValues(
					TagValues.filter((item) => item.tagId !== Number(e.target.value))
				);
			}
		} else {
			setTagValues([{ tagId: Number(e.target.value) }]);
		}
	};


	const saveNewHobby = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.postHobby(
				ResidenceDetails.tenantIdentifier,
				ResidenceDetails.residenceIdentifier,
				ResidenceDetails.unitIdentifier,
				CurrentHobby.residentId,
				{
					name: hobbyValues.name,
					description: hobbyValues.description,
					stageOfLife: hobbyValues.stageOfLife,
					startFrom: hobbyValues.startFrom,
					endTo: hobbyValues.endTo,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: hobbyValues.relevant
				}
			);
			setAlertText('Hobby wurde erfolgreich gespeichert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Hobby wurde nicht gespeichert');
			SetShowErrorAlert(true);
		}
	};

	useEffect(() => {
		const loadStagesOfLifeAndTags = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getStagesOfLife(
					ResidenceDetails.tenantIdentifier
				);
				setStagesOfLifeList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the StagesOfLife data!');
					}
				} else {
					console.log('There was an error fetching the StagesOfLife data!');
				}
			}
			try {
				const result = await api.getTags(ResidenceDetails.tenantIdentifier);
				console.log(result)
				setTags(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the available Tags data!');
					}
				} else {
					console.log('There was an error fetching the available Tags data!');
				}
			}
		};
		trackPromise(loadStagesOfLifeAndTags());
	}, [
		ResidenceDetails.tenantIdentifier,
		ResidenceDetails.unitIdentifier,
		ResidenceDetails.tenantIndentifier
	]);

	const updateHobby = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.putHobby(
				ResidenceDetails.tenantIdentifier,
				ResidenceDetails.residenceIdentifier,
				ResidenceDetails.unitIdentifier,
				CurrentHobby.residentId,
				CurrentHobby.hobbyId,
				{
					name: hobbyValues.name,
					description: hobbyValues.description,
					stageOfLife: hobbyValues.stageOfLife,
					startFrom: hobbyValues.startFrom,
					endTo: hobbyValues.endTo,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: hobbyValues.relevant
				}
			);
			setAlertText('Hobby wurde erfolgreich geändert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Hobby wurde nicht geändert');
			SetShowErrorAlert(true);
		}
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					maxWidth='md'>
					<DialogTitle>
						{DisplayHobby
							? 'Details'
							: [IsNewHobby ? 'Neues Hobby anlegen' : 'Hobby bearbeiten']}
					</DialogTitle>
					<div className='new-line' />
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={8}>
									<TextField
										fullWidth
										label='Hobby'
										name='name'
										id='outlined-size-normal'
										value={hobbyValues.name}
										onChange={handleInputChange}
										required
										disabled={DisplayHobby ? true : false}
										inputProps={{
											maxLength: 100,
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<FormControl variant='outlined' sx={{ width: '100%' }}>
										<InputLabel id='demo-simple-select-helper-label'>
											Lebensabschnitt
										</InputLabel>
										{Object.entries(stagesOfLifeList).length !== 0 && (
											<Select
												variant='outlined'
												label='Lebensabschnitt'
												name='stageOfLife'
												onChange={handleInputChange}
												defaultValue={
													hobbyValues.stageOfLife ? hobbyValues.stageOfLife : ''
												}
												disabled={DisplayHobby}>
												<MenuItem value={undefined}>
													<em>Unbekannt</em>
												</MenuItem>
												{stagesOfLifeList.map(makeMenuItemForName)}
											</Select>
										)}
									</FormControl>
								</Grid>
								<Grid item xs={8}>
									<TextField
										fullWidth
										multiline
										rows={3.4}
										label='Beschreibung'
										name='description'
										id='outlined-size-normal'
										value={hobbyValues.description}
										onChange={handleInputChange}
										disabled={DisplayHobby}
										inputProps={{
											maxLength: 500,
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										rows={1}
										label='von'
										name='startFrom'
										id='outlined-size-normal'
										disabled={DisplayHobby}
										value={hobbyValues.startFrom}
										onChange={handleInputChange}
										inputProps={{
											maxLength: 25,
										}}
									/>
									<TextField
										margin='normal'
										fullWidth
										rows={1}
										label='bis'
										name='endTo'
										id='outlined-size-normal'
										disabled={DisplayHobby}
										value={hobbyValues.endTo}
										onChange={handleInputChange}
										inputProps={{
											maxLength: 25,
										}}
									/>
								</Grid>
								<Grid item xs={12}>
								<FormControl>
									<Tooltip
										title='Tags bzgl. der Strukturierten Informationssammlung'
										placement='top'>
										<FormLabel>SIS Tag</FormLabel>
									</Tooltip>
									<FormGroup>
										<Grid container columnSpacing={2} rowSpacing={0}>
											{Tags.filter(
												(el) => el.tagGroupIdentifier === 'SIS'
											).map((item, index) => (
												<Grid item xs={4} key={index}>
													<FormControlLabel
														key={item.tagId}
														value={item.tagId}
														checked={isTagTicked(TagValues, item.tagId)}
														control={<Checkbox />}
														label={item.tagAbbreviation}
														onChange={(e) => getTagValue(e)}
														disabled={DisplayHobby}
													/>
												</Grid>
											))}
										</Grid>
									</FormGroup>
								</FormControl>
								</Grid>
								<Grid item xs={12}>
								<FormControl>
									<Tooltip title="Pflegerelevant?" placement="top">
									<FormLabel>Pflegerelevant?</FormLabel>
									</Tooltip>
									<FormControl>
											<RadioGroup
												row
												aria-labelledby="demo-controlled-radio-buttons-group"
												name="controlled-radio-buttons-group"
												value={hobbyValues.relevant}
												onChange={handleRadioChange}
											>
										<FormControlLabel value={true} disabled={DisplayHobby} control={<Radio />} label="Ja" />
										<FormControlLabel value={false} disabled={DisplayHobby} control={<Radio />} label="Nein" />
									</RadioGroup>
									</FormControl>
								</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction='row'
										justifyContent='flex-end'
										alignItems='center'
										spacing={2}>
										<Grid item>
											<Button variant='contained' onClick={handleClose}>
												{<Close />} {DisplayHobby ? 'Schließen' : 'Abbrechen'}
											</Button>
										</Grid>
										{!DisplayHobby && (
											<Grid item>
												<Button
													variant='contained'
													disabled={!isSaveButtonEnabled(hobbyValues)}
													onClick={IsNewHobby ? saveNewHobby : updateHobby}>
													{<Save />} Speichern
												</Button>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
