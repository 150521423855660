/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidenceEventMediaInsertParameters
 */
export interface ResidenceEventMediaInsertParameters {
    /**
     * 
     * @type {Array<number>}
     * @memberof ResidenceEventMediaInsertParameters
     */
    residenceMediaIds?: Array<number>;
}

export function ResidenceEventMediaInsertParametersFromJSON(json: any): ResidenceEventMediaInsertParameters {
    return ResidenceEventMediaInsertParametersFromJSONTyped(json, false);
}

export function ResidenceEventMediaInsertParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceEventMediaInsertParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'residenceMediaIds': !exists(json, 'residenceMediaIds') ? undefined : json['residenceMediaIds'],
    };
}

export function ResidenceEventMediaInsertParametersToJSON(value?: ResidenceEventMediaInsertParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'residenceMediaIds': value.residenceMediaIds,
    };
}


