import { useCallback } from 'react';
import {
	Paper,
	Button,
	Tooltip,
	FormControl,
	Typography,
	Select,
	MenuItem,
	SelectChangeEvent,
} from '@mui/material';
import MaterialTable, { Column, MTableBodyRow } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { Edit, Extension } from '@mui/icons-material';

import { Note, NoteStatus, StageOfLife, Tag } from '../generated';
import {
	determineStatusChipColor,
	dropDownFilter,
	filterForEmotion,
	filterForSISTags,
	noteCategoryItems,
	returnTagChips,
	TCCStyledBadgeButton,
	viewportHeight,
} from './format';
import React from 'react';
import { fontSize } from '@mui/system';
import grey from '@mui/material/colors/grey';

interface NotesTableViewProps {
	noteList: Note[];
	forwardToNote: any;
	stagesOfLifeList: StageOfLife[];
	noteStatusList: NoteStatus[];
	tags: Tag[];
	handleClickOpenConnectionForm: any;
	handleClickOpenNoteStatusForm: any;
	isAuthorized: boolean;
}

export default function NotesTableView({
	noteList,
	forwardToNote,
	stagesOfLifeList,
	noteStatusList,
	tags,
	handleClickOpenConnectionForm,
	handleClickOpenNoteStatusForm,
	isAuthorized,
}: NotesTableViewProps) {
	const [currentStageOfLife, setCurrentStageOfLife] = React.useState('');
	const [currentCategory, setCurrentCategory] = React.useState(undefined);
	const [currentNoteStatus, setCurrentNoteStatus] = React.useState('');
	const handleChangeStagesOfLife = (event: SelectChangeEvent) => {
		setCurrentStageOfLife(event.target.value);
	};

	const handleChangeCategory = (event: SelectChangeEvent) => {
		setCurrentCategory(event.target.value);
	};

	const handleChangeNoteStatus = (event: SelectChangeEvent) => {
		setCurrentNoteStatus(event.target.value);
	};

	let SisTagDict = Object.assign(
		{},
		...tags
			.filter((el) => el.tagGroupIdentifier === 'SIS')
			.map((item) => item.tagAbbreviation)
			.map((x) => ({ [x.substring(0, 4)]: x }))
	);

	let EmotionTagDict = Object.assign(
		{},
		...tags
			.filter((el) => el.tagGroupIdentifier === 'EMOTION')
			.map((item) => item.tagAbbreviation)
			.map((x) => ({ [x.substring(0, 3)]: x }))
	);

	const columns: Column<Note>[] = [
		{
			width: 'min-content',
			sorting: false,
			render: useCallback((note: Note) => {
				return (
					<TCCStyledBadgeButton
						badgeContent={note.connects.length}
						onClick={() => handleClickOpenConnectionForm(note)}
					/>
				);
			}, []),
		},
		{
			width: 'min-content',
			sorting: false,
			render: useCallback(
				(data) => {
					return (
						<Tooltip title='Öffne Erinnerung'>
							<Button onClick={() => forwardToNote(data)}>
								{<Extension sx={{ color: 'green', fontSize: '30px' }} />}
							</Button>
						</Tooltip>
					);
				},
				[forwardToNote]
			),
		},
		{
			title: 'Erlebnis',
			width: 'max-content',
			field: 'title',
			render: useCallback(
				(data: any) => (
					<Typography variant='h6' color={grey[500]}>
						{data.title}
					</Typography>
				),
				[]
			),
		},
		{
			title: 'Lebensabschnitt',
			field: 'stageOfLife',
			filtering: true,
			customSort: (a, b) =>
				stagesOfLifeList.findIndex((obj) => {
					return obj.name === a.stageOfLife;
				}) -
				stagesOfLifeList.findIndex((obj) => {
					return obj.name === b.stageOfLife;
				}),
			defaultFilter: currentStageOfLife,
			filterComponent: () =>
				dropDownFilter({
					value: currentStageOfLife,
					valueList: stagesOfLifeList,
					onChange: handleChangeStagesOfLife,
					width: '109%',
					name: 'stageOfLife',
				}),
			render: useCallback((data: Note) => data.stageOfLife, []),
		},
		{
			title: 'Kategorie',
			field: 'category',
			align: 'center',
			filtering: true,
			defaultFilter: currentCategory,
			filterComponent: () =>
				dropDownFilter({
					value: currentCategory,
					valueList: noteCategoryItems,
					onChange: handleChangeCategory,
					width: '109%',
					name: 'category',
				}),
			render: useCallback(
				(data: Note) =>
					noteCategoryItems.find((el) => el.value === data.category)?.icon,
				[]
			),
		},
		{
			title: 'SIS Tags',
			field: 'tags',
			width: 'min-content',
			filtering: true,
			customFilterAndSearch: (filter: object, rowData: Note) =>
				filterForSISTags(filter, rowData),
			lookup: SisTagDict,
			filterCellStyle: {
				lineBreak: 'strict',
				overflowWrap: 'anywhere',
			},
			render: useCallback(
				(data: any) => returnTagChips(data.tags, 'SIS', null),
				[]
			),
		},
		{
			title: 'Gefühle',
			field: 'tags',
			filtering: true,
			sorting: false,
			align: 'center',
			customFilterAndSearch: (filter: object, rowData: Note) =>
				filterForEmotion(filter, rowData),
			lookup: EmotionTagDict,

			render: useCallback(
				(data: any) => returnTagChips(data.tags, 'EMOTION', null),
				[]
			),
		},
		{
			title: 'Autor:in',
			width: 'min-content',
			field: 'modifyingUser',
		},
		{
			title: 'Status',
			field: 'noteStatus',
			filtering: true,
			defaultFilter: currentNoteStatus,
			filterComponent: () => (
				<FormControl variant='outlined'>
					{Object.entries(noteStatusList).length !== 0 && (
						<Select
							variant='outlined'
							name='noteStatusName'
							value={currentNoteStatus}
							onChange={handleChangeNoteStatus}
							defaultValue={currentNoteStatus}>
							<MenuItem value={''}>
								{' '}
								<em>
									<b>Alle</b>{' '}
								</em>{' '}
							</MenuItem>
							{noteStatusList.map((el) => (
								<MenuItem key={el.noteStatusId} value={el.noteStatus}>
									{' '}
									{el.noteStatusName}{' '}
								</MenuItem>
							))}
						</Select>
					)}
				</FormControl>
			),
			render: useCallback(
				(data: Note) => {
					return (
						<Tooltip
							title={isAuthorized ? 'Aktualisiere den Status' : ''}
							placement='top'>
							<span>
								<Button
									disabled={!isAuthorized}
									onClick={() => handleClickOpenNoteStatusForm(data)}
									sx={{
										color: 'white',
										fontSize: 'medium',
										backgroundColor: determineStatusChipColor(data.noteStatus),
										'&:hover': {
											backgroundColor: 'darkgrey',
										},
									}}>
									{data.noteStatusName}
									<Edit sx={{ color: 'white' }} />
								</Button>
							</span>
						</Tooltip>
					);
				},
				[isAuthorized]
			),
		},
		{
			title: 'techValidFrom',
			field: 'techValidFrom',
			defaultSort: 'desc',
			sorting: true,
			hidden: true,
		},
	];

	return (
		<MaterialTable
			localization={{
				body: {
					emptyDataSourceMessage: 'Keine Erinnerungen hinterlegt.',
				},
			}}
			style={{
				borderRadius: '30px',
				fontSize: '12',
				color: grey[500],
			}}
			icons={MaterialTableIcons()}
			columns={columns}
			data={noteList.sort((a, b) =>
				stagesOfLifeList.findIndex((obj) => {
					return obj.name === a.stageOfLife;
				}) -
				stagesOfLifeList.findIndex((obj) => {
					return obj.name === b.stageOfLife;
				}),
			)}
			components={{
				Container: (props) => <Paper {...props} elevation={3} />,
				Row: (props) => (
					<MTableBodyRow key={props.noteId} id={props.noteId} {...props} />
				),
			}}
			options={{
				paging: false,
				showTitle: false,
				sorting: true,
				filtering: true,
				search: false,
				rowStyle: {
					fontSize: 'subtitle2',
				},
				headerStyle: {
					position: 'sticky',
					top: 0,
					backgroundColor: 'white',
				},
				maxBodyHeight: viewportHeight(80),
			}}
		/>
	);
}
