import { useCallback } from 'react';
import {
	Paper,
	Button,
	Tooltip,
	Typography,
	SelectChangeEvent,
	Badge,
} from '@mui/material';
import MaterialTable, { Column } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import {
	AddAPhoto,
	Delete,
	Edit,
	GroupAdd,
	PlayArrow,
} from '@mui/icons-material';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { ResidenceGroup } from '../generated';
import {
	abbreviateString,
	dropDownFilter,
	groupCategoryItems,
	viewportHeight,
} from './format';
import React from 'react';
import grey from '@mui/material/colors/grey';

interface GroupTableViewProps {
	groupList: ResidenceGroup[];
	isAuthorized: boolean;
	handleClickOpenGroupForm: any;
	handleClickOpenResidentToGroupForm: any;
	handleDeleteGroup: any;
	handleClickOpenMediaFileForm: any;
	handleAddLinkToMediaFile: any;
	handleClickOpenMediaAlbumForm: any;
}

export default function GroupTableView({
	groupList,
	isAuthorized,
	handleClickOpenGroupForm,
	handleClickOpenResidentToGroupForm,
	handleDeleteGroup,
	handleClickOpenMediaAlbumForm,
	handleClickOpenMediaFileForm,
	handleAddLinkToMediaFile,
}: GroupTableViewProps) {
	const [currentGroupCategory, setCurrentGroupCategory] =
		React.useState(undefined);
	const handleChangeGroupCategory = (group: SelectChangeEvent) => {
		setCurrentGroupCategory(group.target.value);
	};

	const getTooltipTitle = (group: ResidenceGroup) => {
		return group?.residenceGroupCategory
			? groupCategoryItems?.find(
				(el) => el.value === group?.residenceGroupCategory
			)?.name
			: '';
	};

	const columns: Column<ResidenceGroup>[] = [
		{
			title: 'Gruppe',
			width: 'max-content',
			field: 'name',
			render: useCallback(
				(data: ResidenceGroup) => (
					<Typography variant='h6' color={grey[500]}>
						{data.name}
					</Typography>
				),
				[]
			),
		},
		{
			title: 'Motto',
			width: '40%',
			field: 'motto',
			render: useCallback(
				(data: ResidenceGroup) => (
					<Typography variant='h6' color={grey[500]}>
						{abbreviateString(data?.motto, 80)}
					</Typography>
				),
				[]
			),
		},
		{
			title: 'Kategorie',
			field: 'residenceGroupCategory',
			align: 'center',
			filtering: true,
			defaultFilter: currentGroupCategory,
			filterComponent: () =>
				dropDownFilter({
					value: currentGroupCategory,
					valueList: groupCategoryItems,
					onChange: handleChangeGroupCategory,
					width: '90%',
					name: 'residenceGroupCategory',
				}),
			render: useCallback((data: ResidenceGroup) => {
				return (
					<Tooltip title={getTooltipTitle(data)}>
						<span>
							{
								groupCategoryItems.find(
									(el) => el.value === data?.residenceGroupCategory
								)?.icon
							}
						</span>
					</Tooltip>
				);
			}, []),
		},
		{
			title: 'Unterkategorie',
			field: 'residenceGroupSubCategory',
			align: 'center',
			filtering: true,
		},
		{
			title: 'Autor:in',
			width: 'min-content',
			field: 'modifyingUser',
		},
		{
			title: 'Medien',
			field: 'media',
			filtering: false,
			sorting: false,
			render: useCallback((residenceGroup: ResidenceGroup) => {
				return (
					<React.Fragment>
						<Tooltip title={isAuthorized ? 'Medien anzeigen' : ''}>
							<span>
								<Button
									disabled={
										!isAuthorized || residenceGroup.residenceMedia?.length === 0
									}
									onClick={() => handleClickOpenMediaAlbumForm(residenceGroup)}>
									<Badge
										badgeContent={residenceGroup.residenceMedia?.length}
										color='primary'>
										<PlayArrow
											color={
												isAuthorized &&
													residenceGroup.residenceMedia?.length > 0
													? 'primary'
													: 'secondary'
											}
										/>
									</Badge>
								</Button>
							</span>
						</Tooltip>
					</React.Fragment>
				);
			}, []),
		},
		{
			title: 'Mitglieder',
			field: 'associatedResidents',
			sorting: false,
			filtering: false,
			render: useCallback((residenceGroup: ResidenceGroup) => {
				return (
					<React.Fragment>
						<Tooltip
							title={isAuthorized ? 'Mitglieder anzeigen/hinzufügen' : ''}>
							<span>
								<Button
									disabled={!isAuthorized}
									onClick={() =>
										handleClickOpenResidentToGroupForm(residenceGroup)
									}>
									<Badge
										badgeContent={
											residenceGroup.associatedResidents?.length ?? 0
										}
										color='primary'>
										<GroupAdd
											style={{ transform: 'matrix(-1.0, 0, 0,1,0,1)' }}
											color={
												isAuthorized &&
													residenceGroup.associatedResidents?.length > 0
													? 'primary'
													: 'secondary'
											}
										/>
									</Badge>
								</Button>
							</span>
						</Tooltip>
					</React.Fragment>
				);
			}, []),
		},
		{
			title: 'Hochgeladen am',
			field: 'techValidFrom',
			defaultSort: 'desc',
			sorting: true,
			hidden: true,
		},
		{
			sorting: false,
			render: useCallback(
				(group: ResidenceGroup) => {
					return (
						<React.Fragment>
							<Tooltip title={isAuthorized ? 'Bearbeite Gruppe' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() => handleClickOpenGroupForm(false, group)}>
										<Edit color={isAuthorized ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={isAuthorized ? 'Archiviere Gruppe' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() => handleDeleteGroup(group.residenceGroupId)}>
										<Delete color={isAuthorized ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
							<Tooltip
								title={isAuthorized ? 'Medium hochladen und verknüpfen' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() => handleClickOpenMediaFileForm(group)}>
										<AddAPhoto color={isAuthorized ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={isAuthorized ? 'Medien verknüpfen' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() => handleAddLinkToMediaFile(group)}>
										<AddLinkIcon
											color={isAuthorized ? 'primary' : 'secondary'}
										/>
									</Button>
								</span>
							</Tooltip>
						</React.Fragment>
					);
				},
				[isAuthorized]
			),
		},
	];

	return (
		<MaterialTable
			localization={{
				body: {
					emptyDataSourceMessage: 'Keine Gruppe hinterlegt.',
				},
			}}
			style={{
				borderRadius: '30px',
				fontSize: '12',
				color: grey[500],
			}}
			icons={MaterialTableIcons()}
			columns={columns}
			data={groupList}
			components={{
				Container: (props) => <Paper {...props} elevation={3} />,
			}}
			options={{
				paging: false,
				showTitle: false,
				sorting: true,
				filtering: true,
				search: false,
				rowStyle: {
					fontSize: 'subtitle2',
				},
				headerStyle: {
					position: 'sticky',
					top: 0,
					backgroundColor: 'white',
				},
				maxBodyHeight: viewportHeight(80),
			}}
		/>
	);
}
