import React from 'react';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	NativeSelect,
	TextField,
	Typography,
} from '@mui/material';

import { ErrorAlert, SuccessAlert } from './Alerts';
import keycloak, { getNetworkApi } from './keycloak';
import { Resident, ResidentMarkedForDeletion, Unit } from '../generated/models';
import { PostResidentDeleteReservationRequest } from '../generated/apis/DefaultApi';
import Confirm from './Confirm';
import { isEmptyField } from './format';
import TabPanel from './TabPanel';
import { KeycloakTokenParsed } from 'keycloak-js';

export default function ResidentRemovalPanel({ value, index }) {
	const [residentsMarkedForDeletionList, setResidentsMarkedForDeletionList] =
		useState([] as ResidentMarkedForDeletion[]);
	const [refresh, setRefresh] = useState(0);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [unitList, setUnitList] = useState([] as Unit[]);
	const [unitIndex, setUnitIndex] = useState({} as number);
	const [residentList, setResidentList] = useState([] as Resident[]);

	type ParsedToken = KeycloakTokenParsed & {
		tenantIdentifier?: string;
	};

	const parsedToken: ParsedToken | undefined = keycloak?.idTokenParsed;

	const tenantIdentifier: string = parsedToken.tenantIdentifier;

	const [deleteResident, setDeleteResident] = useState({
		tenantIdentifier: tenantIdentifier,
		residentId: undefined,
		residentDeleteReservationInsertParameters: { comment: '' },
	} as PostResidentDeleteReservationRequest);
	const [showConfirmMarkForDeletion, setShowConfirmMarkForDeletion] =
		useState(false);
	const [deleteText, setDeleteText] = useState('');

	const handleUnitChange = (e) => {
		setUnitIndex(e.target.value);
	};

	const handleDeleteResidentChange = (e) => {
		const { name, value } = e.target;
		name === 'comment'
			? setDeleteResident({
					...deleteResident,
					residentDeleteReservationInsertParameters: {
						[name]: value,
					},
			  })
			: setDeleteResident({
					...deleteResident,
					[name]: value,
			  });
	};

	const handleMarkeResidentforDeletion = () => {
		setShowConfirmMarkForDeletion(true);
		setDeleteText('Wollen Sie wirklich Bewohner:in zum Löschen vormerken?');
	};

	useEffect(() => {
		const loadDeletionList = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidentsMarkedForDeletion(
					tenantIdentifier
				);
				setResidentsMarkedForDeletionList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log(
							'There was an error fetching the Marked for Deletion data!'
						);
					}
				} else {
					console.log(
						'There was an error fetching the Marked for Deletion data!'
					);
				}
			}
		};
		trackPromise(loadDeletionList());
	}, [tenantIdentifier, showSuccessAlert, refresh]);

	useEffect(() => {
		const loadUnits = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUnits(tenantIdentifier);
				setUnitList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'Wohnbereichinformationen konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Wohnbereichinformationen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUnits());
	}, []);

	useEffect(() => {
		const loadResidentData = async () => {
			const api = getNetworkApi();
			if (unitList[unitIndex].tenantIdentifier !== undefined) {
				try {
					const result = await api.getResidentList(
						unitList[unitIndex].tenantIdentifier,
						unitList[unitIndex].residenceIdentifier,
						unitList[unitIndex].unitIdentifier
					);
					setResidentList(result);
					setRefresh(refresh + 1);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Bewohner:innen konnten nicht abgerufen werden');
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Bewohner:innen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadResidentData());
	}, [unitIndex, unitList, showSuccessAlert, refresh]);

	const markResidentforDeletion = async () => {
		const client = getNetworkApi();
		try {
			await client.postResidentDeleteReservation(
				deleteResident.tenantIdentifier,
				deleteResident.residentId,
				{
					comment:
						deleteResident.residentDeleteReservationInsertParameters.comment ===
						void 0
							? null
							: deleteResident.residentDeleteReservationInsertParameters
									.comment,
				}
			);
			setAlertText(
				'Bewohner:in wurde erfolgreich markiert zur endgültigen Löschung'
			);
			setShowConfirmMarkForDeletion(false);
			setShowSuccessAlert(true);
			setRefresh(refresh + 1);
		} catch (error) {
			setAlertText('Bewohner:in wurde nicht markiert zur endgültigen Löschung');
			setShowErrorAlert(true);
		}
	};

	const isValidInput = () => {
		if (!isEmptyField(unitIndex) && !isEmptyField(deleteResident.residentId)) {
			return true;
		}
		return false;
	};

	return (
		<React.Fragment>
			<TabPanel value={value} index={index}>
				<Grid item xs={12}>
					<Typography>
						Merke eine:n Bewohner:In zur Löschung vor. Zur endgültigen
						Entfernung aller Daten muss ein:e Kollege:In zustimmen.
					</Typography>
					<Grid item xs={6} sx={{ mt: 4 }}>
						<Grid container direction={'column'} spacing={4}>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel variant='standard' htmlFor='uncontrolled-native'>
										Wohnbereich auswählen
									</InputLabel>
									<NativeSelect
										onChange={handleUnitChange}
										inputProps={{
											name: 'unit',
											id: 'uncontrolled-native',
										}}
										sx={{ color: 'green' }}>
										<option aria-label='None' value='' />
										{unitList.map((el, index) => (
											<option key={index} value={index}>
												{el.residenceIdentifier} - {el.unitIdentifier}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl fullWidth>
									<InputLabel variant='standard' htmlFor='uncontrolled-native'>
										Bewohner:In auswählen
									</InputLabel>
									<NativeSelect
										onChange={handleDeleteResidentChange}
										inputProps={{
											name: 'residentId',
											id: 'uncontrolled-native',
										}}
										sx={{ color: 'green' }}>
										<option aria-label='None' value='' />
										{residentList
											.filter(
												(val) =>
													!residentsMarkedForDeletionList
														.map((el) => el.residentId)
														.includes(val.residentId)
											)
											.filter(
												(el) =>
													el.unitIdentifier ===
													unitList[unitIndex]?.unitIdentifier
											)
											.map((el, index) => (
												<option key={el.residentId} value={el.residentId}>
													{el.surname}, {el.firstName} (Zimmer: {el.room})
												</option>
											))}
									</NativeSelect>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									label='Erläuterung'
									name='comment'
									multiline
									maxRows={4}
									id='outlined-size-normal'
									value={
										deleteResident.residentDeleteReservationInsertParameters
											.comment
									}
									onChange={handleDeleteResidentChange}
									inputProps={{ maxLength: 500 }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} sx={{ mt: 5 }}>
						<Button
							variant='contained'
							onClick={handleMarkeResidentforDeletion}
							disabled={!isValidInput()}>
							Bewohner:In zum Löschen vormerken
						</Button>
					</Grid>
				</Grid>
			</TabPanel>
			{showConfirmMarkForDeletion && (
				<Confirm
					open={showConfirmMarkForDeletion}
					setOpen={setShowConfirmMarkForDeletion}
					text={deleteText}
					executefct={markResidentforDeletion}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
