import React, { useCallback, useState } from 'react';

import { Close, Delete, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Paper,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { getNetworkApi } from './keycloak';

import {
	ConnectData,
	ConnectInsertParameters,
	ConnectType,
} from '../generated';
import { formatDate, ResidentIdData } from './format';
import { MaterialTableIcons } from '../MaterialTableIcons';
import MaterialTable, { Column, MTableBodyRow } from '@material-table/core';
import Confirm from './Confirm';

interface ConnectionFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	residentIdData: ResidentIdData;
	connectType: ConnectType;
	databaseId: number;
	currentConnectDataList: ConnectData[];
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ConnectionForm(prop: ConnectionFormProp) {
	const {
		open,
		setOpen,
		residentIdData,
		connectType,
		databaseId,
		currentConnectDataList,
		setAlertText,
		setShowSuccessAlert,
		setShowErrorAlert,
	} = prop;

	const [newConnectData, setNewConnectData] = useState(
		undefined as ConnectData
	);

	const [connectIdForDeletion, setConnectIdForDeletion] = useState(
		undefined as number
	);

	const [deleteText, setDeleteText] = useState('');

	const [showConfirmConnectDataDeletion, setShowConfirmConnectDataDeletion] =
		useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setNewConnectData({
			...newConnectData,
			[name]: value,
		});
	};

	const handleDeleteConnectData = (connectId: number) => {
		setDeleteText('Wollen Sie den Connect wirklich archivieren?');
		setConnectIdForDeletion(connectId);
		setShowConfirmConnectDataDeletion(true);
	};

	const saveConnectData = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let connectInsertParamenters: ConnectInsertParameters = {
			databaseId: databaseId,
			comment: newConnectData?.comment,
			connectType: connectType,
		};

		try {
			await client.postConnect(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				connectInsertParamenters
			);
			setAlertText('Connect wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Connect wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const deleteConnectData = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.deactivateConnect(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				connectIdForDeletion
			);
			setShowSuccessAlert(true);
			setAlertText('Connect wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Connect konnte nicht archiviert werden');
			console.log('There was an error while deactivating the connect!');
		}
	};

	const isAuthorized: boolean = true;

	const columns: Column<ConnectData>[] = [
		{
			title: 'Von',
			width: 'max-content',
			field: 'modifyingUser',
		},
		{
			title: 'Datum',
			width: 'max-content',
			field: 'techValidFrom',
			type: 'date',
			filtering: false,
			defaultSort: 'desc',
			render: useCallback(
				(connectData) => formatDate(connectData?.techValidFrom),
				[]
			),
		},
		{
			title: 'Kommentar',
			width: 'max-content',
			field: 'comment',
		},
		{
			sorting: false,
			width: 'min-content',
			render: useCallback(
				(connectData: ConnectData) => {
					return (
						<React.Fragment>
							<Tooltip title={isAuthorized ? 'Archiviere Connect' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() =>
											handleDeleteConnectData(connectData?.connectId)
										}>
										<Delete color={isAuthorized ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
						</React.Fragment>
					);
				},
				[isAuthorized]
			),
		},
	];

	return (
		<React.Fragment>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
				<DialogTitle>{'Connect hinzufügen'}</DialogTitle>
				<div />
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									multiline
									rows={2}
									label='Kommentar (optional)'
									name='comment'
									id='outlined-size-normal'
									value={newConnectData?.comment}
									onChange={handleInputChange}
									inputProps={{ maxLength: 250 }}
								/>
							</Grid>

							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} Abbrechen
										</Button>
									</Grid>
									<Grid item>
										<Button variant='contained' onClick={saveConnectData}>
											{<Save />} Speichern
										</Button>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}></Grid>
							{currentConnectDataList?.length > 0 && (
								<Grid item xs={12}>
									<MaterialTable
										localization={{
											body: {
												emptyDataSourceMessage: 'Keine Connects hinterlegt.',
											},
										}}
										icons={MaterialTableIcons()}
										columns={columns}
										data={currentConnectDataList}
										components={{
											Container: (props) => <Paper {...props} elevation={0} />,
											Row: (props) => (
												<MTableBodyRow
													key={props.title}
													id={props.title}
													{...props}
												/>
											),
										}}
										options={{
											paging: false,
											showTitle: false,
											sorting: true,
											filtering: true,
											search: false,
											rowStyle: { fontSize: 22 },
										}}
									/>
								</Grid>
							)}
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
			{showConfirmConnectDataDeletion && (
				<Confirm
					open={showConfirmConnectDataDeletion}
					setOpen={setShowConfirmConnectDataDeletion}
					text={deleteText}
					executefct={deleteConnectData}
				/>
			)}
		</React.Fragment>
	);
}
