import Keycloak from 'keycloak-js';
import {
	Configuration,
	ConfigurationParameters,
	DefaultApi,
} from '../generated';

const keycloakConfig: Keycloak.KeycloakConfig = {
	realm:
		process.env.NODE_ENV === 'development'
			? process.env.REACT_APP_KEYCLOAK_REALM
			: window.env.KEYCLOAK_REALM,
	url:
		process.env.NODE_ENV === 'development'
			? process.env.REACT_APP_KEYCLOAK_URL
			: window.env.KEYCLOAK_URL,
	clientId:
		process.env.NODE_ENV === 'development'
			? process.env.REACT_APP_KEYCLOAK_CLIENT_ID
			: window.env.KEYCLOAK_CLIENT_ID,
};

const keycloak = Keycloak(keycloakConfig);

/**
 * Create a Configuration object with the backend server
 */
export const getNetworkApi = () => {
	const params: ConfigurationParameters = {
		basePath:
			process.env.NODE_ENV === 'development'
				? process.env.REACT_APP_API_BASEURL
				: window.env.API_BASEURL,
		accessToken: 'Bearer ' + keycloak.token,
		headers: {
			Authorization: 'Bearer ' + keycloak.token,
			Accept:
				'application/json, image/png, image/jpeg, text/plain, application/pdf',
		},
	};
	const configuration = new Configuration(params);
	return new DefaultApi(configuration);
};

export default keycloak;
