/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectData,
    ConnectDataFromJSON,
    ConnectDataFromJSONTyped,
    ConnectDataToJSON,
    ResidenceEventCategory,
    ResidenceEventCategoryFromJSON,
    ResidenceEventCategoryFromJSONTyped,
    ResidenceEventCategoryToJSON,
    ResidenceEventMedia,
    ResidenceEventMediaFromJSON,
    ResidenceEventMediaFromJSONTyped,
    ResidenceEventMediaToJSON,
    Resident,
    ResidentFromJSON,
    ResidentFromJSONTyped,
    ResidentToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResidenceEvent
 */
export interface ResidenceEvent {
    /**
     * 
     * @type {number}
     * @memberof ResidenceEvent
     */
    residenceEventId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResidenceEvent
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceEvent
     */
    description?: string;
    /**
     * 
     * @type {ResidenceEventCategory}
     * @memberof ResidenceEvent
     */
    residenceEventCategory?: ResidenceEventCategory;
    /**
     * 
     * @type {string}
     * @memberof ResidenceEvent
     */
    residenceEventSubCategory?: string;
    /**
     * 
     * @type {Date}
     * @memberof ResidenceEvent
     */
    eventDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ResidenceEvent
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Array<ConnectData>}
     * @memberof ResidenceEvent
     */
    connects?: Array<ConnectData>;
    /**
     * 
     * @type {Array<ResidenceEventMedia>}
     * @memberof ResidenceEvent
     */
    residenceMedia?: Array<ResidenceEventMedia>;
    /**
     * 
     * @type {Array<Resident>}
     * @memberof ResidenceEvent
     */
    associatedResidents?: Array<Resident>;
}

export function ResidenceEventFromJSON(json: any): ResidenceEvent {
    return ResidenceEventFromJSONTyped(json, false);
}

export function ResidenceEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'residenceEventId': !exists(json, 'residenceEventId') ? undefined : json['residenceEventId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'residenceEventCategory': !exists(json, 'residenceEventCategory') ? undefined : ResidenceEventCategoryFromJSON(json['residenceEventCategory']),
        'residenceEventSubCategory': !exists(json, 'residenceEventSubCategory') ? undefined : json['residenceEventSubCategory'],
        'eventDate': !exists(json, 'eventDate') ? undefined : (new Date(json['eventDate'])),
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'connects': !exists(json, 'connects') ? undefined : ((json['connects'] as Array<any>).map(ConnectDataFromJSON)),
        'residenceMedia': !exists(json, 'residenceMedia') ? undefined : ((json['residenceMedia'] as Array<any>).map(ResidenceEventMediaFromJSON)),
        'associatedResidents': !exists(json, 'associatedResidents') ? undefined : ((json['associatedResidents'] as Array<any>).map(ResidentFromJSON)),
    };
}

export function ResidenceEventToJSON(value?: ResidenceEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'residenceEventId': value.residenceEventId,
        'title': value.title,
        'description': value.description,
        'residenceEventCategory': ResidenceEventCategoryToJSON(value.residenceEventCategory),
        'residenceEventSubCategory': value.residenceEventSubCategory,
        'eventDate': value.eventDate === undefined ? undefined : (value.eventDate.toISOString().substr(0,10)),
        'modifyingUser': value.modifyingUser,
        'connects': value.connects === undefined ? undefined : ((value.connects as Array<any>).map(ConnectDataToJSON)),
        'residenceMedia': value.residenceMedia === undefined ? undefined : ((value.residenceMedia as Array<any>).map(ResidenceEventMediaToJSON)),
        'associatedResidents': value.associatedResidents === undefined ? undefined : ((value.associatedResidents as Array<any>).map(ResidentToJSON)),
    };
}


