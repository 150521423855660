import React, { useCallback, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	Grid,
	ThemeProvider,
	useMediaQuery,
	useTheme
} from '@mui/material';
import Paper from '@mui/material/Paper';
import {formatDate} from '../components/format';
import { Close } from '@mui/icons-material';
import { viewportHeight } from './format';
import { Resident } from '../generated';
import MaterialTable, { Column } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { grey } from '@mui/material/colors';

interface ResidentsFromFileFormProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	residentsFromFile: Resident[];
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ResidentsFromFileForm({
	open,
	setOpen,
	residentsFromFile,

}: ResidentsFromFileFormProps) {
	const [materialTableLocalization] = useState({
		body: {
			emptyDataSourceMessage: 'Keine Nutzerinnen und Nutzer importiert.',
		},
		pagination: {
			labelDisplayedRows: '{from}-{to} von {count}',
			labelRowsSelect: 'Zeilen',
			labelRowsPerPage: 'Zeilen pro Seite:',
		},
	});


	const handleClose = () => {
		setOpen(false);
	};

	
	const newResidentColumns: Column<Resident>[] = [
		{
			title: 'Vorname',
			field: 'firstName',
			sorting: true,
		},
        {
			title: 'Nachname',
			field: 'surname',
			sorting: true,
		},
        {
			title: 'Wohnbereich',
			field: 'unitIdentifier',
			sorting: true,
		},
        {
			title: 'Bezugsperson',
			field: 'associatedCaregiver',
			sorting: true,
		},
        {
			title: 'Geburtstag',
			field: 'birthday',
            type: "date",
			sorting: true,
            render: useCallback((data) => formatDate(data.birthday), []),
		},
        
	
	];

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					maxWidth='md'>
					<DialogTitle>{'Update der folgenden Bewohner:innen konnte nicht durchgeführt werden:'}</DialogTitle>
					<DialogContent>
						<Grid container spacing={2} paddingTop={2}>
							<Grid item xs={12}>
								<MaterialTable
									localization={materialTableLocalization}
									style={{ borderRadius: '30px', color: grey[500] }}
									icons={MaterialTableIcons()}
									columns={newResidentColumns}
									data={residentsFromFile}
									components={{
										Container: (props) => <Paper {...props} elevation={3} />,
									}}
									options={{
										paging: true,
										showTitle: false,
										sorting: true,
										filtering: false,
										search: false,
										rowStyle: { fontSize: 16 },
										headerStyle: {
											position: 'sticky',
											top: 0,
											backgroundColor: 'white',
										},
										maxBodyHeight: viewportHeight(80),
										showTextRowsSelected: false,
									}}
								/>
							</Grid>
							
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} {'Ok'}
										</Button>
									</Grid>
									<Grid item>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
