/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResidenceMediaData
 */
export interface ResidenceMediaData {
    /**
     * 
     * @type {number}
     * @memberof ResidenceMediaData
     */
    residenceMediaId?: number;
    /**
     * 
     * @type {string}
     * @memberof ResidenceMediaData
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceMediaData
     */
    residenceIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceMediaData
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceMediaData
     */
    mediaTypeIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceMediaData
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof ResidenceMediaData
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof ResidenceMediaData
     */
    techValidFrom?: Date;
}

export function ResidenceMediaDataFromJSON(json: any): ResidenceMediaData {
    return ResidenceMediaDataFromJSONTyped(json, false);
}

export function ResidenceMediaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceMediaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'residenceMediaId': !exists(json, 'residenceMediaId') ? undefined : json['residenceMediaId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'residenceIdentifier': !exists(json, 'residenceIdentifier') ? undefined : json['residenceIdentifier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'mediaTypeIdentifier': !exists(json, 'mediaTypeIdentifier') ? undefined : json['mediaTypeIdentifier'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'techValidFrom': !exists(json, 'techValidFrom') ? undefined : (new Date(json['techValidFrom'])),
    };
}

export function ResidenceMediaDataToJSON(value?: ResidenceMediaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'residenceMediaId': value.residenceMediaId,
        'tenantIdentifier': value.tenantIdentifier,
        'residenceIdentifier': value.residenceIdentifier,
        'fileName': value.fileName,
        'mediaTypeIdentifier': value.mediaTypeIdentifier,
        'comment': value.comment,
        'modifyingUser': value.modifyingUser,
        'techValidFrom': value.techValidFrom === undefined ? undefined : (value.techValidFrom.toISOString().substr(0,10)),
    };
}


