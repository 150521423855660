/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectData,
    ConnectDataFromJSON,
    ConnectDataFromJSONTyped,
    ConnectDataToJSON,
    NoteCategory,
    NoteCategoryFromJSON,
    NoteCategoryFromJSONTyped,
    NoteCategoryToJSON,
    NoteComment,
    NoteCommentFromJSON,
    NoteCommentFromJSONTyped,
    NoteCommentToJSON,
    NoteMedia,
    NoteMediaFromJSON,
    NoteMediaFromJSONTyped,
    NoteMediaToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface Note
 */
export interface Note {
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    noteId?: number;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof Note
     */
    residentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    stageOfLife?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    pointInTime?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    noteStatusName?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    noteStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof Note
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof Note
     */
    techValidFrom?: Date;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Note
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {Array<NoteComment>}
     * @memberof Note
     */
    comments?: Array<NoteComment>;
    /**
     * 
     * @type {Array<NoteMedia>}
     * @memberof Note
     */
    media?: Array<NoteMedia>;
    /**
     * 
     * @type {Array<ConnectData>}
     * @memberof Note
     */
    connects?: Array<ConnectData>;
    /**
     * 
     * @type {NoteCategory}
     * @memberof Note
     */
    category?: NoteCategory;
    /**
     * 
     * @type {boolean}
     * @memberof Note
     */
    relevant?: boolean;
}

export function NoteFromJSON(json: any): Note {
    return NoteFromJSONTyped(json, false);
}

export function NoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Note {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'noteId': !exists(json, 'noteId') ? undefined : json['noteId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'stageOfLife': !exists(json, 'stageOfLife') ? undefined : json['stageOfLife'],
        'pointInTime': !exists(json, 'pointInTime') ? undefined : json['pointInTime'],
        'noteStatusName': !exists(json, 'noteStatusName') ? undefined : json['noteStatusName'],
        'noteStatus': !exists(json, 'noteStatus') ? undefined : json['noteStatus'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'techValidFrom': !exists(json, 'techValidFrom') ? undefined : (new Date(json['techValidFrom'])),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'comments': !exists(json, 'comments') ? undefined : ((json['comments'] as Array<any>).map(NoteCommentFromJSON)),
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(NoteMediaFromJSON)),
        'connects': !exists(json, 'connects') ? undefined : ((json['connects'] as Array<any>).map(ConnectDataFromJSON)),
        'category': !exists(json, 'category') ? undefined : NoteCategoryFromJSON(json['category']),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function NoteToJSON(value?: Note | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'noteId': value.noteId,
        'tenantIdentifier': value.tenantIdentifier,
        'residentId': value.residentId,
        'title': value.title,
        'content': value.content,
        'stageOfLife': value.stageOfLife,
        'pointInTime': value.pointInTime,
        'noteStatusName': value.noteStatusName,
        'noteStatus': value.noteStatus,
        'modifyingUser': value.modifyingUser,
        'techValidFrom': value.techValidFrom === undefined ? undefined : (value.techValidFrom.toISOString().substr(0,10)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'comments': value.comments === undefined ? undefined : ((value.comments as Array<any>).map(NoteCommentToJSON)),
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(NoteMediaToJSON)),
        'connects': value.connects === undefined ? undefined : ((value.connects as Array<any>).map(ConnectDataToJSON)),
        'category': NoteCategoryToJSON(value.category),
        'relevant': value.relevant,
    };
}


