import React, { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Close, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	useMediaQuery,
	useTheme,
	Tooltip,
	FormGroup,
	FormLabel,
	FormControlLabel,
	Checkbox,
	FormControl,
	RadioGroup,
	Radio
} from '@mui/material';
import { getNetworkApi } from './keycloak';
import { Tag } from '../generated/models/Tag';
import { isEmptyField, isTagTicked, ResidentIdData } from './format';
import { Greeting } from '../generated/models/Greeting';
import { GreetingInsertParameters } from '../generated/models/GreetingInsertParameters';
import { GreetingUpdateParameters } from '../generated/models/GreetingUpdateParameters';

interface GreetingFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isNewGreeting: boolean;
	displayGreeting: boolean;
	residentIdData: ResidentIdData;
	selectedGreeting: Greeting;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function GreetingForm(prop: GreetingFormProp) {
	const {
		open,
		setOpen,
		isNewGreeting,
		displayGreeting,
		residentIdData,
		selectedGreeting,
		setAlertText,
		setShowSuccessAlert,
		setShowErrorAlert,
	} = prop;

	const [greeting, setGreeting] = useState(selectedGreeting);
	const [Tags, setTags] = useState([] as Tag[]);
	const [TagValues, setTagValues] = useState(greeting.tags as Tag[]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setGreeting({
			...greeting,
			[name]: value,
		});
	};

	const handleRadioChange = (event) => {
	setGreeting((prevGreeting) => ({
		...prevGreeting,
		relevant: event.target.value === "true"
	}));
	};

	const saveGreeting = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let greetingInsertParameters: GreetingInsertParameters = {
			title: greeting.title,
			comment: greeting?.comment,
			greetingPersonName: greeting.greetingPersonName,
			tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
			relevant: greeting.relevant
		};
		try {
			await client.postResidentGreeting(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				greetingInsertParameters
			);
			setAlertText('Grüße wurden erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Grüße wurden nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const getTagValue = (e) => {
		if (Object.entries(TagValues).length !== 0) {
			if (e.target.checked) {
				setTagValues((prevTags) => [
					...prevTags,
					{ tagId: Number(e.target.value) },
				]);
			} else {
				setTagValues(
					TagValues.filter((item) => item.tagId !== Number(e.target.value))
				);
			}
		} else {
			setTagValues([{ tagId: Number(e.target.value) }]);
		}
	};

	const updateGreeting = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let greetingUpdateParameters: GreetingUpdateParameters = {
			title: greeting.title,
			comment: greeting?.comment,
			greetingPersonName: greeting.greetingPersonName,
			tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
			relevant: greeting.relevant
		};
		try {
			await client.putResidentGreeting(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				greeting.greetingId,
				greetingUpdateParameters
			);
			setAlertText('Grüße wurden erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Grüße wurden nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const isSaveButtonEnabled = (greeting: Greeting) => {
		return (
			!isEmptyField(greeting?.title) &&
			!isEmptyField(greeting?.greetingPersonName)
		);
	};

	useEffect(() => {
		const loadTags = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getTags(residentIdData.tenantIdentifier);
				setTags(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the available Tags data!');
					}
				} else {
					console.log('There was an error fetching the available Tags data!');
				}
			}
		};
		trackPromise(loadTags());
	}, [residentIdData.tenantIdentifier]);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth={'md'}>
				<DialogTitle>
					{displayGreeting
						? 'Gruß anzeigen'
						: [isNewGreeting ? 'Grüße anlegen' : 'Grüße bearbeiten']}
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Titel '
								name='title'
								id='outlined-size-normal'
								value={greeting?.title}
								onChange={handleInputChange}
								inputProps={{ maxLength: 1000 }}
								required
								disabled={displayGreeting}
							/>
						</Grid>
						<Grid item xs={6} marginTop={1}>
							<TextField
								fullWidth
								label='Absender'
								name='greetingPersonName'
								id='outlined-size-normal'
								value={greeting?.greetingPersonName}
								onChange={handleInputChange}
								inputProps={{ maxLength: 100 }}
								required
								disabled={displayGreeting}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								multiline
								rows={10}
								label='Kommentar'
								name='comment'
								id='outlined-size-normal'
								value={greeting?.comment}
								onChange={handleInputChange}
								inputProps={{ maxLength: 10000 }}
								disabled={displayGreeting}
							/>
						</Grid>
						<Grid item xs={12}>
								<FormControl>
									<Tooltip
										title='Tags bzgl. der Strukturierten Informationssammlung'
										placement='top'>
										<FormLabel>SIS Tag</FormLabel>
									</Tooltip>
									<FormGroup>
										<Grid container columnSpacing={2} rowSpacing={0}>
											{Tags.filter((el) => el.tagGroupIdentifier === 'SIS').map(
												(item, index) => (
													<Grid item xs={4} key={index}>
														<FormControlLabel
															key={item.tagId}
															value={item.tagId}
															checked={isTagTicked(TagValues, item.tagId)}
															control={<Checkbox />}
															label={item.tagAbbreviation}
															onChange={getTagValue}
															disabled={displayGreeting}
														/>
													</Grid>
												)
											)}
										</Grid>
									</FormGroup>
								</FormControl>
						</Grid>
						<Grid item xs={12}>
						<FormControl>
							<Tooltip title="Pflegerelevant?" placement="top">
							<FormLabel>Pflegerelevant?</FormLabel>
							</Tooltip>
							<FormControl>
									<RadioGroup
										row
										aria-labelledby="demo-controlled-radio-buttons-group"
										name="controlled-radio-buttons-group"
										value={greeting.relevant}
										onChange={handleRadioChange}
									>
								<FormControlLabel value={true} disabled={displayGreeting}  control={<Radio />} label="Ja" />
								<FormControlLabel value={false} disabled={displayGreeting} control={<Radio />} label="Nein" />
							</RadioGroup>
							</FormControl>
						</FormControl>
						</Grid>
						<Grid item xs={12}>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
								spacing={2}>
								<Grid item>
									<Button variant='contained' onClick={handleClose}>
										{<Close />} {displayGreeting ? 'Schließen' : 'Abbrechen'}
									</Button>
								</Grid>
								{!displayGreeting && (
									<Grid item>
										<Button
											variant='contained'
											disabled={!isSaveButtonEnabled(greeting)}
											onClick={isNewGreeting ? saveGreeting : updateGreeting}>
											{<Save />} Speichern
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
