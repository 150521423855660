import { Alert, AlertTitle, Snackbar } from '@mui/material';

export function SuccessAlert({ text, open, setOpen }) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
			<Alert severity='success'>
				<AlertTitle>Erfolg</AlertTitle>
				{text}
			</Alert>
		</Snackbar>
	);
}

export function ErrorAlert({ text, open, setOpen }) {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Snackbar
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
			<Alert severity='error'>
				<AlertTitle>Fehler</AlertTitle>
				{text}
			</Alert>
		</Snackbar>
	);
}
