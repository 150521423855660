import { useCallback } from 'react';
import { Paper, Button, Tooltip, Badge } from '@mui/material';
import MaterialTable, { Column } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { AddAPhoto, Delete, Edit, PlayArrow } from '@mui/icons-material';
import AddLinkIcon from '@mui/icons-material/AddLink';

import { Greeting } from '../generated';
import {
	formatDate,
	TCCStyledBadgeButton,
	viewportHeight,
} from './format';
import React from 'react';
import { grey } from '@mui/material/colors';

interface GreetingsTableViewProps {
	greetingsList: Greeting[];
	handleClickOpenConnectionForm: any;
	handleClickOpenMediaAlbumForm: any;
	handleClickOpenGreetingsForm: any;
	handleDeleteGreeting: any;
	handleClickOpenMediaFileForm: any;
	handleAddLinkToMediaFile: any;
	isAuthorized: boolean;
}

export default function GreetingsTableView({
	greetingsList,
	handleClickOpenGreetingsForm,
	handleClickOpenConnectionForm,
	handleClickOpenMediaAlbumForm,
	handleDeleteGreeting,
	handleClickOpenMediaFileForm,
	handleAddLinkToMediaFile,
	isAuthorized,
}: GreetingsTableViewProps) {

	const columns: Column<any>[] = [
		{
			width: 'min-content',
			sorting: false,
			render: useCallback((greeting: Greeting) => {
				return (
					<TCCStyledBadgeButton
						badgeContent={greeting.connects.length}
						onClick={() => handleClickOpenConnectionForm(greeting)}
					/>
				);
			}, []),
		},
		{
			title: 'Titel',
			field: 'title',
			sorting: true,
		},
		{
			title: 'Kommentar',
			field: 'comment',
			sorting: false,
		},
		{
			title: 'Verfasser',
			field: 'greetingPersonName',
			sorting: false,
		},
		{
			title: 'Medien',
			field: 'media',
			sorting: false,
			render: useCallback((greeting: Greeting) => {
				return (
					<React.Fragment>
						<Tooltip title={isAuthorized ? 'Medien anzeigen' : ''}>
							<span>
								<Button
									disabled={!isAuthorized || greeting.media?.length === 0}
									onClick={() => handleClickOpenMediaAlbumForm(greeting)}>
									<Badge badgeContent={greeting.media?.length} color='primary'>
										<PlayArrow
											color={
												isAuthorized && greeting.media?.length > 0
													? 'primary'
													: 'secondary'
											}
										/>
									</Badge>
								</Button>
							</span>
						</Tooltip>
					</React.Fragment>
				);
			}, []),
		},
		{
			title: 'Hochgeladen am',
			field: 'techValidFrom',
			defaultSort: 'desc',
			sorting: true,
			render: useCallback(
				(greeting: Greeting) => formatDate(greeting.techValidFrom),
				[]
			),
		},
		{
			sorting: false,
			render: useCallback(
				(greeting: Greeting) => {
					return (
						<React.Fragment>
							<Tooltip title={isAuthorized ? 'Bearbeite Gruß' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() =>
											handleClickOpenGreetingsForm(false, greeting)
										}>
										<Edit color={isAuthorized ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={isAuthorized ? 'Archiviere Gruß' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() => handleDeleteGreeting(greeting.greetingId)}>
										<Delete color={isAuthorized ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
							<Tooltip
								title={isAuthorized ? 'Medium hochladen und verknüpfen' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() => handleClickOpenMediaFileForm(greeting)}>
										<AddAPhoto color={isAuthorized ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={isAuthorized ? 'Medien verknüpfen' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() => handleAddLinkToMediaFile(greeting)}>
										<AddLinkIcon
											color={isAuthorized ? 'primary' : 'secondary'}
										/>
									</Button>
								</span>
							</Tooltip>
						</React.Fragment>
					);
				},
				[isAuthorized]
			),
		},
	];

	return (
		<MaterialTable
			localization={{
				body: {
					emptyDataSourceMessage: 'Keine Grüße hinterlegt.',
				},
			}}
			style={{
				borderRadius: '30px',
				fontSize: '12',
				color: grey[500],
			}}
			icons={MaterialTableIcons()}
			columns={columns}
			data={greetingsList}
			components={{
				Container: (props) => <Paper {...props} elevation={3} />,
			}}
			options={{
				paging: false,
				showTitle: false,
				sorting: true,
				filtering: true,
				search: false,
				rowStyle: { fontSize: 'subtitle2' },
				headerStyle: {
					position: 'sticky',
					top: 0,
					backgroundColor: 'white',
				},
				maxBodyHeight: viewportHeight(80),
			}}
		/>
	);
}
