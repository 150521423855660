import React, { useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import LuggageIcon from '@mui/icons-material/Luggage';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FaceRetouchingOffIcon from '@mui/icons-material/FaceRetouchingOff';
import MailIcon from '@mui/icons-material/Mail';

import ConfirmResidentRemovalPanel from '../components/ConfirmResidentRemovalPanel';
import ResidentRelocationPanel from '../components/ResidentRelocationPanel';
import ResidentRemovalPanel from '../components/ResidentRemovalPanel';
import RetrieveEmailsPanel from '../components/RetrieveEmailsPanel';
import { trackPromise } from 'react-promise-tracker';
import keycloak, { getNetworkApi } from '../components/keycloak';
import { BaseRole } from '../components/Roles';
import { KeycloakTokenParsed } from 'keycloak-js';

export default function Admin(props) {
	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}
	const [userRoleList, setUserRoleList] = useState([]);

	type ParsedToken = KeycloakTokenParsed & {
		tenantIdentifier?: string;
	};

	const parsedToken: ParsedToken | undefined = keycloak?.idTokenParsed;

	let tenantIdentifier = parsedToken.tenantIdentifier;

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the data!');
					}
				} else {
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const isResidentAdminPermission = userRoleList.includes(
		'R-' + BaseRole.BEWOHNERADMINISTRATION + '-' + tenantIdentifier
	);

	const isDeletePermission = userRoleList.includes(
		'R-' + BaseRole.BEWOHNERDATENENTFERNUNG + '-' + tenantIdentifier
	);

	const isOutsideInputManagementPermission = userRoleList.includes(
		'R-' + BaseRole.VERWALTUNGEXTERNERINPUT + '-' + tenantIdentifier
	);

	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const getTabPanelsDependingOnPermissions = () => {
		let conditionalIndexEmailsPanel = 0;
		let conditionalIndexResidentRelocationPanel =
			isOutsideInputManagementPermission ? 1 : 0;
		let conditionalIndexResidentRemovalPanel =
			isOutsideInputManagementPermission ? 2 : 1;
		let conditionalIndexConfirmResidentRemovalPanel =
			isOutsideInputManagementPermission ? 3 : 2;

		let conditionalTabs = (
			<Tabs
				value={value}
				onChange={handleChange}
				variant='scrollable'
				scrollButtons='auto'
				aria-label='basic tabs example'>
				{isOutsideInputManagementPermission && (
					<Tab
						label='Emails abrufen'
						icon={<MailIcon />}
						{...a11yProps(conditionalIndexEmailsPanel)}
					/>
				)}
				<Tab
					label='Bewohner:In umziehen'
					icon={<LuggageIcon />}
					{...a11yProps(conditionalIndexResidentRelocationPanel)}
				/>
				{isDeletePermission && (
					<Tab
						label='Bewohner:In zum Löschen vormerken'
						icon={<FaceRetouchingOffIcon />}
						{...a11yProps(conditionalIndexResidentRemovalPanel)}
					/>
				)}
				{isDeletePermission && (
					<Tab
						label='Löschung bestätigen'
						icon={<CheckBoxIcon />}
						{...a11yProps(conditionalIndexConfirmResidentRemovalPanel)}
					/>
				)}
			</Tabs>
		);
		let conditionalPanels = (
			<>
				{isOutsideInputManagementPermission && (
					<RetrieveEmailsPanel
						value={value}
						index={conditionalIndexEmailsPanel}
					/>
				)}
				<ResidentRelocationPanel
					value={value}
					index={conditionalIndexResidentRelocationPanel}
				/>
				{isDeletePermission && (
					<ResidentRemovalPanel
						value={value}
						index={conditionalIndexResidentRemovalPanel}
					/>
				)}
				{isDeletePermission && (
					<ConfirmResidentRemovalPanel
						value={value}
						index={conditionalIndexConfirmResidentRemovalPanel}
					/>
				)}
			</>
		);

		return (
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					{conditionalTabs}
				</Box>
				{conditionalPanels}
			</Box>
		);
	};

	return (
		isResidentAdminPermission && (
			<React.Fragment>
				<Grid container direction='column' spacing={2} sx={{ padding: 5 }}>
					<Grid item xs={12}>
						{getTabPanelsDependingOnPermissions()}
					</Grid>
				</Grid>
			</React.Fragment>
		)
	);
}
