import * as React from 'react';
import { Close, Done, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
} from '@mui/material';

export default function QuestionCommentForm({
	open,
	setOpen,
	setReturnValuesList,
	currentQuestionId,
	currentComment,
	currentReturnValuesList,
	isEditResidentAssessment,
}) {
	const [CommentString, setCommentString] = React.useState(
		currentComment as string
	);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		setCommentString(e.target.value);
	};

	const saveQuestionComment = async () => {
		setCommentString(CommentString);
		if (isEditResidentAssessment) {
			let currentQuestionUpdateParameters: {
				assessedValue: number;
				comment: string;
			} = currentReturnValuesList;
			currentQuestionUpdateParameters.comment = CommentString;
			setReturnValuesList(currentQuestionUpdateParameters);
		} else {
			let newReturnValuesList: {
				id: number;
				assessedValue: number;
				comment: string;
			}[] = currentReturnValuesList;
			newReturnValuesList.find((obj) => obj.id === currentQuestionId).comment =
				CommentString;
			setReturnValuesList(newReturnValuesList);
		}
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{'Kommentar bearbeiten'}</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label='Kommentar'
									name='comment'
									id='outlined-size-normal'
									value={CommentString}
									onChange={handleInputChange}
									multiline
									rows={4}
									inputProps={{
										maxLength: 500,
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} Abbrechen
										</Button>
									</Grid>
									<Grid item>
										<Button variant='contained' onClick={saveQuestionComment}>
											{isEditResidentAssessment ? <Done /> : <Save />}
											{isEditResidentAssessment ? 'Ok' : 'Speichern'}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
