import { GroupAdd, PlayArrow } from '@mui/icons-material';
import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Divider,
	Tooltip,
	Button,
	Badge,
	CardActions,
} from '@mui/material';

import { ResidenceEvent } from '../generated';
import { abbreviateString, eventCategoryItems, formatDate } from './format';

interface EventCardProps {
	event: ResidenceEvent;
	onClickCard: React.MouseEventHandler<HTMLButtonElement>;
	onClickMediaAlbum: any;
	onClickResidentToEventForm: any;
	isAuthorized: boolean;
}

export default function EventCard({
	event,
	onClickCard,
	onClickMediaAlbum,
	onClickResidentToEventForm,
	isAuthorized,
}: EventCardProps) {
	let cardcolor = 'primary';

	const getTooltipTitle = () => {
		let part1 = event?.residenceEventCategory
			? eventCategoryItems?.find(
					(el) => el.value === event?.residenceEventCategory
			  )?.name
			: '';
		let part2 = event?.residenceEventSubCategory
			? ' (' + event.residenceEventSubCategory + ')'
			: '';
		return part1 + part2;
	};

	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 10, height: '100%' }}
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
			}}
			elevation={5}>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12}>
					<CardActionArea onClick={onClickCard}>
						<CardContent>
							<Tooltip
								title={getTooltipTitle() == null ? '' : getTooltipTitle()}>
								<Typography
									align='center'
									fontSize='24'
									variant='h6'
									color={cardcolor}>
									{abbreviateString(event.title, 30)}{' '}
									{event?.residenceEventCategory &&
										eventCategoryItems?.find(
											(el) => el.value === event?.residenceEventCategory
										)?.icon}
								</Typography>
							</Tooltip>
						</CardContent>
					</CardActionArea>
				</Grid>
				<Grid item xs={12}>
					<Divider variant='middle'>
						{event?.eventDate || event?.residenceEventSubCategory ? (
							<Typography fontSize={12} color={'primary'} variant={'body2'}>
								{' '}
								{formatDate(event?.eventDate)}
								{formatDate(event?.eventDate) &&
									event?.residenceEventSubCategory &&
									', '}
								{event?.residenceEventSubCategory}
							</Typography>
						) : (
							void 0
						)}
					</Divider>
				</Grid>
			</Grid>
			<CardActionArea onClick={onClickCard}>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={12}>
							<Typography
								fontSize={16}
								color={'primary'}
								variant={'body2'}
								justifyContent={'center'}
								marginTop={2}
								marginBottom={2}
								align={'left'}
								style={{ wordWrap: 'break-word' }}>
								{abbreviateString(event?.description, 60)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12}>
					<CardActions sx={{ justifyContent: 'center' }}>
						<Tooltip title={isAuthorized ? 'Medien anzeigen' : ''}>
							<span>
								<Button
									disabled={!isAuthorized || event.residenceMedia?.length === 0}
									onClick={onClickMediaAlbum}>
									<Badge
										badgeContent={event.residenceMedia?.length}
										color='primary'>
										<PlayArrow
											color={
												isAuthorized && event.residenceMedia?.length > 0
													? 'primary'
													: 'secondary'
											}
										/>
									</Badge>
								</Button>
							</span>
						</Tooltip>
						<Tooltip
							title={isAuthorized ? 'Teilnehmer anzeigen/hinzufügen' : ''}>
							<span>
								<Button
									disabled={!isAuthorized}
									onClick={() => onClickResidentToEventForm(event)}>
									<Badge
										badgeContent={event.associatedResidents?.length ?? 0}
										color='primary'>
										<GroupAdd
											style={{ transform: 'matrix(-1.0, 0, 0,1,0,1)' }}
											color={
												isAuthorized && event.associatedResidents?.length > 0
													? 'primary'
													: 'secondary'
											}
										/>
									</Badge>
								</Button>
							</span>
						</Tooltip>
					</CardActions>
				</Grid>
			</Grid>
		</Card>
	);
}
