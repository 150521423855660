import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { getNetworkApi } from '../components/keycloak';
import { Add, Autorenew, GridView, TableRows } from '@mui/icons-material';
import { AssessedProfile, AssessmentTypeEnum, ConnectType } from '../generated';
import { Button, Grid, Tooltip } from '@mui/material';
import { SuccessAlert, ErrorAlert } from '../components/Alerts';
import {
	isAuthorizedForRoleTenant,
	ResidentIdData,
} from '../components/format';
import Confirm from '../components/Confirm';
import { useHistory, useLocation } from 'react-router-dom';

import BarChartIcon from '@mui/icons-material/BarChart';

import { BaseRole } from '../components/Roles';
import ConnectionForm from '../components/ConnectionForm';
import { trackPromise } from 'react-promise-tracker';
import MotivationAssessmentForm from '../components/MotivationAssessmentForm';
import MotivationsTableView from '../components/MotivationsTableView';
import MotivationsGridView from '../components/MotivationsGridView';

export default function Motivations(props) {
	const residentIdData: ResidentIdData = useMemo(() => {
		return {
			tenantIdentifier: props.match.params.tenantId,
			residenceIdentifier: props.match.params.residenceId,
			unitIdentifier: props.match.params.unitId,
			residentId: Number(props.match.params.residentId),
		};
	}, [props]);

	const [userRoleList, setUserRoleList] = React.useState([]);

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	let history = useHistory();
	const [assessedProfilesList, setAssessedProfilesList] = useState(
		[] as AssessedProfile[]
	);
	const [refresh, setRefresh] = useState(0);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState(undefined as string);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);

	const [showMotivationAssessmentForm, setShowMotivationAssessmentForm] =
		useState(false);

	const [showConfirmMotivationDeletion, setShowConfirmMotivationDeletion] =
		useState(false);

	const [deleteText, setDeleteText] = useState('');

	const [deleteResidentAssessmentId, setDeleteResidentAssessmentId] = useState(
		0 as number
	);
	const [showConnectionForm, setShowConnectionForm] = useState(false);

	const [currentMotivationData, setCurrentMotivationData] = useState(
		{} as AssessedProfile
	);

	const isAuthorized: boolean = useMemo(() => {
		return isAuthorizedForRoleTenant(
			userRoleList,
			BaseRole.FRAGEBOGENVERWALTUNG,
			residentIdData.tenantIdentifier,
			residentIdData.residenceIdentifier,
			residentIdData.unitIdentifier
		);
	}, [
		userRoleList,
		residentIdData.tenantIdentifier,
		residentIdData.residenceIdentifier,
		residentIdData.unitIdentifier,
	]);

	const handleClickOpenConnectionForm = (assessedProfile: AssessedProfile) => {
		setShowConnectionForm(true);
		setCurrentMotivationData(assessedProfile);
	};

	useEffect(() => {
		const loadAssessedProfiles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getAssessedProfilesFromResidentId(
					residentIdData.tenantIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.residentId,
					AssessmentTypeEnum.Motivation
				);
				setAssessedProfilesList(result);
			} catch (error) {
				if (error.message) {
					setShowErrorAlert(true);
					if (error.response && error.response.status === 401) {
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setAlertText('Fragebogen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setAlertText('Fragebogen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadAssessedProfiles());
	}, [residentIdData, refresh, showSuccessAlert]);

	const deleteMotivation = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateResidentAssessment(
				residentIdData.tenantIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residenceIdentifier,
				deleteResidentAssessmentId
			);
			setShowSuccessAlert(true);
			setShowConfirmMotivationDeletion(false);
			setAlertText('Fragebogen wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Fragebogen konnte nicht archiviert werden');
			console.log('There was an error while deactivating!');
		}
	};

	const handleDeleteMotivation = (ResidentAssessmentId: number) => {
		setShowConfirmMotivationDeletion(true);
		setDeleteText('Wollen Sie den Fragebogen wirklich archivieren?');
		setDeleteResidentAssessmentId(ResidentAssessmentId);
	};

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	const handleClickOpenMotivationAssessmentForm = () => {
		setShowMotivationAssessmentForm(true);
	};

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	let query = useQuery();

	const [tableView, setTableView] = useState(
		query.get('tableView') === 'true' ? query.get('tableView') : false
	);

	const handleSwitchChange = () => {
		setTableView(!tableView);
	};

	useMemo(() => {
		history.replace({
			search: `?tableView=${tableView}`,
		});
	}, [tableView]);

	return (
		<React.Fragment>
			<Grid
				container
				direction='column'
				justifyContent='space-between'
				alignItems='right'
				sx={{ padding: 3 }}>
				<Grid item xs={1} sx={{ mt: 2 }}>
					<Button
						disabled={!isAuthorized}
						variant='contained'
						color={isAuthorized ? 'primary' : 'secondary'}
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleClickOpenMotivationAssessmentForm()}>
						{<Add />}
						Fragebogen
					</Button>
					<Button
						variant='contained'
						color='primary'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleRefresh()}>
						{<Autorenew />}
					</Button>
					<Tooltip
						title={
							assessedProfilesList.length > 0
								? 'Zeige Motivationsprofile im Zeitverlauf'
								: ''
						}>
						<Button
							disabled={!(assessedProfilesList.length > 0)}
							variant='contained'
							color={assessedProfilesList.length > 0 ? 'primary' : 'secondary'}
							sx={{ float: 'right', mr: 5 }}
							onClick={() =>
								history.push({
									pathname:
										'/tenantId=' +
										residentIdData.tenantIdentifier +
										',residenceId=' +
										residentIdData.residenceIdentifier +
										',unitId=' +
										residentIdData.unitIdentifier +
										'/profile/' +
										'residentId=' +
										residentIdData.residentId +
										'/motivation/motivationChart',
								})
							}>
							{<BarChartIcon />}
						</Button>
					</Tooltip>
					<Tooltip title={tableView ? 'Motivprofilekärtchen' : 'Tabelle'}>
						<Button
							variant='contained'
							sx={{ float: 'right', mr: 5 }}
							onClick={() => handleSwitchChange()}>
							{tableView ? <GridView /> : <TableRows />}
						</Button>
					</Tooltip>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ mx: 5 }}>
				{tableView ? (
					<MotivationsTableView
						assessedProfilesList={assessedProfilesList}
						residentIdData={residentIdData}
						isAuthorized={isAuthorized}
						history={history}
						handleClickOpenConnectionForm={handleClickOpenConnectionForm}
						handleDeleteMotivation={handleDeleteMotivation}
					/>
				) : (
					<MotivationsGridView
						assessedProfilesList={assessedProfilesList}
						residentIdData={residentIdData}
						history={history}
						handleClickOpenConnectionForm={handleClickOpenConnectionForm}
						isAuthorized={isAuthorized}
					/>
				)}
			</Grid>
			{showMotivationAssessmentForm && (
				<MotivationAssessmentForm
					open={showMotivationAssessmentForm}
					setOpen={setShowMotivationAssessmentForm}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					residentIdData={residentIdData}
				/>
			)}
			{showConfirmMotivationDeletion && (
				<Confirm
					open={showConfirmMotivationDeletion}
					setOpen={setShowConfirmMotivationDeletion}
					text={deleteText}
					executefct={deleteMotivation}
				/>
			)}
			{showConnectionForm && (
				<ConnectionForm
					open={showConnectionForm}
					setOpen={setShowConnectionForm}
					residentIdData={residentIdData}
					connectType={ConnectType.Residentassessment}
					databaseId={currentMotivationData.residentAssessmentId}
					currentConnectDataList={currentMotivationData.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showSuccessAlert && alertText && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
