import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	TextField,
	Grid,
	useMediaQuery,
	useTheme,
	Tooltip,
	FormGroup,
	FormLabel,
	FormControlLabel,
	Checkbox,
	FormControl,
	RadioGroup,
	Radio
} from '@mui/material';
import { Close, Save } from '@mui/icons-material'
import { getNetworkApi } from './keycloak';
import { Link } from '../generated';
import React, { useEffect, useState } from 'react';
import { addHttp, handleKeyPress, isEmptyField, isTagTicked } from './format';
import { Tag } from '../generated/models/Tag';
import { trackPromise } from 'react-promise-tracker';
export default function LinkForm({
	open,
	setOpen,
	isReadOnly,
	IsNewLink,
	CurrentLink,
	ResidenceDetails,
	setAlertText,
	SetShowSuccessAlert,
	SetShowErrorAlert,
}) {
	const [linkValues, setLinkValues] = React.useState(CurrentLink as Link);
	const [Tags, setTags] = useState([] as Tag[]);
	const [TagValues, setTagValues] = useState(linkValues.tags as Tag[]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleRadioChange = (event) => {
	setLinkValues((prevLinkValues) => ({
		...prevLinkValues,
		relevant: event.target.value === "true"
	}));
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setLinkValues({
			...linkValues,
			[name]: value,
		});
	};

	const getTagValue = (e) => {
		if (Object.entries(TagValues).length !== 0) {
			if (e.target.checked) {
				setTagValues((prevTags) => [
					...prevTags,
					{ tagId: Number(e.target.value) },
				]);
			} else {
				setTagValues(
					TagValues.filter((item) => item.tagId !== Number(e.target.value))
				);
			}
		} else {
			setTagValues([{ tagId: Number(e.target.value) }]);
		}
	};



	const saveNewLink = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.postLink(
				ResidenceDetails.tenantIdentifier,
				ResidenceDetails.residenceIdentifier,
				ResidenceDetails.unitIdentifier,
				CurrentLink.residentId,
				{
					name: linkValues.name,
					linkString: addHttp(linkValues.linkString),
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: linkValues.relevant
				}
			);
			setAlertText('Link wurde erfolgreich gespeichert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Link wurde nicht gespeichert');
			SetShowErrorAlert(true);
		}
	};

	const updateLink = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.putLink(
				ResidenceDetails.tenantIdentifier,
				ResidenceDetails.residenceIdentifier,
				ResidenceDetails.unitIdentifier,
				CurrentLink.residentId,
				CurrentLink.linkId,
				{
					name: linkValues.name,
					linkString: addHttp(linkValues.linkString),
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: linkValues.relevant
				}
			);
			setAlertText('Link wurde erfolgreich geändert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Link wurde nicht geändert');
			SetShowErrorAlert(true);
		}
	};


    const isSaveButtonEnabled = (link : Link) => {
        return (!isEmptyField(link?.name) && !isEmptyField(link?.linkString));
	};
	
	useEffect(() => {
		const loadTags = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getTags(ResidenceDetails.tenantIdentifier);
				console.log(result)
				setTags(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the available Tags data!');
					}
				} else {
					console.log('There was an error fetching the available Tags data!');
				}
			}
		};
		trackPromise(loadTags());
	}, [
		ResidenceDetails.tenantIdentifier,
		ResidenceDetails.unitIdentifier,
		ResidenceDetails.tenantIndentifier
	]);


	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
	
	return (
		<React.Fragment>
			<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth = 'md'>
				<DialogTitle>
					{isReadOnly? 'Details' : [IsNewLink ? 'Neuen Link anlegen' : 'Link bearbeiten']}
				</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label='Link Name'
									name='name'
									id='outlined-size-normal'
									value={linkValues.name}
									onChange={handleInputChange}
									onKeyPress={handleKeyPress}
									inputProps={{
										maxLength: 100,
									}}
									required
									disabled={isReadOnly}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									multiline
									rows={3}
									label='Link'
									name='linkString'
									id='outlined-size-normal'
									value={linkValues.linkString}
									onChange={handleInputChange}
									inputProps={{
										maxLength: 500,
									}}
									required
									disabled={isReadOnly}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<Tooltip
										title='Tags bzgl. der Strukturierten Informationssammlung'
										placement='top'>
										<FormLabel>SIS Tag</FormLabel>
									</Tooltip>
									<FormGroup>
										<Grid container columnSpacing={2} rowSpacing={0}>
											{Tags.filter(
												(el) => el.tagGroupIdentifier === 'SIS'
											).map((item, index) => (
												<Grid item xs={4} key={index}>
													<FormControlLabel
														key={item.tagId}
														value={item.tagId}
														checked={isTagTicked(TagValues, item.tagId)}
														control={<Checkbox />}
														label={item.tagAbbreviation}
														onChange={(e) => getTagValue(e)}
														disabled={isReadOnly}
													/>
												</Grid>
											))}
										</Grid>
									</FormGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
							<FormControl>
								<Tooltip title="Pflegerelevant?" placement="top">
								<FormLabel>Pflegerelevant?</FormLabel>
								</Tooltip>
								<FormControl>
									<RadioGroup
										row
										aria-labelledby="demo-controlled-radio-buttons-group"
										name="controlled-radio-buttons-group"
										value={linkValues.relevant}
										onChange={handleRadioChange}
									>
									<FormControlLabel value={true} disabled={isReadOnly} control={<Radio />} label="Ja" />
									<FormControlLabel value={false} disabled={isReadOnly} control={<Radio />} label="Nein" />
								</RadioGroup>
								</FormControl>
							</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} {isReadOnly ? 'Schließen' : 'Abbrechen'}
										</Button>
									</Grid>
									{!isReadOnly && (
										<Grid item>
											<Button
												variant='contained'
												disabled={!isSaveButtonEnabled(linkValues)}
												onClick={IsNewLink ? saveNewLink : updateLink}>
												{<Save />} Speichern
											</Button>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
