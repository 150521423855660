import { useCallback } from 'react';
import { Paper, Button, Tooltip, Typography } from '@mui/material';
import MaterialTable, { Column } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import { Delete, Edit, Info } from '@mui/icons-material';

import { AssessedProfile } from '../generated';
import {
	formatDate,
	ResidentIdData,
	TCCStyledBadgeButton,
	viewportHeight,
} from './format';
import React from 'react';
import grey from '@mui/material/colors/grey';

interface MotivationsTableViewProps {
	assessedProfilesList: AssessedProfile[];
	residentIdData: ResidentIdData;
	handleClickOpenConnectionForm: any;
	history: any;
	handleDeleteMotivation: any;
	isAuthorized: boolean;
}

export default function MotivationsTableView({
	assessedProfilesList,
	residentIdData,
	handleClickOpenConnectionForm,
	history,
	handleDeleteMotivation,
	isAuthorized,
}: MotivationsTableViewProps) {
	const columns: Column<any>[] = [
		{
			width: 'min-content',
			sorting: false,
			render: useCallback((assessedProfile: AssessedProfile) => {
				return (
					<TCCStyledBadgeButton
						badgeContent={assessedProfile.connects.length}
						onClick={() => handleClickOpenConnectionForm(assessedProfile)}
					/>
				);
			}, []),
		},
		{
			title: 'Name',
			field: 'name',
			filtering: false,
			render: useCallback((data: any) => <Typography variant='h6' color={grey[500]}> {data.name} </Typography> , []),
		},
		{
			title: 'Bearbeiter:in',
			field: 'assessingUser',
			render: useCallback((data: any) => <Typography variant='subtitle1' color={grey[500]}> {data.assessingUser} </Typography>, []),
		},
		{   
			title: 'Version',
			field: 'version' 
		},
		{
			title: 'Datum',
			field: 'assessmentTimeStamp',
			filtering: false,
			defaultSort: 'desc',
			render: useCallback(
				(data: any) => <Typography variant='subtitle1' color={grey[500]}> {formatDate(data.assessmentTimeStamp)} </Typography>,
				[]
			),
		},
		{
			sorting: false,
			render: useCallback(
				(data: AssessedProfile) => {
					return (
						<React.Fragment>
							<Tooltip title='Auswertung'>
								<Button
									onClick={() =>
										history.push({
											pathname:
												'/tenantId=' +
												residentIdData.tenantIdentifier +
												',residenceId=' +
												residentIdData.residenceIdentifier +
												',unitId=' +
												residentIdData.unitIdentifier +
												'/profile/' +
												'residentId=' +
												residentIdData.residentId +
												'/motivation/motivationResult/residentAssessmentId=' +
												data.residentAssessmentId +
												',assessmentProfileId=' +
												data.assessmentProfileId,
										})
									}>
									{<Info color='primary' />}
								</Button>
							</Tooltip>
							<Tooltip title={isAuthorized ? 'Bearbeite Fragebogen' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() =>
											history.push({
												pathname:
													'/tenantId=' +
													residentIdData.tenantIdentifier +
													',residenceId=' +
													residentIdData.residenceIdentifier +
													',unitId=' +
													residentIdData.unitIdentifier +
													'/profile/' +
													'residentId=' +
													residentIdData.residentId +
													'/motivation/editMotivationAssessment/residentAssessmentId=' +
													data.residentAssessmentId +
													',assessmentProfileId=' +
													data.assessmentProfileId +
													',assessmentProfileName=' +
													data.name,
											})
										}>
										<Edit color={isAuthorized ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
							<Tooltip title={isAuthorized ? 'Archiviere Fragebogen' : ''}>
								<span>
									<Button
										disabled={!isAuthorized}
										onClick={() =>
											handleDeleteMotivation(data.residentAssessmentId)
										}>
										<Delete color={isAuthorized ? 'primary' : 'secondary'} />
									</Button>
								</span>
							</Tooltip>
						</React.Fragment>
					);
				},
				[residentIdData, history, isAuthorized]
			),
		},
	];

	return (
		<MaterialTable
			localization={{
				body: {
					emptyDataSourceMessage: 'Keine Fragebögen hinterlegt.',
				},
			}}
			style={{ borderRadius: '30px', color: grey[500] }}
			icons={MaterialTableIcons()}
			columns={columns}
			data={assessedProfilesList}
			components={{
				Container: (props) => <Paper {...props} elevation={3} />,
			}}
			options={{
				paging: false,
				showTitle: false,
				sorting: true,
				filtering: true,
				search: false,
				rowStyle: {
					fontSize: 16,
					},
				headerStyle: {
					position: 'sticky',
					top: 0,
					backgroundColor: 'white',
				},
				maxBodyHeight: viewportHeight(80),
			}}
		/>
	);
}
