/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NoteComment
 */
export interface NoteComment {
    /**
     * 
     * @type {number}
     * @memberof NoteComment
     */
    noteCommentId?: number;
    /**
     * 
     * @type {number}
     * @memberof NoteComment
     */
    noteId?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteComment
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteComment
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteComment
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof NoteComment
     */
    lastUpdatedDate?: Date;
}

export function NoteCommentFromJSON(json: any): NoteComment {
    return NoteCommentFromJSONTyped(json, false);
}

export function NoteCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'noteCommentId': !exists(json, 'noteCommentId') ? undefined : json['noteCommentId'],
        'noteId': !exists(json, 'noteId') ? undefined : json['noteId'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'lastUpdatedDate': !exists(json, 'lastUpdatedDate') ? undefined : (new Date(json['lastUpdatedDate'])),
    };
}

export function NoteCommentToJSON(value?: NoteComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'noteCommentId': value.noteCommentId,
        'noteId': value.noteId,
        'content': value.content,
        'tenantIdentifier': value.tenantIdentifier,
        'modifyingUser': value.modifyingUser,
        'lastUpdatedDate': value.lastUpdatedDate === undefined ? undefined : (value.lastUpdatedDate.toISOString().substr(0,10)),
    };
}


