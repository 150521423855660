import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Divider,
} from '@mui/material';
import grey from '@mui/material/colors/grey';
import { useHistory } from 'react-router-dom';
import {
	BorderLinearProgress,
	conditionalDividerText,
	formatDate,
	GetDistinctAssessmentTargets,
} from './format';

export default function AssessmentSummaryCard({
	title,
	icon,
	cardcolor,
	path,
	mostRecentAssessedProfile,
	residentIdData,
}) {
	let history = useHistory();

	const displayDistinctAssessmentTargets = GetDistinctAssessmentTargets(
		mostRecentAssessedProfile,
		residentIdData
	);

	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 5, height: '100%' }}
			elevation={5}>
			<CardActionArea onClick={() => history.push(path)}>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={3}>
							{icon}
						</Grid>
						<Grid item xs={6} textAlign={'center'}>
							<Typography variant='h5' color={cardcolor}>
								{' '}
								{title}{' '}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<Divider variant='middle' style={{ fontSize: 12 }}>
					{conditionalDividerText(
						mostRecentAssessedProfile ? [mostRecentAssessedProfile] : []
					)}
				</Divider>

				{mostRecentAssessedProfile && (
					<CardContent>
						<Grid
							container
							spacing={2}
							direction='column'
							sx={{
								overflowX: 'auto',
							}}>
							<Grid
								item
								xs={12}
								style={{ fontSize: 18, color: grey[500] }}
								sx={{
									textAlign: 'center',
								}}>
								{mostRecentAssessedProfile?.name?.length > 30
									? mostRecentAssessedProfile?.name?.substring(0, 30) + '...'
									: mostRecentAssessedProfile?.name}
							</Grid>
							<Grid
								item
								xs={12}
								style={{ fontSize: 12, color: grey[500] }}
								sx={{
									textAlign: 'center',
									marginTop: -1,
								}}>
								<em>
									({mostRecentAssessedProfile?.assessingUser},{' '}
									{formatDate(mostRecentAssessedProfile?.assessmentTimeStamp)})
								</em>
							</Grid>
							{displayDistinctAssessmentTargets.map((el, index) => (
								<Grid
									item
									key={index}
									style={{ fontSize: 16, color: grey[500] }}>
									<b>{el.targetName}</b>
									{':   '}
									{el.targetDesc + '  '}
									{Math.round(el.score) + '%'}

									<BorderLinearProgress
										variant='determinate'
										value={el.score}
									/>
								</Grid>
							))}
						</Grid>
					</CardContent>
				)}
			</CardActionArea>
		</Card>
	);
}
