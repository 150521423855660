import React from 'react';
import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Divider,
	Button,
	CardActions,
	Tooltip,
	Box,
} from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircleOutlined';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ResidentMediaData } from '../generated';
import DisplayMedia from './DisplayMedia';
import {
	conditionalDividerText,
	formatDate,
	abbreviateString,
	mediaTypeToIcon,
	ResidentIdData,
} from './format';
import { getNetworkApi } from './keycloak';
import { trackPromise } from 'react-promise-tracker';
import { ErrorAlert } from './Alerts';
import VideoThumbnail from 'react-video-thumbnail';
import { grey } from '@mui/material/colors';

interface MediaSummaryCardProps {
	title: string;
	icon: any;
	cardcolor: string;
	path: string;
	residentIdData: ResidentIdData;
	mostRecentMediaList: ResidentMediaData[];
	mostRecentMediaUrl: string;
}

export default function MediaSummaryCard({
	title,
	icon,
	cardcolor,
	path,
	residentIdData,
	mostRecentMediaList,
	mostRecentMediaUrl,
}: MediaSummaryCardProps) {
	const [selectedMediaFile, setSelectedMediaFile] = useState(new Blob());
	const [showMediaFile, setShowMediaFile] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	let history = useHistory();

	const loadSingleMediaFile = async (fileMetaData: ResidentMediaData) => {
		const api = getNetworkApi();
		try {
			const result = await api.getMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				fileMetaData.mediaId
			);
			return result;
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Die ausgewählte Mediendatei konnte nicht abgerufen werden');
			return null;
		}
	};

	const handleShowMediaFile = async () => {
		let promiseForBlob = trackPromise(
			loadSingleMediaFile(mostRecentMediaList[0])
		);
		setSelectedMediaFile(await promiseForBlob);
		setShowMediaFile(true);
	};

	const displayPreview = () => {
		if (mostRecentMediaList[0].mediaTypeIdentifier.includes('image')) {
			return (
				<Box
					component={'img'}
					sx={{
						maxHeight: { xs: 100, md: 100 },
						maxWidth: { xs: 100, md: 100 },
					}}
					alt=''
					src={mostRecentMediaUrl}
				/>
			);
		} else if (mostRecentMediaList[0].mediaTypeIdentifier.includes('video')) {
			return (
				<Box
					sx={{
						maxHeight: { xs: 100, md: 100 },
						maxWidth: { xs: 100, md: 100 },
					}}>
					<VideoThumbnail
						videoUrl={mostRecentMediaUrl}
						width={150}
						height={100}
					/>
				</Box>
			);
		} else {
			return null;
		}
	};

	const isVideoOrPicture = (mediaTypeId: string) => {
		return mediaTypeId.includes('image') || mediaTypeId.includes('video');
	};

	const isPreviewAvailable = (
		residentMediaData: ResidentMediaData
	): boolean => {
		if (
			residentMediaData &&
			isVideoOrPicture(residentMediaData.mediaTypeIdentifier)
		) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<React.Fragment>
			<Card
				sx={{ color: cardcolor, borderRadius: 5, height: '100%' }}
				elevation={5}>
				<CardActionArea onClick={() => history.push(path)}>
					<CardContent>
						<Grid container spacing={2} direction='row'>
							<Grid item xs={3}>
								{icon}
							</Grid>
							<Grid item xs={6} textAlign={'center'}>
								<Typography variant='h5' color={cardcolor}>
									{' '}
									{title}{' '}
								</Typography>
							</Grid>
						</Grid>
					</CardContent>
					<Divider variant='middle' style={{ fontSize: 12 }}>
						{conditionalDividerText(mostRecentMediaList)}
					</Divider>
				</CardActionArea>
				{mostRecentMediaList[0] && (
					<CardActions>
						<Tooltip title='Zeige Datei an' sx={{ color: cardcolor }}>
							<Button fullWidth onClick={() => handleShowMediaFile()}>
								{<PlayCircleIcon />}
								{isPreviewAvailable(mostRecentMediaList[0]) && displayPreview()}
							</Button>
						</Tooltip>
					</CardActions>
				)}
				<CardActionArea onClick={() => history.push(path)}>
					<CardContent>
						<Grid
							container
							rowSpacing={2}
							columnSpacing={2}
							columns={{ xs: 3, md: 6, lg: 12 }}
							justifyContent='space-betwen'
							alignItems='stretch'
							sx={{
								marginTop: 0,
								overflowX: 'auto',
							}}>
							{mostRecentMediaList.map((media, index) => (
								<>
									<Grid item xs={3} md={2} lg={3}>
										{mediaTypeToIcon(media.mediaTypeIdentifier)}
									</Grid>
									<Grid
										item
										xs={3}
										md={4}
										lg={9}
										style={{ fontSize: 18, color: grey[500] }}>
										{abbreviateString(media.fileName, 30)}
									</Grid>
								</>
							))}
						</Grid>
					</CardContent>
				</CardActionArea>
			</Card>
			{showMediaFile && (
				<DisplayMedia
					open={showMediaFile}
					setOpen={setShowMediaFile}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
					metaData={mostRecentMediaList[0]}
					mediaFile={selectedMediaFile}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
