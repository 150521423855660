/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeDateDetail
 */
export interface ChangeDateDetail {
    /**
     * 
     * @type {string}
     * @memberof ChangeDateDetail
     */
    category?: string;
    /**
     * 
     * @type {Date}
     * @memberof ChangeDateDetail
     */
    lastChangeDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ChangeDateDetail
     */
    elementId?: number;
}

export function ChangeDateDetailFromJSON(json: any): ChangeDateDetail {
    return ChangeDateDetailFromJSONTyped(json, false);
}

export function ChangeDateDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeDateDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : json['category'],
        'lastChangeDate': !exists(json, 'lastChangeDate') ? undefined : (new Date(json['lastChangeDate'])),
        'elementId': !exists(json, 'elementId') ? undefined : json['elementId'],
    };
}

export function ChangeDateDetailToJSON(value?: ChangeDateDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': value.category,
        'lastChangeDate': value.lastChangeDate === undefined ? undefined : (value.lastChangeDate.toISOString().substr(0,10)),
        'elementId': value.elementId,
    };
}


