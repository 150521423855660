/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResidenceEventCategory {
    Party = 'PARTY',
    Memorial = 'MEMORIAL',
    Excursion = 'EXCURSION',
    Visit = 'VISIT',
    Culture = 'CULTURE',
    Retrospect = 'RETROSPECT',
    Gifts = 'GIFTS',
    Feast = 'FEAST',
    Other = 'OTHER'
}

export function ResidenceEventCategoryFromJSON(json: any): ResidenceEventCategory {
    return ResidenceEventCategoryFromJSONTyped(json, false);
}

export function ResidenceEventCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceEventCategory {
    return json as ResidenceEventCategory;
}

export function ResidenceEventCategoryToJSON(value?: ResidenceEventCategory | null): any {
    return value as any;
}

