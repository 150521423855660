import { GroupAdd, PlayArrow } from '@mui/icons-material';
import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Divider,
	Tooltip,
	CardActions,
	Button,
	Badge,
} from '@mui/material';

import { ResidenceGroup } from '../generated';
import { abbreviateString, groupCategoryItems } from './format';

interface GroupCardProps {
	group: ResidenceGroup;
	onClickCard: React.MouseEventHandler<HTMLButtonElement>;
	onClickMediaAlbum: any;
	onClickResidentToGroupForm: any;
	isAuthorized: boolean;
}

export default function GroupCard({
	group,
	onClickCard,
	onClickMediaAlbum,
	onClickResidentToGroupForm,
	isAuthorized,
}: GroupCardProps) {
	let cardcolor = 'primary';

	const getTooltipTitle = () => {
		let part1 = group?.residenceGroupCategory
			? groupCategoryItems?.find(
					(el) => el.value === group?.residenceGroupCategory
			  )?.name
			: '';
		let part2 = group?.residenceGroupSubCategory
			? ' (' + group.residenceGroupSubCategory + ')'
			: '';
		return part1 + part2;
	};

	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 10, height: '100%' }}
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
			}}
			elevation={5}>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12}>
					<CardActionArea onClick={onClickCard}>
						<CardContent>
							<Tooltip
								title={getTooltipTitle() == null ? '' : getTooltipTitle()}>
								<Typography
									align='center'
									fontSize='24'
									variant='h6'
									color={cardcolor}>
									{abbreviateString(group.name, 30)}{' '}
									{group?.residenceGroupCategory &&
										groupCategoryItems?.find(
											(el) => el.value === group?.residenceGroupCategory
										)?.icon}
								</Typography>
							</Tooltip>
						</CardContent>
					</CardActionArea>
					<Divider variant='middle' />
				</Grid>
			</Grid>
			<CardActionArea onClick={onClickCard}>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={12}>
							<Typography
								fontSize={16}
								color={'primary'}
								variant={'body2'}
								justifyContent={'center'}
								marginTop={2}
								marginBottom={2}
								align={'left'}
								style={{ wordWrap: 'break-word' }}>
								{abbreviateString(group?.motto, 80)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12}>
					<CardActions sx={{ justifyContent: 'center' }}>
						<Tooltip title={isAuthorized ? 'Medien anzeigen' : ''}>
							<span>
								<Button
									disabled={!isAuthorized || group.residenceMedia?.length === 0}
									onClick={onClickMediaAlbum}>
									<Badge
										badgeContent={group.residenceMedia?.length}
										color='primary'>
										<PlayArrow
											color={
												isAuthorized && group.residenceMedia?.length > 0
													? 'primary'
													: 'secondary'
											}
										/>
									</Badge>
								</Button>
							</span>
						</Tooltip>
						<Tooltip
							title={isAuthorized ? 'Mitglieder anzeigen/hinzufügen' : ''}>
							<span>
								<Button
									disabled={!isAuthorized}
									onClick={() => onClickResidentToGroupForm(group)}>
									<Badge
										badgeContent={group.associatedResidents?.length ?? 0}
										color='primary'>
										<GroupAdd
											style={{ transform: 'matrix(-1.0, 0, 0,1,0,1)' }}
											color={
												isAuthorized && group.associatedResidents?.length > 0
													? 'primary'
													: 'secondary'
											}
										/>
									</Badge>
								</Button>
							</span>
						</Tooltip>
					</CardActions>
				</Grid>
			</Grid>
		</Card>
	);
}
