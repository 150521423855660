/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectData,
    ConnectDataFromJSON,
    ConnectDataFromJSONTyped,
    ConnectDataToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface Hobby
 */
export interface Hobby {
    /**
     * 
     * @type {number}
     * @memberof Hobby
     */
    hobbyId?: number;
    /**
     * 
     * @type {number}
     * @memberof Hobby
     */
    residentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Hobby
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Hobby
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Hobby
     */
    stageOfLife?: string;
    /**
     * 
     * @type {string}
     * @memberof Hobby
     */
    startFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof Hobby
     */
    endTo?: string;
    /**
     * 
     * @type {string}
     * @memberof Hobby
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Hobby
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Array<ConnectData>}
     * @memberof Hobby
     */
    connects?: Array<ConnectData>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Hobby
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof Hobby
     */
    relevant?: boolean;
}

export function HobbyFromJSON(json: any): Hobby {
    return HobbyFromJSONTyped(json, false);
}

export function HobbyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hobby {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hobbyId': !exists(json, 'hobbyId') ? undefined : json['hobbyId'],
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'stageOfLife': !exists(json, 'stageOfLife') ? undefined : json['stageOfLife'],
        'startFrom': !exists(json, 'startFrom') ? undefined : json['startFrom'],
        'endTo': !exists(json, 'endTo') ? undefined : json['endTo'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'connects': !exists(json, 'connects') ? undefined : ((json['connects'] as Array<any>).map(ConnectDataFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function HobbyToJSON(value?: Hobby | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hobbyId': value.hobbyId,
        'residentId': value.residentId,
        'name': value.name,
        'description': value.description,
        'stageOfLife': value.stageOfLife,
        'startFrom': value.startFrom,
        'endTo': value.endTo,
        'tenantIdentifier': value.tenantIdentifier,
        'modifyingUser': value.modifyingUser,
        'connects': value.connects === undefined ? undefined : ((value.connects as Array<any>).map(ConnectDataToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


