/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectData,
    ConnectDataFromJSON,
    ConnectDataFromJSONTyped,
    ConnectDataToJSON,
    FavoriteFoodMedia,
    FavoriteFoodMediaFromJSON,
    FavoriteFoodMediaFromJSONTyped,
    FavoriteFoodMediaToJSON,
    FavoriteFoodType,
    FavoriteFoodTypeFromJSON,
    FavoriteFoodTypeFromJSONTyped,
    FavoriteFoodTypeToJSON,
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface FavoriteFood
 */
export interface FavoriteFood {
    /**
     * 
     * @type {number}
     * @memberof FavoriteFood
     */
    favoriteFoodId?: number;
    /**
     * 
     * @type {string}
     * @memberof FavoriteFood
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {number}
     * @memberof FavoriteFood
     */
    residentId?: number;
    /**
     * 
     * @type {string}
     * @memberof FavoriteFood
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FavoriteFood
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof FavoriteFood
     */
    modifyingUser?: string;
    /**
     * 
     * @type {FavoriteFoodType}
     * @memberof FavoriteFood
     */
    favoriteFoodType?: FavoriteFoodType;
    /**
     * 
     * @type {Array<FavoriteFoodMedia>}
     * @memberof FavoriteFood
     */
    media?: Array<FavoriteFoodMedia>;
    /**
     * 
     * @type {Array<ConnectData>}
     * @memberof FavoriteFood
     */
    connects?: Array<ConnectData>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof FavoriteFood
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof FavoriteFood
     */
    relevant?: boolean;
}

export function FavoriteFoodFromJSON(json: any): FavoriteFood {
    return FavoriteFoodFromJSONTyped(json, false);
}

export function FavoriteFoodFromJSONTyped(json: any, ignoreDiscriminator: boolean): FavoriteFood {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'favoriteFoodId': !exists(json, 'favoriteFoodId') ? undefined : json['favoriteFoodId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'favoriteFoodType': !exists(json, 'favoriteFoodType') ? undefined : FavoriteFoodTypeFromJSON(json['favoriteFoodType']),
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(FavoriteFoodMediaFromJSON)),
        'connects': !exists(json, 'connects') ? undefined : ((json['connects'] as Array<any>).map(ConnectDataFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function FavoriteFoodToJSON(value?: FavoriteFood | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'favoriteFoodId': value.favoriteFoodId,
        'tenantIdentifier': value.tenantIdentifier,
        'residentId': value.residentId,
        'name': value.name,
        'description': value.description,
        'modifyingUser': value.modifyingUser,
        'favoriteFoodType': FavoriteFoodTypeToJSON(value.favoriteFoodType),
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(FavoriteFoodMediaToJSON)),
        'connects': value.connects === undefined ? undefined : ((value.connects as Array<any>).map(ConnectDataToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


