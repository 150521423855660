import { Close, Save } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { MediaMetaUpdateParameters, ResidentMediaData } from '../generated';
import { getNetworkApi } from './keycloak';
import { getMediaFileType } from './format';
import FileUploader from './FileUploader';

export default function ProfilePictureForm({
	open,
	setOpen,
	residentIdData,
	profilePictureChangeTracker,
	setProfilePictureChangeTracker,
	setAlertText,
	setShowSuccessAlert,
	setShowErrorAlert,
}) {
	const [mediaFileMetadata, setMediaFileMetadata] = useState(
		null as ResidentMediaData
	);
	const [selectedFile, setSelectedFile] = useState({} as File);
	const [selectedFileName, setSelectedFileName] = useState('');
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [isImage, setIsImage] = useState(true as boolean);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setMediaFileMetadata({
			...mediaFileMetadata,
			[name]: value,
		});
	};

	const handleSelectedFile = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		setSelectedFileName(file?.name);
		setIsImage(getMediaFileType(file?.name) === 'img');
		setIsFilePicked(true);
	};

	// The save-method contains a workaround:
	// the post request does not have the mediaCategories (due to problems with multipart/form-data & arrays of objects),
	// hence we submit an additional update request after the post
	const saveMediaFile = async () => {
		setOpen(false);
		const client = getNetworkApi();

		let mediaId: number = undefined;
		const comment =
			mediaFileMetadata?.comment !== ''
				? mediaFileMetadata?.comment
				: undefined;
		const stageOfLife = undefined;
		const isProfilePicture = true;
		const mediaCategories = [];

		try {
			mediaId = await client.postResidentMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				selectedFile,
				selectedFile.name,
				comment,
				stageOfLife
			);
		} catch (error) {
			setAlertText('Datei wurde nicht gespeichert');
			setShowErrorAlert(true);
		}

		let mediaMetaUpdateParameters: MediaMetaUpdateParameters = {
			comment: comment,
			stageOfLife: stageOfLife,
			mediaCategories: mediaCategories,
			isProfilePicture: isProfilePicture,
		};

		try {
			await client.putResidentMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				mediaId,
				mediaMetaUpdateParameters
			);

			setAlertText('Datei wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
			setProfilePictureChangeTracker(profilePictureChangeTracker + 1);
		} catch (error) {
			setAlertText('Datei wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const makeDisabledTextField = (
		label: string,
		name: string,
		value: string
	) => {
		return (
			<TextField
				label={label}
				name={name}
				id='outlined-size-normal'
				value={value}
				disabled
			/>
		);
	};

	const displayPreview = () => {
		const mediaFileType = getMediaFileType(selectedFileName);
		if (mediaFileType === 'img') {
			return (
				<Box
					component={mediaFileType}
					sx={{
						maxHeight: { xs: 150, md: 150 },
						maxWidth: { xs: 150, md: 150 },
					}}
					alt=''
					src={URL.createObjectURL(selectedFile)}
				/>
			);
		} else {
			return null;
		}
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<Dialog fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth='md'>
				<DialogTitle>Profilbild hochladen</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<React.Fragment>
								{isFilePicked ? (
										<Grid item xs={2}>
											{displayPreview()}
									</Grid>
								) : null}
								<Grid item xs={3.5} sx={{marginLeft:3}}>
									<FileUploader onChange={handleSelectedFile} />
								<FormControl sx={{marginTop: 3.5 }}>
									{makeDisabledTextField('Datei', 'fileName', selectedFileName)}
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<TextField
										fullWidth
										multiline
										rows={3.2}
										label='Kommentar'
										name='comment'
										id='outlined-size-normal'
										value={mediaFileMetadata?.comment}
										onChange={handleInputChange}
									/>
								</Grid>
							</React.Fragment>
						</Grid>
						{!isImage ? (
							<p style={{ color: 'red' }}>
								Warnung: Die ausgewählte Datei ist kein gültiges Bildformat.
								Speichern als Profilbild nicht möglich.
							</p>
						) : (
							<p>
								<em>
									Ein vorhandenes Profilbild wird durch das Hochladen eines
									neuen ersetzt.
								</em>
							</p>
						)}
						

						<Grid item xs={12}>
							<Grid
								container
								direction='row'
								justifyContent='flex-end'
								alignItems='center'
								spacing={2}
								marginTop={0.1}>
								<Grid item>
									<Button variant='contained' onClick={handleClose}>
										{<Close />} Abbrechen
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant='contained'
										disabled={getMediaFileType(selectedFileName) !== 'img'}
										onClick={saveMediaFile}>
										{<Save />} Speichern
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
