import React, { useCallback, useMemo } from 'react';
import { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory, useLocation } from 'react-router-dom';

import { getNetworkApi } from '../components/keycloak';
import { Add, Autorenew, GridView, TableRows } from '@mui/icons-material';
import { Button, Grid, SelectChangeEvent, Tooltip } from '@mui/material';

import { ConnectType, Note, NoteStatus, StageOfLife } from '../generated';
import { SuccessAlert, ErrorAlert } from '../components/Alerts';

import {
	isAuthorizedForRoleTenant,
	ResidentIdData,
} from '../components/format';
import NoteForm from '../components/NoteForm';
import { Tag } from '../generated/models/Tag';
import NoteStatusForm from '../components/NoteStatusForm';
import { BaseRole } from '../components/Roles';
import ConnectionForm from '../components/ConnectionForm';
import NotesTableView from '../components/NotesTableView';
import NotesGridView from '../components/NotesGridView';

export default function Notes(props) {
	const residentIdData: ResidentIdData = {
		tenantIdentifier: props.match.params.tenantId,
		residenceIdentifier: props.match.params.residenceId,
		unitIdentifier: props.match.params.unitId,
		residentId: Number(props.match.params.residentId),
	};

	const [userRoleList, setUserRoleList] = React.useState([]);

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	const [noteList, setNoteList] = useState([] as Note[]);
	const [stagesOfLifeList, setStagesOfLifeList] = useState([] as StageOfLife[]);
	const [tags, setTags] = useState([] as Tag[]);
	const [refresh, setRefresh] = useState(0);
	const [showSuccessAlert, setShowSuccessAlert] = useState(
		props.location.state?.isError !== undefined
			? !props.location.state.isError
			: false
	);
	const [showErrorAlert, setShowErrorAlert] = useState(
		props.location.state?.isError !== undefined
			? props.location.state.isError
			: false
	);
	const [alertText, setAlertText] = useState(
		props.location.state?.alertText ? props.location.state.alertText : ''
	);
	const [showMemoryForm, setShowMemoryForm] = useState(false);
	const [isNewMemory, setIsNewMemory] = useState(true);
	const [currentMemoryData, setCurrentMemoryData] = useState({} as Note);
	const [noteStatusList, setNoteStatusList] = useState([] as NoteStatus[]);

	const [showMemoryStatusForm, setShowMemoryStatusForm] = useState(false);
	const defaultMemoryData: Note = {
		title: '',
		content: '',
		stageOfLife: '',
		pointInTime: '',
		noteId: undefined,
		tags: {} as Tag[],
	};
	const [showConnectionForm, setShowConnectionForm] = useState(false);

	const isAuthorized: boolean = useMemo(() => {
		return isAuthorizedForRoleTenant(
			userRoleList,
			BaseRole.BEWOHNERVERWALTUNG,
			residentIdData.tenantIdentifier,
			residentIdData.residenceIdentifier,
			residentIdData.unitIdentifier
		);
	}, [
		userRoleList,
		residentIdData.residenceIdentifier,
		residentIdData.unitIdentifier,
	]);

	useEffect(() => {
		const loadNotes = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getNotesList(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					residentIdData.residentId
				);
				setNoteList(
					result.sort(
						(a, b) =>
							stagesOfLifeList.findIndex((obj) => {
								return obj.name === a.stageOfLife;
							}) -
							stagesOfLifeList.findIndex((obj) => {
								return obj.name === b.stageOfLife;
							})
					)
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Erinnerungen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Erinnerungen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadNotes());
	}, [residentIdData.residentId, showSuccessAlert, refresh]);

	const handleClickOpenMemoryForm = (
		IsNewMemory: boolean,
		MemoryData: Note
	) => {
		setIsNewMemory(IsNewMemory);
		setShowMemoryForm(true);
		setCurrentMemoryData(MemoryData);
	};

	const handleClickOpenNoteStatusForm = (currentMemory: Note) => {
		setShowMemoryStatusForm(true);
		setCurrentMemoryData(currentMemory);
	};

	const handleClickOpenConnectionForm = (note: Note) => {
		setShowConnectionForm(true);
		setCurrentMemoryData(note);
	};

	useEffect(() => {
		const loadStagesOfLifeandTags = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getStagesOfLife(
					residentIdData.tenantIdentifier
				);
				setStagesOfLifeList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the StagesOfLife data!');
					}
				} else {
					console.log('There was an error fetching the StagesOfLife data!');
				}
			}
			try {
				const result = await api.getTags(residentIdData.tenantIdentifier);
				setTags(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the available Tags data!');
					}
				} else {
					console.log('There was an error fetching the available Tags data!');
				}
			}
		};
		trackPromise(loadStagesOfLifeandTags());
	}, [residentIdData.tenantIdentifier, showSuccessAlert, refresh]);

	useEffect(() => {
		const loadNoteStatus = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getNoteStatuses(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier
				);
				setNoteStatusList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the memory status data!');
					}
				} else {
					console.log('There was an error fetching the  memory status data!');
				}
			}
		};
		trackPromise(loadNoteStatus());
	}, [residentIdData.tenantIdentifier, showSuccessAlert, refresh]);

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	let history = useHistory();

	const forwardToNote = useCallback(
		(tableData) => {
			history.push({
				pathname:
					'/tenantId=' +
					residentIdData.tenantIdentifier +
					',residenceId=' +
					residentIdData.residenceIdentifier +
					',unitId=' +
					residentIdData.unitIdentifier +
					'/profile/' +
					'residentId=' +
					residentIdData.residentId +
					'/memory/noteId=' +
					tableData.noteId,
			});
		},
		[residentIdData.residentId, userRoleList, history]
	);

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	let query = useQuery();

	const [tableView, setTableView] = useState(
		query.get('tableView') === 'true' ? query.get('tableView') : false
	);

	const handleSwitchChange = () => {
		setTableView(!tableView);
	};

	useMemo(() => {
		history.replace({
			search: `?tableView=${tableView}`,
		});
	}, [history, tableView]);

	return (
		<React.Fragment>
			<Grid
				container
				direction='column'
				justifyContent='space-between'
				alignItems='right'
				sx={{ padding: 3 }}>
				<Grid item xs={1} sx={{ mt: 2, marginBottom: 5 }}>
					<Button
						disabled={!isAuthorized}
						color={isAuthorized ? 'primary' : 'secondary'}
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleClickOpenMemoryForm(true, defaultMemoryData)}>
						{<Add />} Erinnerung
					</Button>
					<Button
						variant='contained'
						sx={{ float: 'right', mr: 5 }}
						onClick={() => handleRefresh()}>
						{<Autorenew />}
					</Button>
					<Tooltip title={tableView ? 'Erinnerungkärtchen' : 'Tabelle'}>
						<Button
							variant='contained'
							sx={{ float: 'right', mr: 5 }}
							onClick={() => handleSwitchChange()}>
							{tableView ? <GridView /> : <TableRows />}
						</Button>
					</Tooltip>
				</Grid>
				<Grid item xs={12}>
					{tableView ? (
						<NotesTableView
							noteList={noteList}
							forwardToNote={forwardToNote}
							isAuthorized={isAuthorized}
							stagesOfLifeList={stagesOfLifeList}
							noteStatusList={noteStatusList}
							tags={tags}
							handleClickOpenConnectionForm={handleClickOpenConnectionForm}
							handleClickOpenNoteStatusForm={handleClickOpenNoteStatusForm}
						/>
					) : (
						<NotesGridView
							noteList={noteList}
							forwardToNote={forwardToNote}
							handleClickOpenConnectionForm={handleClickOpenConnectionForm}
						/>
					)}
				</Grid>
			</Grid>
			{showConnectionForm && (
				<ConnectionForm
					open={showConnectionForm}
					setOpen={setShowConnectionForm}
					residentIdData={residentIdData}
					connectType={ConnectType.Note}
					databaseId={currentMemoryData.noteId}
					currentConnectDataList={currentMemoryData.connects}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showMemoryForm && (
				<NoteForm
					open={showMemoryForm}
					setOpen={setShowMemoryForm}
					IsNewMemory={isNewMemory}
					residentIdData={residentIdData}
					setAlertText={setAlertText}
					SetShowSuccessAlert={setShowSuccessAlert}
					SetShowErrorAlert={setShowErrorAlert}
					CurrentMemoryData={currentMemoryData}
					setCurrentMemoryData={setCurrentMemoryData}
				/>
			)}
			{showMemoryStatusForm && (
				<NoteStatusForm
					open={showMemoryStatusForm}
					setOpen={setShowMemoryStatusForm}
					residentIdData={residentIdData}
					CurrentMemoryData={currentMemoryData}
					setAlertText={setAlertText}
					SetShowSuccessAlert={setShowSuccessAlert}
					SetShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
