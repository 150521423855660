import { Grid } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import {
	AssessedProfile,
	AssessmentProfile,
	AssessmentResultsForTargetId,
	AssessmentTypeEnum,
} from '../generated';
import { ResidentIdData } from './format';
import { getNetworkApi } from './keycloak';
import MotivationCard from './MotivationCard';

interface MotivationsGridViewProps {
	assessedProfilesList: AssessedProfile[];
	handleClickOpenConnectionForm: any;
	residentIdData: ResidentIdData;
	history: any;
	isAuthorized: boolean;
}

export default function MotivationsGridView({
	assessedProfilesList,
	handleClickOpenConnectionForm,
	residentIdData,
	history,
	isAuthorized,
}: MotivationsGridViewProps) {
	const [assessmentProfileList, setAssessmentProfileList] = useState(
		[] as AssessmentProfile[]
	);

	useEffect(() => {
		const loadAssessmentProfiles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getAssessmentProfilesForAssessmentType(
					residentIdData.tenantIdentifier,
					AssessmentTypeEnum.Motivation
				);
				setAssessmentProfileList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the data!');
					}
				} else {
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadAssessmentProfiles());
	}, [residentIdData.tenantIdentifier]);

	let assessmentProfileIds: number[] = assessmentProfileList.map(
		(el) => el.assessmentProfileId
	);

	const [assessmentResultsForTargetIds, setAssessmentResultsForTargetIds] =
		useState([] as AssessmentResultsForTargetId[]);

	useEffect(() => {
		const loadAssessmentResultsForTargetIds = async () => {
			const api = getNetworkApi();

			try {
				for (let id of assessmentProfileIds) {
					const result =
						await api.getAssessmentResultsForTargetIdsForAssessmentProfile(
							residentIdData.tenantIdentifier,
							residentIdData.unitIdentifier,
							residentIdData.residenceIdentifier,
							residentIdData.residentId,
							AssessmentTypeEnum.Motivation,
							id
						);
					setAssessmentResultsForTargetIds((prev) => ({ ...prev, result }));
				}
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the data!');
					}
				} else {
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadAssessmentResultsForTargetIds());
	}, [residentIdData[0], assessmentProfileIds[0]]);

	return (
		<Grid
			container
			spacing={{ xs: 2, lg: 3 }}
			columns={{ xs: 3, sm: 5, md: 7, lg: 9 }}>
			<Grid item xs={12} padding={5}
				style={{
					fontSize: '12',
					color: grey[500],
					textAlign: 'center',
				
				}}
			>
				{(assessedProfilesList?.length > 0 )?'' : 'Keine Fragebögen hinterlegt.'}
			</Grid>
			{assessedProfilesList.map((motivation) => (
				<Grid
					item
					xs={3}
					sm={3}
					md={3}
					lg={3}
					key={motivation.residentAssessmentId}>
					<MotivationCard
						motivation={motivation}
						residentIdData={residentIdData}
						onClickCard={() =>
							history.push({
								pathname:
									'/tenantId=' +
									residentIdData.tenantIdentifier +
									',residenceId=' +
									residentIdData.residenceIdentifier +
									',unitId=' +
									residentIdData.unitIdentifier +
									'/profile/' +
									'residentId=' +
									residentIdData.residentId +
									'/motivation/motivationResult/residentAssessmentId=' +
									motivation.residentAssessmentId +
									',assessmentProfileId=' +
									motivation.assessmentProfileId,
							})
						}
						onClickConnectButton={() =>
							handleClickOpenConnectionForm(motivation)
						}
						isAuthorized={isAuthorized}
					/>
				</Grid>
			))}
		</Grid>
	);
}
