/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GreetingMediaUpdateParameters
 */
export interface GreetingMediaUpdateParameters {
    /**
     * 
     * @type {Array<number>}
     * @memberof GreetingMediaUpdateParameters
     */
    mediaIds?: Array<number>;
}

export function GreetingMediaUpdateParametersFromJSON(json: any): GreetingMediaUpdateParameters {
    return GreetingMediaUpdateParametersFromJSONTyped(json, false);
}

export function GreetingMediaUpdateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): GreetingMediaUpdateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mediaIds': !exists(json, 'mediaIds') ? undefined : json['mediaIds'],
    };
}

export function GreetingMediaUpdateParametersToJSON(value?: GreetingMediaUpdateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mediaIds': value.mediaIds,
    };
}


