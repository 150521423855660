import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import {
	AppBar,
	Toolbar,
	Typography,
	Button,
	Tooltip,
	IconButton,
	Divider,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import {
	AdminPanelSettings,
	ArrowBack,
	Edit,
	Extension,
	Help,
	Logout,
	Theaters,
	ThumbUpOffAlt,
	EmojiPeople,
	QuestionAnswer,
	EventAvailable,
} from '@mui/icons-material';
import BarChartIcon from '@mui/icons-material/BarChart';
import type { KeycloakTokenParsed } from 'keycloak-js';
import { useLocation, useHistory } from 'react-router-dom';
import { BaseRole } from './Roles';
import { getNetworkApi } from './keycloak';
import { trackPromise } from 'react-promise-tracker';
import icon_tcc from '../data/icon_tcc_without_background.svg';
import DisplayLocalMedia from './DisplayLocalMedia';
import { isLandscape, ResidentIdData } from './format';
import { Resident } from '../generated';
import grey from '@mui/material/colors/grey';

const Menu = () => {
	type ParsedToken = KeycloakTokenParsed & {
		email?: string;
		preferred_username?: string;
		given_name?: string;
		family_name?: string;
		tenantIdentifier?: string;
	};
	const [showUserGuidelinesFile, setShowUserGuidelinesFile] = useState(false);
	const [showQuestionGuidelinesFile, setShowQuestionGuidelinesFile] =
		useState(false);

	const { keycloak, initialized } = useKeycloak();

	const location = useLocation();

	const parsedToken: ParsedToken | undefined = keycloak?.idTokenParsed;

	let tenantIdentifier = parsedToken?.tenantIdentifier;

	const [residentIdData, setResidentIdData] = useState(
		undefined as ResidentIdData
	);

	const [residentData, setResidentData] = useState({} as Resident);

	const Home = () => {
		const IsStartPage = location.pathname === '/';
		const history = useHistory();

		const revertToHome = () => {
			history.replace('/');
		};

		return (
			!IsStartPage && (
				<IconButton
					color='primary'
					aria-label='Zurück zur Übersicht'
					component='span'
					onClick={revertToHome}>
					<HomeIcon sx={{ fontSize: '30px' }} />
				</IconButton>
			)
		);
	};

	const BackButton = () => {
		const hasNoRevertButton = location.pathname === '/' || !location.key;
		const history = useHistory();

		const revertBack = () => {
			history.goBack();
		};

		return (
			!hasNoRevertButton && (
				<IconButton color='primary' component='span' onClick={revertBack}>
					<ArrowBack sx={{ fontSize: '30px' }} />
				</IconButton>
			)
		);
	};

	const AdminButton = () => {
		const [userRoleList, setUserRoleList] = useState([]);
		const isAdminPage = location.pathname === '/admin';
		const history = useHistory();

		useEffect(() => {
			const loadUserRoles = async () => {
				const api = getNetworkApi();
				try {
					const result = await api.getUserEffectiveRoles();
					setUserRoleList(result);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							console.log('User Unauthorized!');
						} else {
							console.log('There was an error fetching the data!');
						}
					} else {
						console.log('There was an error fetching the data!');
					}
				}
			};
			trackPromise(loadUserRoles());
		}, []);

		const isResidentAdminPermission = userRoleList.includes(
			'R-' + BaseRole.BEWOHNERADMINISTRATION + '-' + tenantIdentifier
		);

		const openAdminPage = () => {
			history.push({
				pathname: '/admin',
			});
		};

		return (
			!isAdminPage &&
			(
				<Tooltip
					sx={{ width: 'maxContent' }}
					title={isResidentAdminPermission ? 'Admin Bereich': 'Sie haben nicht die benötigten Rechte, um auf den Admin Bereich zuzugreifen'}>
					<IconButton  color='secondary' component='span'>
					<IconButton  disabled={!isResidentAdminPermission} color='primary' component='span' onClick={openAdminPage}>
						<AdminPanelSettings sx={{ fontSize: '30px' }} />
					</IconButton>
					</IconButton>
				</Tooltip>
			)
		);
	};

	function getUrlParameterWithDelimiter(
		parameterString: string,
		delimiter: string
	) {
		let parameter = undefined;
		let str = parameterString + '=';
		if (location.pathname.indexOf(str) !== -1) {
			let index = location.pathname.indexOf(str);
			parameter = location.pathname
				.substring(index + str.length)
				.split(delimiter)[0];
		}
		return parameter;
	}

	useEffect(() => {
		setResidentIdData({
			tenantIdentifier: getUrlParameterWithDelimiter('tenantId', ','),
			residenceIdentifier: getUrlParameterWithDelimiter('residenceId', ','),
			unitIdentifier: getUrlParameterWithDelimiter('unitId', '/'),
			residentId: Number(getUrlParameterWithDelimiter('residentId', '/')),
		});
	}, [location]);

	useEffect(() => {
		if (residentIdData && !isNaN(residentIdData.residentId)) {
			const loadResidentData = async () => {
				const api = getNetworkApi();
				try {
					const result = await api.getResident(
						residentIdData.tenantIdentifier,
						residentIdData.residenceIdentifier,
						residentIdData.unitIdentifier,
						residentIdData.residentId
					);
					setResidentData(result);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							console.log('User Unauthorized!');
						} else {
							console.log('There was an error fetching the data!');
						}
					} else {
						console.log('There was an error fetching the data!');
					}
				}
			};
			trackPromise(loadResidentData());
		}
	}, [residentIdData, location]);

	function getResidentName(residentData) {
		let fullName =
			residentData?.firstName !== undefined &&
			residentData?.surname !== undefined
				? residentData?.firstName + ' ' + residentData?.surname
				: '';
		let abbreviatedName =
			fullName.length > 20
				? residentData?.firstName.charAt(0) + '. ' + residentData?.surname
				: fullName;
		let conditionalName = isLandscape() ? fullName : abbreviatedName;
		return residentData !== undefined ? conditionalName : '';
	}

	function MenuTitle({ path, residentData }) {
		let heading = '';
		let icon;
		let residentName: string;
		let conditionalSeparator = isLandscape() ? ' - ' : '';
		let conditionalStyle = isLandscape() ? { mr: 2 } : { mr: 0 };
		let conditionalLineBreak = isLandscape() ? null : <br />;

		residentName = getResidentName(residentData);
		let hasResidentName = residentName.length > 0 ? true : false;

		if (path.pathname.match('/memories') != null) {
			heading = 'Erinnerungen';
			icon = <Extension sx={{ mr: 2, fontSize: '30px', color: grey[500] }} />;
		} else if (path.pathname.match('/memory') != null) {
			heading = 'Erinnerung';
			icon = <Extension sx={{ mr: 2, fontSize: '30px', color: grey[500] }} />;
		} else if (path.pathname.match('/media') != null) {
			heading = 'Medien';
			icon = (
				<Theaters
					sx={{ conditionalStyle, fontSize: '30px', color: grey[500] }}
				/>
			);
		} else if (path.pathname.match('/greetings') != null) {
			heading = 'Grüße';
			icon = (
				<EmojiPeople
					sx={{ conditionalStyle, fontSize: '30px', color: grey[500] }}
				/>
			);
		} else if (path.pathname.match('/editMotivationAssessment') != null) {
			heading = 'Fragebogen bearbeiten';
			icon = (
				<Edit sx={{ conditionalStyle, fontSize: '30px', color: grey[500] }} />
			);
		} else if (path.pathname.match('/motivationResult') != null) {
			heading = 'Motivprofilauswertung';
			icon = (
				<ThumbUpOffAlt
					sx={{ conditionalStyle, fontSize: '30px', color: grey[500] }}
				/>
			);
		} else if (path.pathname.match('/motivationChart') != null) {
			heading = 'Motive im Zeitverlauf';
			icon = (
				<BarChartIcon
					sx={{ conditionalStyle, fontSize: '30px', color: grey[500] }}
				/>
			);
		} else if (path.pathname.match('/motivation') != null) {
			heading = 'Motivprofile';
			icon = (
				<ThumbUpOffAlt
					sx={{ conditionalStyle, fontSize: '30px', color: grey[500] }}
				/>
			);
		} else if (path.pathname.match('/profile/') != null) {
			heading = 'Übersicht';
			icon = '';
		} else if (path.pathname.match('/admin') != null) {
			heading = 'Administrator Seite';
			icon = (
				<AdminPanelSettings
					sx={{ conditionalStyle, fontSize: '30px', color: grey[500] }}
				/>
			);
			hasResidentName = false;
		} else if (parsedToken) {
			heading = 'Übersicht';
			icon = '';
			hasResidentName = false;
		} else {
			heading = '';
			icon = '';
			hasResidentName = false;
		}

		return (
			<Typography
				variant={isLandscape() ? 'h5' : undefined}
				sx={{
					margin: 'auto',
					justifyContent: 'center',
					alignItems: 'center',
					display: 'flex',
				}}>
				{icon}
				{heading}
				{hasResidentName && conditionalSeparator}
				{conditionalLineBreak}
				{hasResidentName && residentName}
			</Typography>
		);
	}

	const HelpButton = () => {
		return (
			<Tooltip
				sx={{ width: 'maxContent' }}
				title={'Benutzerleitfaden anzeigen'}>
				<span>
					<IconButton
						color='primary'
						component='span'
						sx={{ marginLeft: 'auto', marginRight: 'auto' }}
						onClick={handleShowUserGuidelines}>
						<Help sx={{ fontSize: '30px' }} color='primary' elevation={5} />
					</IconButton>
				</span>
			</Tooltip>
		);
	};
	const QuestionAnswerButton = () => {
		return (
			<Tooltip
				sx={{ width: 'maxContent' }}
				title={'Biografiefragebogen anzeigen'}>
				<span>
					<IconButton
						color='primary'
						component='span'
						sx={{ marginLeft: 'auto', marginRight: 'auto' }}
						onClick={handleShowQuestionGuidelines}>
						<QuestionAnswer
							sx={{ fontSize: '30px' }}
							color='primary'
							elevation={5}
						/>
					</IconButton>
				</span>
			</Tooltip>
		);
	};

	const LogoutButton = () => {
		return (
			<Tooltip title={'Abmelden'}>
				<span>
					<IconButton
						color='primary'
						component='span'
						onClick={() => keycloak.logout()}>
						<Logout sx={{ fontSize: '30px' }} color='primary' elevation={5} />
					</IconButton>
				</span>
			</Tooltip>
		);
	};

	const handleShowUserGuidelines = () => {
		setShowUserGuidelinesFile(true);
	};

	const handleShowQuestionGuidelines = () => {
		setShowQuestionGuidelinesFile(true);
	};

	let conditionalStyle = isLandscape()
		? { marginLeft: 2, marginRight: 2 }
		: { marginLeft: 0, marginRight: 0 };

	const onLogin = () => {
		keycloak.init({
			onLoad: 'check-sso',
			pkceMethod: 'S256',
			silentCheckSsoRedirectUri:
				window.location.origin + '/silent-check-sso.html',
		});
		keycloak.login();
	};

	return (
		<React.Fragment>
			<AppBar
				color='secondary'
				style={{ borderRadius: '20px' }}
				position='sticky'>
				<Toolbar>
					{keycloak && !keycloak.authenticated && (
						<Button
							variant='contained'
							sx={{ marginLeft: 'auto' }}
							onClick={onLogin}>
							Anmelden
						</Button>
					)}
					{keycloak && keycloak.authenticated && (
						<React.Fragment>
							<img src={icon_tcc} width={50} alt='' />
							<Divider
								orientation='vertical'
								flexItem={true}
								variant='middle'
								sx={{
									marginLeft: '1%',
								}}
							/>
							<Typography sx={conditionalStyle} variant='h6'>
								Hallo {parsedToken?.given_name}
							</Typography>
							<AdminButton />
							<Home />
							<BackButton />
							<MenuTitle path={location} residentData={residentData} />
							<QuestionAnswerButton />
							<HelpButton />
							<LogoutButton />
						</React.Fragment>
					)}
				</Toolbar>
			</AppBar>
			{showUserGuidelinesFile && (
				<DisplayLocalMedia
					open={showUserGuidelinesFile}
					setOpen={setShowUserGuidelinesFile}
					filePathInPublicDir='/data/Benutzerleitfaden.pdf'
					mediaType={'pdf'}
				/>
			)}
			{showQuestionGuidelinesFile && (
				<DisplayLocalMedia
					open={showQuestionGuidelinesFile}
					setOpen={setShowQuestionGuidelinesFile}
					filePathInPublicDir='/data/Biografiefragen.pdf'
					mediaType={'pdf'}
				/>
			)}
		</React.Fragment>
	);
};

export default Menu;
