/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutsideInputStatusEnum,
    OutsideInputStatusEnumFromJSON,
    OutsideInputStatusEnumFromJSONTyped,
    OutsideInputStatusEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface OutsideInputMediaUpdateParameters
 */
export interface OutsideInputMediaUpdateParameters {
    /**
     * 
     * @type {OutsideInputStatusEnum}
     * @memberof OutsideInputMediaUpdateParameters
     */
    status?: OutsideInputStatusEnum;
}

export function OutsideInputMediaUpdateParametersFromJSON(json: any): OutsideInputMediaUpdateParameters {
    return OutsideInputMediaUpdateParametersFromJSONTyped(json, false);
}

export function OutsideInputMediaUpdateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutsideInputMediaUpdateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : OutsideInputStatusEnumFromJSON(json['status']),
    };
}

export function OutsideInputMediaUpdateParametersToJSON(value?: OutsideInputMediaUpdateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': OutsideInputStatusEnumToJSON(value.status),
    };
}


