import React, { useEffect, useState } from 'react';
import {
	AssessedQuestion,
	AssessmentProfile,
	AssessmentTypeEnum,
	Question,
	ResidentAssessmentInsertParameters,
} from '../generated';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	NativeSelect,
	Slider,
	Table,
	TableBody,
	TableContainer,
	TableRow,
	Tooltip,
} from '@mui/material';
import keycloak, { getNetworkApi } from '../components/keycloak';
import { trackPromise } from 'react-promise-tracker';
import { AddComment, Close, Info, Save } from '@mui/icons-material';
import QuestionCommentForm from '../components/QuestionCommentForm';
import { TableCellStyled } from '../theme';
import QuestionGuidanceForm from '../components/QuestionGuidanceForm';
import { getMarksForSlider, ResidentIdData } from '../components/format';
import { KeycloakTokenParsed } from 'keycloak-js';

interface MotivationAssessmentFormProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	residentIdData: ResidentIdData;
}

export default function MotivationAssessmentForm({
	open,
	setOpen,
	setAlertText,
	setShowSuccessAlert,
	setShowErrorAlert,
	residentIdData,
}: MotivationAssessmentFormProps) {
	const [assessmentProfileList, setAssessmentProfileList] = useState(
		[] as AssessmentProfile[]
	);
	const [questionList, setQuestionList] = useState([] as Question[]);

	const [assessmentProfileIndex, setAssessmentProfileIndex] = useState(0);
	const [returnValuesList, setReturnValuesList] = useState(
		[] as { id: number; assessedValue: number; comment: string }[]
	);
	const [currentQuestionId, setCurrentQuestionId] = useState(null as number);
	const [isEditResidentAssessment] = useState(false);

	const [showQuestionCommentForm, setShowQuestionCommentForm] = useState(false);
	const [showQuestionGuidanceForm, setShowQuestionGuidanceForm] =
		useState(false);

	type ParsedToken = KeycloakTokenParsed & {
		email?: string;
		preferred_username?: string;
		given_name?: string;
		family_name?: string;
	};

	const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;

	const handleIndexChange = (e) => setAssessmentProfileIndex(e.target.value);

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const loadAssessmentProfiles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getAssessmentProfilesForAssessmentType(
					residentIdData.tenantIdentifier,
					AssessmentTypeEnum.Motivation
				);
				setAssessmentProfileList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Fragebögen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Fragebögen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadAssessmentProfiles());
	}, [residentIdData.tenantIdentifier]);

	useEffect(() => {
		const loadQuestionData = async () => {
			const api = getNetworkApi();
			if (
				assessmentProfileList[assessmentProfileIndex].tenantIdentifier !==
				undefined
			) {
				try {
					const result = await api.getAssessmentQuestions(
						assessmentProfileList[assessmentProfileIndex].tenantIdentifier,
						assessmentProfileList[assessmentProfileIndex].assessmentProfileId
					);
					setQuestionList(result);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText(
								'Fragen für Fragebogen konnten nicht abgerufen werden'
							);
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'Fragen für Fragebogen konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadQuestionData());
	}, [assessmentProfileIndex, assessmentProfileList]);

	const handleEditQuestionComment = (inputQuestionId: number) => {
		setCurrentQuestionId(inputQuestionId);

		if (!returnValuesList.some((obj) => obj.id === inputQuestionId)) {
			let question = questionList.find((x) => x.questionId === inputQuestionId);
			let min = question?.scaleMin;
			let max = question?.scaleMax;

			setReturnValuesList((prev) => [
				...prev,
				{
					id: inputQuestionId,
					assessedValue: getDefaultValue(min, max),
					comment: void 0,
				},
			]);
		}

		setShowQuestionCommentForm(true);
	};

	const getDefaultValue = (min: number, max: number) => {
		return (min + max) / 2;
	};

	const handleDisplayQuestionGuidance = (questionId: number) => {
		setCurrentQuestionId(questionId);
		setShowQuestionGuidanceForm(true);
	};

	const handleSliderChange = (e, questionId: number) => {
		const { value } = e.target;
		setCurrentQuestionId(questionId);

		if (!returnValuesList.some((obj) => obj.id === questionId)) {
			setReturnValuesList((prev) => [
				...prev,
				{
					id: questionId,
					assessedValue: value,
					comment: void 0,
				},
			]);
		} else {
			let newReturnValuesList: {
				id: number;
				assessedValue: number;
				comment: string;
			}[] = returnValuesList;
			newReturnValuesList.find((obj) => obj.id === questionId).assessedValue =
				value;
			setReturnValuesList(newReturnValuesList);
		}
	};

	const saveAssessmentRequest = async () => {
		setOpen(false);
		let assessedQuestionList = [] as AssessedQuestion[];
		questionList.forEach((el) => {
			assessedQuestionList = [
				...assessedQuestionList,
				{
					//QuestionList elements
					questionId: el.questionId,
					question: el.question,
					tenantIdentifier: el.tenantIdentifier,
					scaleMin: el.scaleMin,
					scaleMax: el.scaleMax,
					guidanceMin: el.guidanceMin,
					guidanceMax: el.guidanceMax,
					guidance: el.guidance,
					rank: el.rank,

					//ReturnValueList elements
					assessedValue: returnValuesList.some((x) => x.id === el.questionId)
						? returnValuesList.find((x) => x.id === el.questionId).assessedValue
						: getDefaultValue(el.scaleMin, el.scaleMax),
					comment: returnValuesList.find((x) => x.id === el.questionId)
						?.comment,

					//yet undefined
					residentAssessmentId: void 0,
				},
			];
		});

		let insertParams = {
			assessingUser: parsedToken?.preferred_username,
			assessmentProfileId:
				assessmentProfileList[assessmentProfileIndex].assessmentProfileId,
			assessedQuestions: assessedQuestionList,
		} as ResidentAssessmentInsertParameters;
		const client = getNetworkApi();
		try {
			await client.postResidentAssessment(
				residentIdData.tenantIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.residentId,
				insertParams
			);
			setAlertText('Fragebogen wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			console.log('There was an error saving the assessment');
			setAlertText('Fragebogen wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const isNewComment = (questionId) => {
		return (
			typeof returnValuesList.find((obj) => obj.id === questionId)?.comment ===
				'undefined' ||
			returnValuesList.find((obj) => obj.id === questionId)?.comment ===
				(void 0 || '')
		);
	};

	return (
		<React.Fragment>
			<Dialog fullScreen={true} open={open} onClose={handleClose}>
				<DialogTitle>{'Motivationsprofil erfassen'}</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					{Object.entries(assessmentProfileList).length !== 0 ? (
						<Grid container direction='column' spacing={2} sx={{ padding: 5 }}>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='space-between'
									alignItems='center'>
									<Grid item xs={5}>
										<FormControl fullWidth>
											<InputLabel
												variant='standard'
												htmlFor='uncontrolled-native'>
												Fragebogen auswählen
											</InputLabel>
											<NativeSelect
												value={
													assessmentProfileList[assessmentProfileIndex].name
												}
												onChange={handleIndexChange}
												inputProps={{
													name: 'assessmentProfile',
													id: 'uncontrolled-native',
												}}
												sx={{ color: 'green' }}>
												{assessmentProfileList.map((el, index) => (
													<option key={index} value={index}>
														{el.name}
													</option>
												))}
											</NativeSelect>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							<TableContainer sx={{ maxHeight: '90%', pt: 3 }}>
								<Table sx={{ fontSize: 40 }}>
									<TableBody>
										{questionList.map((el, index) => (
											<TableRow key={el.questionId}>
												<TableCellStyled>{el.question}</TableCellStyled>
												<TableCellStyled width={30}></TableCellStyled>
												<TableCellStyled width={200}>
													{
														<Slider
															aria-label='answer-slider'
															defaultValue={getDefaultValue(
																el.scaleMin,
																el.scaleMax
															)}
															step={1}
															name='assessedValue'
															marks={getMarksForSlider(
																el.scaleMin,
																el.scaleMax,
																el.guidanceMin,
																el.guidanceMax
															)}
															min={el.scaleMin}
															max={el.scaleMax}
															valueLabelDisplay='auto'
															onChange={(e) =>
																handleSliderChange(e, el.questionId)
															}
														/>
													}
												</TableCellStyled>
												<TableCellStyled width={30}></TableCellStyled>
												<TableCellStyled>
													{
														<Tooltip
															title={
																isNewComment(el.questionId)
																	? 'Kommentar hinzufügen'
																	: 'Kommentar bearbeiten'
															}>
															<IconButton
																size='medium'
																onClick={() =>
																	handleEditQuestionComment(el.questionId)
																}>
																{
																	<AddComment
																		style={{
																			color: isNewComment(el.questionId)
																				? '#e0e0e0'
																				: 'green',
																		}}
																	/>
																}
															</IconButton>
														</Tooltip>
													}
												</TableCellStyled>
												<TableCellStyled width={10}>
													{el.guidance !== void 0 && (
														<Tooltip title='Guidance anzeigen'>
															<Button
																onClick={() =>
																	handleDisplayQuestionGuidance(el.questionId)
																}>
																{<Info />}
															</Button>
														</Tooltip>
													)}
												</TableCellStyled>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} {'Abbrechen'}
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant='contained'
											onClick={() => saveAssessmentRequest()}>
											{<Save />} Speichern
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					) : (
						<h3>Wird geladen ...</h3>
					)}
				</DialogContent>
			</Dialog>
			{showQuestionCommentForm && (
				<QuestionCommentForm
					open={showQuestionCommentForm}
					setOpen={setShowQuestionCommentForm}
					currentQuestionId={currentQuestionId}
					setReturnValuesList={setReturnValuesList}
					currentComment={
						returnValuesList.find((x) => x.id === currentQuestionId)?.comment
					}
					currentReturnValuesList={returnValuesList}
					isEditResidentAssessment={isEditResidentAssessment}
				/>
			)}
			{showQuestionGuidanceForm && (
				<QuestionGuidanceForm
					open={showQuestionGuidanceForm}
					setOpen={setShowQuestionGuidanceForm}
					currentGuidance={
						questionList.find((x) => x.questionId === currentQuestionId)
							.guidance
					}
				/>
			)}
		</React.Fragment>
	);
}
