/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ResidenceEventCategory,
    ResidenceEventCategoryFromJSON,
    ResidenceEventCategoryFromJSONTyped,
    ResidenceEventCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface ResidenceEventCategoryMapping
 */
export interface ResidenceEventCategoryMapping {
    /**
     * 
     * @type {ResidenceEventCategory}
     * @memberof ResidenceEventCategoryMapping
     */
    residenceEventCategory?: ResidenceEventCategory;
    /**
     * 
     * @type {string}
     * @memberof ResidenceEventCategoryMapping
     */
    residenceEventSubCategory?: string;
}

export function ResidenceEventCategoryMappingFromJSON(json: any): ResidenceEventCategoryMapping {
    return ResidenceEventCategoryMappingFromJSONTyped(json, false);
}

export function ResidenceEventCategoryMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceEventCategoryMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'residenceEventCategory': !exists(json, 'residenceEventCategory') ? undefined : ResidenceEventCategoryFromJSON(json['residenceEventCategory']),
        'residenceEventSubCategory': !exists(json, 'residenceEventSubCategory') ? undefined : json['residenceEventSubCategory'],
    };
}

export function ResidenceEventCategoryMappingToJSON(value?: ResidenceEventCategoryMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'residenceEventCategory': ResidenceEventCategoryToJSON(value.residenceEventCategory),
        'residenceEventSubCategory': value.residenceEventSubCategory,
    };
}


