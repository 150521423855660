/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Email,
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
    OutsideInputStatusEnum,
    OutsideInputStatusEnumFromJSON,
    OutsideInputStatusEnumFromJSONTyped,
    OutsideInputStatusEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface OutsideInput
 */
export interface OutsideInput {
    /**
     * 
     * @type {number}
     * @memberof OutsideInput
     */
    outsideInputId?: number;
    /**
     * 
     * @type {string}
     * @memberof OutsideInput
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {Date}
     * @memberof OutsideInput
     */
    techValidFrom?: Date;
    /**
     * 
     * @type {OutsideInputStatusEnum}
     * @memberof OutsideInput
     */
    status?: OutsideInputStatusEnum;
    /**
     * 
     * @type {Email}
     * @memberof OutsideInput
     */
    inputJson?: Email;
    /**
     * 
     * @type {number}
     * @memberof OutsideInput
     */
    residentId?: number;
    /**
     * 
     * @type {string}
     * @memberof OutsideInput
     */
    modifyingUser?: string;
}

export function OutsideInputFromJSON(json: any): OutsideInput {
    return OutsideInputFromJSONTyped(json, false);
}

export function OutsideInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutsideInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'outsideInputId': !exists(json, 'outsideInputId') ? undefined : json['outsideInputId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'techValidFrom': !exists(json, 'techValidFrom') ? undefined : (new Date(json['techValidFrom'])),
        'status': !exists(json, 'status') ? undefined : OutsideInputStatusEnumFromJSON(json['status']),
        'inputJson': !exists(json, 'inputJson') ? undefined : EmailFromJSON(json['inputJson']),
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
    };
}

export function OutsideInputToJSON(value?: OutsideInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'outsideInputId': value.outsideInputId,
        'tenantIdentifier': value.tenantIdentifier,
        'techValidFrom': value.techValidFrom === undefined ? undefined : (value.techValidFrom.toISOString().substr(0,10)),
        'status': OutsideInputStatusEnumToJSON(value.status),
        'inputJson': EmailToJSON(value.inputJson),
        'residentId': value.residentId,
        'modifyingUser': value.modifyingUser,
    };
}


