/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Residence
 */
export interface Residence {
    /**
     * 
     * @type {string}
     * @memberof Residence
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof Residence
     */
    residenceIdentifier?: string;
}

export function ResidenceFromJSON(json: any): Residence {
    return ResidenceFromJSONTyped(json, false);
}

export function ResidenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Residence {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'residenceIdentifier': !exists(json, 'residenceIdentifier') ? undefined : json['residenceIdentifier'],
    };
}

export function ResidenceToJSON(value?: Residence | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantIdentifier': value.tenantIdentifier,
        'residenceIdentifier': value.residenceIdentifier,
    };
}


