import { Close, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { getNetworkApi } from './keycloak';

import {
	ResidenceGroup,
	ResidenceGroupCategoryMapping,
	ResidenceGroupInsertParameters,
	ResidenceGroupUpdateParameters,
} from '../generated';

import {
	EventIdData,
	groupCategoryItems,
	isEmptyField,
	makeMenuItemForGroupSubCategories,
	makeMenuItemForValue,
} from './format';
import React, { useEffect } from 'react';
import SelectDate from './SelectDate';
import { trackPromise } from 'react-promise-tracker';

interface GroupFormProps {
	open: boolean;
	isReadOnly: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isNewGroup: boolean;
	groupIdData: EventIdData;
	displayGroup: boolean;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	currentGroupData: ResidenceGroup;
}

export default function GroupForm(prop: GroupFormProps) {
	const {
		open,
		setOpen,
		isReadOnly,
		isNewGroup,
		groupIdData,
		displayGroup,
		setAlertText,
		setShowSuccessAlert,
		setShowErrorAlert,
		currentGroupData,
	} = prop;

	const [groupValues, setGroupValues] = React.useState(
		currentGroupData as ResidenceGroup
	);

	const [residenceGroupCategoryMapping, setResidenceGroupCategoryMapping] =
		React.useState([] as ResidenceGroupCategoryMapping[]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setGroupValues({
			...groupValues,
			[name]: value,
		});
	};

	const handleInputChangeGroupCategory = (e) => {
		const { name, value } = e.target;
		setGroupValues({
			...groupValues,
			residenceGroupCategory: value,
			residenceGroupSubCategory: undefined,
		});
	};

	const saveNewGroup = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceGroupInsertParameters: ResidenceGroupInsertParameters = {
			name: groupValues.name,
			motto: groupValues.motto,
			residenceGroupCategory: groupValues.residenceGroupCategory,
			residenceGroupSubCategory: groupValues.residenceGroupSubCategory,
		};
		try {
			await client.postResidenceGroup(
				groupIdData.tenantIdentifier,
				groupIdData.residenceIdentifier,
				residenceGroupInsertParameters
			);
			setAlertText('Gruppe wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Gruppe wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateGroup = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceGroupUpdateParameters: ResidenceGroupUpdateParameters = {
			name: groupValues.name,
			motto: groupValues.motto,
			residenceGroupCategory: groupValues.residenceGroupCategory,
			residenceGroupSubCategory: groupValues.residenceGroupSubCategory,
		};
		try {
			await client.putResidentGroup(
				groupIdData.tenantIdentifier,
				groupIdData.residenceIdentifier,
				groupValues.residenceGroupId,
				residenceGroupUpdateParameters
			);
			setAlertText('Gruppe wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Gruppe wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	useEffect(() => {
		const loadSubCategories = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidenceGroupCategoryMapping(
					groupIdData.tenantIdentifier
				);
				setResidenceGroupCategoryMapping(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the SubCategories data!');
					}
				} else {
					console.log('There was an error fetching the Subcategories data!');
				}
			}
		};
		trackPromise(loadSubCategories());
	}, [groupIdData.tenantIdentifier]);

	const isSaveButtonEnabled = (GroupValues: ResidenceGroup) => {
		return !isEmptyField(GroupValues?.name);
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const makeSelectDate = (
		label: string,
		name: string,
		date: Date,
		values: ResidenceGroup,
		setValues,
		disabled: boolean,
		enableFuture: boolean
	) => {
		return (
			<SelectDate
				label={label}
				name={name}
				value={date === undefined ? null : date}
				Values={values}
				setValues={setValues}
				disabled={isReadOnly}
				enableFuture={enableFuture}
			/>
		);
	};

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth='md'>
				<DialogTitle>
					{displayGroup
						? 'Gruppe anzeigen'
						: [isNewGroup ? 'Gruppe anlegen' : 'Gruppe bearbeiten']}
				</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<TextField
										fullWidth
										label='Gruppe'
										name='name'
										id='outlined-size-normal'
										value={groupValues?.name}
										onChange={handleInputChange}
										inputProps={{ maxLength: 50 }}
										required
										disabled={displayGroup}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-helper-label'>
										Kategorie
									</InputLabel>
									{Object.entries(groupCategoryItems).length !== 0 && (
										<Select
											variant='outlined'
											label='Kategorie'
											name='residenceGroupCategory'
											onChange={handleInputChangeGroupCategory}
											defaultValue={groupValues?.residenceGroupCategory}
											disabled={displayGroup}>
											<MenuItem value={undefined}>
												{' '}
												<em>Unbekannt</em>{' '}
											</MenuItem>
											{groupCategoryItems.map(makeMenuItemForValue)}
										</Select>
									)}
								</FormControl>
							</Grid>
							<Grid item xs={8}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<TextField
										fullWidth
										multiline
										rows={4}
										label='Motto'
										name='motto'
										id='outlined-size-normal'
										value={groupValues?.motto}
										onChange={handleInputChange}
										inputProps={{ maxLength: 500 }}
										disabled={displayGroup}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-helper-label'>
										Unterkategorie
									</InputLabel>
									{Object.entries(residenceGroupCategoryMapping).length !==
										0 && (
										<Select
											variant='outlined'
											label='Unterkategorie'
											name='residenceGroupSubCategory'
											onChange={handleInputChange}
											defaultValue={groupValues?.residenceGroupSubCategory}
											disabled={displayGroup}>
											<MenuItem value={undefined}>
												{' '}
												<em>Unbekannt</em>{' '}
											</MenuItem>
											{residenceGroupCategoryMapping
												.filter(
													(el) =>
														el.residenceGroupCategory ===
														groupValues?.residenceGroupCategory
												)
												.map(makeMenuItemForGroupSubCategories)}
										</Select>
									)}
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} {displayGroup ? 'Schließen' : 'Abbrechen'}
										</Button>
									</Grid>
									{!displayGroup && (
										<Grid item>
											<Button
												variant='contained'
												disabled={!isSaveButtonEnabled(groupValues)}
												onClick={isNewGroup ? saveNewGroup : updateGroup}>
												{<Save />} Speichern
											</Button>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
