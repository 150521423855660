/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResidenceGroupCategory {
    Reading = 'READING',
    Sports = 'SPORTS',
    Cooking = 'COOKING',
    Music = 'MUSIC',
    Games = 'GAMES',
    Outside = 'OUTSIDE',
    Stories = 'STORIES',
    ManualWork = 'MANUAL_WORK',
    Entertainment = 'ENTERTAINMENT',
    Other = 'OTHER'
}

export function ResidenceGroupCategoryFromJSON(json: any): ResidenceGroupCategory {
    return ResidenceGroupCategoryFromJSONTyped(json, false);
}

export function ResidenceGroupCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResidenceGroupCategory {
    return json as ResidenceGroupCategory;
}

export function ResidenceGroupCategoryToJSON(value?: ResidenceGroupCategory | null): any {
    return value as any;
}

