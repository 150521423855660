import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import { Close, Save } from '@mui/icons-material';
import {
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	useMediaQuery,
	useTheme,
	RadioGroup,
	Radio
} from '@mui/material';
import { getNetworkApi } from './keycloak';

import { Note, Resident, StageOfLife } from '../generated';
import { Tag } from '../generated/models/Tag';
import {
	isTagTicked,
	makeMenuItemForName,
	makeMenuItemForValue,
	noteCategoryItems,
	ResidentIdData,
} from './format';

interface NoteFormProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	IsNewMemory: boolean;
	residentIdData: ResidentIdData;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	SetShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	SetShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	CurrentMemoryData: Note;
	setCurrentMemoryData: React.Dispatch<React.SetStateAction<Note>>;
}

export default function NoteForm(prop: NoteFormProps) {
	const {
		open,
		setOpen,
		IsNewMemory,
		residentIdData,
		setAlertText,
		SetShowSuccessAlert,
		SetShowErrorAlert,
		CurrentMemoryData,
		setCurrentMemoryData,
	} = prop;

	const [StagesOfLifeList, setStagesOfLifeList] = useState([] as StageOfLife[]);

	const [Tags, setTags] = useState([] as Tag[]);
	const [TagValues, setTagValues] = useState(CurrentMemoryData.tags as Tag[]);
	const [MemoryValues, setMemoryValues] = React.useState(
		CurrentMemoryData as Note
	);

	const handleClose = () => {
		setOpen(false);
	};

	const handleRadioChange = (event) => {
	setMemoryValues((prevMemoryValues) => ({
		...prevMemoryValues,
		relevant: event.target.value === "true"
	}));
	};



	const getTagValue = (e) => {
		if (Object.entries(TagValues).length !== 0) {
			if (e.target.checked) {
				setTagValues((prevTags) => [
					...prevTags,
					{ tagId: Number(e.target.value) },
				]);
			} else {
				setTagValues(
					TagValues.filter((item) => item.tagId !== Number(e.target.value))
				);
			}
		} else {
			setTagValues([{ tagId: Number(e.target.value) }]);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setMemoryValues({
			...MemoryValues,
			[name]: value,
		});
	};


	useEffect(() => {
		const loadStagesOfLifeandTags = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getStagesOfLife(
					residentIdData.tenantIdentifier
				);
				setStagesOfLifeList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the StagesOfLife data!');
					}
				} else {
					console.log('There was an error fetching the StagesOfLife data!');
				}
			}
			try {
				const result = await api.getTags(residentIdData.tenantIdentifier);
				setTags(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the available Tags data!');
					}
				} else {
					console.log('There was an error fetching the available Tags data!');
				}
			}
		};
		trackPromise(loadStagesOfLifeandTags());
	}, [residentIdData.tenantIdentifier]);

	const saveNewMemory = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.postNote(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				{
					title: MemoryValues.title,
					content: MemoryValues?.content,
					stageOfLife:
						MemoryValues.stageOfLife !== 'Unbekannt' &&
						MemoryValues.stageOfLife !== ''
							? MemoryValues.stageOfLife
							: undefined,
					pointInTime: MemoryValues?.pointInTime,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					category: MemoryValues.category,
					relevant: MemoryValues.relevant
				}
			);
			setAlertText('Erinnerung wurde erfolgreich gespeichert');
			SetShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Erinnerung wurde nicht gespeichert');
			SetShowErrorAlert(true);
		}
	};

	const updateMemory = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.putNote(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				CurrentMemoryData.noteId,
				{
					title: MemoryValues.title,
					content: MemoryValues?.content,
					stageOfLife:
						MemoryValues.stageOfLife !== 'Unbekannt' &&
						MemoryValues.stageOfLife !== ''
							? MemoryValues.stageOfLife
							: undefined,
					pointInTime: MemoryValues?.pointInTime,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					category: MemoryValues.category,
					relevant: MemoryValues.relevant
				}
			);
			setAlertText('Erinnerung wurde erfolgreich geändert');
			SetShowSuccessAlert(true);
			setCurrentMemoryData(MemoryValues);
		} catch (error) {
			setAlertText('Erinnerung wurde nicht geändert');
			SetShowErrorAlert(true);
		}
	};

	const isSaveButtonEnabled = (MemoryValues: any) => {
		return MemoryValues.title !== '';
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth='md'>
				<DialogTitle>
					{IsNewMemory ? 'Erinnerung anlegen' : 'Erinnerung bearbeiten'}
				</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<TextField
										fullWidth
										label='Erlebnis'
										name='title'
										id='outlined-size-normal'
										value={MemoryValues.title}
										onChange={handleInputChange}
										inputProps={{ maxLength: 100 }}
										required
									/>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-helper-label'>
										Lebensabschnitt
									</InputLabel>
									{Object.entries(StagesOfLifeList).length !== 0 && (
										<Select
											variant='outlined'
											label='Lebensabschnitt'
											name='stageOfLife'
											onChange={handleInputChange}
											defaultValue={CurrentMemoryData.stageOfLife}>
											<MenuItem value={'Unbekannt'}>
												{' '}
												<em>Unbekannt</em>{' '}
											</MenuItem>
											{StagesOfLifeList.map(makeMenuItemForName)}
										</Select>
									)}
								</FormControl>
							</Grid>
							<Grid item xs={8}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<TextField
										fullWidth
										multiline
										rows={3.4}
										label='Beschreibung'
										name='content'
										id='outlined-size-normal'
										value={MemoryValues.content}
										onChange={handleInputChange}
										inputProps={{ maxLength: 500 }}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<TextField
										label='Augenblick'
										name='pointInTime'
										id='outlined-size-normal'
										value={MemoryValues.pointInTime}
										onChange={handleInputChange}
										inputProps={{ maxLength: 50 }}
									/>
								</FormControl>
								<FormControl
									variant='outlined'
									sx={{ width: '100%' }}
									margin='normal'>
									<InputLabel id='demo-simple-select-helper-label'>
										Kategorie
									</InputLabel>
									{Object.entries(noteCategoryItems).length !== 0 && (
										<Select
											variant='outlined'
											label='Kategorie'
											name='category'
											onChange={handleInputChange}
											defaultValue={CurrentMemoryData.category}>
											<MenuItem value={undefined}>
												{' '}
												<em>Unbekannt</em>{' '}
											</MenuItem>
											{noteCategoryItems.map(makeMenuItemForValue)}
										</Select>
									)}
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<FormLabel>Gefühle</FormLabel>
									<FormGroup>
										<Grid container columnSpacing={2} rowSpacing={0}>
											{Tags.filter(
												(el) => el.tagGroupIdentifier === 'EMOTION'
											).map((item, index) => (
												<Grid item xs={4} key={index}>
													<FormControlLabel
														key={item.tagId}
														value={item.tagId}
														checked={isTagTicked(TagValues, item.tagId)}
														control={<Checkbox />}
														label={item.tagAbbreviation}
														onChange={(e) => getTagValue(e)}
													/>
												</Grid>
											))}
										</Grid>
									</FormGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<Tooltip
										title='Tags bzgl. der Strukturierten Informationssammlung'
										placement='top'>
										<FormLabel>SIS Tag</FormLabel>
									</Tooltip>
									<FormGroup>
										<Grid container columnSpacing={2} rowSpacing={0}>
											{Tags.filter((el) => el.tagGroupIdentifier === 'SIS').map(
												(item, index) => (
													<Grid item xs={4} key={index}>
														<FormControlLabel
															key={item.tagId}
															value={item.tagId}
															checked={isTagTicked(TagValues, item.tagId)}
															control={<Checkbox />}
															label={item.tagAbbreviation}
															onChange={getTagValue}
														/>
													</Grid>
												)
											)}
										</Grid>
									</FormGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
							<FormControl>
								<Tooltip title="Pflegerelevant?" placement="top">
								<FormLabel>Pflegerelevant?</FormLabel>
								</Tooltip>
								<FormControl>
										<RadioGroup
											row
											aria-labelledby="demo-controlled-radio-buttons-group"
											name="controlled-radio-buttons-group"
											value={MemoryValues.relevant}
											onChange={handleRadioChange}
										>
									<FormControlLabel value={true} control={<Radio />} label="Ja" />
									<FormControlLabel value={false} control={<Radio />} label="Nein" />
								</RadioGroup>
								</FormControl>
							</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} Abbrechen
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant='contained'
											disabled={!isSaveButtonEnabled(MemoryValues)}
											onClick={IsNewMemory ? saveNewMemory : updateMemory}>
											{<Save />} Speichern
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
