import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	TextField,
	Grid,
	ThemeProvider,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	useTheme,
	useMediaQuery,
	Tooltip,
	FormGroup,
	FormLabel,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio
} from '@mui/material';
import { Close, Save } from '@mui/icons-material';
import { Tag } from '../generated/models/Tag';
import React, { useEffect, useState } from 'react';
import { getNetworkApi } from './keycloak';
import { Relative, RelativeRole, RelativeType } from '../generated';
import { trackPromise } from 'react-promise-tracker';
import SelectDate from './SelectDate';
import {
	isEmptyField,
	isValidBirthDate,
	isValidDate,
	makeMenuItemForName,
	makeMenuItemForValue,
	visitFrequencyItems,
	isTagTicked
} from './format';
import SelectRadioButton from './SelectRadioButton';

export default function RelativeForm({
	open,
	setOpen,
	IsNewRelative: isNewRelative,
	isReadOnly,
	CurrentRelative: currentRelative,
	ResidenceDetails: residenceDetails,
	setAlertText,
	SetShowSuccessAlert: setShowSuccessAlert,
	SetShowErrorAlert: setShowErrorAlert,
}) {
	const [relativeValues, setRelativeValues] = React.useState(
		currentRelative as Relative
	);
	const [Tags, setTags] = useState([] as Tag[]);
	const [TagValues, setTagValues] = useState(relativeValues.tags as Tag[]);

	const [allRelativeTypesUnordered, setAllRelativeTypesUnordered] = useState(
		[] as RelativeType[]
	);

	const [allRelativeRoles, setAllRelativeRoles] = useState(
		[] as RelativeRole[]
	);

	const handleRadioChange = (event) => {
	setRelativeValues((prevRelativeValues) => ({
		...prevRelativeValues,
		relevant: event.target.value === "true"
	}));
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setRelativeValues({
			...relativeValues,
			[name]: value,
		});
	};

	const getTagValue = (e) => {
		if (Object.entries(TagValues).length !== 0) {
			if (e.target.checked) {
				setTagValues((prevTags) => [
					...prevTags,
					{ tagId: Number(e.target.value) },
				]);
			} else {
				setTagValues(
					TagValues.filter((item) => item.tagId !== Number(e.target.value))
				);
			}
		} else {
			setTagValues([{ tagId: Number(e.target.value) }]);
		}
	};



	const isSaveButtonEnabled = (relative: Relative) => {
		return (
			!isEmptyField(relative?.firstName) &&
			!isEmptyField(relative?.surname) &&
			!isEmptyField(relative?.relativeType) &&
			(isEmptyField(relative?.birthday) ||
				isValidBirthDate(relative?.birthday)) &&
			(isEmptyField(relative?.dateOfDeath) ||
				isValidDate(relative?.dateOfDeath))

		);
	};

	const saveNewRelative = async () => {
		setOpen(false);
		const client = getNetworkApi();

		try {
			await client.postRelative(
				residenceDetails.tenantIdentifier,
				residenceDetails.residenceIdentifier,
				residenceDetails.unitIdentifier,
				currentRelative.residentId,
				{
					firstName: relativeValues.firstName,
					surname: relativeValues.surname,
					relativeType: relativeValues.relativeType,
					relativeRole: relativeValues.relativeRole,
					comment: relativeValues.comment,
					lifeStage: relativeValues.lifeStage,
					gender: relativeValues.gender,
					education: relativeValues.education,
					profession: relativeValues.profession,
					relationship: relativeValues.relationship,
					visitFrequency: relativeValues.visitFrequency,
					birthday:
						relativeValues.birthday != null
							? relativeValues.birthday
							: undefined,
					dateOfDeath:
						relativeValues.dateOfDeath != null
							? relativeValues.dateOfDeath
							: undefined,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: relativeValues.relevant
				}
			);
			setAlertText('Bezugsperson wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Bezugsperson wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	useEffect(() => {
		const loadRelativeTypesAndTags = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getRelativeTypes(
					residenceDetails.tenantIdentifier,
					residenceDetails.residenceIdentifier,
					residenceDetails.unitIdentifier
				);
				setAllRelativeTypesUnordered(
					result.sort((a: RelativeType, b: RelativeType) =>
						a.name.localeCompare(b.name)
					)
				);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the RelativeType data!');
					}
				} else {
					console.log('There was an error fetching the RelativeType data!');
				}
			}
					try {
				const result = await api.getTags(residenceDetails.tenantIdentifier);
				console.log(result)
				setTags(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the available Tags data!');
					}
				} else {
					console.log('There was an error fetching the available Tags data!');
				}
			}
		};
		trackPromise(loadRelativeTypesAndTags());
	}, [
		residenceDetails.tenantIdentifier,
		residenceDetails.residenceIdentifier,
		residenceDetails.unitIdentifier,
	]);

	// move "Sonstige" to back
	let allRelativeTypes = allRelativeTypesUnordered;
	let indexSonstige = allRelativeTypesUnordered.findIndex(
		(el) => el.name === 'Sonstige'
	);

	if (indexSonstige > -1) {
		let allRelativeTypesOrdered = allRelativeTypesUnordered;
		allRelativeTypesOrdered.push(
			allRelativeTypesOrdered.splice(indexSonstige, 1)[0]
		);
		allRelativeTypes = allRelativeTypesOrdered;
	}

	useEffect(() => {
		const loadRelativeRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getRelativeRoles(
					residenceDetails.tenantIdentifier,
					residenceDetails.residenceIdentifier,
					residenceDetails.unitIdentifier
				);
				setAllRelativeRoles(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the RelativeRole data!');
					}
				} else {
					console.log('There was an error fetching the RelativeRole data!');
				}
			}
		};
		trackPromise(loadRelativeRoles());
	}, [
		residenceDetails.tenantIdentifier,
		residenceDetails.residenceIdentifier,
		residenceDetails.unitIdentifier,
	]);

	/**update Relative */
	const updateRelative = async () => {
		setOpen(false);
		const client = getNetworkApi();
		try {
			await client.putRelative(
				residenceDetails.tenantIdentifier,
				residenceDetails.residenceIdentifier,
				residenceDetails.unitIdentifier,
				currentRelative.residentId,
				currentRelative.relativeId,
				{
					firstName: relativeValues.firstName,
					surname: relativeValues.surname,
					relativeType: relativeValues.relativeType,
					relativeRole: relativeValues.relativeRole,
					comment: relativeValues.comment,
					lifeStage: relativeValues.lifeStage,
					gender: relativeValues.gender,
					education: relativeValues.education,
					profession: relativeValues.profession,
					relationship: relativeValues.relationship,
					visitFrequency: relativeValues.visitFrequency,
					birthday:
						relativeValues.birthday != null
							? relativeValues.birthday
							: undefined,
					dateOfDeath:
						relativeValues.dateOfDeath != null
							? relativeValues.dateOfDeath
							: undefined,
					tags: Object.entries(TagValues).length !== 0 ? TagValues : undefined,
					relevant: relativeValues.relevant
				}
			);
			setAlertText('Bezugsperson wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Bezugsperson wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};


	const MakeTextField = (
		label: string,
		name: string,
		value: string,
		onChange,
		isRequired: boolean,
		disabled: boolean,
		maxLength: number,
		multiline: boolean
	) => {
		return (
			<TextField
				fullWidth
				multiline={multiline}
				label={label}
				name={name}
				id='outlined-size-normal'
				value={value}
				onChange={onChange}
				required={isRequired}
				disabled={disabled}
				inputProps={{
					maxLength: maxLength,
				}}
			/>
		);
	};

	const MakeSelectDate = (
		label: string,
		name: string,
		date: Date,
		values: Relative,
		setValues,
		disabled: boolean
	) => {
		return (
			<SelectDate
				label={label}
				name={name}
				value={date === undefined ? null : date}
				Values={values}
				setValues={setValues}
				disabled={disabled}
			/>
		);
	};

	const genderItems = [
		{ id: 'male', title: 'männlich' },
		{ id: 'female', title: 'weiblich' },
		{ id: 'divers', title: 'divers' },
	];

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					maxWidth='md'>
					<DialogTitle>
						{isReadOnly
							? 'Details'
							: [
									isNewRelative
										? 'Neue Bezugsperson anlegen'
										: 'Bezugsperson bearbeiten',
							  ]}
					</DialogTitle>
					<div className='new-line' />
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={4}>
									{MakeTextField(
										'Vorname(n)',
										'firstName',
										relativeValues.firstName,
										handleInputChange,
										true,
										isReadOnly,
										100,
										false
									)}
								</Grid>
								<Grid item xs={4}>
									{MakeTextField(
										'Nachname(n)',
										'surname',
										relativeValues.surname,
										handleInputChange,
										true,
										isReadOnly,
										100,
										false
									)}
								</Grid>
								<Grid item xs={4}>
									<FormControl
										variant='outlined'
										required
										sx={{ width: '100%' }}>
										<InputLabel id='demo-simple-select-helper-label'>
											Beziehung
										</InputLabel>
										{Object.entries(allRelativeTypes).length !== 0 && (
											<Select
												variant='outlined'
												label='Beziehung'
												name='relativeType'
												onChange={handleInputChange}
												disabled={isReadOnly}
												defaultValue={
													relativeValues.relativeType
														? relativeValues.relativeType
														: ''
												}>
												{allRelativeTypes.map(makeMenuItemForName)}
											</Select>
										)}
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									<FormControl variant='outlined' sx={{ width: '100%' }}>
										<InputLabel id='demo-simple-select-helper-label'>
											Rolle
										</InputLabel>
										{Object.entries(allRelativeRoles).length !== 0 && (
											<Select
												variant='outlined'
												label='Rolle'
												name='relativeRole'
												disabled={isReadOnly}
												onChange={handleInputChange}
												defaultValue={relativeValues.relativeRole}>
												<MenuItem>
													{' '}
													<em>unbekannt</em>{' '}
												</MenuItem>
												{allRelativeRoles.map(makeMenuItemForName)}
											</Select>
										)}
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									{MakeSelectDate(
										'Geburtstag',
										'birthday',
										relativeValues.birthday,
										relativeValues,
										setRelativeValues,
										isReadOnly
									)}
								</Grid>
								<Grid item xs={4}>
									{MakeSelectDate(
										'Todestag',
										'dateOfDeath',
										relativeValues.dateOfDeath,
										relativeValues,
										setRelativeValues,
										isReadOnly
									)}
								</Grid>
								<Grid item xs={12}>
									<SelectRadioButton
										name='gender'
										label='Geschlecht'
										value={relativeValues.gender}
										isDisplay={isReadOnly}
										onChange={handleInputChange}
										items={genderItems}
										inputProps={{
											maxLength: 50,
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									{MakeTextField(
										'Studium',
										'education',
										relativeValues.education,
										handleInputChange,
										false,
										isReadOnly,
										100,
										true
									)}
								</Grid>
								<Grid item xs={4}>
									{MakeTextField(
										'Beruf',
										'profession',
										relativeValues.profession,
										handleInputChange,
										false,
										isReadOnly,
										100,
										true
									)}
								</Grid>
								<Grid item xs={4}>
									{MakeTextField(
										'Altersstufe',
										'lifeStage',
										relativeValues.lifeStage,
										handleInputChange,
										false,
										isReadOnly,
										100,
										false
									)}
								</Grid>
								<Grid item xs={4}>
									<FormControl variant='outlined' sx={{ width: '100%' }}>
										<InputLabel id='demo-simple-select-helper-label'>
											Besuchshäufigkeit
										</InputLabel>
										{Object.entries(visitFrequencyItems).length !== 0 && (
											<Select
												variant='outlined'
												label='Besuchshäufigkeit'
												name='visitFrequency'
												disabled={isReadOnly}
												onChange={handleInputChange}
												defaultValue={currentRelative.visitFrequency}>
												<MenuItem value={undefined}>
													{' '}
													<em>Unbekannt</em>{' '}
												</MenuItem>
												{visitFrequencyItems.map(makeMenuItemForValue)}
											</Select>
										)}
									</FormControl>
								</Grid>
								<Grid item xs={4}>
									{MakeTextField(
										'Bindung',
										'relationship',
										relativeValues.relationship,
										handleInputChange,
										false,
										isReadOnly,
										100,
										true
									)}
								</Grid>
								<Grid item xs={4}>
									{MakeTextField(
										'Kommentar',
										'comment',
										relativeValues.comment,
										handleInputChange,
										false,
										isReadOnly,
										100,
										true
									)}
								</Grid>
								<Grid item xs={12}>
								<FormControl>
									<Tooltip
										title='Tags bzgl. der Strukturierten Informationssammlung'
										placement='top'>
										<FormLabel>SIS Tag</FormLabel>
									</Tooltip>
									<FormGroup>
										<Grid container columnSpacing={2} rowSpacing={0}>
											{Tags.filter(
												(el) => el.tagGroupIdentifier === 'SIS'
											).map((item, index) => (
												<Grid item xs={4} key={index}>
													<FormControlLabel
														key={item.tagId}
														value={item.tagId}
														checked={isTagTicked(TagValues, item.tagId)}
														control={<Checkbox />}
														label={item.tagAbbreviation}
														onChange={(e) => getTagValue(e)}
														disabled={isReadOnly}
													/>
												</Grid>
											))}
										</Grid>
									</FormGroup>
								</FormControl>
								</Grid>
								<Grid item xs={12}>
								<FormControl>
									<Tooltip title="Pflegerelevant?" placement="top">
									<FormLabel>Pflegerelevant?</FormLabel>
									</Tooltip>
									<FormControl>
											<RadioGroup
												row
												aria-labelledby="demo-controlled-radio-buttons-group"
												name="controlled-radio-buttons-group"
												value={relativeValues.relevant}
												onChange={handleRadioChange}
											>
										<FormControlLabel value={true} disabled={isReadOnly}  control={<Radio />} label="Ja" />
										<FormControlLabel value={false}  disabled={isReadOnly} control={<Radio />} label="Nein" />
									</RadioGroup>
									</FormControl>
								</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction='row'
										justifyContent='flex-end'
										alignItems='center'
										spacing={2}>
										<Grid item>
											<Button variant='contained' onClick={handleClose}>
												{<Close />} {isReadOnly ? 'Schließen' : 'Abbrechen'}
											</Button>
										</Grid>
										{!isReadOnly && (
											<Grid item>
												<Button
													variant='contained'
													disabled={!isSaveButtonEnabled(relativeValues)}
													onClick={
														isNewRelative ? saveNewRelative : updateRelative
													}>
													{<Save />} Speichern
												</Button>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
