import { PlayArrow } from '@mui/icons-material';
import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Divider,
	CardActions,
	Tooltip,
	Button,
	Badge,
} from '@mui/material';

import { Greeting } from '../generated';
import { TCCStyledBadgeButton, abbreviateString, formatDate } from './format';

interface GreetingCardProps {
	greeting: Greeting;
	onClickCard: any;
	onClickConnectButton: any;
	onClickMediaAlbum: any;
	isAuthorized: boolean;
}

export default function GreetingCard({
	greeting,
	onClickCard,
	onClickConnectButton,
	onClickMediaAlbum,
	isAuthorized,
}: GreetingCardProps) {
	let cardcolor = 'primary';

	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 10, height: '100%' }}
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
			}}
			elevation={5}>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={4}>
					<CardActions sx={{ justifyContent: 'right' }}>
						<TCCStyledBadgeButton
							onClick={onClickConnectButton}
							badgeContent={greeting.connects.length}
						/>
					</CardActions>
				</Grid>
				<Grid item xs={8}>
					<CardActionArea onClick={onClickCard}>
						<CardContent>
							<Typography
								align='left'
								fontSize='24'
								variant='h6'
								color={cardcolor}>
								{abbreviateString(greeting.title, 15)}
							</Typography>
						</CardContent>
					</CardActionArea>
				</Grid>
				<Grid item xs={12}>
					<Divider variant='middle'>
						{greeting !== undefined ? (
							<Typography fontSize={12} color={'primary'} variant={'body2'}>
								{' '}
								{greeting?.greetingPersonName}
								{greeting?.greetingPersonName &&
									formatDate(greeting?.techValidFrom) &&
									', '}
								{formatDate(greeting?.techValidFrom)}
							</Typography>
						) : (
							void 0
						)}
					</Divider>
				</Grid>
			</Grid>

			<CardActionArea onClick={onClickCard}>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={12}>
							<Typography
								fontSize={16}
								color={'primary'}
								variant={'body2'}
								justifyContent={'center'}
								marginTop={2}
								marginBottom={2}>
								{abbreviateString(greeting?.comment, 150)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={12}>
					<CardActions sx={{ justifyContent: 'center' }}>
						<Tooltip title={isAuthorized ? 'Medien anzeigen' : ''}>
							<span>
								<Button
									disabled={!isAuthorized || greeting.media?.length === 0}
									onClick={onClickMediaAlbum}>
									<Badge badgeContent={greeting.media?.length} color='primary'>
										<PlayArrow
											color={
												isAuthorized && greeting.media?.length > 0
													? 'primary'
													: 'secondary'
											}
										/>
									</Badge>
								</Button>
							</span>
						</Tooltip>
					</CardActions>
				</Grid>
			</Grid>
		</Card>
	);
}
