import { Grid } from '@mui/material';
import grey from '@mui/material/colors/grey';
import { Greeting, Note } from '../generated';
import GreetingCard from './GreetingCard';

interface GreetingsGridViewProps {
	greetingsList: Greeting[];
	handleDisplayGreeting: any;
	handleClickOpenConnectionForm: any;
	handleClickOpenMediaAlbumForm: any;
	isAuthorized: boolean;
}

export default function GreetingsGridView({
	greetingsList,
	handleDisplayGreeting,
	handleClickOpenConnectionForm,
	handleClickOpenMediaAlbumForm,
	isAuthorized,
}: GreetingsGridViewProps) {
	return (
		<Grid
			container
			spacing={{ xs: 2, lg: 3 }}
			columns={{ xs: 3, sm: 6, md: 9, lg: 12 }}>
			<Grid item xs={12} padding={5}
				style={{
					fontSize: '12',
					color: grey[500],
					textAlign: 'center',
				
				}}
			>
				{(greetingsList?.length > 0 )?'' : 'Keine Grüße hinterlegt.'}
			</Grid>
			{greetingsList.map((greeting) => (
				<Grid item xs={3} sm={3} md={3} lg={3} key={greeting.greetingId}>
					<GreetingCard
						greeting={greeting}
						onClickCard={() => handleDisplayGreeting(greeting)}
						onClickConnectButton={() => handleClickOpenConnectionForm(greeting)}
						onClickMediaAlbum={() => handleClickOpenMediaAlbumForm(greeting)}
						isAuthorized={isAuthorized}
					/>
				</Grid>
			))}
		</Grid>
	);
}
