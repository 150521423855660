/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Email,
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
    OutsideInputStatusEnum,
    OutsideInputStatusEnumFromJSON,
    OutsideInputStatusEnumFromJSONTyped,
    OutsideInputStatusEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface OutsideInputUpdateParameters
 */
export interface OutsideInputUpdateParameters {
    /**
     * 
     * @type {OutsideInputStatusEnum}
     * @memberof OutsideInputUpdateParameters
     */
    status?: OutsideInputStatusEnum;
    /**
     * 
     * @type {Email}
     * @memberof OutsideInputUpdateParameters
     */
    inputJson?: Email;
    /**
     * 
     * @type {number}
     * @memberof OutsideInputUpdateParameters
     */
    residentId?: number;
}

export function OutsideInputUpdateParametersFromJSON(json: any): OutsideInputUpdateParameters {
    return OutsideInputUpdateParametersFromJSONTyped(json, false);
}

export function OutsideInputUpdateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutsideInputUpdateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : OutsideInputStatusEnumFromJSON(json['status']),
        'inputJson': !exists(json, 'inputJson') ? undefined : EmailFromJSON(json['inputJson']),
        'residentId': !exists(json, 'residentId') ? undefined : json['residentId'],
    };
}

export function OutsideInputUpdateParametersToJSON(value?: OutsideInputUpdateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': OutsideInputStatusEnumToJSON(value.status),
        'inputJson': EmailToJSON(value.inputJson),
        'residentId': value.residentId,
    };
}


