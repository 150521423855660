import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Divider,
} from '@mui/material';
import grey from '@mui/material/colors/grey';
import { useHistory } from 'react-router-dom';
import { Note } from '../generated';
import {
	conditionalDividerText,
	determineTagIconInLine,
	abbreviateString,
} from './format';

interface NoteSummaryCardProps {
	title: string;
	icon: any;
	cardcolor: string;
	path: string;
	mostRecentNotesList: Note[];
}

export default function NoteSummaryCard({
	title,
	icon,
	cardcolor,
	path,
	mostRecentNotesList,
}: NoteSummaryCardProps) {
	let history = useHistory();

	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 5, height: '100%' }}
			elevation={5}>
			<CardActionArea onClick={() => history.push(path)}>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={3}>
							{icon}
						</Grid>
						<Grid item xs={6} textAlign={'center'}>
							<Typography variant='h5' color={cardcolor}>
								{' '}
								{title}{' '}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<Divider variant='middle' style={{ fontSize: 12 }}>
					{conditionalDividerText(mostRecentNotesList)}
				</Divider>
				<CardContent>
					<Grid
						container
						rowSpacing={2}
						columnSpacing={2}
						columns={{ xs: 3, md: 6, lg: 12 }}
						sx={{
							marginTop: 0,
							overflowX: 'auto',
						}}>
						{mostRecentNotesList.map((note, index) => (
							<>
								<Grid item xs={3} md={3} lg={4} style={{ fontSize: 2 }}>
									{note?.tags === undefined
										? void 0
										: note?.tags
												.filter((el) => el.tagGroupIdentifier === 'EMOTION')
												.map((el, index) =>
													determineTagIconInLine(el.tagAbbreviation)
												)}
								</Grid>
								<Grid
									item
									xs={3}
									md={3}
									lg={8}
									style={{ fontSize: 18, color: grey[500] }}>
									{abbreviateString(note.title, 30)}
								</Grid>
							</>
						))}
					</Grid>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
