/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkUpdateParameters
 */
export interface LinkUpdateParameters {
    /**
     * 
     * @type {string}
     * @memberof LinkUpdateParameters
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkUpdateParameters
     */
    linkString?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof LinkUpdateParameters
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof LinkUpdateParameters
     */
    relevant?: boolean;
}

export function LinkUpdateParametersFromJSON(json: any): LinkUpdateParameters {
    return LinkUpdateParametersFromJSONTyped(json, false);
}

export function LinkUpdateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkUpdateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'linkString': !exists(json, 'linkString') ? undefined : json['linkString'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function LinkUpdateParametersToJSON(value?: LinkUpdateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'linkString': value.linkString,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


