import React from 'react';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	ThemeProvider,
	Button,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import theme from '../theme';
import { OutsideInputMediaData, ResidentMediaData } from '../generated';
import DisplayContent from './DisplayContent';

interface DisplayMediaProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	metaData:
		| ResidentMediaData
		| OutsideInputMediaData
		| { fileName: string; mediaTypeIdentifier: string };
	mediaFile: Blob | File;
}

export default function DisplayMedia(prop: DisplayMediaProp) {
	const {
		open,
		setOpen,
		setAlertText,
		setShowErrorAlert,
		metaData,
		mediaFile,
	} = prop;

	const handleClose = () => {
		setOpen(false);
	};

	const getTitle = () => {
		return 'Zeige Datei: ' + metaData?.fileName;
	};

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog open={open} onClose={handleClose} maxWidth='lg'>
					<Grid item xs={12} marginRight={2} marginTop={2}>
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='center'
							spacing={2}>
							<Grid item>
								<Button variant='contained' onClick={handleClose}>
									{<Close />} Schließen
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<DialogTitle>{getTitle()}</DialogTitle>
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<DisplayContent
										mediaFile={mediaFile}
										mediaType={metaData.mediaTypeIdentifier}
										title={metaData.fileName}
										setAlertText={setAlertText}
										setShowErrorAlert={setShowErrorAlert}
									/>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
