import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Divider,
	CardActions,
	Button,
	Tooltip,
} from '@mui/material';

import DoorFrontIcon from '@mui/icons-material/DoorFront';
import PersonIcon from '@mui/icons-material/Person';
import { Info } from '@mui/icons-material';

import { Resident } from '../generated';
import { GetNewIcon, nameFieldForResidentCard } from './format';

interface ResidentProfileCardProps {
	resident: Resident;
	onClickCard: any;
	onClickInfoButton: any;
}

export default function ResidentProfileCard({
	resident,
	onClickCard,
	onClickInfoButton,
}: ResidentProfileCardProps) {
	let cardcolor = 'primary';
	let backgroundColor = 'grey';

	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 10, minHeight: 'maxContent' }}
			elevation={5}>
			<CardActionArea onClick={onClickCard}>
				<CardContent>
					{GetNewIcon(resident.residentSince, false)}
					<Grid container spacing={2} direction='row'>
						<Grid item xs={12}>
							<Typography align='center' fontSize='24' variant='h6' color={cardcolor}>
								{nameFieldForResidentCard(resident)}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<Divider variant='middle'></Divider>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={2}>
							<DoorFrontIcon color='primary' />
						</Grid>
						<Grid item xs={10}>
							<Typography fontSize={18} color={'primary'} variant={'body2'}>{resident.room}</Typography>
						</Grid>
						<Grid item xs={2}>
							<PersonIcon color='primary' />
						</Grid>
						<Grid item xs={10}>
							<Typography fontSize={18} color={'primary'} variant={'body2'}>
								{resident.associatedCaregiver}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Tooltip title='Zeige die letzten Änderungen'>
					<Button fullWidth onClick={onClickInfoButton}>
						<Info color='primary' />
					</Button>
				</Tooltip>
			</CardActions>
		</Card>
	);
}
