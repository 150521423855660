import React, { useCallback, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	ThemeProvider,
	Paper,
	Button,
	Tooltip,
} from '@mui/material';
import theme from '../theme';
import MaterialTable, { Column } from '@material-table/core';
import { MaterialTableIcons } from '../MaterialTableIcons';
import ForwardIcon from '@mui/icons-material/Forward';
import { formatDate } from '../components/format';
import { ChangeDateDetail, Resident } from '../generated';
import { useHistory } from 'react-router-dom';

export default function LastChangeInfo({
	open,
	setOpen,
	currentResident,
	UserRoleList,
}) {
	const handleClose = () => {
		setOpen(false);
	};

	enum Category {
		STAMMDATEN = 'Stammdaten',
		ERINNERUNGEN = 'Erinnerungen',
		PROFIL = 'Profil',
		MOTIVATIONSPROFIL = 'Motivationsprofil',
		GRUESSE = 'Gruesse',
		MEDIEN = 'Medien',
	}

	let history = useHistory();

	function getPathToResidentProfile(elementId: Number) {
		return '/tenantId=' +
				currentResident.tenantIdentifier +
				',residenceId=' +
				currentResident.residenceIdentifier +
				',unitId=' +
				currentResident.unitIdentifier +
				'/profile/' +
				'residentId=' +
				elementId;
	}

	function getPathToMemory(elementId: Number) {
		return '/tenantId=' +
				currentResident.tenantIdentifier +
				',residenceId=' +
				currentResident.residenceIdentifier +
				',unitId=' +
				currentResident.unitIdentifier +
				'/profile/' +
				'residentId=' +
				currentResident.residentId +
				'/memory/noteId=' +
				elementId;
	}

	function getPathToMotivations(elementId: Number) {
		return '/tenantId=' +
				currentResident.tenantIdentifier +
				',residenceId=' +
				currentResident.residenceIdentifier +
				',unitId=' +
				currentResident.unitIdentifier +
				'/profile/' +
				'residentId=' +
				currentResident.residentId +
				'/motivation/motivationResult/residentAssessmentId=' +
				elementId +
				',assessmentProfileId=1';
	}

	function getPathToGreeting(elementId: Number) {
		return '/tenantId=' +
				currentResident.tenantIdentifier +
				',residenceId=' +
				currentResident.residenceIdentifier +
				',unitId=' +
				currentResident.unitIdentifier +
				'/profile/' +
				'residentId=' +
				currentResident.residentId +
				'/greetings';
	}

	function getPathToMedia(elementId: Number) {
		return '/tenantId=' +
				currentResident.tenantIdentifier +
				',residenceId=' +
				currentResident.residenceIdentifier +
				',unitId=' +
				currentResident.unitIdentifier +
				'/profile/' +
				'residentId=' +
				currentResident.residentId +
				'/media';
	}

	const routeFromCategory = useCallback(
		(category: String, data: ChangeDateDetail) => {
			const myObj = {
				pathname: '',
			};
			const elementId = data.elementId;

			switch (category) {
				case Category.STAMMDATEN:
					myObj.pathname = getPathToResidentProfile(elementId);
					return myObj;
				case Category.ERINNERUNGEN:

					myObj.pathname = getPathToMemory(elementId);
					return myObj;
				case Category.MOTIVATIONSPROFIL:
					myObj.pathname = getPathToMotivations(elementId);
					return myObj;
				case Category.GRUESSE:
					myObj.pathname = getPathToGreeting(elementId);
					return myObj;
				case Category.MEDIEN:
					myObj.pathname = getPathToMedia(elementId);
					return myObj;
				default:
					myObj.pathname = getPathToResidentProfile(elementId);
					return myObj;
			}
		},
		[Category.ERINNERUNGEN, Category.GRUESSE, Category.MEDIEN, Category.MOTIVATIONSPROFIL, Category.STAMMDATEN, getPathToGreeting, getPathToMedia, getPathToMemory, getPathToMotivations, getPathToResidentProfile]
	);

	const columns: Column<any>[] = [
		{
			title: 'Geändert am',
			field: 'lastChangeDate',
			type: 'date',
			filtering: false,
			sorting: false,
			render: useCallback((data) => formatDate(data.lastChangeDate), []),
		},
		{
			title: 'Kategorie',
			field: 'category',
			filtering: false,
			sorting: false,
		},
		{
			field: 'category',
			filtering: false,
			sorting: false,
			render: useCallback(
				(data) => {
					return (
						<Tooltip title='Detailansicht'>
							<Button
								onClick={() =>
									history.push(
										routeFromCategory(
											data.category,
											data
										)
									)
								}>
								{<ForwardIcon color='primary' />}
							</Button>
						</Tooltip>
					);
				},
				[history, routeFromCategory]
			),
		},
	];

	const getTitle = (currentResident: Resident) => {
		let residentName =
			currentResident.firstName + ' ' + currentResident.surname;
		return 'Letzte Änderungen - ' + residentName;
	};

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					open={open}
					onClose={handleClose}
					fullWidth={true}
					maxWidth='md'>
					<DialogTitle>{getTitle(currentResident)}</DialogTitle>
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<MaterialTable
										localization={{
											body: {
												emptyDataSourceMessage: 'Keine Daten vorhanden.',
											},
										}}
										icons={MaterialTableIcons()}
										columns={columns}
										data={currentResident.lastChangeDateDetails}
										components={{
											Container: (props) => <Paper {...props} elevation={0} />,
										}}
										options={{
											paging: false,
											showTitle: false,
											sorting: false,
											filtering: false,
											search: false,
											rowStyle: { fontSize: 22 },
										}}
									/>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
