/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
    VisitFrequency,
    VisitFrequencyFromJSON,
    VisitFrequencyFromJSONTyped,
    VisitFrequencyToJSON,
} from './';

/**
 * 
 * @export
 * @interface RelativeInsertParameters
 */
export interface RelativeInsertParameters {
    /**
     * 
     * @type {string}
     * @memberof RelativeInsertParameters
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof RelativeInsertParameters
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof RelativeInsertParameters
     */
    relativeType?: string;
    /**
     * 
     * @type {string}
     * @memberof RelativeInsertParameters
     */
    relativeRole?: string;
    /**
     * 
     * @type {string}
     * @memberof RelativeInsertParameters
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof RelativeInsertParameters
     */
    birthday?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RelativeInsertParameters
     */
    dateOfDeath?: Date;
    /**
     * 
     * @type {string}
     * @memberof RelativeInsertParameters
     */
    lifeStage?: string;
    /**
     * 
     * @type {string}
     * @memberof RelativeInsertParameters
     */
    profession?: string;
    /**
     * 
     * @type {string}
     * @memberof RelativeInsertParameters
     */
    education?: string;
    /**
     * 
     * @type {string}
     * @memberof RelativeInsertParameters
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof RelativeInsertParameters
     */
    relationship?: string;
    /**
     * 
     * @type {VisitFrequency}
     * @memberof RelativeInsertParameters
     */
    visitFrequency?: VisitFrequency;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof RelativeInsertParameters
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof RelativeInsertParameters
     */
    relevant?: boolean;
}

export function RelativeInsertParametersFromJSON(json: any): RelativeInsertParameters {
    return RelativeInsertParametersFromJSONTyped(json, false);
}

export function RelativeInsertParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelativeInsertParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'relativeType': !exists(json, 'relativeType') ? undefined : json['relativeType'],
        'relativeRole': !exists(json, 'relativeRole') ? undefined : json['relativeRole'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'dateOfDeath': !exists(json, 'dateOfDeath') ? undefined : (new Date(json['dateOfDeath'])),
        'lifeStage': !exists(json, 'lifeStage') ? undefined : json['lifeStage'],
        'profession': !exists(json, 'profession') ? undefined : json['profession'],
        'education': !exists(json, 'education') ? undefined : json['education'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'relationship': !exists(json, 'relationship') ? undefined : json['relationship'],
        'visitFrequency': !exists(json, 'visitFrequency') ? undefined : VisitFrequencyFromJSON(json['visitFrequency']),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function RelativeInsertParametersToJSON(value?: RelativeInsertParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'surname': value.surname,
        'relativeType': value.relativeType,
        'relativeRole': value.relativeRole,
        'comment': value.comment,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substr(0,10)),
        'dateOfDeath': value.dateOfDeath === undefined ? undefined : (value.dateOfDeath.toISOString().substr(0,10)),
        'lifeStage': value.lifeStage,
        'profession': value.profession,
        'education': value.education,
        'gender': value.gender,
        'relationship': value.relationship,
        'visitFrequency': VisitFrequencyToJSON(value.visitFrequency),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


