import { Button, Tooltip, IconButton, Paper, Typography } from '@mui/material';
import {
	Edit,
	Delete,
	ControlPoint,
	Info,
	ContactPhone,
	Gavel,
} from '@mui/icons-material';
import {
	isAnniversary,
	isAuthorizedRelative,
	isCaringRelative,
	TCCStyledBadgeButton,
} from '../components/format';
import { FaCross } from 'react-icons/fa';
import { StyledCake } from '../Styles';
import TabPanel from './TabPanel';
import MaterialTable, { Column, MTableBodyRow } from '@material-table/core';
import { useCallback } from 'react';
import { Relative } from '../generated';
import { MaterialTableIcons } from '../MaterialTableIcons';

export default function RelativePanel({
	value,
	index,
	list,
	defaultValue,
	hasEditPermission,
	handleDisplay,
	handleEdit,
	handleDelete,
	handleConnect,
}) {
	const columns: Column<Relative>[] = [
		{
			width: '10%',
		},
		{
			field: 'description',
			width: '90%',
			filtering: false,
			render: useCallback(
				(data: Relative) => (
					<Typography variant='body2' color='primary' fontSize={16}>
						<TCCStyledBadgeButton
							badgeContent={data.connects.length}
							onClick={() => handleConnect(data)}
						/>
						{data.firstName + ' ' + data.surname}
						{data.dateOfDeath !== undefined && isAnniversary(data.dateOfDeath) && (
							<FaCross
								style={{
									fontSize: '24',
									color: 'primary',
									verticalAlign: 'middle',
									marginLeft: '10',
								}}
							/>
						)}
						&nbsp;
						{'(' + data.relativeType + ')'}
						&nbsp;
						{data.birthday !== undefined && isAnniversary(data.birthday) && (
							<StyledCake style={{ fontSize: '30' }} />
						)}
						&nbsp;
						{isAuthorizedRelative(data.relativeRole) && (
							<ContactPhone
								style={{
									verticalAlign: 'middle',
									fontSize: '24',
								}}
							/>
						)}
						&nbsp;
						{isCaringRelative(data.relativeRole) && (
							<Gavel
								style={{
									verticalAlign: 'middle',
									fontSize: '24',
								}}
							/>
						)}
						<Tooltip title='Details anzeigen'>
							<Button onClick={() => handleDisplay(data)}>
								{<Info color='primary' />}
							</Button>
						</Tooltip>
					</Typography>
				),
				[]
			),
			cellStyle: {
				maxWidth: '100%',
				whiteSpace: 'pre-line',
				textOverflow: 'ellipsis',
			},
		},
	];
	return (
		<TabPanel value={value} index={index}>
			<MaterialTable
				localization={{
					body: {
						emptyDataSourceMessage: 'Keine Einträge hinterlegt.',
					},
				}}
				style={{
					maxHeight: 293,
					overflow: 'auto',
					width: '100%',
					fontStyle: 'inherit',
				}}
				icons={MaterialTableIcons()}
				columns={columns}
				data={list}
				components={{
					Container: (props) => <Paper {...props} elevation={0} />,
					Row: (props) => (
						<MTableBodyRow
							key={props.relativeId}
							id={props.relativeId}
							{...props}
						/>
					),
				}}
				options={{
					paging: false,
					showTitle: false,
					sorting: false,
					filtering: false,
					toolbar: false,
					tableLayout: 'fixed',
					search: false,
					rowStyle: {
						fontSize: 18,
						height: 3,
					},
				}}
				detailPanel={[
					{
						tooltip: 'Aktionen',
						render: (row) => {
							return (
								<div key={row.rowData.relativeId}>
									{hasEditPermission ? (
										<Tooltip title='Archiviere Bezugsperson'>
											<Button
												onClick={() => handleDelete(row.rowData.relativeId)}>
												{<Delete sx={{ marginLeft: 5 }} color='primary' />}
											</Button>
										</Tooltip>
									) : (
										<Button disabled>{<Delete color='secondary' />}</Button>
									)}
									{hasEditPermission ? (
										<Tooltip title='Bearbeite Bezugsperson'>
											<Button onClick={() => handleEdit(false, row.rowData)}>
												{<Edit color='primary' />}
											</Button>
										</Tooltip>
									) : (
										<Button disabled>{<Edit color='secondary' />}</Button>
									)}
								</div>
							);
						},
					},
				]}
			/>
			{hasEditPermission ? (
				<Tooltip placement='right' title='Bezugsperson hinzufügen'>
					<IconButton
						sx={{
							position: 'sticky',
							right: '100%',
							marginTop: -2,
							marginBottom: -5,
						}}
						size='medium'
						onClick={() => handleEdit(true, defaultValue)}>
						{<ControlPoint color='primary' />}
					</IconButton>
				</Tooltip>
			) : (
				<IconButton size='medium' disabled>
					{<ControlPoint color='secondary' />}
				</IconButton>
			)}
		</TabPanel>
	);
}
