import { Close, Save } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { getNetworkApi } from './keycloak';

import {
	ResidenceEvent,
	ResidenceEventCategoryMapping,
	ResidenceEventInsertParameters,
	ResidenceEventUpdateParameters,
} from '../generated';

import {
	eventCategoryItems,
	EventIdData,
	isEmptyField,
	makeMenuItemForSubCategories,
	makeMenuItemForValue,
} from './format';
import React, { useEffect } from 'react';
import SelectDate from './SelectDate';
import { trackPromise } from 'react-promise-tracker';

interface EventFormProps {
	open: boolean;
	isReadOnly: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isNewEvent: boolean;
	eventIdData: EventIdData;
	displayEvent: boolean;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	currentEventData: ResidenceEvent;
}

export default function EventForm(prop: EventFormProps) {
	const {
		open,
		setOpen,
		isReadOnly,
		isNewEvent,
		eventIdData,
		displayEvent,
		setAlertText,
		setShowSuccessAlert,
		setShowErrorAlert,
		currentEventData,
	} = prop;

	const [eventValues, setEventValues] = React.useState(
		currentEventData as ResidenceEvent
	);

	const [residenceEventCategoryMapping, setResidenceEventCategoryMapping] =
		React.useState([] as ResidenceEventCategoryMapping[]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setEventValues({
			...eventValues,
			[name]: value,
		});
	};

	const saveNewEvent = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceEventInsertParameters: ResidenceEventInsertParameters = {
			title: eventValues.title,
			description: eventValues.description,
			residenceEventCategory: eventValues.residenceEventCategory,
			residenceEventSubCategory: eventValues.residenceEventSubCategory,
			eventDate:
				eventValues.eventDate != null ? eventValues.eventDate : undefined,
		};
		try {
			await client.postResidenceEvent(
				eventIdData.tenantIdentifier,
				eventIdData.residenceIdentifier,
				residenceEventInsertParameters
			);
			setAlertText('Event wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Event wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateEvent = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let residenceEventUpdateParameters: ResidenceEventUpdateParameters = {
			title: eventValues.title,
			description: eventValues.description,
			residenceEventCategory: eventValues.residenceEventCategory,
			residenceEventSubCategory: eventValues.residenceEventSubCategory,
			eventDate:
				eventValues.eventDate != null ? eventValues.eventDate : undefined,
		};
		try {
			await client.putResidentEvent(
				eventIdData.tenantIdentifier,
				eventIdData.residenceIdentifier,
				eventValues.residenceEventId,
				residenceEventUpdateParameters
			);
			setAlertText('Event wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Event wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	useEffect(() => {
		const loadSubCategories = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidenceEventCategoryMapping(
					eventIdData.tenantIdentifier
				);
				setResidenceEventCategoryMapping(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the SubCategories data!');
					}
				} else {
					console.log('There was an error fetching the Subcategories data!');
				}
			}
		};
		trackPromise(loadSubCategories());
	}, [eventIdData.tenantIdentifier]);

	const isSaveButtonEnabled = (EventValues: ResidenceEvent) => {
		return !isEmptyField(EventValues?.title);
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const makeSelectDate = (
		label: string,
		name: string,
		date: Date,
		values: ResidenceEvent,
		setValues,
		disabled: boolean,
		enableFuture: boolean
	) => {
		return (
			<SelectDate
				label={label}
				name={name}
				value={date === undefined ? null : date}
				Values={values}
				setValues={setValues}
				disabled={isReadOnly}
				enableFuture={enableFuture}
			/>
		);
	};

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth='md'>
				<DialogTitle>
					{displayEvent
						? 'Event anzeigen'
						: [isNewEvent ? 'Event anlegen' : 'Event bearbeiten']}
				</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={8}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<TextField
										fullWidth
										label='Event'
										name='title'
										id='outlined-size-normal'
										value={eventValues?.title}
										onChange={handleInputChange}
										inputProps={{ maxLength: 50 }}
										required
										disabled={displayEvent}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									{makeSelectDate(
										'Datum',
										'eventDate',
										eventValues?.eventDate,
										eventValues,
										setEventValues,
										isReadOnly,
										true
									)}
								</FormControl>
							</Grid>
							<Grid item xs={8}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<TextField
										fullWidth
										multiline
										rows={8}
										label='Beschreibung'
										name='description'
										id='outlined-size-normal'
										value={eventValues?.description}
										onChange={handleInputChange}
										inputProps={{ maxLength: 500 }}
										disabled={displayEvent}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant='outlined' sx={{ width: '100%' }}>
									<InputLabel id='demo-simple-select-helper-label'>
										Kategorie
									</InputLabel>
									{Object.entries(eventCategoryItems).length !== 0 && (
										<Select
											variant='outlined'
											label='Kategorie'
											name='residenceEventCategory'
											onChange={handleInputChange}
											defaultValue={eventValues?.residenceEventCategory}
											disabled={displayEvent}>
											<MenuItem value={undefined}>
												{' '}
												<em>Unbekannt</em>{' '}
											</MenuItem>
											{eventCategoryItems.map(makeMenuItemForValue)}
										</Select>
									)}
								</FormControl>
								<FormControl
									variant='outlined'
									sx={{ width: '100%', marginTop: 2 }}>
									<InputLabel id='demo-simple-select-helper-label'>
										Unterkategorie
									</InputLabel>
									{Object.entries(residenceEventCategoryMapping).length !==
										0 && (
										<Select
											variant='outlined'
											label='Unterkategorie'
											name='residenceEventSubCategory'
											onChange={handleInputChange}
											defaultValue={eventValues?.residenceEventSubCategory}
											disabled={displayEvent}>
											<MenuItem value={undefined}>
												{' '}
												<em>Unbekannt</em>{' '}
											</MenuItem>
											{residenceEventCategoryMapping
												.filter(
													(el) =>
														el.residenceEventCategory ===
														eventValues?.residenceEventCategory
												)
												.map(makeMenuItemForSubCategories)}
										</Select>
									)}
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} {displayEvent ? 'Schließen' : 'Abbrechen'}
										</Button>
									</Grid>
									{!displayEvent && (
										<Grid item>
											<Button
												variant='contained'
												disabled={!isSaveButtonEnabled(eventValues)}
												onClick={isNewEvent ? saveNewEvent : updateEvent}>
												{<Save />} Speichern
											</Button>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
