import React, { useEffect, useMemo, useState } from 'react';
import keycloak, { getNetworkApi } from './keycloak';
import {
	Residence,
	ResidenceGroup,
	ResidenceGroupMediaInsertParameters,
	ResidenceGroupMediaUpdateParameters,
	Resident,
} from '../generated';
import { trackPromise } from 'react-promise-tracker';
import {
	Grid,
	Button,
	Tooltip,
	FormControl,
	InputLabel,
	NativeSelect,
	Typography,
} from '@mui/material';
import {
	getEventIdData,
	isAuthorizedForRoleTenant,
} from './format';
import { Add, Autorenew, GridView, TableRows } from '@mui/icons-material';
import { SuccessAlert, ErrorAlert } from './Alerts';
import { BaseRole } from './Roles';
import Confirm from './Confirm';
import TabPanel from './TabPanel';
import { useHistory, useLocation } from 'react-router-dom';
import GroupsGridView from './GroupsGridView';
import GroupForm from './GroupForm';
import GroupTableView from './GroupTableView';
import ResidenceMediaFileForm from './ResidenceMediaFileForm';
import GroupsDisplayMediaAlbumForm from './GroupsDisplayMediaAlbumForm';
import GroupToMediaLinkForm from './GroupToMediaLinkForm';
import GroupToResidentLinkForm from './GroupToResidentLinkForm';
import { KeycloakTokenParsed } from 'keycloak-js';

export default function ResidenceGroupPanel({ value, index }) {
	let history = useHistory();

	const location = useLocation();

	const handleChangeResidence = (e) => {
		setResidenceIndex(e.target.value);
		setResidentIndex(undefined);
		setCurrentResident(undefined);
	};
	const handleChangeResident = (e) => {
		setResidentIndex(e.target.value);
	};

	const [tableView, setTableView] = useState(false as boolean);

	const [residenceList, setResidenceList] = useState([] as Residence[]);
	const [residentList, setResidentList] = useState([] as Resident[]);
	const [userRoleList, setUserRoleList] = useState([]);
	const [displayGroup, setDisplayGroup] = useState(false);
	const [groupIdForDeletion, setGroupIdForDeletion] = useState(
		undefined as number
	);
	const [showConfirmGroupDeletion, setShowConfirmGroupDeletion] =
		useState(false);
	const [showGroupForm, setShowGroupForm] = useState(false);
	const [isNewGroup, setIsNewGroup] = useState(false);
	const [groupList, setGroupList] = useState([] as ResidenceGroup[]);
	const [refresh, setRefresh] = useState(0);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [deleteText, setDeleteText] = useState('');
	const [currentGroupData, setCurrentGroupData] = useState(
		undefined as ResidenceGroup
	);

	const [showMediaFileForm, setShowMediaFileForm] = useState(false);
	const [mediaIdForLink, setMediaIdForLink] = useState(undefined as number);
	const [showAddMediaLinkForm, setShowAddMediaLinkForm] = useState(
		false as boolean
	);
	const [showDisplayMediaAlbumForm, setShowDisplayMediaAlbumForm] = useState(
		false as boolean
	);

	const [showAddResidentToGroupLinkForm, setShowAddResidentToGroupLinkForm] =
		useState(false as boolean);
	const [currentResidence, setCurrentResidence] = useState({} as Residence);

	const [currentResident, setCurrentResident] = useState(
		residentList.find(
			(el) => el.residentId === location?.state?.residentId
		) as Resident
	);

	type ParsedToken = KeycloakTokenParsed & {
		tenantIdentifier?: string;
	};

	const parsedToken: ParsedToken | undefined = keycloak?.idTokenParsed;

	const tenantIdentifier: string = parsedToken.tenantIdentifier;

	useEffect(() => {
		const loadResidence = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getResidences(tenantIdentifier);
				setResidenceList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'Wohnheimsinformationen konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Wohnheimsinformationen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadResidence());
	}, []);

	const [residenceIndex, setResidenceIndex] = useState(0 as number);

	const isAuthorized: boolean = useMemo(() => {
		return isAuthorizedForRoleTenant(
			userRoleList,
			BaseRole.HEIMGRUPPEN,
			tenantIdentifier,
			residenceList[residenceIndex]?.residenceIdentifier
				? residenceList[residenceIndex]?.residenceIdentifier
				: residenceList[0]?.residenceIdentifier,
			undefined
		);
	}, [userRoleList, residenceList, residenceIndex]);

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	useEffect(() => {
		const loadResidents = async () => {
			const api = getNetworkApi();
			if (currentResidence) {
				try {
					const result = await api.getResidenceResidentList(
						currentResidence.tenantIdentifier,
						currentResidence.residenceIdentifier
					);
					setResidentList(result);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText(
								'Bewohnerinformationen konnten nicht abgerufen werden'
							);
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'Bewohnerinformationen konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadResidents());
	}, [
		currentResidence,
		residenceIndex,
		index,
		value,
		refresh,
		residenceList[0],
	]);

	const [residentIndex, setResidentIndex] = useState(
		residentList.findIndex(
			(el) => el.residentId === location?.state?.residentId
		) as number
	);

	useMemo(() => {
		setResidentIndex(
			residentList.findIndex(
				(el) => el.residentId === location?.state?.residentId
			)
		);
	}, [residentList]);

	const deleteGroup = async () => {
		const client = getNetworkApi();
		try {
			await client.deactivateResidenceGroup(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				groupIdForDeletion
			);
			setShowSuccessAlert(true);
			setShowConfirmGroupDeletion(false);
			setAlertText('Gruppe wurde erfolgreich archiviert');
		} catch (error) {
			setShowErrorAlert(true);
			setAlertText('Gruppe konnte nicht archiviert werden');
			console.log(
				'There was an error while deactivating: ' + groupIdForDeletion
			);
		}
	};

	const handleDeleteGroup = (residenceGroupId: number) => {
		setDeleteText('Wollen Sie die Gruppe wirklich archivieren?');
		setGroupIdForDeletion(residenceGroupId);
		setShowConfirmGroupDeletion(true);
	};

	const handleClickOpenGroupForm = (
		isNewGroup: boolean,
		groupData: ResidenceGroup
	) => {
		setCurrentGroupData(undefined);
		if (!isNewGroup) {
			setCurrentGroupData(groupData);
		}
		setIsNewGroup(isNewGroup);
		setShowGroupForm(true);
		setDisplayGroup(false);
	};

	const handleDisplayGroup = (groupData: ResidenceGroup) => {
		setDisplayGroup(true);
		setShowGroupForm(true);
		setCurrentGroupData(groupData);
	};

	const handleClickOpenMediaAlbumForm = (groupData: ResidenceGroup) => {
		setCurrentGroupData(groupData);
		setShowDisplayMediaAlbumForm(true);
	};

	const handleClickOpenMediaFileForm = (groupData: ResidenceGroup) => {
		setCurrentGroupData(groupData);
		setShowMediaFileForm(true);
	};

	const handleAddLinkToMediaFile = (groupData: ResidenceGroup) => {
		setCurrentGroupData(groupData);
		setShowAddMediaLinkForm(true);
	};

	const handleClickOpenResidentToGroupForm = (groupData: ResidenceGroup) => {
		setCurrentGroupData(groupData);
		setShowAddResidentToGroupLinkForm(true);
	};

	useEffect(() => {
		if (mediaIdForLink !== undefined) {
			currentGroupData?.residenceMedia?.length > 0
				? updateMediaToGroupLinks()
				: saveMediaToGroupLink();
		}
	}, [mediaIdForLink]);

	const saveMediaToGroupLink = async () => {
		let residenceGroupMediaInsertParameters: ResidenceGroupMediaInsertParameters =
			{
				residenceMediaIds: [Number(mediaIdForLink)],
			};

		const client = getNetworkApi();
		try {
			await client.postResidenceGroupMedia(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				currentGroupData?.residenceGroupId,
				residenceGroupMediaInsertParameters
			);

			setAlertText('Verknüpfung zu Medien wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
	};

	const updateMediaToGroupLinks = async () => {
		let residenceMediaIds: number[] = [];
		currentGroupData.residenceMedia.forEach((elem) => {
			residenceMediaIds.push(elem.residenceMediaId);
		});
		residenceMediaIds.push(mediaIdForLink);
		let residenceGroupMediaUpdateParameters: ResidenceGroupMediaUpdateParameters =
			{
				residenceMediaIds: residenceMediaIds,
			};
		const client = getNetworkApi();
		try {
			await client.putResidenceGroupMedia(
				currentResidence.tenantIdentifier,
				currentResidence.residenceIdentifier,
				currentGroupData.residenceGroupId,
				residenceGroupMediaUpdateParameters
			);
			setAlertText('Verknüpfung zu Medien wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Verknüpfung zu Medien wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	useMemo(() => {
		setCurrentResidence(residenceList[residenceIndex]);
		setCurrentResident(undefined);
	}, [residenceIndex, index, value, residenceList]);

	useMemo(() => {
		setCurrentResident(residentList[residentIndex]);
	}, [residentIndex, residenceIndex, index, value]);

	const handleSwitchChange = () => {
		setTableView(!tableView);
	};

	useEffect(() => {
		const loadGroups = async () => {
			const api = getNetworkApi();
			if (currentResidence.residenceIdentifier !== undefined) {
				try {
					const result = await api.getResidenceGroups(
						currentResidence.tenantIdentifier,
						currentResidence.residenceIdentifier
					);
					setGroupList(
						currentResident
							? result.filter((group) =>
									group.associatedResidents.find(
										(resident) =>
											resident.residentId === currentResident.residentId
									)
							  )
							: result
					);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Gruppen konnten nicht abgerufen werden');
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Gruppen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				}
			} else {
				try {
					const result = await api.getResidenceGroups(
						tenantIdentifier,
						residenceList[0].residenceIdentifier
					);
					setGroupList(result);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Gruppen konnten nicht abgerufen werden');
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Gruppen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadGroups());
	}, [
		currentResidence,
		showSuccessAlert,
		refresh,
		showDisplayMediaAlbumForm,
		showMediaFileForm,
		showAddMediaLinkForm,
		currentResident,
		residenceList,
		index,
		value,
	]);

	return (
		<React.Fragment>
			<TabPanel value={value} index={index}>
				{Object.entries(residenceList).length !== 0 ? (
					<>
						<Grid container direction='row' spacing={2} sx={{ padding: 5 }}>
							<Grid item xs={3}>
								<FormControl fullWidth>
									<InputLabel
										sx={{ fontSize: 18 }}
										variant='standard'
										htmlFor='uncontrolled-native'>
										Wohnheim auswählen
									</InputLabel>
									<NativeSelect
										defaultValue={residenceIndex}
										onChange={handleChangeResidence}
										inputProps={{
											name: 'residence',
											id: 'uncontrolled-native',
										}}
										sx={{ color: 'green', fontSize: 18 }}>
										{residenceList.map((el, index) => (
											<option key={el.residenceIdentifier} value={index}>
												{el.residenceIdentifier}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Grid>
							<Grid item xs={3}>
								<FormControl fullWidth>
									<InputLabel
										sx={{ fontSize: 18 }}
										variant='standard'
										htmlFor='uncontrolled-native'>
										Bewohner:in auswählen
									</InputLabel>
									<NativeSelect
										value={residentIndex}
										onChange={handleChangeResident}
										inputProps={{
											name: 'resident',
											id: 'uncontrolled-native',
										}}
										sx={{ color: 'green', fontSize: 18 }}>
										<option aria-label='None' value='' />
										{residentList.map((el, index) => (
											<option key={el.residentId} value={index}>
												{el.surname + ', ' + el.firstName}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<Grid
									container
									direction='column'
									justifyContent='space-between'
									alignItems='right'
									sx={{ padding: 0 }}>
									<Grid item xs={1} sx={{ mt: 2 }}>
										<Button
											disabled={!isAuthorized}
											color={isAuthorized ? 'primary' : 'secondary'}
											variant='contained'
											sx={{ float: 'right' }}
											onClick={() => handleClickOpenGroupForm(true, null)}>
											{<Add />} Gruppe
										</Button>
										<Button
											variant='contained'
											sx={{ float: 'right', mr: 5 }}
											onClick={() => handleRefresh()}>
											{<Autorenew />}
										</Button>
										<Tooltip title={tableView ? 'Gruppenkärtchen' : 'Tabelle'}>
											<Button
												variant='contained'
												sx={{ float: 'right', mr: 5 }}
												onClick={() => handleSwitchChange()}>
												{tableView ? <GridView /> : <TableRows />}
											</Button>
										</Tooltip>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								{tableView ? (
									<GroupTableView
										groupList={groupList}
										handleClickOpenGroupForm={handleClickOpenGroupForm}
										handleDeleteGroup={handleDeleteGroup}
										isAuthorized={isAuthorized}
										handleClickOpenMediaFileForm={handleClickOpenMediaFileForm}
										handleClickOpenMediaAlbumForm={
											handleClickOpenMediaAlbumForm
										}
										handleClickOpenResidentToGroupForm={
											handleClickOpenResidentToGroupForm
										}
										handleAddLinkToMediaFile={handleAddLinkToMediaFile}
									/>
								) : (
									<GroupsGridView
										groupList={groupList}
										handleDisplayGroup={handleDisplayGroup}
										handleClickOpenMediaAlbumForm={
											handleClickOpenMediaAlbumForm
										}
										handleClickOpenResidentToGroupForm={
											handleClickOpenResidentToGroupForm
										}
										isAuthorized={isAuthorized}
									/>
								)}
							</Grid>
						</Grid>
					</>
				) : (
					<Typography>Wird geladen ...</Typography>
				)}
			</TabPanel>
			{showGroupForm && (
				<GroupForm
					open={showGroupForm}
					setOpen={setShowGroupForm}
					isNewGroup={isNewGroup}
					isReadOnly={displayGroup}
					displayGroup={displayGroup}
					groupIdData={getEventIdData(currentResidence)}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					currentGroupData={currentGroupData}
				/>
			)}
			{showMediaFileForm && (
				<ResidenceMediaFileForm
					open={showMediaFileForm}
					setOpen={setShowMediaFileForm}
					isNewMediaFile={true}
					currentResidence={currentResidence}
					setMediaId={setMediaIdForLink}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					currentMediaFileMetaData={undefined}
				/>
			)}
			{showAddMediaLinkForm && (
				<GroupToMediaLinkForm
					open={showAddMediaLinkForm}
					setOpen={setShowAddMediaLinkForm}
					currentResidence={currentResidence}
					currentGroup={currentGroupData}
					groupId={currentGroupData?.residenceGroupId}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showDisplayMediaAlbumForm && (
				<GroupsDisplayMediaAlbumForm
					open={showDisplayMediaAlbumForm}
					isAuthorized={isAuthorized}
					setOpen={setShowDisplayMediaAlbumForm}
					currentResidence={currentResidence}
					residenceMediaIdList={currentGroupData?.residenceMedia}
					title={'Medien für Gruppe: ' + currentGroupData?.name}
				/>
			)}
			{showAddResidentToGroupLinkForm && (
				<GroupToResidentLinkForm
					open={showAddResidentToGroupLinkForm}
					setOpen={setShowAddResidentToGroupLinkForm}
					currentResidence={currentResidence}
					currentGroup={currentGroupData}
					groupId={currentGroupData?.residenceGroupId}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
					setShowSuccessAlert={setShowSuccessAlert}
				/>
			)}
			{showConfirmGroupDeletion && (
				<Confirm
					open={showConfirmGroupDeletion}
					setOpen={setShowConfirmGroupDeletion}
					text={deleteText}
					executefct={deleteGroup}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
