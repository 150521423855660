import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Divider,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';
import { Greeting } from '../generated';

import { conditionalDividerText, abbreviateString } from './format';

interface GreetingSummaryCardProps {
	title: string;
	icon: any;
	cardcolor: string;
	path: string;
	mostRecentGreetingsList: Greeting[];
}

export default function GreetingSummaryCard({
	title,
	icon,
	cardcolor,
	path,
	mostRecentGreetingsList,
}: GreetingSummaryCardProps) {
	let history = useHistory();

	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 5, height: '100%' }}
			elevation={5}>
			<CardActionArea onClick={() => history.push(path)}>
				<CardContent>
					<Grid container spacing={2} direction='row'>
						<Grid item xs={3}>
							{icon}
						</Grid>
						<Grid item xs={6} textAlign={'center'}>
							<Typography variant='h5' color={cardcolor}>
								{' '}
								{title}{' '}
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<Divider variant='middle' style={{ fontSize: 12 }}>
					{conditionalDividerText(mostRecentGreetingsList)}
				</Divider>
				<CardContent>
					<Grid
						container
						spacing={2}
						sx={{
							marginTop: 0,
							overflowX: 'auto',
						}}>
						{mostRecentGreetingsList.map((greeting, index) => (
							<Grid item xs={12}>
								<Typography
									color={cardcolor}
									style={{
										fontSize: 18,
										fontWeight: 'bold',
									}}>
									{abbreviateString(greeting.greetingPersonName, 30)}
								</Typography>
								<Typography
									style={{
										fontSize: 16,
										color: grey[500],
									}}>
									{abbreviateString(greeting.title, 30)}
								</Typography>
							</Grid>
						))}
					</Grid>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
