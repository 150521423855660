/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutsideInputStatusEnum,
    OutsideInputStatusEnumFromJSON,
    OutsideInputStatusEnumFromJSONTyped,
    OutsideInputStatusEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface OutsideInputMediaData
 */
export interface OutsideInputMediaData {
    /**
     * 
     * @type {number}
     * @memberof OutsideInputMediaData
     */
    outsideInputMediaId?: number;
    /**
     * 
     * @type {number}
     * @memberof OutsideInputMediaData
     */
    outsideInputId?: number;
    /**
     * 
     * @type {string}
     * @memberof OutsideInputMediaData
     */
    tenantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof OutsideInputMediaData
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutsideInputMediaData
     */
    mediaTypeIdentifier?: string;
    /**
     * 
     * @type {OutsideInputStatusEnum}
     * @memberof OutsideInputMediaData
     */
    status?: OutsideInputStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OutsideInputMediaData
     */
    modifyingUser?: string;
    /**
     * 
     * @type {Date}
     * @memberof OutsideInputMediaData
     */
    techValidFrom?: Date;
}

export function OutsideInputMediaDataFromJSON(json: any): OutsideInputMediaData {
    return OutsideInputMediaDataFromJSONTyped(json, false);
}

export function OutsideInputMediaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutsideInputMediaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'outsideInputMediaId': !exists(json, 'outsideInputMediaId') ? undefined : json['outsideInputMediaId'],
        'outsideInputId': !exists(json, 'outsideInputId') ? undefined : json['outsideInputId'],
        'tenantIdentifier': !exists(json, 'tenantIdentifier') ? undefined : json['tenantIdentifier'],
        'fileName': !exists(json, 'fileName') ? undefined : json['fileName'],
        'mediaTypeIdentifier': !exists(json, 'mediaTypeIdentifier') ? undefined : json['mediaTypeIdentifier'],
        'status': !exists(json, 'status') ? undefined : OutsideInputStatusEnumFromJSON(json['status']),
        'modifyingUser': !exists(json, 'modifyingUser') ? undefined : json['modifyingUser'],
        'techValidFrom': !exists(json, 'techValidFrom') ? undefined : (new Date(json['techValidFrom'])),
    };
}

export function OutsideInputMediaDataToJSON(value?: OutsideInputMediaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'outsideInputMediaId': value.outsideInputMediaId,
        'outsideInputId': value.outsideInputId,
        'tenantIdentifier': value.tenantIdentifier,
        'fileName': value.fileName,
        'mediaTypeIdentifier': value.mediaTypeIdentifier,
        'status': OutsideInputStatusEnumToJSON(value.status),
        'modifyingUser': value.modifyingUser,
        'techValidFrom': value.techValidFrom === undefined ? undefined : (value.techValidFrom.toISOString().substr(0,10)),
    };
}


