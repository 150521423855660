import React, { useEffect, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import {
	FormControl,
	Grid,
	InputLabel,
	NativeSelect,
	Typography,
} from '@mui/material';

import { getNetworkApi } from '../components/keycloak';
import AssessmentTargetChart from '../components/AssessmentTargetChart';
import AssesmentResultInfo from '../components/AssessmentResultInfo';
import { ErrorAlert } from '../components/Alerts';

import {
	AssessmentProfile,
	AssessmentTypeEnum,
	AssessmentResultsForTargetId,
	AssessmentResultWithTimestamp,
} from '../generated';
import { ResidentIdData } from '../components/format';

interface AssessmentChartDataDict {
	[assessmentTargetId: number]: AssessmentResultWithTimestamp[];
}

export default function MotivationChart(props) {
	const residentIdData: ResidentIdData = useMemo(() => {
		return {
			tenantIdentifier: props.match.params.tenantId,
			residenceIdentifier: props.match.params.residenceId,
			unitIdentifier: props.match.params.unitId,
			residentId: Number(props.match.params.residentId),
		};
	}, [props]);

	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');

	const [showAssesmentResultInfo, setShowAssesmentResultInfo] = useState(false);
	const [currentAssesmentResult, setCurrentAssesmentResult] = useState({
		tenantidentifier: residentIdData.tenantIdentifier,
		unitIdentifier: residentIdData.unitIdentifier,
		residenceIdentifier: residentIdData.residenceIdentifier,
		assessmentProfileId: undefined,
		residentAssessmentId: undefined,
		assessmentTargetId: undefined,
		assessmentTargetName: undefined,
	});

	const [assessmentProfileList, setAssessmentProfileList] = useState(
		[] as AssessmentProfile[]
	);
	const [assessmentProfileIndex, setAssessmentProfileIndex] = useState(0);

	const [selectedAssessmentProfileId, setSelectedAssessmentProfileId] =
		useState(1);

	const [assessmentResultsForTargetIds, setAssessmentResultsForTargetIds] =
		useState([] as AssessmentResultsForTargetId[]);

	const [assessmentChartDataDict, setAssessmentChartDataDict] = useState(
		{} as AssessmentChartDataDict
	);

	useEffect(() => {
		const loadAssessmentProfiles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getAssessmentProfilesForAssessmentType(
					residentIdData.tenantIdentifier,
					AssessmentTypeEnum.Motivation
				);
				setAssessmentProfileList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Fragebögen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Fragebögen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadAssessmentProfiles());
	}, [residentIdData.tenantIdentifier]);

	useEffect(() => {
		const loadAssessmentResultsForTargetIds = async () => {
			const api = getNetworkApi();
			try {
				const result =
					await api.getAssessmentResultsForTargetIdsForAssessmentProfile(
						residentIdData.tenantIdentifier,
						residentIdData.unitIdentifier,
						residentIdData.residenceIdentifier,
						residentIdData.residentId,
						AssessmentTypeEnum.Motivation,
						selectedAssessmentProfileId
					);
				setAssessmentResultsForTargetIds(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Auswertungen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Auswertungen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadAssessmentResultsForTargetIds());
	}, [residentIdData, selectedAssessmentProfileId]);

	useEffect(() => {
		let resultDict: AssessmentChartDataDict = {};
		assessmentResultsForTargetIds.forEach(function (item) {
			resultDict[item.assessmentTargetId] = item.assessmentResults;
		});
		setAssessmentChartDataDict(resultDict);
	}, [assessmentResultsForTargetIds]);

	const getData = (
		assessmentTargetId: number
	): AssessmentResultWithTimestamp[] => {
		let data = [];
		assessmentChartDataDict[assessmentTargetId]?.forEach(function (el) {
			data = [...data, el];
		});
		return data;
	};

	const handleClick = (data, index) => {
		setShowAssesmentResultInfo(true);
		setCurrentAssesmentResult({
			...currentAssesmentResult,
			assessmentProfileId:
				assessmentProfileList[assessmentProfileIndex].assessmentProfileId,
			residentAssessmentId: data.residentAssessmentId,
			assessmentTargetId: data.assessmentTargetId,
			assessmentTargetName: data.assessmentTargetName,
		});
	};

	const handleIndexChange = (e) => {
		setAssessmentProfileIndex(e.target.value);
		setSelectedAssessmentProfileId(
			assessmentProfileList[e.target.value].assessmentProfileId
		);
	};

	return (
		<React.Fragment>
			{Object.entries(assessmentProfileList).length !== 0 && (
				<>
					<Grid container direction='column' spacing={2} sx={{ padding: 5 }}>
						<Grid item xs={12}>
							<Grid
								container
								direction='row'
								justifyContent='space-between'
								alignItems='center'>
								<Grid item xs={5}>
									<FormControl fullWidth>
										<InputLabel
											variant='standard'
											htmlFor='uncontrolled-native'>
											Fragebogen auswählen
										</InputLabel>
										<NativeSelect
											value={
												assessmentProfileList[assessmentProfileIndex]?.name
											}
											onChange={handleIndexChange}
											inputProps={{
												name: 'assessmentProfile',
												id: 'uncontrolled-native',
											}}
											sx={{ color: 'green' }}>
											{assessmentProfileList.map((el, index) => (
												<option key={index} value={index}>
													{' '}
													{el?.name}{' '}
												</option>
											))}
										</NativeSelect>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Typography sx={{ padding: 5 }}>
						Per Klick auf die Säulen des Diagramms werden die Details zur
						Auswertung angezeigt.
					</Typography>
					<Grid
						container
						spacing={{ xs: 2, lg: 3 }}
						columns={{ xs: 4, md: 8, lg: 12 }}
						sx={{ padding: 5 }}>
						{Array.from(Array(9)).map((_, index) => (
							<Grid item xs={4} md={4} lg={4} key={index}>
								<AssessmentTargetChart
									data={getData(index + 1)}
									handleClick={handleClick}
								/>
							</Grid>
						))}
					</Grid>
				</>
			)}
			{showAssesmentResultInfo && (
				<AssesmentResultInfo
					open={showAssesmentResultInfo}
					setOpen={setShowAssesmentResultInfo}
					currentAssesmentResult={currentAssesmentResult}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
