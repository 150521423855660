import React, { useEffect, useState } from 'react';

import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	ThemeProvider,
	Button,
	Card,
	Tooltip,
} from '@mui/material';
import { ChevronLeft, ChevronRight, Close, Title } from '@mui/icons-material';

import theme from '../theme';
import DisplayContent from './DisplayContent';
import { Greeting } from '../generated/models/Greeting';
import { FavoriteFood, FavoriteFoodMedia, GreetingMedia } from '../generated';
import { trackPromise } from 'react-promise-tracker';
import { getNetworkApi } from './keycloak';
import { ResidentIdData } from './format';

interface DisplayMediaAlbumFormProp {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
	residentIdData: ResidentIdData;
	currentDisplayMediaAlbum: Greeting | FavoriteFood;
	title: String;
	mediaIdList: GreetingMedia[]  | FavoriteFoodMedia[] ;
}

export default function DisplayMediaAlbumForm(prop: DisplayMediaAlbumFormProp) {
	const {
		open,
		setOpen,
		setAlertText,
		setShowErrorAlert,
		residentIdData,
		currentDisplayMediaAlbum,
		title,
		mediaIdList,
	} = prop;

	const [mediaIdListIndex, setMediaIdListIndex] = useState(0 as number);
	const [selectedMediaFile, setSelectedMediaFile] = useState(new Blob());

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickRightArrow = () => {
		let i = mediaIdListIndex;
		setMediaIdListIndex((i + 1) % mediaIdList.length);
	};

	const handleClickLeftArrow = () => {
		let i = mediaIdListIndex;

		if (i === 0) {
			setMediaIdListIndex(mediaIdList.length - 1);
		} else {
			setMediaIdListIndex(i - 1);
		}
	};

	useEffect(() => {
		const loadSingleMediaFile = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getMedia(
					residentIdData.tenantIdentifier,
					residentIdData.residenceIdentifier,
					residentIdData.unitIdentifier,
					mediaIdList[mediaIdListIndex]?.mediaId
				);
				setSelectedMediaFile(result);
			} catch (error) {
				return null;
			}
		};
		trackPromise(loadSingleMediaFile());
	}, [mediaIdListIndex]);

	const displayContent = () => {
		if (mediaIdList.length === 0) {
			return null;
		} else {
			return (
				<DisplayContent
					mediaFile={selectedMediaFile}
					mediaType={mediaIdList[mediaIdListIndex]?.mediaTypeIdentifier}
					title={mediaIdList[mediaIdListIndex]?.fileName}
					setAlertText={setAlertText}
					setShowErrorAlert={setShowErrorAlert}
				/>
			);
		}
	};

	return (
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<Dialog
					open={open}
					onClose={handleClose}
					maxWidth= 'lg'
					sx={{ maxHeight: '100%' }}>
					<Grid item xs={12} marginRight={2} marginTop={2}>
						<Grid
							container
							direction='row'
							justifyContent='flex-end'
							alignItems='center'
							spacing={2}>
							<Grid item>
								<Button variant='contained' onClick={handleClose}>
									{<Close />} Schließen
								</Button>
							</Grid>
						</Grid>
					</Grid>
					<DialogTitle>{title}</DialogTitle>
					<DialogContent>
						<form>
							<Grid container spacing={2}>
								<Grid item xs={12} style={{ textAlign: 'center' }}>
									<Tooltip title={'vorheriges Medium'} placement='top'>
										<span>
											<Button
												disabled={mediaIdList.length < 2}
												onClick={() => handleClickLeftArrow()}>
												<ChevronLeft />
											</Button>
										</span>
									</Tooltip>

									<Tooltip title={'nächstes Medium'} placement='top'>
										<span>
											<Button
												disabled={mediaIdList.length < 2}
												onClick={() => handleClickRightArrow()}>
												<ChevronRight />
											</Button>
										</span>
									</Tooltip>
									<Card>{displayContent()}</Card>
								</Grid>
							</Grid>
						</form>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
