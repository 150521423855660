import React, { useCallback, useEffect, useMemo, useState } from 'react';
import keycloak, { getNetworkApi } from '../components/keycloak';
import { Resident, Unit } from '../generated';
import { trackPromise } from 'react-promise-tracker';
import {
	Grid,
	NativeSelect,
	FormControl,
	InputLabel,
	Button,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	isAuthorizedForRoleTenant,
} from '../components/format';
import ResidentForm from '../components/ResidentForm';
import { Add, Autorenew, Face, GridView, TableRows } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { SuccessAlert, ErrorAlert } from '../components/Alerts';
import { useHistory, useLocation } from 'react-router-dom';
import { BaseRole } from '../components/Roles';
import LastChangeInfo from '../components/LastChangeInfo';
import ResidentsGridView from '../components/ResidentsGridView';
import ResidentsTableView from '../components/ResidentsTableView';
import ResidentsFromFileForm from '../components/ResidentsFromFileForm';
import { ResidentFormType } from '../page/ResidentProfile';
import TabPanel from './TabPanel';
import UploadResidentsForm from './UploadResidentsFileForm';
import { KeycloakTokenParsed } from 'keycloak-js';

export default function ResidentsOverviewPanel({ value, index }) {
	const [userRoleList, setUserRoleList] = useState([]);
	const [unitList, setUnitList] = useState([] as Unit[]);
	const [residentList, setResidentList] = useState([] as Resident[]);
	const [currentUnit, setCurrentUnit] = useState(undefined as Unit);
	const [refresh, setRefresh] = useState(0);
	const [showResidentsFromFileForm, setShowResidentsFromFileForm] = useState(false);
	const [residentsFromFile, setResidentsFromFile] = useState([] as Resident[]);
	const [showResidentForm, setShowResidentForm] = useState(false);
	const [showLastChangeInfo, setShowLastChangeInfo] = useState(false);
	const [currentResident, setCurrentResident] = useState({} as Resident);
	const [residentFormType, setResidentFormType] = useState(
		'new' as ResidentFormType
	);
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [alertText, setAlertText] = useState('');
	const [showUploadResidentsForm, setShowUploadResidentsForm] = useState(false);

	let history = useHistory();

	type ParsedToken = KeycloakTokenParsed & {
		tenantIdentifier?: string;
	};

	const parsedToken: ParsedToken | undefined = keycloak?.idTokenParsed;

	let tenantIdentifier = parsedToken.tenantIdentifier;

	function useQuery() {
		return new URLSearchParams(useLocation().search);
	}
	let query = useQuery();

	const [unitIndex, setUnitIndex] = useState(
		query.get('unitId') ? query.get('unitId') : 0
	);

	const handleChangeUnit = (e) => {
		setUnitIndex(e.target.value);
	};

	const [tableView, setTableView] = useState(
		query.get('tableView') === 'true' ? query.get('tableView') : false
	);

	let location = useLocation();

	useMemo(() => {
		setCurrentUnit(unitList[unitIndex]);
		history.push({
			search: `?tableView=${tableView}&unitId=${unitIndex}`,
			state: location?.state,
		});
	}, [unitIndex, unitList]);

	const handleChangeView = () => {
		setTableView(!tableView);
	};

	const isAuthorized: boolean = useMemo(() => {
		return isAuthorizedForRoleTenant(
			userRoleList,
			BaseRole.BEWOHNERVERWALTUNG,
			tenantIdentifier,
			unitList[unitIndex]?.residenceIdentifier,
			unitList[unitIndex]?.unitIdentifier
		);
	}, [userRoleList, unitList, unitIndex]);

	const handleClickInfo = (resident: Resident) => {
		setShowLastChangeInfo(true);
		setCurrentResident(resident);
	};

	const handleClickOpen = (
		residentFormType: ResidentFormType,
		resident: Resident
	) => {
		setResidentFormType(residentFormType);
		setShowResidentForm(true);
		setCurrentResident(resident);
	};

	const handleClickUploadResidentsFile = () => {
		setShowUploadResidentsForm(true);
	};

	const handleRefresh = () => {
		setRefresh(refresh + 1);
	};

	const defineDefaultValues = () => {
		const initialResidentValues: Resident = {
			tenantIdentifier: unitList[unitIndex].tenantIdentifier,
			residenceIdentifier: unitList[unitIndex].residenceIdentifier,
			unitIdentifier: unitList[unitIndex].unitIdentifier,
			firstName: '',
			surname: '',
			gender: 'divers',
			room: '',
			associatedCaregiver: '',
			birthname: '',
			birthplace: '',
			nickname: '',
			birthday: null,
			residentSince: null,
		};
		return initialResidentValues;
	};

	useEffect(() => {
		const loadUserRoles = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUserEffectiveRoles();
				setUserRoleList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText('Benutzerrollen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Benutzerrollen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUserRoles());
	}, []);

	useEffect(() => {
		const loadUnits = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getUnits(tenantIdentifier);
				setUnitList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						setShowErrorAlert(true);
						setAlertText('Nutzer nicht autorisiert');
						console.log('User Unauthorized!');
					} else {
						setShowErrorAlert(true);
						setAlertText(
							'Wohnbereichinformationen konnten nicht abgerufen werden'
						);
						console.log('There was an error fetching the data!');
					}
				} else {
					setShowErrorAlert(true);
					setAlertText('Wohnbereichinformationen konnten nicht abgerufen werden');
					console.log('There was an error fetching the data!');
				}
			}
		};
		trackPromise(loadUnits());
	}, []);

	useEffect(() => {
		const loadResidentData = async () => {
			const api = getNetworkApi();
			if (currentUnit.tenantIdentifier !== undefined) {
				try {
					const result = await api.getResidentList(
						currentUnit.tenantIdentifier,
						currentUnit.residenceIdentifier,
						currentUnit.unitIdentifier
					);
					setResidentList(
						result.sort((a: Resident, b: Resident) =>
							a.surname.localeCompare(b.surname)
						)
					);
				} catch (error) {
					if (error.message) {
						if (error.response && error.response.status === 401) {
							setShowErrorAlert(true);
							setAlertText('Nutzer nicht autorisiert');
							console.log('User Unauthorized!');
						} else {
							setShowErrorAlert(true);
							setAlertText('Bewohner:innen konnten nicht abgerufen werden');
							console.log('There was an error fetching the data!');
						}
					} else {
						setShowErrorAlert(true);
						setAlertText('Bewohner:innen konnten nicht abgerufen werden');
						console.log('There was an error fetching the data!');
					}
				}
			}
		};
		trackPromise(loadResidentData());
	}, [unitIndex, unitList, showSuccessAlert, refresh]);

	const forwardToResidentProfile = useCallback(
		(tableData) => {
			history.push({
				pathname:
					'/tenantId=' +
					currentUnit.tenantIdentifier +
					',residenceId=' +
					currentUnit.residenceIdentifier +
					',unitId=' +
					currentUnit.unitIdentifier +
					'/profile/' +
					'residentId=' +
					tableData.residentId,
			});
		},
		[history, currentUnit]
	);

	return (
		<React.Fragment>
			<TabPanel value={value} index={index}>
				{Object.entries(unitList).length !== 0 ? (
					<>
						<Grid container direction='row' spacing={2} sx={{ padding: 5 }}>
							<Grid item xs={5}>
								<FormControl fullWidth>
									<InputLabel
										sx={{ fontSize: 18 }}
										variant='standard'
										htmlFor='uncontrolled-native'>
										Wohnbereich auswählen
									</InputLabel>
									<NativeSelect
										defaultValue={unitIndex}
										onChange={handleChangeUnit}
										inputProps={{
											name: 'unit',
											id: 'uncontrolled-native',
										}}
										sx={{ color: 'green', fontSize: 18 }}>
										{unitList.map((el, index) => (
											<option key={index} value={index}>
												{el.residenceIdentifier} - {el.unitIdentifier}
											</option>
										))}
									</NativeSelect>
								</FormControl>
							</Grid>
							<Grid item xs={7}>
								<Grid
									container
									direction='column'
									justifyContent='space-between'
									alignItems='right'
									sx={{ padding: 0 }}>
									<Grid item xs={1} sx={{ mt: 2 }}>
										<Tooltip title={'Bewohner:in hinzufügen'}>
											<Button
												variant='contained'
												sx={{ float: 'right' }}
												disabled={!isAuthorized}
												onClick={() =>
													handleClickOpen('new', defineDefaultValues())
												}>
												{<Add />} {<Face />}
											</Button>
										</Tooltip>
										{isAuthorized && (
											<Tooltip title={'Bewohner:innen aus Datei importieren'}>
												<Button
													variant='contained'
													sx={{ float: 'right', mr: 5 }}
													disabled={!isAuthorized}
													onClick={() => handleClickUploadResidentsFile()}>
													{<FileUploadIcon />}
													{<Face />}
												</Button>
											</Tooltip>
										)}

										<Button
											variant='contained'
											sx={{ float: 'right', mr: 5 }}
											onClick={() => handleRefresh()}>
											{<Autorenew />}
										</Button>
										<Tooltip title={tableView ? 'Profilkärtchen' : 'Tabelle'}>
											<Button
												variant='contained'
												sx={{ float: 'right', mr: 5 }}
												onClick={() => handleChangeView()}>
												{tableView ? <GridView /> : <TableRows />}
											</Button>
										</Tooltip>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								{tableView ? (
									<ResidentsTableView
										residentList={residentList}
										forwardToResidentProfile={forwardToResidentProfile}
										handleClickInfo={handleClickInfo}
										handleClickOpen={handleClickOpen}
										isAuthorized={isAuthorized}
									/>
								) : (
									<ResidentsGridView
										residentList={residentList}
										forwardToResidentProfile={forwardToResidentProfile}
										handleClickInfo={handleClickInfo}
									/>
								)}
							</Grid>
						</Grid>
					</>
				) : (
					<Typography>Wird geladen ...</Typography>
				)}
			</TabPanel>
			{showLastChangeInfo && (
				<LastChangeInfo
					open={showLastChangeInfo}
					setOpen={setShowLastChangeInfo}
					currentResident={currentResident}
					UserRoleList={userRoleList}
				/>
			)}
			{showResidentForm && (
				<ResidentForm
					open={showResidentForm}
					setOpen={setShowResidentForm}
					units={unitList}
					unitIndex={unitIndex}
					residentFormType={residentFormType}
					currentResident={currentResident}
					setResidentData={null}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showUploadResidentsForm && (
				<UploadResidentsForm
					open={showUploadResidentsForm}
					setOpen={setShowUploadResidentsForm}
					currentUnit={currentUnit}
					units={unitList}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
					setResidentsFromFile={setResidentsFromFile}
					setShowResidentsFromFileForm={setShowResidentsFromFileForm}
				/>
			)}
			{showResidentsFromFileForm && (
				<ResidentsFromFileForm
					open={showResidentsFromFileForm}
					setOpen={setShowResidentsFromFileForm}
					residentsFromFile={residentsFromFile}
					setAlertText={setAlertText}
					setShowSuccessAlert={setShowSuccessAlert}
					setShowErrorAlert={setShowErrorAlert}
				/>
			)}
			{showSuccessAlert && (
				<SuccessAlert
					text={alertText}
					open={showSuccessAlert}
					setOpen={setShowSuccessAlert}
				/>
			)}
			{showErrorAlert && (
				<ErrorAlert
					text={alertText}
					open={showErrorAlert}
					setOpen={setShowErrorAlert}
				/>
			)}
		</React.Fragment>
	);
}
