import {
	Grid,
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Divider,
	CardActions,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { AssessedProfile } from '../generated';
import {
	TCCStyledBadgeButton,
	abbreviateString,
	ResidentIdData,
	BorderLinearProgress,
	formatDate,
	GetDistinctAssessmentTargets,
} from './format';

interface MotivationCardProps {
	motivation: AssessedProfile;
	residentIdData: ResidentIdData;
	onClickCard: any;
	onClickConnectButton: any;
	isAuthorized: boolean;
}

export default function MotivationCard({
	motivation,
	residentIdData,
	onClickCard,
	onClickConnectButton,
	isAuthorized,
}: MotivationCardProps) {
	let cardcolor = 'primary';

	const displayDistinctAssessmentTargets = GetDistinctAssessmentTargets(
		motivation,
		residentIdData
	);

	return (
		<Card
			sx={{ color: cardcolor, borderRadius: 10, maxHeight: 'auto' }}
			elevation={5}>
			<Grid container spacing={2} direction='row'>
				<Grid item xs={3}>
					<CardActions sx={{ justifyContent: 'right' }}>
						<TCCStyledBadgeButton
							onClick={onClickConnectButton}
							badgeContent={motivation.connects.length}
						/>
					</CardActions>
				</Grid>
				<Grid item xs={9}>
					<CardActionArea onClick={onClickCard}>
						<CardContent>
							<Typography
								align='left'
								fontSize='24'
								variant='h6'
								color={cardcolor}>
								{abbreviateString(motivation.name, 30)}
							</Typography>
						</CardContent>
					</CardActionArea>
				</Grid>
			</Grid>
			<CardActionArea onClick={onClickCard}>
				<Divider variant='middle'>
					{
						<Typography color={'primary'} variant={'body2'} fontSize={12}>
							({motivation?.assessingUser},{' '}
							{formatDate(motivation?.assessmentTimeStamp)})
						</Typography>
					}
				</Divider>
				<CardContent>
					<Grid
						container
						spacing={2}
						direction='column'
						sx={{
							overflowX: 'auto',
						}}>
						{displayDistinctAssessmentTargets.map((el, index) => (
							<Grid item key={index} style={{ fontSize: 16, color: grey[500] }}>
								<b>{el.targetName}</b>
								{':   '}
								{el.targetDesc + '  '}
								{Math.round(el.score) + '%'}

								<BorderLinearProgress variant='determinate' value={el.score} />
							</Grid>
						))}
					</Grid>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
