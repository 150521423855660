/* tslint:disable */
/* eslint-disable */
/**
 * Care Remembrall Backend
 * Care Remembrall Backend API.
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tag,
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './';

/**
 * 
 * @export
 * @interface LifestyleUpdateParameters
 */
export interface LifestyleUpdateParameters {
    /**
     * 
     * @type {string}
     * @memberof LifestyleUpdateParameters
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LifestyleUpdateParameters
     */
    description?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof LifestyleUpdateParameters
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {boolean}
     * @memberof LifestyleUpdateParameters
     */
    relevant?: boolean;
}

export function LifestyleUpdateParametersFromJSON(json: any): LifestyleUpdateParameters {
    return LifestyleUpdateParametersFromJSONTyped(json, false);
}

export function LifestyleUpdateParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): LifestyleUpdateParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'relevant': !exists(json, 'relevant') ? undefined : json['relevant'],
    };
}

export function LifestyleUpdateParametersToJSON(value?: LifestyleUpdateParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'relevant': value.relevant,
    };
}


