import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import { pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import VideoThumbnail from 'react-video-thumbnail';
import { Tag } from '../generated/models/Tag';
import { Close, Save } from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	useMediaQuery,
	useTheme,
	Tooltip,
	RadioGroup,
	Radio
} from '@mui/material';

import {
	MediaCategory,
	MediaMetaUpdateParameters,
	ResidentMediaData,
	StageOfLife,
} from '../generated';
import { getNetworkApi } from './keycloak';
import {
	getMediaFileType,
	isMediaCategoryTicked,
	makeMenuItemForName,
	makeDisabledTextField,
	ResidentIdData,
	isTagTicked,
} from './format';
import FileUploader from './FileUploader';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface MediaFileFormProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isNewMediaFile: boolean;
	residentIdData: ResidentIdData;
	currentMediaFileMetaData: ResidentMediaData;
	setMediaId?: React.Dispatch<React.SetStateAction<number>>;
	setAlertText: React.Dispatch<React.SetStateAction<string>>;
	setShowSuccessAlert: React.Dispatch<React.SetStateAction<boolean>>;
	setShowErrorAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MediaFileForm({
	open,
	setOpen,
	isNewMediaFile,
	residentIdData,
	currentMediaFileMetaData,
	setMediaId,
	setAlertText,
	setShowSuccessAlert,
	setShowErrorAlert,
}: MediaFileFormProps) {
	const [StagesOfLifeList, setStagesOfLifeList] = useState([] as StageOfLife[]);
	const [allMediaCategories, setAllMediaCategories] = useState(
		[] as MediaCategory[]
	);
	const [selectedMediaCategories, setSelectedMediaCategories] = useState(
		currentMediaFileMetaData?.mediaCategories as MediaCategory[]
	);
	const [mediaFileMetadata, setMediaFileMetadata] = useState(
		currentMediaFileMetaData as ResidentMediaData
	);
	const [tags, setTags] = useState([] as Tag[]);
	const [tagValues, setTagValues] = useState(mediaFileMetadata.tags as Tag[]);

	const [selectedFile, setSelectedFile] = useState({} as File);
	const [selectedFileName, setSelectedFileName] = useState('');
	const [isFilePicked, setIsFilePicked] = useState(!isNewMediaFile);

	const handleClose = () => {
		setOpen(false);
	};

	const handleRadioChange = (event) => {
	setMediaFileMetadata((prevMediaFileMetadata) => ({
		...prevMediaFileMetadata,
		relevant: event.target.value === "true"
	}));
	};

	const handleSelectedMediaCategories = (e) => {
		if (
			selectedMediaCategories !== undefined &&
			Object.entries(selectedMediaCategories).length !== 0
		) {
			if (e.target.checked) {
				setSelectedMediaCategories((prev) => [
					...prev,
					{ mediaCategoryId: Number(e.target.value) },
				]);
			} else {
				setSelectedMediaCategories(
					selectedMediaCategories.filter(
						(item) => item.mediaCategoryId !== Number(e.target.value)
					)
				);
			}
		} else {
			setSelectedMediaCategories([{ mediaCategoryId: Number(e.target.value) }]);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setMediaFileMetadata({
			...mediaFileMetadata,
			[name]: value,
		});
	};

	const handleSelectedFile = (event) => {
		const file = event.target.files[0];
		setSelectedFile(file);
		setSelectedFileName(file?.name);
		setIsFilePicked(true);
	};

	const getTagValue = (e) => {
		if (Object.entries(tagValues).length !== 0) {
			if (e.target.checked) {
				setTagValues((prevTags) => [
					...prevTags,
					{ tagId: Number(e.target.value) },
				]);
			} else {
				setTagValues(
					tagValues.filter((item) => item.tagId !== Number(e.target.value))
				);
			}
		} else {
			setTagValues([{ tagId: Number(e.target.value) }]);
		}
	};

	useEffect(() => {
		const loadStagesOfLife = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getStagesOfLife(
					residentIdData.tenantIdentifier
				);
				setStagesOfLifeList(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the StagesOfLife data!');
					}
				} else {
					console.log('There was an error fetching the StagesOfLife data!');
				}
			}
		};
		trackPromise(loadStagesOfLife());
		const loadMediaCategories = async () => {
			const api = getNetworkApi();
			try {
				const result = await api.getMediaCategories(
					residentIdData.tenantIdentifier
				);
				setAllMediaCategories(result);
			} catch (error) {
				if (error.message) {
					if (error.response && error.response.status === 401) {
						console.log('User Unauthorized!');
					} else {
						console.log('There was an error fetching the media category data!');
					}
				} else {
					console.log('There was an error fetching the media category data!');
				}
			}
		};
		trackPromise(loadMediaCategories());
		
		const loadTags = async () => {
		const api = getNetworkApi();
		try {
			const result = await api.getTags(residentIdData.tenantIdentifier);
			setTags(result);
		} catch (error) {
			if (error.message) {
				if (error.response && error.response.status === 401) {
					console.log('User Unauthorized!');
				} else {
					console.log('There was an error fetching the available tags data!');
				}
			} else {
				console.log('There was an error fetching the available tags data!');
			}
		}
		};
		trackPromise(loadTags());

	}, [residentIdData.tenantIdentifier]);

	// The save-method contains a workaround:
	// the post request does not have the mediaCategories (due to problems with multipart/form-data & arrays of objects),
	// hence we submit an additional update request after the post
	const saveMediaFile = async () => {
		setOpen(false);
		const client = getNetworkApi();

		let mediaId: number = undefined;
		const comment =
			mediaFileMetadata !== undefined && mediaFileMetadata.comment !== ''
				? mediaFileMetadata.comment
				: undefined;
		const stageOfLife =
			mediaFileMetadata !== undefined &&
			mediaFileMetadata.stageOfLife !== 'Unbekannt' &&
			mediaFileMetadata.stageOfLife !== ''
				? mediaFileMetadata.stageOfLife
				: undefined;
		const isProfilePicture =
			mediaFileMetadata?.isProfilePicture === undefined
				? false
				: mediaFileMetadata.isProfilePicture;
		const mediaCategories =
			selectedMediaCategories !== undefined &&
			Object.entries(selectedMediaCategories).length !== 0
				? selectedMediaCategories
				: [];
		try {
			mediaId = await client.postResidentMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				residentIdData.residentId,
				selectedFile,
				selectedFile.name,
				comment,
				stageOfLife
			);
		} catch (error) {
			setAlertText('Datei wurde nicht gespeichert');
			setShowErrorAlert(true);
		}

		let mediaMetaUpdateParameters: MediaMetaUpdateParameters = {
			comment: comment,
			stageOfLife: stageOfLife,
			mediaCategories: mediaCategories,
			isProfilePicture: isProfilePicture,
			tags: Object.entries(tagValues).length !== 0 ? tagValues : undefined,
			relevant: mediaFileMetadata.relevant
		};
		try {
			await client.putResidentMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				mediaId,
				mediaMetaUpdateParameters
			);

			setAlertText('Datei wurde erfolgreich gespeichert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Datei wurde nicht gespeichert');
			setShowErrorAlert(true);
		}
		setMediaId && setMediaId(mediaId);
	};

	const updateMediaMetaData = async () => {
		setOpen(false);
		const client = getNetworkApi();
		let mediaMetaUpdateParameters: MediaMetaUpdateParameters = {
			comment:
				mediaFileMetadata.comment !== ''
					? mediaFileMetadata.comment
					: undefined,
			stageOfLife:
				mediaFileMetadata.stageOfLife !== 'Unbekannt' &&
				mediaFileMetadata.stageOfLife !== ''
					? mediaFileMetadata.stageOfLife
					: undefined,
			isProfilePicture:
				mediaFileMetadata.isProfilePicture === undefined
					? false
					: mediaFileMetadata.isProfilePicture,
			mediaCategories:
				Object.entries(selectedMediaCategories).length !== 0
					? selectedMediaCategories
					: [],
			tags: Object.entries(tagValues).length !== 0 ? tagValues : undefined,
			relevant: mediaFileMetadata.relevant
		};

		try {
			await client.putResidentMedia(
				residentIdData.tenantIdentifier,
				residentIdData.residenceIdentifier,
				residentIdData.unitIdentifier,
				mediaFileMetadata.mediaId,
				mediaMetaUpdateParameters
			);
			setAlertText('Informationen zur Datei wurde erfolgreich geändert');
			setShowSuccessAlert(true);
		} catch (error) {
			setAlertText('Informationen zur Datei wurde nicht geändert');
			setShowErrorAlert(true);
		}
	};

	const handleBoolean = (event) => {
		const { name, checked } = event.target;
		setMediaFileMetadata({
			...mediaFileMetadata,
			[name]: checked,
		});
	};

	const displayPreview = () => {
		const mediaFileType = getMediaFileType(selectedFileName);
		if (isFilePicked && mediaFileType === 'img') {
			return (
				<Box
					component={mediaFileType}
					sx={{
						maxHeight: { xs: 150, md: 150 },
						maxWidth: { xs: 150, md: 150 },
					}}
					alt=''
					src={URL.createObjectURL(selectedFile)}
				/>
			);
		} else if (isFilePicked && mediaFileType === 'video') {
			return (
				<Box
					sx={{
						maxHeight: { xs: 100, md: 100 },
						maxWidth: { xs: 150, md: 150 },
					}}>
					<VideoThumbnail
						videoUrl={URL.createObjectURL(selectedFile)}
						maxHeight
						width={150}
						height={100}
					/>
				</Box>
			);
		} else {
			return null;
		}
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	let flagShowProfilePicture =
		(getMediaFileType(selectedFileName) === 'img' ||
			mediaFileMetadata?.mediaTypeIdentifier?.includes('image')) &&
		!window.location.pathname.includes('greetings');

	return (
		<React.Fragment>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				maxWidth={'md'}>
				<DialogTitle>
					{isNewMediaFile ? 'Datei hochladen' : 'Datei bearbeiten'}
				</DialogTitle>
				<div className='new-line' />
				<DialogContent>
					<form>
						<Grid container spacing={2}>
							<Grid item xs={2.5}>
								{isNewMediaFile && (
									<React.Fragment>{displayPreview()}</React.Fragment>
								)}
								{flagShowProfilePicture && (
									<Grid>
										<FormControl variant='outlined'>
											<FormControlLabel
												control={
													<Checkbox
														checked={
															mediaFileMetadata?.isProfilePicture ? true : false
														}
														onChange={handleBoolean}
														name='isProfilePicture'
														inputProps={{
															'aria-label': 'controlled',
														}}
													/>
												}
												label='Profilbild'
											/>
										</FormControl>
										<div>
											<em>
												<p
													style={{
														fontSize: 18,
														marginTop: -10,
													}}>
													Ein etwaiges vorhandenes Profilbild wird durch
													Anklicken dieses Häkchens ersetzt.
												</p>
											</em>
										</div>
									</Grid>
								)}
							</Grid>
							<Grid item xs={4} sx={{ marginLeft: 2 }}>
								{isNewMediaFile && (
									<React.Fragment>
										<FileUploader onChange={handleSelectedFile} />
									</React.Fragment>
								)}
								<FormControl sx={{ marginTop: 3.5 }}>
									{makeDisabledTextField(
										'Name',
										'fileName',
										isNewMediaFile
											? selectedFileName
											: mediaFileMetadata.fileName
									)}
								</FormControl>
								<FormControl
									variant='outlined'
									sx={{ width: '100%', marginTop: 3.5 }}>
									<InputLabel id='demo-simple-select-helper-label'>
										Lebensabschnitt
									</InputLabel>
									{Object.entries(StagesOfLifeList).length !== 0 && (
										<Select
											variant='outlined'
											label='Lebensabschnitt'
											name='stageOfLife'
											inputProps={{
												'aria-label': 'controlled',
											}}
											onChange={handleInputChange}
											defaultValue={
												mediaFileMetadata?.stageOfLife
													? mediaFileMetadata.stageOfLife
													: ''
											}>
											<MenuItem value=''>
												<em>Unbekannt</em>
											</MenuItem>
											{StagesOfLifeList.map(makeMenuItemForName)}
										</Select>
									)}
								</FormControl>
							</Grid>
							<Grid item xs={4.5} sx={{ marginLeft: 3 }}>
								<FormControl>
									<FormLabel>Kategorie</FormLabel>
									<FormGroup>
										<Grid container columnSpacing={4} rowSpacing={0}>
											{allMediaCategories.map((item, index) => (
												<Grid item xs={6} key={index}>
													<FormControlLabel
														key={item.mediaCategoryId}
														value={item.mediaCategoryId}
														checked={isMediaCategoryTicked(
															selectedMediaCategories,
															item.mediaCategoryId
														)}
														control={<Checkbox />}
														label={item.name}
														onChange={(e) => handleSelectedMediaCategories(e)}
													/>
												</Grid>
											))}
										</Grid>
									</FormGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									multiline
									rows={2}
									label='Kommentar'
									name='comment'
									id='outlined-size-normal'
									value={mediaFileMetadata?.comment}
									onChange={handleInputChange}
									inputProps={{ maxLength: 50 }}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<Tooltip
										title='Tags bzgl. der Strukturierten Informationssammlung'
										placement='top'>
										<FormLabel>SIS Tag</FormLabel>
									</Tooltip>
									<FormGroup>
										<Grid container columnSpacing={2} rowSpacing={0}>
											{tags.filter((el) => el.tagGroupIdentifier === 'SIS').map(
												(item, index) => (
													<Grid item xs={4} key={index}>
														<FormControlLabel
															key={item.tagId}
															value={item.tagId}
															checked={isTagTicked(tagValues, item.tagId)}
															control={<Checkbox />}
															label={item.tagAbbreviation}
															onChange={getTagValue}
														/>
													</Grid>
												)
											)}
										</Grid>
									</FormGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
							<FormControl>
								<Tooltip title="Pflegerelevant?" placement="top">
								<FormLabel>Pflegerelevant?</FormLabel>
								</Tooltip>
								<FormControl>
										<RadioGroup
											row
											aria-labelledby="demo-controlled-radio-buttons-group"
											name="controlled-radio-buttons-group"
											value={mediaFileMetadata.relevant}
											onChange={handleRadioChange}
										>
									<FormControlLabel value={true} control={<Radio />} label="Ja" />
									<FormControlLabel value={false} control={<Radio />} label="Nein" />
								</RadioGroup>
								</FormControl>
							</FormControl>
							</Grid>
							<Grid item xs={12}>
								<Grid
									container
									direction='row'
									justifyContent='flex-end'
									alignItems='center'
									spacing={2}>
									<Grid item>
										<Button variant='contained' onClick={handleClose}>
											{<Close />} Abbrechen
										</Button>
									</Grid>
									<Grid item>
										<Button
											variant='contained'
											disabled={!isFilePicked}
											onClick={
												isNewMediaFile ? saveMediaFile : updateMediaMetaData
											}>
											{<Save />} Speichern
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
